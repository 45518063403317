import { AppTypes } from '../types';
import { ICoord } from '../../Interfaces/dataModel';

export type AppProp = {
  lang: string;
  coords: ICoord;
};

const initialState: AppProp = {
  lang: 'it',
  coords: { latitude: 0, longitude: 0 },
};

const AppReducer = (state = initialState, action: { type: AppTypes; payload: any }) => {
  switch (action.type) {
    case AppTypes.SET_LANG:
      // return {  lang: action.payload } ;
      return {
        ...state,
        lang: action.payload,
      };
    case AppTypes.SET_COORDS:
      return {
        ...state,
        coords: action.payload,
      };
    default:
      return state;
  }
};

export default AppReducer;
