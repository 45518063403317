import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../hooks/adsHooks';
import confirmationImage from '../images/confirmation.png';
import serverApi from '../api/serverApi';

const CheckOutResult = () => {
	const [session, setSession] = useState<any>();
	const { t } = useTranslation();
	const { auth } = useAppState();

	let hv = '120px';

	const location = useLocation();
	const { id }: any = useParams();
	const sessionId = location.search.replace('?session_id=', '');
	console.log('🔥 🔥 ', sessionId, id);

	if (auth.token !== '') {
		if (!auth.user.active) {
			hv = '170px';
		}
	}

	useEffect(() => {
		async function fetchSession() {
			setSession(await serverApi.checkOutSession(sessionId, id));
		}
		if (sessionId) {
			fetchSession();
		}
	}, [sessionId, id]);

	useEffect(() => {
		if (session) {
			if (session.payment_status !== 'paid') {
				//fixme I need to restore all selected options ad to free ad. And I need to inform a user the result payment
				// I can to modifiy the ad with a `id` came back from response server
			}
		}
	}, [session]);
	return (
		<div style={{ height: `calc(100vh - ${hv})` }} className='w-screen  bg-gray-700 -p-0'>
			<div className='bg-white h-full min-h-full flex items-center justify-center '>
				<div className=' flex flex-col align-middle '>
					<p className='text-5xl  flex  text-gray-700'>
						<img
							src={confirmationImage}
							alt='Confirmation'
							className=' mx-auto  w-20 h-20  overflow-hidden object-center  '
						/>
					</p>
					<p className='text-2xl mt-2 md:text-3xl font-light leading-normal'>
						{t('Thanks for your purchase')}
					</p>
					<p className='mb-3'>{t('Your ad has been successfully published')}</p>

					<a
						href='/'
						className=' mt-1 w-1/3   bg-black  py-1 px-2 text-white  outline-none focus:outline-none hover:bg-gray-700   '
					>
						{t('Home page')}
					</a>
				</div>
			</div>
		</div>
	);
};

export default CheckOutResult;
