import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import serverApi from '../../api/serverApi';
import { IUser } from '../../Interfaces/dataModel';
import { useDispatch } from 'react-redux';
import * as authAction from '../../rdx/actions/auth';

type userSideBarProp = {
	userProfile: IUser;
};

const UserSideBar: React.FC<userSideBarProp> = ({ userProfile }) => {
	const { t, i18n } = useTranslation();
	// console.log(`${process.env.REACT_APP_SERVER_URL_ROOT}/${userProfile?.avatar}`);
	const dispatch = useDispatch();
	const [avatar, setAvatar] = useState<string>(
		`${process.env.REACT_APP_SERVER_URL_ROOT}/${userProfile?.avatar}`
	);

	const avatarSrc = useMemo(() => {
		return (
			<img
				className='flex w-full object-fill sm:object-cover rounded-lg '
				src={`${avatar}`}
				alt='avatar'
			/>
		);
	}, [avatar]);

	const uploadAvatar = async (e: any) => {
		const fileList = e.target.files;

		const formData = new FormData();
		formData.append('avatar', fileList[0], fileList[0].name);
		try {
			const user: IUser = await serverApi.uploadAvatar(formData);
			setAvatar(`${process.env.REACT_APP_SERVER_URL_ROOT}/${user.avatar}`);
			dispatch(authAction.userDataUpdate(user));
		} catch (e) {}
	};

	return (
		<>
			{/* left side bar */}
			<div className='w-full p-0  sm:p-5 md:w-1/4'>
				<div className='flex flex-wrap'>
					<div className='mb-4 w-full'>
						{avatarSrc}

						<label className='items-center justify-center cursor-pointer h-14 mt-3 mb-3 text-gray-600 bg-gray-100 border-2 border-gray-300 border-dashed flex hover:bg-gray-50'>
							<svg
								className='w-8 h-8 text-gray-400'
								fill='currentColor'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 20 20'
							>
								<path d='M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z' />
							</svg>
							<span className='ml-2'>{'Upload image'}</span>
							<input type='file' className='hidden' onChange={uploadAvatar} />
						</label>
					</div>
					<div className='mb-4 flex-wrap px-1'>
						<div className='text-xl font-medium text-gray-800'>
							{userProfile.lastName} {userProfile.name}
						</div>
						<div className='text text-gray-600 font-light'>{userProfile.email}</div>
					</div>
				</div>

				<div className='px-1'>
					<div className='font-medium text-gray-600'>{t('Do you own a business?')}</div>
					<div className='items-center mt-2'>
						<div className='mr-1'>
							<a
								href={`/${i18n.language}/shop-registration`}
								className='flex p-0  pb-1 text-sm  leading-relaxed  border-gray-300 hover:border-gray-100  js-underline-indigo relative '
							>
								<span>{t('Register your shop with')} </span>{' '}
								<span className='font-semibold ml-1 text-indigo-800'>Faitu Shop</span>
							</a>
						</div>
						<div className='mr-1'></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UserSideBar;
