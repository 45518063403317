import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Spinner } from 'react-awesome-spinners';

import { IOrder, IOrderItem } from '../../../../Interfaces/dataModel';
import { IoCall } from 'react-icons/io5';
import { CommentMessage } from '../../../../Components/messages';
import serverApi from '../../../../api/serverApi';
import { IsoDateToUtcDate } from '../../../../utils/isoDateToUtcDate';

type orderItemProp = {
	orderItem: IOrderItem;
};

const OrderItem: React.FC<orderItemProp> = ({ orderItem }) => {
	return (
		<div className='flex mt-3'>
			<div className='flex-auto text-sm '>
				<div className='truncate text-gray-700'>{orderItem.name}</div>
				<div className='flex'>
					<div className='flex-1 text-gray-600'>
						<span className='font-medium'>{orderItem.quantity}</span> x {orderItem.price}
					</div>
					<div className='flex justify-end justify-items-end text-gray-600'>
						<NumberFormat
							value={orderItem.sum}
							thousandSeparator=''
							fixedDecimalScale={true}
							decimalScale={2}
							displayType='text'
							prefix={`${orderItem.currency} `}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

type orderDetailProp = {
	selectedOrder: IOrder;
	onOrderUpdateClick: () => void;
	onRejectOrderClick: () => void;
};

const OrderDetail: React.FC<orderDetailProp> = ({
	selectedOrder,
	onOrderUpdateClick,
	onRejectOrderClick,
}) => {
	const { t } = useTranslation();
	// const { selectedOrder }  = props;
	const [loading, setLoading] = useState<boolean>(false);
	const [preparationTime, setPreparationTime] = useState<string>('');

	const onSubmit = () => {
		setLoading(true);
		const updateStatus = async (data: any) => {
			console.log(data);
			setTimeout(async () => {
				setLoading(false);
				await serverApi.orderUpdate(selectedOrder._id, data);
				onOrderUpdateClick();
			}, 1500);
		};

		if (!selectedOrder.orderAccept) {
			if (preparationTime) {
				updateStatus({ orderAccept: true, preparationTime: preparationTime });
			} else {
				updateStatus({ orderAccept: true });
			}
		} else if (selectedOrder.driver || selectedOrder.pickUpOn) {
			const today = new Date();
			const completedAndPickUpOn = today.setUTCHours(
				IsoDateToUtcDate(today.getTimezoneOffset(), today)
			);
			updateStatus({
				orderComplete: completedAndPickUpOn,
				pickUpOn: completedAndPickUpOn,
			});
		}
	};

	const onDeleteDriverHandle = async () => {
		await serverApi.orderUpdate(selectedOrder._id, {
			driver: null,
			driverAcceptOn: null,
		});
		onOrderUpdateClick();
	};

	return (
		<div className='p-6 w-full'>
			{loading && (
				<div
					className='transition opacity-70 bg-white z-50 fixed w-1/4 p-0
                  pointer-events-none flex items-center
                  justify-center content-container'
				>
					<Spinner color='#080808' size={64} />
				</div>
			)}
			<h2 className='text-center font-semibold'>
				{t('Order')} {selectedOrder?.orderId}
			</h2>
			<h2 className='text-center font-bold'>
				{moment.utc(selectedOrder?.createdAt).format('DD-MM-YY HH:mm:ss')}
			</h2>
			{/* product list */}

			{selectedOrder?.items.map((item: IOrderItem) => (
				<OrderItem key={item._id} orderItem={item} />
			))}

			<div className='mt-5  border-t border-gray-300'>
				<div className='flex italic text-sm p-1 mt-4 text-gray-400 font-medium border border-gray-200'>
					<span className='mr-2'>{t('Comment:')}</span>
					{selectedOrder?.extraFees.comment}
				</div>

				<div className='flex text-sm pt-5'>
					<h5 className='flex-1 font-medium text-gray-400'>{t('Subtotal')}</h5>
					<h5 className='flex font-medium text-gray-400'>
						<NumberFormat
							value={selectedOrder?.total.amount}
							thousandSeparator=''
							fixedDecimalScale={true}
							decimalScale={2}
							displayType='text'
							prefix={`${selectedOrder?.total.currency} `}
						/>
					</h5>
				</div>

				<div className='flex text-sm'>
					<h5 className='flex-1 font-medium text-gray-400'>{t('Extra fees')}</h5>
					<h5 className='flex font-medium text-gray-400'>
						<NumberFormat
							value={selectedOrder?.extraFees.fees ? -selectedOrder?.extraFees.fees : 0}
							thousandSeparator=''
							fixedDecimalScale={true}
							decimalScale={2}
							displayType='text'
							prefix={`${selectedOrder?.total.currency} `}
						/>
					</h5>
				</div>

				<div className='flex text-sm mt-5'>
					<h5 className='flex-1 font-medium '>{t('Total')}</h5>
					<h5 className='flex font-medium'>
						{/* {cart.currency} {cart.deliveryCost + cart.totalAmount} */}
						<NumberFormat
							className='font-semibold'
							value={
								selectedOrder?.extraFees.fees &&
								selectedOrder?.total.amount - selectedOrder?.extraFees.fees
							}
							thousandSeparator=''
							fixedDecimalScale={true}
							decimalScale={2}
							displayType='text'
							prefix={`${selectedOrder?.total.currency} `}
						/>
					</h5>
				</div>

				{!selectedOrder.orderAccept && (
					<div className=' text-sm mt-5 w-full'>
						<label htmlFor='email' className='block text-sm leading-5 text-gray-900'>
							{t('Preparation time')}
						</label>
						<div className=' w-full '>
							<div className='relative rounded-md shadow-sm'>
								<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
									<span className='text-gray-500 sm:text-sm'>{t('min')}</span>
								</div>
								<input
									type='text'
									className=' focus:ring-black focus:border-black border-2 h-10 block w-full text-right  border-gray-200    '
									placeholder='50'
									id='preparationTimeId'
									name='preparationTime'
									value={preparationTime || ''}
									onChange={(e: any) => setPreparationTime(e.target.value)}
								/>
							</div>
							<CommentMessage>
								{t('It is used to inform the delivery man when to collect the order')}
							</CommentMessage>
						</div>
					</div>
				)}
				{!selectedOrder.pickUpOn && (
					<div className='w-full text-sm mt-5'>
						<h5 className='font-medium mb-2'>{t('Driver information')}</h5>
						{selectedOrder?.driver ? (
							<React.Fragment>
								<p>
									{selectedOrder.driver.lastName} {selectedOrder.driver.name}
								</p>
								<p className='font-semibold'>
									Accepted On: {moment(selectedOrder.driverAcceptOn).format('DD-MM-yy HH:mm:ss')}
								</p>

								<div className='flex text-sm items-center space-x-1'>
									<div className='flex flex-1 items-center text-sm'>
										<IoCall className='text-gray-500 mr-1' />
										<a href={`tel:${selectedOrder.driver.mobilePhone}`}>
											{selectedOrder.driver.mobilePhone}
										</a>{' '}
									</div>

									<div className='flex justify-end float-right'>
										<span className='inline-flex rounded-md shadow-sm '>
											<button
												onClick={onDeleteDriverHandle}
												style={{ border: '1px solid rgba(27,31,35,.15)' }}
												type='button'
												className='py-1 px-3 mt-1 text-sm flex items-center rounded-sm bg-gray-50 text-gray-800 focus:outline-none active:outline-none hover:text-black'
											>
												<span className='font-medium'>{t('Delete driver')}</span>
											</button>
										</span>
									</div>
								</div>
							</React.Fragment>
						) : (
							<p>No driver yet confirm</p>
						)}
					</div>
				)}
				{selectedOrder.pickUpOn && <p className='font-bold text-indigo-700'>{t('Takeaway')}</p>}
				<div className='mt-6 w-full'>
					{!selectedOrder.driver && !selectedOrder.orderAccept && (
						<div className='flex  items-center text-white'>
							<button
								onClick={onSubmit}
								className='w-full btn-black rounded-none font-medium uppercase'
							>
								{t('Confirm order')}
							</button>
						</div>
					)}
					{selectedOrder.driver && (
						<div className='flex  items-center text-white'>
							<button
								onClick={onSubmit}
								className='w-full btn-black rounded-none font-medium uppercase'
							>
								{t('Close Order')}
							</button>
						</div>
					)}
					{selectedOrder.pickUpOn && !selectedOrder.driver && (
						<div className='flex  items-center text-white'>
							<button
								onClick={onSubmit}
								className='w-full btn-black rounded-none font-medium uppercase'
							>
								{t('Close Order')}
							</button>
						</div>
					)}

					{!selectedOrder.driver && (
						<button
							onClick={onRejectOrderClick}
							className='w-full mt-1 btn-black bg-red-600 rounded-none font-medium hover:bg-red-500 uppercase'
						>
							{t('Reject')}
						</button>
					)}
				</div>
				<div className='flex items-center text-white'></div>
			</div>
		</div>
	);
};

export default OrderDetail;
