import { AuthTypes } from '../types';
import { IUser } from '../../Interfaces/dataModel';

export type UserStateProp = {
  token: string;
  user: IUser;
  authenticate: boolean;
  message: string;
};

const initialState: UserStateProp = {
  token: '',
  user: {
    _id: '',
    avatar: '',
    email: '',
    lastName: '',
    name: '',
    isDriver: false,
    active: false,
    mobilePhone: '',
    mobilePhoneVerified: false,
  },
  authenticate: false,
  message: '',
};

const AuthReducer = (
  state = initialState,
  action: { type: AuthTypes; user: IUser; token: string }
) => {
  switch (action.type) {
    case AuthTypes.CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
      };
    case AuthTypes.LOGIN_FAIL:
      return {
        ...state,
        token: '',
        authenticate: false,
        message: 'Incorrect email or password',
      };
    case AuthTypes.AUTHENTICATE:
      return {
        ...state,
        message: '',
        token: action.token,
        user: action.user,
        authenticate: action.token !== '',
      };
    case AuthTypes.UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };
    case AuthTypes.LOG_OUT:
      return initialState;
    default:
      return state;
  }
};

export default AuthReducer;
