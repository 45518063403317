import { FilterTypes } from '../types';
import { ISection } from '../../Interfaces/dataModel';
import { IAd } from '../../Interfaces/dataModel';

export interface Filters {
  priceRange: Array<number>;
  distance: number;
  unit: string;
  listings: IAd[];
  sideBar: IAd[];
  loading: boolean;
  page: number;
  total: number;
  section: ISection;
  title:string;
}

const initialState: Filters = {
  priceRange: [0, 0],
  distance: 100,
  unit: 'km',
  listings: [],
  sideBar: [],
  loading: false,
  // active page
  page: 1,
  // total record found on query
  total: 0,
  section: { category: undefined, subCategory: undefined },
  title:''
};

const FilterReducer = (state = initialState, action: { type: FilterTypes; payload: any }) => {
  switch (action.type) {
    case FilterTypes.SET_RESET:
      return {
        ...state,
        priceRange: [0, 0],
        distance: 100,
        unit: 'km',
        page: 1,
        title:''
      };
    case FilterTypes.SET_PRICE_RANGE:
      return {
        ...state,
        priceRange: action.payload,
      };
    case FilterTypes.SET_DISTANCE:
      return {
        ...state,
        distance: action.payload,
      };
    case FilterTypes.SET_UNIT:
      return {
        ...state,
        unit: action.payload,
      };
    case FilterTypes.SET_SIDE_BAR:
      return {
        ...state,
        sideBar:action.payload,
      };
    case FilterTypes.EXECUTE_QUERY:
      return {
        ...state,
        listings: action.payload,
        // loading: false,
      };
    case FilterTypes.SET_LOADING:
      console.log('passing here set_loading 🔥 🔥 ', action.payload ? 'true' : 'false');
      return {
        ...state,
        loading: action.payload,
      };
    case FilterTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case FilterTypes.SET_SECTION:
      return {
        ...state,
        section: action.payload,
      };
    case FilterTypes.SET_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case FilterTypes.SET_TITLE_QUERY: 
      return {
        ...state,
        title: action.payload
      }
    default:
      return state;
  }
};

export default FilterReducer;
