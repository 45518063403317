import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';
import serverApi from '../../api/serverApi';
import Loader from '../../Components/loader';
import { useTranslation } from 'react-i18next';

// test redux counter
import { useDispatch } from 'react-redux';
import * as cartActions from '../../rdx/actions/cart';

import { IShop, Item } from '../../Interfaces/shop.interface';
import ShopItem from './components/shopItem';
import { ShopHeader, TopMenuNavBar } from './components/shopNav';
import noCover from '../../images/no-cover.jpeg';
// error page not found component
import { PageNotFound } from '../../errorPage';

import { RestaurantJsonld } from '../../utils/Seo';

const ShopperPage: React.FC = () => {
	const [pickUp, setPickUp] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { shopper, distance }: any = useParams();
	const [shop, setShop] = useState<IShop>();
	const [deliveryCost, setDeliveryCost] = useState<number>(0);
	// save a copy of original delivery fees! This when user decide switch many time to take away and delivery
	const [holderDeliveryCost, setHolderDeliveryCost] = useState<number>(0);
	// showing the message when shop is close and user try adding item to cart
	const [showMsgBlockedCart, setShowMsgBlockedCart] = useState<boolean>(false);
	// if not found shop
	const [errorShop, setErrorShop] = useState<boolean>(false);

	useEffect(() => {
		if (pickUp) {
			dispatch(cartActions.addDeliveryCost(0));
			dispatch(cartActions.addPickUp(true));
			setDeliveryCost(0);
		} else {
			dispatch(cartActions.addDeliveryCost(deliveryCost));
			setDeliveryCost(holderDeliveryCost);
			dispatch(cartActions.addPickUp(false));
		}
	}, [dispatch, deliveryCost, pickUp, holderDeliveryCost]);

	useEffect(() => {
		dispatch(cartActions.addShop(shopper));
	}, [shopper, dispatch]);

	useEffect(() => {
		if (shop) {
			if (shop.delivery) {
				if (shop.deliveryFees > 0) {
					setDeliveryCost(distance * shop.deliveryFees);
				} else {
					if (distance > 3) {
						setDeliveryCost(distance * 2.15);
						setHolderDeliveryCost(distance * 2.15);
					} else {
						setDeliveryCost(8);
						setHolderDeliveryCost(8);
					}
				}
			} else {
				setPickUp(true);
			}
		}
	}, [shop, distance]);

	useEffect(() => {
		let isMounted = false;
		const fetchData = async () => {
			try {
				if (!isMounted) {
					const idata = await serverApi.shopDetail(shopper);
					console.log(idata.shop);
					if (idata.shop === undefined || idata.shop === null) {
						throw new Error('no shop found');
					}
					setTimeout(() => {
						setShop(idata.shop);
					}, 300);
				}
			} catch (e) {
				setShop(undefined);
				setErrorShop(true);
				isMounted = true;
			}
		};
		fetchData();
		return () => {
			isMounted = true;
		};
	}, [shopper]);

	const onItemClick = (product: Item) => {
		// before adding item to cart check if shop is open or close
		if (!shop?.isClosed) {
			dispatch(
				cartActions.addToCart({
					code: product.code,
					id: product._id,
					quantity: 1,
					price: product.price.amount,
					name: product.name,
					sum: product.price.amount,
					currency: product.price.currency,
					thumb: product.thumb,
				})
			);
		} else {
			setShowMsgBlockedCart(true);
			setTimeout(() => {
				setShowMsgBlockedCart(false);
			}, 3000);
		}
	};

	if (!shop) {
		if (!errorShop) {
			return <Loader />;
		} else {
			return <PageNotFound />;
		}
	}

	return (
		<>
			<RestaurantJsonld shop={shop} t={t} />
			<div className='max-w-screen-2xl mx-auto  antialiased  '>
				{showMsgBlockedCart && (
					<div className='transition opacity-90 fixed w-full bg-white z-10  h-full flex justify-center items-center'>
						<div className='text-center border-4 border-gray-400 p-6 z-40 bg-white opacity-100'>
							<h1 className='text-3xl font-medium'>{t('The shop is closed')}</h1>
							<p className='text-xl text-center'>
								{t('Check the opening hours, or try again later')}
							</p>
							<p className='text-xl text-center'>{t('He may have too many orders')}</p>
							<p className='text-xl text-center font-medium'>{t('Try later')}</p>
						</div>
					</div>
				)}
				<div className='justify-center '>
					<ShopHeader
						name={shop.name}
						logo={shop?.logo}
						coverThumb={shop?.coverThumb ? shop?.coverThumb : noCover}
						location={shop?.location}
						isClosed={shop?.isClosed}
					/>
					<TopMenuNavBar menu={shop?.menu} />
					{/* PickUp / delivery Option only mobile */}
					{shop.delivery && shop.takeAway && (
						<div className='block sm:hidden'>
							<div className='inline-flex w-full font-medium  p-1  border-gray-200 border transition duration-200 ease-in'>
								<button
									type='button'
									onClick={() => setPickUp(true)}
									className={`text-sm py-1 px-4 ${
										pickUp ? 'bg-gray-100 font-semibold' : ''
									}  rounded-md text-black  focus:outline-none transition duration-300 ease-linear`}
								>
									{t('Takeaway')}
								</button>
								<button
									type='button'
									onClick={() => setPickUp(false)}
									className={`text-sm py-1 px-4 ${
										!pickUp ? 'bg-gray-100 font-semibold' : ''
									}  rounded-md text-black  focus:outline-none transition duration-500 ease-out `}
								>
									{t('Delivery')}
								</button>
							</div>
						</div>
					)}

					<div className='flex'>
						<div className='flex flex-col w-full md:w-8/12  lg:w-9/12'>
							{shop?.menu?.categories.map((cat: any, index: number) => {
								return (
									<section id={cat.name} key={index}>
										<div className='mt-5 px-2 '>
											<div className='flex'>
												<h1 className='flex w-full items-start text-2xl font-bold black'>
													{cat.name}
												</h1>
											</div>
											<ShopItem items={cat.items} onItemClick={onItemClick} />
										</div>
									</section>
								);
							})}
						</div>
						<div className='hidden w-full md:block md:w-4/12 lg:w-3/12 flex-col mt-5 px-2'>
							{shop.delivery && shop.takeAway && (
								<div className='inline-flex w-full font-medium  p-1 rounded-md border-gray-200 border transition duration-200 ease-in'>
									<button
										type='button'
										onClick={() => setPickUp(true)}
										className={`text-sm py-1 px-4  ${
											pickUp ? 'bg-gray-100 font-semibold' : ''
										}  rounded-md text-black  focus:outline-none transition duration-300 ease-linear`}
									>
										{t('Takeway')}
									</button>
									<button
										type='button'
										onClick={() => setPickUp(false)}
										className={`text-sm py-1 px-4 ${
											!pickUp ? 'bg-gray-100 font-semibold' : ''
										}  rounded-md text-black  focus:outline-none transition duration-500 ease-out `}
									>
										{t('Delivery')}
									</button>
								</div>
							)}

							<div className='rounded-md border  shadow-sm p-3 mt-4'>
								<h4 className='font-semibold  leading-relaxed'>{t('Timetables')}</h4>
								<ul className='text-sm mt-3'>
									{shop?.workingTime?.map((day: any, index: number) => (
										<li key={index} className='mb-1'>
											<div className='w-full flex justify-between items-center  px-2'>
												<div className='space-x-1'>{t(day.day)}</div>
												<div className='flex items-center space-x-2'>
													{day.openHours[0]?.from} {day.openHours[0]?.to}
												</div>
											</div>
											{day.openHours[1] && (
												<div className=' flex justify-end px-2'>
													{day.openHours[1].from} {day.openHours[1].to}
												</div>
											)}
											{day.openHours[2] && (
												<div className=' flex justify-end px-2'>
													{day.openHours[2].from} {day.openHours[2].to}
												</div>
											)}
										</li>
									))}
								</ul>
							</div>
							{!shop.delivery && shop.takeAway && (
								<div className='rounded-md border shadow-sm p-3 mt-2'>
									<div className='flex justify-between items-center font-medium  '>
										<h3>{t('Take away shop only')} </h3>
									</div>
								</div>
							)}
							{shop.delivery && (
								<div className='rounded-md border shadow-sm p-3 mt-2'>
									{shop.deliveryFees > 0 ? (
										<React.Fragment>
											<div className='flex justify-between items-center  '>
												<h3>{`${t('Costi di consegna')}`} </h3>
												<span className='text-sm'> {distance} Km</span>
												<NumberFormat
													className='font-semibold'
													value={distance * shop.deliveryFees}
													thousandSeparator=''
													fixedDecimalScale={true}
													decimalScale={2}
													displayType='text'
													prefix='$'
												/>
											</div>
											<p className='mt-1 text-sm font-medium'>
												{t('The shop delivers with its own couriers')}
											</p>
										</React.Fragment>
									) : distance > 3 ? (
										<div className='flex justify-between items-center  '>
											<h3>Costi di consegna </h3>
											<span className='text-sm'> {distance} Km</span>
											<NumberFormat
												className='font-semibold'
												value={distance * 2.15}
												thousandSeparator=''
												fixedDecimalScale={true}
												decimalScale={2}
												displayType='text'
												prefix='$'
											/>
										</div>
									) : (
										<div className='flex justify-between items-center  '>
											<h3>{t('Delivery costs')} </h3>
											<span className='text-sm'> {distance} Km</span>
											<NumberFormat
												className='font-semibold'
												value={deliveryCost}
												thousandSeparator=''
												fixedDecimalScale={true}
												decimalScale={2}
												displayType='text'
												prefix='$'
											/>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ShopperPage;
