export const customStyles = {
  option: (styles: any, state: any) => ({
    ...styles,
    color: state.isSelected ? 'black' : styles.color,
    backgroundColor: state.isSelected ? 'white' : styles.color,
    '&:hover': {
      color: '#FFF',
      backgroundColor: 'black',
    },
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    fontSize: '16px',
    borderRadius: 0,
    // background: '#F3F4F6',
    outline: 0,

    boxShadow: 'none', // state.isFocused ? 0 : 0, //'0 0 0 0.2rem rgba(120, 194, 173, 0.25)' : 0,
    borderColor: state.isFocused ? 'black' : 'E5E7EB',
    borderWidth: state.isFocused ? 2 : 2,
    '&:hover': {
      borderColor: state.isFocused ? 'E5E7EB' : 'E5E7EB',
      borderWidth: state.isFocused ? 2 : 2,
    },
    '&:focus': {
      outline: 'none !important',
      borderWidth: 2,
    },
  }),
};
