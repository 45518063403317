import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import Loader from '../Components/loader';
import serverApi from '../api/serverApi';
import { IAd } from '../Interfaces/dataModel';
import ClassFiedItem from '../UI/classFied/classFiedItem';

const UserClassFieds: React.FC = () => {
	const { id } = useParams<any>();
	const [listing, setListing] = useState<IAd[]>([]);
	const [user, setUser] = useState<any>();

	useEffect(() => {
		let mounted = true;
		const fetch = async () => {
			if (mounted) {
				const SrvData = await serverApi.classFiedByUserId(id);
				if (SrvData.status === 'success') {
					setListing(SrvData.ad);
					setUser(SrvData.user);
				}
			}
		};
		fetch();
		console.log(id);
		return () => {
			mounted = false;
		};
	}, [id]);

	if (!listing) {
		return <Loader />;
	}
	return (
		<div className='max-w-screen-2xl mx-auto px-0 sm:px-6 mt-10 '>
			<div className=''>
				<div className='py-auto'>
					<h1 className='text-xl px-2 mb-5 sm:mb-10'>
						Lista annunci di{' '}
						<span className='font-bold'>
							{user?.lastName} {user?.name}
						</span>
					</h1>
				</div>
				<div className='w-full flex flex-wrap sm:w-full  lg:w-7/12 px-2'>
					{listing.length > 0 &&
						listing.map((item: IAd, index: number) => <ClassFiedItem key={index} item={item} />)}
				</div>
			</div>
		</div>
	);
};

export default UserClassFieds;
