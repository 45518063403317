import React, { useState } from 'react';
import { IDrawerProps } from '../../Interfaces/UI';
import RegistrationDrawer from './RegistrationDrawer';
import { Transition } from '@headlessui/react';
import { CgClose } from 'react-icons/cg';

import { useTranslation } from 'react-i18next';

// redux stuff to set reducer
import { useDispatch } from 'react-redux';
import * as authAction from '../../rdx/actions/auth';

import { useForm } from 'react-hook-form';

const LoginDrawer: React.FC<IDrawerProps> = ({ isOpen, closeHandle }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [openDrawerRegistration, setOpenDrawerRegistration] = useState<boolean>(false);

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		criteriaMode: 'all',
	});

	const onRegistrationDrawerClick = () => {
		setOpenDrawerRegistration(true);
		closeHandle();
	};
	const onCloseRegistration = () => {
		setOpenDrawerRegistration(false);
	};

	const onLogin = async (data: any) => {
		// const email = 'ken.saadi@yahoo.com';
		// const password = 'Swatch001';
		const { email, password } = data;
		dispatch(authAction.login(email, password));
		reset({});
		// prevent key enter
		if (isOpen) {
			closeHandle();
		}
	};
	return (
		<>
			<RegistrationDrawer isOpen={openDrawerRegistration} closeHandle={onCloseRegistration} />

			<div className={`${isOpen ? 'block' : 'hidden'} fixed inset-0 overflow-auto z-50`}>
				<div className='absolute inset-0 overflow-y-auto overflow-x-hidden z-50'>
					<Transition
						show={isOpen}
						enter='transform transition ease-in-out'
						enterFrom='translate-x-full'
						enterTo='translate-x-0'
						leave='transform transition ease-in-out '
						leaveFrom='translate-x-0'
						leaveTo='translate-x-full'
					>
						<section className='absolute inset-y-0 right-0 max-w-xs sm:max-w-md z-50'>
							<div className='bg-white shadow-xl h-full'>
								<form onSubmit={handleSubmit(onLogin)}>
									<header className='space-y-1 py-6 px-4 bg-indigo-700'>
										<div className='flex items-center justify-between'>
											<h2 className='text-xl leading-7 font-medium text-white'>{t('Log in')}</h2>
											<button
												onClick={closeHandle}
												aria-label='Close panel'
												className='text-indigo-200 outline-none hover:text-white focus:outline-none active:outline-none'
											>
												<CgClose size={'1.4em'} />
											</button>
										</div>
										<div>
											<p className='text-md sm:text-base md:text-md lg:text-lg  text-indigo-300'>
												{t('Log in to faitu to reply to ads or create new ones')}
											</p>
										</div>
									</header>
									<div className=''>
										<div className='px-4 '>
											<div className='space-y-6 pt-6 pb-5'>
												<div className='space-y-1'>
													<label
														htmlFor='email'
														className='block text-base font-medium leading-5 text-gray-900'
													>
														{t('Email address')}
													</label>
													<div className='relative shadow-sm'>
														<input
															{...register('email', {
																required: `${t('Email address required')}`,
																pattern: {
																	value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
																	message: `${t('invalid email address')}`,
																},
															})}
															type='email'
															name='email'
															id='emailId'
															autoComplete='off'
															className=' focus:ring-black focus:border-black block w-full  border-gray-300 '
														/>
														{errors.email && (
															<span className='text-red-500'>{errors.email.message}</span>
														)}
													</div>
												</div>

												<div className='space-y-1'>
													<label
														htmlFor='password'
														className='block text-base font-medium leading-5 text-gray-900'
													>
														{t('Password')}
													</label>
													<div className='relative shadow-sm'>
														<input
															{...register('password', {
																required: `${t('You must specify a password')}`,
																minLength: {
																	value: 6,
																	message: `${t('Password must have at least 6 characters')}`,
																},
															})}
															onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
																if (e.key === 'Enter') {
																	handleSubmit(onLogin);
																}
															}}
															type='password'
															name='password'
															id='passwordId'
															autoComplete='off'
															className=' focus:ring-black focus:border-black block w-full  border-gray-300 '
														/>
														{errors.password && (
															<span className='text-red-500'>{errors.password.message}</span>
														)}
													</div>
												</div>

												<div className='flex justify-end space-y-2 space-x-3  '>
													<span className='font-medium leading-5 text-gray-900  '>
														<a className='hover:underline' href='/'>
															{t('Forgot password?')}
														</a>
													</span>
												</div>
											</div>

											<button
												type='submit'
												className='py-2 w-full px-3 bg-black text-white hover:bg-gray-700 focus:outline-none transition duration-300 font-semibold'
											>
												{t('Log in')}
											</button>
											<div className='flex justify-center space-y-2 space-x-3 mt-5  '>
												<span className='font-normal leading-5 text-gray-900  '>
													{t("Don't have an account yet?")}{' '}
													<button
														onClick={onRegistrationDrawerClick}
														className='hover:underline text-indigo-800'
													>
														{t('Sign in')}.
													</button>
												</span>
											</div>
										</div>
									</div>
								</form>
							</div>
						</section>
					</Transition>
				</div>
			</div>
		</>
	);
};

export default LoginDrawer;
