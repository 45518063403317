import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import popcorn from '../../../../styles/effect/popcorn.mp3';
import useSound from 'use-sound';
import ReactTooltip from 'react-tooltip';
import { IoCall } from 'react-icons/io5';
import { IOrder } from '../../../../Interfaces/dataModel';

type orderProps = {
	order: IOrder;
	onClick: (orderItem: IOrder) => void;
};

const Order: React.FC<orderProps> = ({ order, onClick }) => {
	const { t } = useTranslation();
	const [play, { stop }] = useSound(popcorn, { volume: 0.9 });
	const [bgColor, setBgColor] = useState<string>('');
	const [isDriverAccepted, setIsDriverAccepted] = useState<boolean>(false);

	useEffect(() => {
		if (order.driver) {
			setIsDriverAccepted(true);
		}
		const today = new Date();
		// I need to create a new object date from order.updateAt
		const updateDate = new Date(order.updatedAt);
		// Check if date of orders is greater than today
		if (updateDate.getUTCDate() < today.getUTCDate()) {
			setBgColor('bg-gray-200');
		}
		return () => {};
	}, [order.driver, order.updatedAt]);

	useEffect(() => {
		let mounted = true;
		if (!order.orderAccept || isDriverAccepted) {
			const interval = setInterval(() => {
				if (!mounted) {
					stop();
					clearInterval(interval);
				}
				if (!isDriverAccepted && order.orderAccept) {
					console.log('clear interval');
					stop();
					clearInterval(interval);
				}
				play();
				setBgColor('bg-yellow-100');
				setTimeout(() => {
					setBgColor('');
				}, 2000);
			}, 5000);
			return () => clearInterval(interval);
		}
		return () => {
			mounted = false;
		};
	}, [order, play, isDriverAccepted, stop]);

	return (
		<div className={`flex text-left p-0  border ${bgColor}`}>
			<ReactTooltip place='top' type='dark' effect='float' />
			<div className='flex w-full '>
				<div className='w-full flex flex-col  '>
					<div className='pb-0'>
						<div className='flex  p-1'>
							<h3 className='flex-1 font-semibold  leading-3'>{order.orderId}</h3>
							<span className='flex text-sm justify-end leading-3'>
								{moment.utc(order.createdAt).format('DD-MM-YY HH:mm')}
							</span>
						</div>
						<div className='mt-1 p-1'>
							<div className='flex'>
								<div className='w-1/2'>
									<p className='text-sm'>
										{order.client.lastName} {order.client.name}
									</p>
									<p className='text-sm'>{order.delivery.address.street}</p>
									<p className='text-sm'>
										{order.delivery.address.zipCode} {order.delivery.address.city}
									</p>
									<p className='text-sm inline-flex items-center space-x-1 mt-2'>
										<IoCall className='text-gray-500' />
										<a href={`tel:${order.client.mobilePhone}`}>{order.client.mobilePhone}</a>{' '}
									</p>
									{order.pickUpOn && (
										<div>
											<p className='font-bold'>{t('Takeaway')}</p>
											<p className='font-medium text-indigo-700 text-sm'>
												{moment.utc(order.pickUpOn).format('DD-MM-YY HH:mm')}
											</p>
										</div>
									)}
								</div>

								<div className='w-1/2'>
									<p className='text-sm text-right w-full pr-2'>
										<NumberFormat
											value={order.total.amount}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
											prefix={`${order.total.currency} `}
										/>
									</p>
									<p className='text-sm text-right w-full pr-2'>
										<a href='#tooltip' data-tip={t('Fixed fees for driver discount')}>
											{' '}
											<i className='fas fa-info-circle ml-1'></i>{' '}
										</a>

										<NumberFormat
											value={-order.extraFees.fees}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
											prefix={`${order.total.currency} `}
										/>
									</p>
									<p className='text-sm text-right w-full pr-2 font-semibold'>
										<NumberFormat
											value={order.total.amount - order.extraFees.fees}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
											prefix={`${order.total.currency} `}
										/>
									</p>

									{/* <p className='text-red-800 bg-indigo-300 rounded-md pr-1 font-bold uppercase text-right mt-2 text-sm'>
										{order.orderAccept && !order.driverAcceptOn && (
											<span>{t('Waiting Confirmation')}</span>
										)}
										{!order.orderAccept && <span>{t('Request')}</span>}
										{order.orderAccept && order.driverAcceptOn && (
											<span>{t('Ready for delivery')}</span>
										)}
									</p> */}
								</div>
							</div>
						</div>
						<div className='flex items-center text-white'>
							<button
								onClick={() => {
									if (order.driver) {
										console.log('passing from detail button event');
										setIsDriverAccepted(false);
										// stop();
									}
									// stop();
									onClick(order);
								}}
								className='w-full btn-black rounded-none font-medium uppercase'
							>
								{/* {t('Detail')} */}
								{order.orderAccept && !order.driverAcceptOn && (
									<span>{t('Waiting Confirmation')}</span>
								)}
								{!order.orderAccept && <span>{t('Request')}</span>}
								{order.orderAccept && order.driverAcceptOn && (
									<span>{t('Ready for delivery')}</span>
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Order;
