import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import UserContent from '../../UI/User/user-content';
import UserSideBar from '../../UI/User/user-sidebar';
import serverApi from '../../api/serverApi';
import { IUser } from '../../Interfaces/dataModel';
import { useSelector } from 'react-redux';
import { AppState } from '../../rdx/rootReducer';
import { useTranslation } from 'react-i18next';
import { useLocation, Redirect } from 'react-router-dom';

interface IUserlocationState {
	activeTab: number;
}

const UserProfile: React.FC = () => {
	const { t } = useTranslation();
	const { state } = useLocation<IUserlocationState>();
	console.log(state);
	const [userProfile, setUserProfile] = useState<IUser>();
	const { user, token } = useSelector((state: AppState) => state.auth);

	useEffect(() => {
		const fetch = async () => {
			try {
				const me: IUser = await serverApi.me();
				// note: Try to error handling when grab user data for exception
				// if (me.code! >= 400) {
				//   throw new Error(me.message);
				// }
				setUserProfile(me);
			} catch (err) {
				toast.error(err.message);
			}
		};
		if (token) {
			fetch();
		}
		return () => {};
	}, [token]);

	/** Update user when children tree user rdx updated it */
	useEffect(() => {
		setUserProfile(user);
		return () => {};
	}, [user]);

	if (!token) {
		return (
			<Redirect
				to={{
					pathname: '/error',
					state: {
						status: 401,
						title: `${t('Unauthorized access')}`,
						message: `${t('You must be logged in to access profile management')}`,
					},
				}}
			/>
		);
	}
	return (
		<div className='w-full'>
			<div className='relative max-w-screen-xl mx-auto px-1 sm:px-6 py-10'>
				<div>
					<div className='relative flex flex-wrap pb-10'>
						{userProfile ? (
							<>
								<UserSideBar userProfile={userProfile} />

								<UserContent userProfile={userProfile} tab={state ? state.activeTab : 0} />
							</>
						) : (
							<div />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserProfile;
