import React, { useEffect, useState } from 'react';

import { IShop } from '../../../Interfaces/shop.interface';
import serverApi from '../../../api/serverApi';
import { useTranslation } from 'react-i18next';

import { Redirect } from 'react-router-dom';

import { useAppState } from '../../../hooks/adsHooks';
import { useForm, useStep } from 'react-hooks-helper';

import Loader from '../../../Components/loader';

import ShopHeaderNav from './sections/ShopHeaderNav';
import ShopInformation from './sections/ShopInformation';

import ProductShopManager from './sections/ProductShopManager';

const steps = [{ id: 'information' }, { id: 'product-manager' }];

type ShopDispatchProps = {
	shop: IShop;
	branches: any;
	sections: any;
};

const ShopManagerDispatch: React.FC<ShopDispatchProps> = ({ shop, branches, sections }) => {
	const { t } = useTranslation();
	const { auth } = useAppState();
	const { step, navigation } = useStep({ initialStep: 0, steps });
	const { id }: any = step;

	const [formData, setForm] = useForm<IShop | any>(shop);

	const props = { formData, setForm, navigation, branches, sections };

	if (!auth.token) {
		return (
			<Redirect
				to={{
					pathname: '/error',
					state: {
						status: 401,
						title: `${t('Unauthorized access')}`,
						message: `${t('To access the shop management you must be registered')}`,
					},
				}}
			/>
		);
	}

	switch (id) {
		case 'information':
			return <ShopHeaderNav {...props} children={<ShopInformation {...props} />} />;
		case 'product-manager':
			return <ShopHeaderNav {...props} children={<ProductShopManager {...props} />} />;
		default:
			return <ShopHeaderNav {...props} children={<ShopInformation {...props} />} />;
	}
};

const ShopManager: React.FC = () => {
	const { t } = useTranslation();
	const { auth } = useAppState();
	const [shopData, setShopData] = useState<IShop>();
	const [branches, setBranches] = useState<any>();
	const [sections, setSections] = useState<any>();
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		async function fetch() {
			const data = await serverApi.shopMe();
			console.log(data, '🔥 🔥 🔥 🔥 ');
			if (data.status === 'success') {
				setShopData(data.shop);
				setBranches(data?.branches);
				let sectionTranslated = [];
				sectionTranslated = data?.sections?.map((section: any) => {
					return { _id: section._id, name: t(section.name) };
				});

				setSections(sectionTranslated);
				setLoading(false);
			}
		}
		if (auth.token) {
			fetch();
		}
		return () => {};
	}, [auth, t]);

	if (!auth.token) {
		return <h1>error</h1>;
	}

	if (!loading && shopData) {
		return <ShopManagerDispatch shop={shopData} branches={branches} sections={sections} />;
	} else return <Loader />;
};

export default ShopManager;
