import React from 'react';
import { ImFacebook2, ImInstagram } from 'react-icons/im';
import { useAppState } from '../hooks/adsHooks';
import { useTranslation } from 'react-i18next';

const FooterContent = () => {
	const { t } = useTranslation();
	const { lang } = useAppState().app;
	return (
		<footer className='w-full'>
			<div className='hidden md:block mx-auto max-w-screen-2xl p-3 px-2'>
				<div className='flex space-x-5 border-t p-1 '>
					<div className='flex'>
						<a
							href={`/${lang}/terms`}
							className='border-gray-300 font-medium text-sm leading-6 hover:border-gray-100  underline-effect relative'
						>
							{t('Terms & conditions')}
						</a>
					</div>
					<div className='flex'>
						<a
							href={`/${lang}/faqs`}
							className='border-gray-300 font-medium text-sm leading-6 hover:border-gray-100  underline-effect relative'
						>
							{t('Support')}
						</a>
					</div>

					<div className='flex'>
						<a
							href={`/${lang}/ads`}
							className='border-gray-300 font-medium text-sm leading-6   hover:border-gray-100  underline-effect relative'
						>
							{t('Add ad')}
						</a>
					</div>
					<div className='flex-1'>
						<a
							href={`/${lang}/advertising`}
							className='border-gray-300 font-medium text-sm leading-6   hover:border-gray-100  underline-effect relative'
						>
							{t('Advertising')}
						</a>
					</div>
					<div className='flex justify-end space-x-2 '>
						<div className='flex'>
							<a
								href='https://www.facebook.com/Faitu-2312503232161017'
								className='border-gray-300 text-blue-600  pb-1 hover:text-gray-600 '
							>
								<ImFacebook2 size={20} />
							</a>
						</div>
						<div className='flex'>
							<a
								href='https://www.instagram.com/faituannunci/'
								className='border-gray-300 text-red-600  pb-1 hover:text-gray-600 '
							>
								<ImInstagram size={20} />
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className='block md:hidden mx-auto max-screen-md p3 px-2'>
				<div className='flex space-x-4 border-t p-1'>
					<div className='flex'>
						<a
							href={`/${lang}/terms`}
							className='border-gray-300 font-medium text-sm leading-6 hover:border-gray-100  underline-effect relative'
						>
							{t('Terms & conditions')}
						</a>
					</div>

					<div className='flex'>
						<a
							href={`/${lang}/faqs`}
							className='border-gray-300 font-medium text-sm leading-6 hover:border-gray-100  underline-effect relative'
						>
							{t('Support')}
						</a>
					</div>

					<div className='flex-1'>
						<a
							href={`/${lang}/ads`}
							className='border-gray-300 font-medium text-sm leading-6   hover:border-gray-100  underline-effect relative'
						>
							{t('Add ad')}
						</a>
					</div>

					<div className='hidden lg:flex justify-end space-x-2 '>
						<div className='flex'>
							<a
								href='https://www.facebook.com/Faitu-2312503232161017'
								className='border-gray-300 text-blue-600  pb-1 hover:text-gray-600'
							>
								<ImFacebook2 size={20} />
							</a>
						</div>
						<div className='flex'>
							<a
								href='https://www.instagram.com/faituannunci/'
								className='border-gray-300 text-red-600  pb-1 hover:text-gray-600'
							>
								<ImInstagram size={20} />
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterContent;
