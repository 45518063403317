import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const detectionOptions = {
  order: ['path', 'cookie', 'navigator', 'localStorage', 'htmlTag'],
  lookupFromPathIndex: 0,
  lookupCookie: 'lang',
};

i18n
  // load  translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'it',
    debug: false,

    interpolation: {
      escapeValue: false,
    },
    detection:detectionOptions
  });

// i18n.use(Backend).use(initReactI18next);
// i18n.use(LanguageDetector).init({
//   // preload: ['en', 'it','de','fr'],
//   debug: true,
//   fallbackLng: 'it',
//   detection: detectionOptions,
//   returnObjects: true,
//   saveMissing:true,
//   interpolation: { escapeValue: false },
//   react: { wait: true, useSuspense: true },
// });

export default i18n;

// yarn add i18next i18next-browser-languagedetector i18next-http-backend react-i18next
