import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast, Flip } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import Loader from '../../Components/loader';
import NumberFormat from 'react-number-format';

import { Transition } from '@headlessui/react';
import { IDeliveryAddress, ICartItem } from '../../Interfaces/dataModel';
import { IShop } from '../../Interfaces/shop.interface';

import { useDispatch } from 'react-redux';
import * as cartActions from '../../rdx/actions/cart';

import { useAppState } from '../../hooks/adsHooks';
import serverApi from '../../api/serverApi';
import addressToCoords, { CalculationDistance } from '../../utils/addressToCoord';
import { useForm } from 'react-hook-form';

import { IsoDateToUtcDate } from '../../utils/isoDateToUtcDate';

/** just for test it */
// import { io } from 'socket.io-client';
// import Sfx from '../../styles/effect/beepSfx.mp3';
// import useSound from 'use-sound';
// const [play, { stop }] = useSound(Sfx, { volume: 0.9 });

//note: https://shop-redq.vercel.app/checkout
//note: https://github.com/fgerschau/web-push-notification-example

const OrderCheckOut: React.FC = () => {
	const history = useHistory();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [shop, setShop] = useState<IShop>();
	const [insertMode, setInsertMode] = useState<boolean>(false);
	const [address, setAddress] = useState<IDeliveryAddress[]>([]);
	const [selectedAddress, setSelectedAddress] = useState<IDeliveryAddress>();
	const [distance, setDistance] = useState<any>();
	const [pickUp, setPickUp] = useState<Date>();
	const [loading, setLoading] = useState<boolean>(true);
	const [disableSubmitOrder, setDisableSubmitOrder] = useState<boolean>(true);

	const { cart, auth } = useAppState();

	const {
		register,
		// formState: { errors },
		reset,
		handleSubmit,
	} = useForm({ mode: 'onBlur', reValidateMode: 'onChange', criteriaMode: 'all' });

	useEffect(() => {
		const fetchDataUser = async () => {
			const _user = await serverApi.me();
			setAddress(_user?.deliveryAddress || []);
			const _shop = await serverApi.shopDetail(cart.shopId);
			if (_shop) {
				setShop(_shop.shop);
			}
			setLoading(false);
		};

		fetchDataUser();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (cart.pickUp) {
			if (pickUp) {
				// change the difference ISODate to UTCDate with timezoneOffset minutes
				// if (pickUp.getTimezoneOffset() < 0) {
				//   pickUp.setUTCHours(pickUp.getUTCHours() + Math.abs(pickUp.getTimezoneOffset()) / 60);
				// } else {
				//   pickUp.setUTCHours(pickUp.getUTCHours() - Math.abs(pickUp.getTimezoneOffset()) / 60);
				// }
				pickUp.setUTCHours(IsoDateToUtcDate(pickUp.getTimezoneOffset(), pickUp));

				setDisableSubmitOrder(false);
			}
		} else if (selectedAddress) {
			setDisableSubmitOrder(false);
		} else {
			setDisableSubmitOrder(true);
		}
	}, [cart.pickUp, pickUp, selectedAddress]);

	const addNewHandleSubmit = async (data: any) => {
		const completeAddress = data.zipCode + '+' + data.street + ',' + data.city;
		const coords = await addressToCoords(completeAddress);
		if (coords.lat > 0) {
			data = {
				address: data,
				location: { coordinates: [coords.lat, coords.lng] },
			};
		}

		setAddress([...address, data]);
		const addr = [...address, data];
		console.log(addr);
		await serverApi.updateMe({ deliveryAddress: addr });
		reset({});
	};

	const removeAddress = async (deleteAddress: IDeliveryAddress) => {
		const _address: IDeliveryAddress[] | [] =
			address.filter(
				(addr: IDeliveryAddress) => addr.address.title !== deleteAddress.address.title
			) || [];
		await serverApi.updateMe({ deliveryAddress: _address });
		setAddress(_address);
	};

	const editAddress = (editAddress: IDeliveryAddress) => {
		const index = address.findIndex(
			(addr: IDeliveryAddress) => addr.address.title === editAddress.address.title
		);
		if (index > -1) {
			reset({
				title: address[index].address.title,
				street: address[index].address.street,
				zipCode: address[index].address.zipCode,
				city: address[index].address.city,
			});
			setInsertMode(true);
		}
	};
	const onSelectDeliveryAddress = async (selected: IDeliveryAddress) => {
		console.log(selected);

		const origin = {
			lat: shop!.location.coordinates[0],
			lng: shop!.location.coordinates[1],
		};
		const destination = {
			lat: selected.location.coordinates[0],
			lng: selected.location.coordinates[1],
		};
		// const _distance: any = await DistanceCalculation(origin, destination);
		// console.log('🔥 🔥 ', _mycalc);
		// console.log(_distance);
		// console.log(origin, destination);
		const _distance: any = CalculationDistance(origin, destination);
		console.log(_distance);
		setSelectedAddress(selected);
		if (_distance.meters > 3000) {
			dispatch(cartActions.addDeliveryCost(Math.round(_distance.meters / 1000) * 2.15));
		} else {
			dispatch(cartActions.addDeliveryCost(8));
		}
		setDistance(_distance);
	};

	const pickUpScheduleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPickUp(new Date(e.target.value));
	};

	const sendOrderRequest = async () => {
		console.log('Confirmation order request and sending to server');
		const orderRequest = {
			cart,
			pickUpOn: cart.pickUp ? pickUp?.toString() : null,
			delivery: !cart.pickUp ? selectedAddress : null,
			distance: { km: !cart.pickUp ? Math.round(distance.meters / 1000) : 0 },
			// date: new Date(),
			shop: cart.shopId,
			items: cart.products,
			total: {
				currency: cart.currency,
				amount: cart.totalAmount,
			},
			extraFees: {
				comment: cart.comment,
				fees: 4,
				deliveryFees: cart.deliveryCost,
			},
		};
		console.log(orderRequest);
		console.log(JSON.stringify(orderRequest));

		const OrderRs = await serverApi.ClientOrderRequest(orderRequest);
		if (OrderRs.status === 'success') {
			toast.dark(`${t('Thank you for your order')} ${OrderRs.orderRequest.orderId}`, {
				transition: Flip,
			});
			dispatch(cartActions.clearCart());
			setTimeout(() => {
				history.push('/');
			}, 2000);
		}
	};

	// /** Socket io test */
	// useEffect(() => {
	// 	console.log('🔥 passing SOCKET.IO disabled');
	// 	// const socket = io(`${process.env.REACT_APP_SERVER_URL_ROOT}/log`);
	// 	// socket.on('test', (data: any) => {
	// 	//   console.log(data);
	// 	// });
	// }, []);

	/** THIS IS USED FOR SUBSCRIBE WEB-PUSH NOTIFICATION. CAN'T USED AT THIS TIME */

	/** Starting to subscribe notification service */

	// const publicVapidKey =
	// 	'BOiB0qV-WkyH9AcYVbwpX1s8BjHgD11ijIBgxItNvOo3kFg-8Xoev1bCEPopaNS-3zGb1oKoxMCY9xZ7o6IDsRo';

	// const urlBase64ToUint8Array = (base64String: string) => {
	// 	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	// 	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

	// 	const rawData = window.atob(base64);
	// 	const outputArray = new Uint8Array(rawData.length);

	// 	for (let i = 0; i < rawData.length; ++i) {
	// 		outputArray[i] = rawData.charCodeAt(i);
	// 	}
	// 	return outputArray;
	// };

	// useEffect(() => {
	// 	// https://github.com/seladir/demo-webpush-react/blob/master/src/serviceWorker.js
	// 	const subscribe = async () => {
	// 		if (!('serviceWorker' in navigator)) return;
	// 		navigator.serviceWorker.register('/sw.js', {
	// 			scope: '/',
	// 		});

	// 		const registration = await navigator.serviceWorker.ready;
	// 		let subscription = await registration.pushManager.getSubscription();
	// 		if (subscription) return;
	// 		subscription = await registration.pushManager.subscribe({
	// 			userVisibleOnly: true,
	// 			applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
	// 		});

	// 		console.log(subscription);
	// 		const response = await fetch(`${process.env.REACT_APP_API_URL}/push`, {
	// 			method: 'POST',
	// 			body: JSON.stringify(subscription),
	// 			headers: { 'content-type': 'application/json' },
	// 		});

	// 		console.log(response);
	// 	};
	// 	subscribe();
	// }, []);

	/** broadcast test for send notification request Normally this cal after socket sync or by server midleware post(save) */
	// const boradcast = async () => {
	// 	await fetch(`${process.env.REACT_APP_API_URL}/push/test`, {
	// 		method: 'GET',
	// 		headers: {
	// 			'content-type': 'application/json',
	// 		},
	// 	});
	// };

	if (!auth.token) {
		return (
			<Redirect
				to={{
					pathname: '/error',
					state: {
						status: 401,
						title: `${t('Access denied')}`,
						message: `${t('Activation error')}`,
					},
				}}
			/>
		);
	}

	if (loading) {
		return <Loader />;
	}

	return (
		<div className='max-w-screen-xl mx-auto  antialiased py-10 px-4 '>
			<div className='flex flex-wrap'>
				<div className='flex  w-full md:w-8/12'>
					<div className='p-4 w-full'>
						{cart.pickUp ? (
							<div className='shadow-md bg-white p-4'>
								<div className='flex items-center'>
									<h1 className='flex-1 font-medium text-xl'>{t('Pick Up Schedule')}</h1>
								</div>
								<div className='mt-4'>
									<label
										htmlFor='email'
										className='block text-base font-medium leading-5 text-gray-900'
									>
										{t('Preferred date time pick your articles')}
									</label>

									<input
										onChange={pickUpScheduleChange}
										type='datetime-local'
										name='email'
										id='emailId'
										autoComplete='off'
										className=' focus:ring-black focus:border-black block w-full  border-gray-300 mt-1 '
									/>
								</div>
							</div>
						) : (
							<div className='shadow-md bg-white p-4'>
								<div className='flex items-center'>
									<h1 className='flex-1 font-medium text-xl'>{t('Delivery Address')}</h1>
									<h1 className='flex justify-end items-center '>
										<button
											onClick={() => setInsertMode(!insertMode)}
											className='py-1 px-3 hover:bg-gray-100 flex items-center focus:outline-none outline-none '
										>
											{' '}
											<MdAdd /> {t('Add address')}
										</button>
									</h1>
								</div>

								{/* Form data entry delivery address */}
								<div className={`w-full mt-1 ${insertMode ? 'block' : 'hidden'}`}>
									<Transition
										show={insertMode}
										enter='transition-opacity duration-500'
										enterFrom='opacity-0'
										enterTo='opacity-100'
									>
										<form onSubmit={handleSubmit(addNewHandleSubmit)}>
											<div>
												<label
													htmlFor='title'
													className='block text-base font-medium leading-5 text-gray-900'
												>
													{t('Title')}
												</label>

												<input
													{...register('title')}
													type='text'
													name='title'
													id='titleId'
													autoComplete='off'
													className=' focus:ring-black focus:border-black block w-full  border-gray-300 mt-1 '
												/>
											</div>

											<div className='mt-2'>
												<label
													htmlFor='street'
													className='block text-base font-medium leading-5 text-gray-900'
												>
													{t('Street')}
												</label>

												<input
													{...register('street')}
													type='text'
													name='street'
													id='streetId'
													autoComplete='off'
													className=' focus:ring-black focus:border-black block w-full  border-gray-300 mt-1 '
												/>
											</div>

											<div className='flex w-full space-x-2'>
												<div className='mt-2 w-1/4'>
													<div className=''>
														<label
															htmlFor='zipCode'
															className='block text-base font-medium leading-5 text-gray-900'
														>
															{t('Zip code')}
														</label>

														<input
															{...register('zipCode')}
															type='text'
															name='zipCode'
															id='zipCodeId'
															autoComplete='off'
															className=' focus:ring-black focus:border-black block w-full  border-gray-300 mt-1 '
														/>
													</div>
												</div>
												<div className='mt-2 w-3/4'>
													<div className=''>
														<label
															htmlFor='city'
															className='block text-base font-medium leading-5 text-gray-900'
														>
															{t('City')}
														</label>

														<input
															{...register('city')}
															type='text'
															name='city'
															id='cityId'
															autoComplete='off'
															className=' focus:ring-black focus:border-black block w-full  border-gray-300 mt-1 '
														/>
													</div>
												</div>
											</div>
											<button type='submit' className='btn-black font-medium'>
												{t('Add')}
											</button>
											<button
												type='button'
												onClick={() => {
													setInsertMode(false);
													reset({});
												}}
												className='bg-gray-50 focus:outline-none outline-none hover:bg-gray-100 text-black py-2 px-3 font-medium ml-2'
											>
												{t('Clear')}
											</button>
										</form>
									</Transition>
								</div>

								<div className='w-full'>
									<div className='grid grid-cols-3 gap-2  mt-2'>
										{address.map((addr: IDeliveryAddress, index: number) => (
											<div key={index} className='relative hover-trigger'>
												<div
													onClick={() => onSelectDeliveryAddress(addr)}
													className={`flex text-left border-2 cursor-pointer
                        ${
													addr.address.title === selectedAddress?.address.title
														? 'bg-white border-black'
														: 'border-white bg-gray-50'
												} hover:border-black`}
													// className='flex text-left  border-2 border-white bg-gray-50 hover:border-2 hover:border-black cursor-pointer '
												>
													<div className='flex w-full '>
														<div className='w-full flex flex-col p-2 '>
															<div className='mx-2 pb-0'>
																<div className='absolute hover-target text-sm px-4 py-2 -mt-2 right-0 flex justify-between  '>
																	<button
																		onClick={() => editAddress(addr)}
																		className='flex rounded-full bg-gray-200 py-2 px-2 focus:outline-none'
																	>
																		<MdEdit />
																	</button>
																	<button className='flex rounded-full bg-red-400 py-2 px-2 mt-1 focus:outline-none'>
																		<MdDelete onClick={() => removeAddress(addr)} />
																	</button>
																</div>
																<h3 className='font-semibold text-sm leading-3 mb-2'>
																	{addr.address?.title}
																</h3>
																<p className='text-sm'>{addr.address?.street}</p>
																<p className='text-sm'>
																	{addr.address?.zipCode} {addr.address?.city}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='flex sticky top-20 bg-white  w-full md:w-4/12'>
					<div className='p-6 w-full'>
						<h2 className='text-center font-semibold'>{t('Your Order')}</h2>

						{cart.products.map((product: ICartItem, index: number) => (
							<div key={index} className='flex mt-3'>
								<div className='flex-auto text-sm '>
									<div className='truncate text-gray-600'>{product.name}</div>
									<div className='flex '>
										<div className='flex-1 text-gray-400'>
											<span className='font-medium'>{product.quantity}</span> x {product.price}
										</div>
										<div className='flex justify-end justify-items-end text-gray-400'>
											<NumberFormat
												value={product.sum}
												thousandSeparator=''
												fixedDecimalScale={true}
												decimalScale={2}
												displayType='text'
												prefix={`${product.currency} `}
											/>
										</div>
									</div>
								</div>
							</div>
						))}

						<div className='mt-5  border-t border-gray-300'>
							{cart.comment && (
								<div className='flex italic text-sm p-1 mt-4 text-gray-400 font-medium border border-gray-200'>
									<span className='mr-2'>{t('Comment:')}</span>
									{cart.comment}
								</div>
							)}
							<div className='flex text-sm pt-5'>
								<h5 className='flex-1 font-medium text-gray-400'>{t('Subtotal')}</h5>
								<h5 className='flex font-medium text-gray-400'>
									<NumberFormat
										value={cart.totalAmount}
										thousandSeparator=''
										fixedDecimalScale={true}
										decimalScale={2}
										displayType='text'
										prefix={`${cart.currency} `}
									/>
								</h5>
							</div>
							<div className='flex text-sm'>
								<h5 className='flex-1 font-medium text-gray-400'>{t('Delivery fees')}</h5>
								<h5 className='flex font-medium text-gray-400'>
									<NumberFormat
										value={cart.deliveryCost}
										thousandSeparator=''
										fixedDecimalScale={true}
										decimalScale={2}
										displayType='text'
										prefix={`${cart.currency} `}
									/>
								</h5>
							</div>
							<div className='flex text-sm mt-5'>
								<h5 className='flex-1 font-medium '>{t('Total')}</h5>
								<h5 className='flex font-medium'>
									{/* {cart.currency} {cart.deliveryCost + cart.totalAmount} */}
									<NumberFormat
										className='font-semibold'
										value={cart.deliveryCost + cart.totalAmount}
										thousandSeparator=''
										fixedDecimalScale={true}
										decimalScale={2}
										displayType='text'
										prefix={`${cart.currency} `}
									/>
								</h5>
							</div>
							<div className='flex flex-col text-sm  mt-5'>
								<p>
									{t('By making this purchase you agree to our')}{' '}
									<span className='font-medium hover:text-indigo-600'>
										<a href={`/${i18n.language}/terms`} target='_blank' rel='noreferrer'>
											{t('Terms & conditions')}
										</a>
									</span>
								</p>
								<button
									onClick={sendOrderRequest}
									disabled={disableSubmitOrder}
									className='btn-black rounded-sm font-semibold uppercase w-full disabled:opacity-50'
								>
									{t('Send Order')}
								</button>

								{/* <button
									type='button'
									onClick={boradcast}
									className='btn-black rounded-sm font-semibold uppercase w-full disabled:opacity-50'
								>
									Send Push
								</button> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderCheckOut;
