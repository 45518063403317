import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IDrawerProps } from '../../Interfaces/UI';

import { Transition } from '@headlessui/react';
import { CgClose } from 'react-icons/cg';
import { useForm } from 'react-hook-form';
import serverApi from '../../api/serverApi';

// redux stuff to set reducer
import { useDispatch } from 'react-redux';
import * as authAction from '../../rdx/actions/auth';

const RegistrationDrawer: React.FC<IDrawerProps> = ({ isOpen, closeHandle }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [trackingMessage, setMessage] = useState<string>('Register');
	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		criteriaMode: 'all',
	});
	const password = useRef({});
	password.current = watch('password', '');

	const handleSubmitRegistrationRequest = async (data: any) => {
		const response = await serverApi.signUp(data);

		setLoading(true);
		setTimeout(() => {
			setMessage(t('Send Signup request...'));
		}, 2000);

		setTimeout(() => {
			if (response.status === 'success') {
				setMessage(t('Try log in....'));
				dispatch(authAction.login(data.email, data.password));
			}
		}, 4000);

		setTimeout(() => {
			setMessage(t('Send activation by email....'));
		}, 6000);

		setTimeout(() => {
			setLoading(false);
			//close if is login
			setMessage(t('Register'));
			closeHandle();
		}, 8000);
	};

	return (
		<div className={`${isOpen ? 'block' : 'hidden'} fixed inset-0 overflow-auto z-50`}>
			<div className='absolute inset-0 overflow-y-auto overflow-x-hidden'>
				<Transition
					show={isOpen}
					enter=' transform transition ease-in-out'
					enterFrom='translate-x-full'
					enterTo='translate-x-0'
					leave='transform transition ease-in-out duration-500'
					leaveFrom='translate-x-0'
					leaveTo='translate-x-full'
				>
					<section className='absolute inset-y-0 right-0 max-w-xs sm:max-w-md'>
						<div className='bg-white shadow-xl h-full'>
							<form onSubmit={handleSubmit(handleSubmitRegistrationRequest)}>
								<header className='space-y-1 py-6 px-4 bg-indigo-700'>
									<div className='flex items-center justify-between'>
										<h2 className='text-xl leading-7 font-medium text-white'>
											{t(trackingMessage)}
										</h2>
										<button
											onClick={closeHandle}
											aria-label='Close panel'
											className='text-indigo-200 outline-none hover:text-white focus:outline-none active:outline-none'
										>
											<CgClose size={'1.4em'} />
										</button>
									</div>
									<div>
										<p className='text-md sm:text-base md:text-md lg:text-lg  text-indigo-300'>
											{t('Register a new account. Become part of the faitu community')}
										</p>
									</div>
								</header>
								<div className='divide-y divide-gray-200'>
									{loading ? (
										<div className='justify-center py-10'>
											<div className='w-full text-center justify-center flex '>
												<svg
													className='animate-spin -ml-1 mr-1 h-12 w-12 text-black'
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
												>
													<circle
														className='opacity-25'
														cx='12'
														cy='12'
														r='10'
														stroke='currentColor'
														strokeWidth='4'
													></circle>
													<path
														className='opacity-75'
														fill='currentColor'
														d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
													></path>
												</svg>
											</div>
										</div>
									) : (
										<div className='px-4 divide-y divide-gray-200'>
											<div className='space-y-6 pt-6 pb-5'>
												<div className='space-y-1'>
													<label
														htmlFor='lastName'
														className='block text-base font-medium leading-5 text-gray-900'
													>
														{t('Last name')}
													</label>
													<div className='relative'>
														<input
															{...register('lastName', { required: `${t('Last name required')}` })}
															type='text'
															name='lastName'
															id='lastNameId'
															autoComplete='off'
															className=' focus:ring-black focus:border-black block w-full  border-gray-300'
														/>
														{errors.lastName && (
															<span className='text-red-500'>{errors.lastName.message}</span>
														)}
													</div>
												</div>

												<div className='space-y-1'>
													<label
														htmlFor='name'
														className='block text-base font-medium leading-5 text-gray-900'
													>
														{t('Name')}
													</label>
													<div className='relative'>
														<input
															{...register('name', {
																required: `${t('name required')}`,
															})}
															type='text'
															name='name'
															id='nameId'
															autoComplete='off'
															className=' focus:ring-black focus:border-black block w-full border-gray-300 '
														/>
														{errors.name && (
															<span className='text-red-500'>{errors.name.message}</span>
														)}
													</div>
												</div>

												<div className='space-y-1'>
													<label
														htmlFor='email'
														className='block text-base font-medium leading-5 text-gray-900'
													>
														{t('Email address')}
													</label>
													<div className='relative'>
														<input
															{...register('email', {
																required: `${t('Email address required')}`,
																pattern: {
																	value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
																	message: `${t('invalid email address')}`,
																},
															})}
															type='email'
															name='email'
															id='emailId'
															autoComplete='off'
															className=' focus:ring-black focus:border-black block w-full  border-gray-300 '
														/>
														{errors.email && (
															<span className='text-red-500'>{errors.email.message}</span>
														)}
													</div>
												</div>

												<div className='space-y-1'>
													<label
														htmlFor='password'
														className='block text-base font-medium leading-5 text-gray-900'
													>
														Password
													</label>
													<div className='relative '>
														<input
															{...register('password', {
																required: `${t('You must specify a password')}`,
																minLength: {
																	value: 6,
																	message: `${t('Password must have at least 6 characters')}`,
																},
															})}
															type='password'
															name='password'
															id='passwordId'
															autoComplete='off'
															className=' focus:ring-black focus:border-black block w-full  border-gray-300 '
														/>
														{errors.password && (
															<span className='text-red-500  text-sm font-semibold'>
																{errors.password.message}
															</span>
														)}
													</div>
												</div>

												<div className='space-y-1'>
													<label
														htmlFor='passwordConfirm'
														className='block text-base font-medium leading-5 text-gray-900'
													>
														{t('Repeat password')}
													</label>
													<div className='relative'>
														<input
															{...register('passwordConfirm', {
																required: `${t('You must specify a password')}`,
																minLength: {
																	value: 6,
																	message: `${t('Password must have at least 6 characters')}`,
																},
																validate: (value: string) =>
																	value === password.current || `${t('The password do not match')}`,
															})}
															type='password'
															name='passwordConfirm'
															id='passwordConfirmId'
															autoComplete='off'
															className=' focus:ring-black focus:border-black block w-full  border-gray-300 '
														/>
														{errors.passwordConfirm && (
															<span className='text-red-500 text-sm font-semibold'>
																{errors.passwordConfirm.message}
															</span>
														)}
													</div>
												</div>
											</div>

											<button
												type='submit'
												className='py-2 w-full px-3 bg-black text-white hover:bg-gray-700 focus:outline-none transition duration-300 font-semibold'
											>
												{t('Sign in')}
											</button>
										</div>
									)}
								</div>
							</form>
							{!loading && (
								<div className='text-center text-sm text-gray-700 mt-3 px-3'>
									{t('By continuing I accept the')}{' '}
									<span className='font-semibold text-indigo-800 hover:underline'>
										<a href='/'>{t('conditions of use')}</a>
									</span>{' '}
									{t('of faitu.ch including the')}{' '}
									<span className='font-semibold text-indigo-800 hover:underline'>
										<a href='/'>{t('privacy policy')}</a>
									</span>{' '}
								</div>
							)}
						</div>
					</section>
				</Transition>
			</div>
		</div>
	);
};

export default RegistrationDrawer;
