import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoTrashSharp } from 'react-icons/io5';
import serverApi from '../../api/serverApi';
import DialogBox from '../../Components/dialog-box';

/** stripe */
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const UserAdManager: React.FC = () => {
	const { t } = useTranslation();
	const [ads, setAds] = useState<any[]>([]);
	const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

	const [removedAd, setRemovedAd] = useState<any>();

	const fetchingData = async () => {
		const ads = await serverApi.getUserAds();
		console.log(ads);
		if (ads.status === 'success') {
			setAds(ads.ad);
		}
	};

	useEffect(() => {
		// const fetch = async () => {
		// 	const ads = await serverApi.getUserAds();
		// 	console.log(ads);
		// 	if (ads.status === 'success') {
		// 		setAds(ads.ad);
		// 	}
		// };
		// fetch();
		fetchingData();
		return () => {};
	}, []);

	const handleCheckout = async (event: any, ad: any) => {
		event.preventDefault();
		const line_items = [
			{
				price_data: {
					currency: 'eur',
					product_data: {
						name: 'Costi di rinnovo',
					},
					unit_amount: 200,
				},
				quantity: 1,
			},
		];

		if (ad.adPaid && ad.adPaid > 0) {
			console.log('ad paid is gte > 0');
		}
		console.log(ad);
		const stripe = await stripePromise;
		const response = await serverApi.checkOut(line_items, '000000');
		console.log(response);
		const result = await stripe!.redirectToCheckout({
			sessionId: response.id,
		});
		if (result.error) {
			console.log(result.error);
			// If `redirectToCheckout` fails due to a browser or network
			// error, display the localized error message to your customer
			// using `result.error.message`.
		}
	};
	const removeSelectedAd = (event: any, ad: any) => {
		setRemovedAd(ad);
		setConfirmOpen(true);
	};
	// const handleDeleteAd = async (event: any, ad: any) => {
	const handleDeleteAd = async (e: any) => {
		await serverApi.deleteAd(removedAd._id);
		console.log(removedAd);
		fetchingData();
	};
	return (
		<div className='mt-10'>
			{ads.map((ad: any, index) => {
				const publishedAt = new Date(ad.publishedAt);
				const unpublishedAt = new Date(ad.unpublishedAt);
				return (
					<div className='mt-5 bg-white p-1' key={index}>
						<DialogBox
							title={`${t('Delete this ad?')}`}
							open={confirmOpen}
							onClose={() => setConfirmOpen(false)}
							onConfirm={handleDeleteAd}
						>
							{t('Are you sure you want to delete this ad?')}
						</DialogBox>

						<div className='operation-and-statistics border-b max-w-6xl mx-auto pb-2 '>
							<div className='grid gap-2 sm:gap-5 sm:grid-cols-2 lg:grid-cols-4'>
								<div className='flex'>
									<div className='font-semibold'>{t('Posted on')}:</div>
									<span className='ml-1'>
										{Intl.DateTimeFormat('it-IT', {
											year: 'numeric',
											month: '2-digit',
											day: '2-digit',
										}).format(publishedAt)}
									</span>
								</div>

								<div className='flex'>
									<div className='font-semibold'>{t('End date')}:</div>
									<span className='ml-1'>
										{Intl.DateTimeFormat('it-IT', {
											year: 'numeric',
											month: '2-digit',
											day: '2-digit',
										}).format(unpublishedAt)}
									</span>
								</div>

								<div className='flex text-base items-center '>
									<div className='hover:underline cursor-pointer transition ease-in-out duration-150 rounded-sm'>
										<div className='flex items-center'>
											{/* <RiFacebookBoxLine size={20} /> */}
											<div
												onClick={(e) => handleCheckout(e, ad)}
												id='checkout-button'
												className='flex px-1 '
											>
												{t('Renew with a click')}
											</div>
										</div>
									</div>
								</div>

								<div className='flex text-base items-center '>
									<div className='hover:underline cursor-pointer transition ease-in-out duration-150 rounded-sm'>
										<div onClick={(e) => removeSelectedAd(e, ad)} className='flex items-center'>
											<IoTrashSharp />
											<div className='flex px-1'>{t('Delete')}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='text-gray-700 flex space-x-2 mb-2 '>
							<span className='flex font-semibold'>{t('Ad cost')}</span>
							<span className='flex'>€ {ad.adPaid ? ad.adPaid : '-.-'}</span>
						</div>
						<article className='pt-1 w-full lg:max-w-full lg:flex '>
							{ad.gallery.length > 0 && (
								<div
									className='h-32 lg:w-32 mr-2 flex-none bg-cover rounded  text-center overflow-hidden'
									style={{
										backgroundImage: `url(${ad.gallery[0]})`,
									}}
									title={ad.title}
								></div>
							)}

							<div className='flex-1'>
								<div className=' rounded-b lg:rounded-b-none lg:rounded-r  flex flex-col leading-normal'>
									<div className='mb-1 -mt-1'>
										<div className='text-black '>
											<span className='font-semibold'>{ad.title}</span>
										</div>

										{ad.location.address && (
											<div className='flex space-x-2 mb-2'>
												{/* <p className='text-sm text-gray-600 flex items-center font-semibold'>
													Indirizzo
												</p> */}
												<p className='text-sm text-gray-600 flex items-center'>
													{ad.location.address.street} {ad.location.address.zipCode}{' '}
													{ad.location.address.city}
												</p>
											</div>
										)}

										<p className='text-gray-700 font-normal line-clamp-2 mb-1'>{ad.description}</p>
										{ad.ad?.length > 0 && (
											<div className='text-sm mb-1'>
												<div className='flex justify-start space-x-2 sm:space-x-4'>
													{Object.keys(ad.ad).map((e) => (
														<div key={e} className='inline-block space-x-1'>
															<div className='inline-block font-semibold'>{t(e)}</div>
															<div className='inline-block'>{t(ad.ad[e])}</div>
														</div>
													))}
												</div>
											</div>
										)}
									</div>

									<div className='flex items-center space-x-1'>
										<div className='flex text-sm px-1 py-1   rounded-md bg-gray-200 font-normal'>
											<p className='text-gray-600 font-semibold'>
												{ad.price.currency} {ad.price.amount}
											</p>
										</div>
										<div className='flex'>
											<p className='text-sm text-gray-600 font-semibold'>{ad.price.unit}</p>
										</div>
									</div>
								</div>
							</div>
						</article>

						<div className='operation-and-statistics max-6w-xl  mx-auto mt-2 '>
							<div className='grid gap-2 sm:gap-5 sm:grid-cols-2 lg:grid-cols-4'>
								<div className='p-2 bg-white  border'>
									<div className='text-base text-gray-400'>{t('Views')}</div>
									<div className='flex items-center pt-1'>
										<div className='text-xl font-bold text-gray-900 '>
											{ad.views ? ad.views : 0}
										</div>
									</div>
								</div>

								<div className='p-2 bg-white  border'>
									<div className='text-base text-gray-400'>{t('Click')}</div>
									<div className='flex items-center pt-1'>
										<div className='text-xl font-bold text-gray-900 '>
											{ad.clicks ? ad.clicks : 0}
										</div>
									</div>
								</div>
								<div className='p-2 bg-white  border'>
									<div className='text-base text-gray-400'>{t('Contacts')}</div>
									<div className='flex items-center pt-1'>
										<div className='text-xl font-bold text-gray-900 '>
											{ad.contacts ? ad.contacts : 0}
										</div>
									</div>
								</div>

								{/* <div className='p-2 bg-white  border'>
									<div className='text-base text-gray-400 '>SMS</div>
									<div className='flex items-center pt-1'>
										<div className='text-xl font-bold text-gray-900 '>{ad.sms ? ad.sms : 0}</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default UserAdManager;
