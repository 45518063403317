import { CartTypes } from '../types';
import { ICartItem } from '../../Interfaces/dataModel';
// import { Item } from '../../Interfaces/shop.interface';

export type CartProps = {
  // items: any;
  products: ICartItem[];
  totalAmount: number;
  currency: string;
  itemCount: number;
  deliveryCost: number;
  shopId: string;
  pickUp: boolean;
  comment: string;
};

const initialState: CartProps = {
  // items: {},
  products: [],
  totalAmount: 0,
  currency: '',
  itemCount: 0,
  deliveryCost: 0,
  shopId: '',
  pickUp: false,
  comment: '',
};

function updateItems(ritems: ICartItem[], item: ICartItem): ICartItem[] {
  let newItems: ICartItem[] = ritems;
  let index: number = -1;
  if (ritems) {
    index = ritems.findIndex((itm) => itm.id === item.id);
    console.log(index);
  }
  // find if exist item on list
  if (index > -1) {
    newItems[index].quantity = newItems[index].quantity + 1;
    newItems[index].sum = item.price * newItems[index].quantity;
  } else {
    newItems = [...newItems, item];
  }

  return newItems;
}

const CartReducer = (
  state = initialState,
  action: {
    type: CartTypes;
    product: ICartItem;
    deliveryCost: number;
    shopId: string;
    pickUp: boolean;
    comment: string;
    totalAmount: number;
  }
) => {
  switch (action.type) {
    case CartTypes.ADD_TO_CART:
      const addedProduct = action.product;
      const prodPrice = addedProduct.price;
      // const prodTitle = addedProduct.name;

      // let updateOrNewCartItem: ICartItem;

      const myItems = updateItems(state.products, action.product);

      // if (state.items[addedProduct.id]) {
      //   updateOrNewCartItem = {
      //     id: addedProduct.id,
      //     quantity: state.items[addedProduct.id].quantity + 1,
      //     price: prodPrice,
      //     name: prodTitle,
      //     sum: state.items[addedProduct.id].sum + prodPrice,
      //     currency: action.product.currency,
      //   };
      // } else {
      //   updateOrNewCartItem = action.product;
      // }
      const total = state.totalAmount + prodPrice;

      return {
        ...state,
        // items: { ...state.items, [addedProduct.id]: updateOrNewCartItem },
        products: myItems,
        totalAmount: total, // Math.round(state.totalAmount + prodPrice),
        currency: action.product.currency,
        itemCount: state.itemCount + action.product.quantity,
      };

    case CartTypes.REMOVE_ARTICLE_FROM_CART:
      let copyItems = state.products;

      const index = state.products.findIndex((itm) => itm.id === action.product.id);
      if (copyItems[index].quantity > 1) {
        copyItems[index].quantity = copyItems[index].quantity - 1;
      } else {
        copyItems.splice(index, 1);
      }
      return {
        ...state,
        products: copyItems,
        totalAmount: state.totalAmount - action.product.price, //Math.round(state.totalAmount - action.product.price),
        itemCount: state.itemCount - 1,
      };

    case CartTypes.CLEAR_CART:
      console.log('clear cart reducer');
      return {
        ...state,
        totalAmount: 0,
        itemCount: 0,
        deliveryCost: 0,
        products: [],
        shopId: '',
        comment: '',
      };
    case CartTypes.ADD_SHOP:
      return {
        ...state,
        shopId: action.shopId,
      };
    case CartTypes.ADD_DELIVERY_COST:
      return {
        ...state,
        deliveryCost: action.deliveryCost,
      };
    case CartTypes.ADD_PICKUP:
      return {
        ...state,
        pickUp: action.pickUp,
      };
    case CartTypes.ADD_COMMENT:
      return {
        ...state,
        comment: action.comment,
      };
    default:
      return state;
  }
};

export default CartReducer;
