import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import serverApi from '../../api/serverApi';
import Loader from '../../Components/loader';

import RejectAdImage from '../../images/reject-ad.png';

const RejectAdPublication: React.FC = () => {
	const { id } = useParams<any>();
	const [activate, setActivate] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [activationMessage, setActivationMessage] = useState<string>();

	useEffect(() => {
		let mounted = true;
		const fetch = async () => {
			if (mounted) {
				const result = await serverApi.rejectAd(id);
				if (result.status === 'success') {
					setActivate(true);
					setActivationMessage('Annuncio Rifiutato');
				}
			}
			setLoading(false);
		};

		setTimeout(() => {
			fetch();
		}, 300);

		return () => {
			mounted = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<React.Fragment>
			<div className='mx-auto w-full block h-full flex-col justify-center items-center mt-40 '>
				<div className='flex justify-center items-center '>
					<img className='h-48' src={RejectAdImage} alt='logo' />
				</div>
				<div className='px-3 flex flex-col w-full justify-center items-center'>
					<p
						className={`text-xl text-center ${
							!activate ? 'text-red-800' : 'text-green-800'
						} font-semibold`}
					>
						{activationMessage}
					</p>
				</div>
			</div>
		</React.Fragment>
	);
};

export default RejectAdPublication;
