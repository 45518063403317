import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import { Line, Bar } from 'react-chartjs-2';
import { IShop } from '../../../Interfaces/shop.interface';
import serverApi from '../../../api/serverApi';

// http://icewall-vue.left4code.com/dashboard-overview-3
const options = {
	options: {
		responsive: true,
	},
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
};

type statisticType = {
	_id: string | number;
	total: number;
	delivered: number;
};
const OrderManagerStatistics: React.FC = () => {
	const { t } = useTranslation();
	const [year, setYear] = useState<number>(2021);
	const [totalByYear, setTotalByYear] = useState<statisticType[]>([]);
	const [totalByMonthOfYear, setTotalByMonthOfYear] = useState<statisticType[]>([]);
	const [graphData, setGraphData] = useState<any>();
	const [totalToday, setTotalToday] = useState<statisticType>();
	const [totalDayOfMonth, setTotalDayOfMonth] = useState<statisticType[]>([]);
	const [graphDataDayOfMonth, setGraphDataDayOfMonth] = useState<any>();
	const [totalCurrentMonth, setTotalCurrentMonth] = useState<statisticType>();
	const [shop, setShop] = useState<IShop>();

	useEffect(() => {
		if (!totalDayOfMonth) return;

		const labels = totalDayOfMonth.map((el: statisticType) => el._id);
		console.log(labels);
		const dataRevenue = totalDayOfMonth.map((el: statisticType) => el.total);
		const dataDelivered = totalDayOfMonth.map((el: statisticType) => el.delivered);
		console.log(dataDelivered);
		const iGraph = {
			labels: labels,
			datasets: [
				{
					type: 'bar',
					label: 'Revenue',
					backgroundColor: `rgb(162, 150, 240)`,
					data: dataRevenue,
				},
				{
					type: 'bar',
					label: 'Delivery',
					backgroundColor: `rgb(223, 249, 172)`,
					data: dataDelivered,
					borderColor: 'white',
					borderWidth: 2,
				},
			],
		};

		setGraphDataDayOfMonth(iGraph);

		return () => {};
	}, [totalDayOfMonth]);

	useEffect(() => {
		if (!totalByMonthOfYear) return;

		const labels = totalByMonthOfYear.map((el: statisticType) => el._id);
		console.log(labels);
		const dataRevenue = totalByMonthOfYear.map((el: statisticType) => el.total);
		const dataDelivered = totalByMonthOfYear.map((el: statisticType) => el.delivered);
		console.log(dataDelivered);
		const iGraph = {
			labels: labels,
			datasets: [
				{
					type: 'bar',
					label: 'Revenue',
					backgroundColor: `rgb(98, 76, 240)`,
					data: dataRevenue,
				},
				{
					type: 'bar',
					label: 'Delivery',
					backgroundColor: `rgb(214, 97, 247)`,
					data: dataDelivered,
					borderColor: 'white',
					borderWidth: 2,
				},
			],
		};

		setGraphData(iGraph);

		return () => {};
	}, [totalByMonthOfYear]);

	useEffect(() => {
		let mounted = true;
		const fetch = async () => {
			if (mounted) {
				const srvShop = await serverApi.shopMe();
				if (srvShop.status === 'success') {
					setShop(srvShop.shop);
				}
			}
		};
		fetch();
		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		console.log('mounted orderManagerStatistics');
		let mounted = true;

		const fetch = async () => {
			if (mounted) {
				const queryString = qs.stringify({ qyear: year });
				const statistics = await serverApi.orderManagerStatistics(queryString);
				console.log(statistics, '🔥 🔥 ');
				if (statistics.status === 'success') {
					setTotalByYear(statistics.totalByYear);
					setTotalByMonthOfYear(statistics.totalByMonthOfYear);
					setTotalToday(statistics.totalToday[0]);
					setTotalDayOfMonth(statistics.totalDayOfMonth);
					setTotalCurrentMonth(statistics.totalCurrentMonth[0]);
				}
			}
		};
		fetch();

		return () => {
			mounted = false;
		};
	}, [year]);

	return (
		<div className='px-0 p-4 w-full'>
			<div className='mt-1 mb-3 grid grid-cols-12 sm:gap-10'>
				<div className='col-span-12 sm:col-span-6 md:col-span-4 py-6 sm:pl-5 md:pl-0 lg:pl-5 relative text-center sm:text-left'>
					<div className='flex flex-wrap items-center'>
						<div className='flex items-center w-full sm:w-auto justify-center sm:justify-start mr-auto mb-5 xxl:mb-0'>
							<div className='w-2 h-2 bg-theme-26 rounded-full -mt-4'></div>
							<div className=''>
								<div className='relative text-xl xxl:text-2xl font-medium leading-6 xxl:leading-5 '>
									{/* <span className='absolute text-base xxl:text-xl top-0 left-0 xxl:-mt-1.5'>$</span>{' '} */}
									{t('Monthly Revenue')}
								</div>
								{/* <div className='text-gray-600 dark:text-gray-600 mt-2'> Yearly budget </div> */}
							</div>
						</div>
						<select
							onChange={(e: any) => console.log(setYear(e.target.value))}
							className='form-select bg-transparent outline-none border-black border-opacity-10  mx-auto sm:mx-0 py-1 px-3 w-24 -mt-3 focus:outline-none'
						>
							{totalByYear?.map((year: statisticType) => (
								<option key={year._id} value={year._id}>
									{year._id}
								</option>
							))}
						</select>
					</div>

					{/* <Line type={Bar} data={data} options={options} /> */}
					<Line type={Bar} data={graphData} options={options} />

					<div className='flex w-full'>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Earnings')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6  '>
										<span className='text-2xl xxl:text-3xl font medium leading-6  mr-1 left-0'>
											<sup>{shop?.currencySymbol}</sup>
										</span>
										{totalByYear[0]?.total}{' '}
									</div>
								</div>
							</div>
						</div>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Deliveries')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6 pl-3 xxl:pl-4'>
										{totalByYear[0]?.delivered}{' '}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div className='col-span-12 sm:col-span-6 md:col-span-4 py-6 sm:pl-5 md:pl-0 lg:pl-5 relative text-center sm:text-left'>
          <div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 dark:text-gray-500 leading-3 '>
            Guadagni da inizio anno <span className='font-medium ml-1'>{totalByYear[0]?._id}</span>
          </div>
          <div className='xxl:flex mt-5 mb-3'>
            <div className='flex items-center justify-center sm:justify-start'>
              <div className='relative text-2xl xxl:text-3xl font-medium leading-6 pl-1'>
                <span className='text-2xl xxl:text-3xl font medium leading-6  mr-1 left-0'>
                  <sup>{shop?.currencySymbol}</sup>
                </span>
                {totalByYear[0]?.total}{' '}
              </div>
            </div>
          </div>
          <div className='text-xl xxl:text-2xl font-medium leading-6 xxl:leading-5  '>
            Andamento di questo mese
          </div>
          <div className='xxl:flex mt-5 mb-3'>
            <Line type={Bar} data={graphDataDayOfMonth} options={options} />
          </div>
        </div> */}

				<div className='row-start-2 md:row-start-auto col-span-12 md:col-span-4 py-6 border-black border-opacity-10 border-t md:border-t-0 md:border-l md:border-r border-dashed px-10 sm:px-28 md:px-5 -mx-5'>
					<div className='flex flex-wrap items-center'>
						<div className='flex items-center w-full sm:w-auto justify-center sm:justify-start mr-auto mb-5 xxl:mb-0'>
							<div className='w-2 h-2 -mt-4'></div>
							<div className=''>
								<div className='relative text-xl xxl:text-2xl font-medium leading-6 xxl:leading-5 '>
									{/* <span className='absolute text-base xxl:text-xl top-0 left-0 xxl:-mt-1.5'>$</span>{' '} */}
									{t('Current month revenue')}
								</div>
								{/* <div className='text-gray-600 dark:text-gray-600 mt-2'> Yearly budget </div> */}
							</div>
						</div>
					</div>

					{/* <Line type={Bar} data={data} options={options} /> */}
					<Line type={Bar} data={graphDataDayOfMonth} options={options} />

					<div className='flex w-full'>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Earnings')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6  '>
										<span className='text-2xl xxl:text-3xl font medium leading-6  mr-1 left-0'>
											<sup>{shop?.currencySymbol}</sup>
										</span>
										{totalCurrentMonth?.total}{' '}
									</div>
								</div>
							</div>
						</div>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Deliveries')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6 pl-3 xxl:pl-4'>
										{totalCurrentMonth?.delivered}{' '}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='col-span-12 sm:col-span-6 md:col-span-4 py-6 border-black border-opacity-10 border-t sm:border-t-0 border-l md:border-l-0 border-dashed -ml-4 pl-4 md:ml-0 md:pl-0'>
					<div className='relative text-xl xxl:text-2xl font-medium leading-6 xxl:leading-5 '>
						{t('Daily summary')}
					</div>

					<div className='flex w-full'>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Earnings')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6'>
										<span className='text-2xl xxl:text-3xl font medium leading-6  mr-1 left-0'>
											<sup>{shop?.currencySymbol}</sup>
										</span>
										{totalToday?.total !== undefined ? totalToday?.total : '0.0'}{' '}
									</div>
								</div>
							</div>
						</div>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Deliveries')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6 pl-3 xxl:pl-4'>
										{totalToday?.delivered !== undefined ? totalToday?.delivered : '0'}{' '}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderManagerStatistics;
