import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import serverApi from '../api/serverApi';

import Loader from '../Components/loader';
import { useAppState } from '../hooks/adsHooks';
// import { IUser } from '../Interfaces/dataModel';

// redux stuff to set reducer
import { useDispatch } from 'react-redux';
import * as authAction from '../rdx/actions/auth';

import PublishedImageEmail from '../images/published-ad.png';

const Activation: React.FC = () => {
	const dispatch = useDispatch();
	const { auth } = useAppState();
	const { t } = useTranslation();
	const { token } = useParams<any>();

	const [activate, setActivate] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [activationMessage, setActivationMessage] = useState<string>();

	useEffect(() => {
		const fetch = async () => {
			const activationResult = await serverApi.userActivation(token);
			console.log(activationResult);
			if (activationResult.status === 'success') {
				if (auth.token) {
					let { user } = auth;
					user.active = true;
					dispatch(authAction.userDataUpdate(user));
				}
				setActivationMessage(t('Your email has been successfully activated'));
				setActivate(true);
				setLoading(false);
			} else {
				setActivationMessage(t('The verification key has expired or is invalid.'));
				setActivate(false);
				setLoading(false);
			}
		};
		if (token) {
			fetch();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!token) {
		return (
			<Redirect
				to={{
					pathname: '/error',
					state: {
						status: 401,
						title: `${t('Access denied')}`,
						message: `${t('Activation error')}`,
					},
				}}
			/>
		);
	}

	if (loading) {
		return <Loader />;
	}

	return (
		<React.Fragment>
			<div className='mx-auto w-full block h-full flex-col justify-center items-center mt-40 '>
				<div className='flex justify-center items-center '>
					<img className='h-48' src={PublishedImageEmail} alt='logo' />
				</div>
				<div className='px-3 flex flex-col w-full justify-center items-center'>
					<p
						className={`text-xl text-center ${
							!activate ? 'text-red-800' : 'text-green-800'
						} font-semibold`}
					>
						{activationMessage}
					</p>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Activation;
