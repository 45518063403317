import { CartTypes } from '../types';
import { ICartItem } from '../../Interfaces/dataModel';

export const addToCart = (product: ICartItem) => {
  return { type: CartTypes.ADD_TO_CART, product: product };
};

export const removeFromCart = (product: ICartItem) => {
  return { type: CartTypes.REMOVE_ARTICLE_FROM_CART, product };
};

export const clearCart = () => {
  return { type: CartTypes.CLEAR_CART };
};

export const addDeliveryCost = (deliveryCost: number) => {
  return { type: CartTypes.ADD_DELIVERY_COST, deliveryCost };
};

export const addShop = (shopId: string) => {
  return { type: CartTypes.ADD_SHOP, shopId: shopId };
};

export const addPickUp = (pickUp: boolean) => {
  return { type: CartTypes.ADD_PICKUP, pickUp };
};

export const addComment = (comment: string) => {
  return { type: CartTypes.ADD_COMMENT, comment };
};
