import React, { useEffect, useState } from 'react';

import { useAppState } from '../../../../hooks/adsHooks';
import { CommentMessage } from '../../../../Components/messages';
import FormItem from '../../../../UI/classFied/Forms/formItem';
import { customStyles } from '../../../../utils/utility';

/** Google marker pin use for passing children component to FuGoogleMap  */
import { Marker } from '@react-google-maps/api';
import FuGoogleMap from '../../../../Components/fu-googlemap';
import FuSaveButton from '../../../../Components/fu-saveButton';

import { MdGpsFixed } from 'react-icons/md';

import AddressTooCoords from '../../../../utils/addressToCoord';
import serverApi from '../../../../api/serverApi';

import { useTranslation } from 'react-i18next';

import { CenterProps } from '../../../../Interfaces/dataModel';

import WorkingTimeManager from './WorkingTime';

import { useLocation } from 'react-router-dom';
import qs from 'query-string';

/** stripe */
import { loadStripe } from '@stripe/stripe-js';
import ButtonDecoration from '../../../../Components/buttonDecoration';
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const SubscriptionPlan = () => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	console.log(process.env.REACT_APP_STRIPE_PK);

	const subscribePlan = async (priceId: string) => {
		console.log(priceId);
		const response = await serverApi.getSubscribePlan({
			priceId: priceId,
		});
		const stripe = await stripePromise;
		const result = await stripe!.redirectToCheckout({
			sessionId: response.id,
		});
		if (result.error) {
			console.log(result.error);
		}
	};

	return (
		<>
			<div className='flex flex-col w-full items-center justify-center'>
				<h1 className='text-2xl font-bold text-center text-indigo-700 md:leading-tight'>
					{t('Choose the subscription plan')} <br /> {t("that's right for you")}
				</h1>
			</div>
			<div className='w-full'>
				<div className='flex flex-col w-full  justify-center '>
					<div className='bg-white w-full px-6 py-6 xl:px-6 lg:py-6 shadow-lg'>
						<div className='lg:flex justify-between w-full'>
							<div>
								<div className='flex w-full justify-between lg:justify-start items-center'>
									<h1 className='font-black text-lg lg:text-xl lg:mr-2'>Starter</h1>
									<button
										className='w-6 h-6 bg-gray-100 focus:outline-none  flex items-center justify-center rounded-full'
										onClick={() => setShow1(!show1)}
									>
										{show1 ? (
											<svg
												id='andicators1'
												xmlns='http://www.w3.org/2000/svg'
												width={14}
												height={14}
												viewBox='0 0 18 12'
												fill='none'
											>
												<path
													d='M9.00002 4.73399L2.40001 11.334L0.514683 9.44865L9.00002 0.963319L17.4854 9.44865L15.6 11.334L9.00002 4.73399Z'
													fill='#4A5568'
												/>
											</svg>
										) : (
											<svg
												id='andicators'
												xmlns='http://www.w3.org/2000/svg'
												width={32}
												height={32}
												viewBox='0 0 32 32'
												fill='none'
											>
												<path
													d='M16 17.5626L22.6 10.9626L24.4853 12.848L16 21.3333L7.51465 12.848L9.39998 10.9626L16 17.5626Z'
													fill='#4A5568'
												/>
											</svg>
										)}
									</button>
								</div>
								<div className='pt-2'>
									<p className='text-lg lg:w-10/12'>
										{t(
											'Ideal for trying out the faitu delivery service or if you own a small business'
										)}
									</p>
								</div>
								{show1 && (
									<div id='listHolder' className='pt-8'>
										<div className='flex items-center'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg font-semibold'>{t('Max 15 monthly orders')}</div>
										</div>
										<div className='flex items-center mt-2'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg'>{t('Real-time management of orders')}</div>
										</div>
										<div className='flex items-center mt-2'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg'>{t('No commission on orders')}</div>
										</div>
										<div className='flex items-center mt-2'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg'>{t('Support by email')}</div>
										</div>
									</div>
								)}
							</div>
							<div className='pt-8 lg:pt-0 flex items-center flex-col'>
								<h1 className='font-bold text-2xl lg:text-2xl'>
									€25.<sup>00</sup>
								</h1>
								<button
									// 'price_1IxAjnEkyF8tEQEDm7g90gf0'
									onClick={() => subscribePlan(process.env.REACT_APP_PRICE_STARTER as string)}
									className='py-1 w-full lg:block px-3 rounded-sm text-normal font-bold mt-7 bg-gray-100 focus:outline-none hover:bg-gray-200  '
								>
									{t('Subscribe')}
								</button>
							</div>
						</div>
					</div>

					<div className='bg-indigo-700 text-white w-full px-6 py-6 xl:px-6 lg:py-6 shadow-lg mt-2'>
						<div className='lg:flex justify-between w-full'>
							<div>
								<div className='flex w-full justify-between lg:justify-start items-center'>
									<h1 className='font-black text-lg lg:text-xl lg:mr-2'>Basic</h1>
									<button
										className='w-6 h-6 bg-gray-100 focus:outline-none  flex items-center justify-center rounded-full'
										onClick={() => setShow(!show)}
									>
										{show ? (
											<svg
												id='andicators1'
												xmlns='http://www.w3.org/2000/svg'
												width={14}
												height={14}
												viewBox='0 0 18 12'
												fill='none'
											>
												<path
													d='M9.00002 4.73399L2.40001 11.334L0.514683 9.44865L9.00002 0.963319L17.4854 9.44865L15.6 11.334L9.00002 4.73399Z'
													fill='#4A5568'
												/>
											</svg>
										) : (
											<svg
												id='andicators'
												xmlns='http://www.w3.org/2000/svg'
												width={32}
												height={32}
												viewBox='0 0 32 32'
												fill='none'
											>
												<path
													d='M16 17.5626L22.6 10.9626L24.4853 12.848L16 21.3333L7.51465 12.848L9.39998 10.9626L16 17.5626Z'
													fill='#4A5568'
												/>
											</svg>
										)}
									</button>
								</div>
								<div className='pt-2'>
									<p className='text-lg lg:w-10/12'>
										{t('Ideal in times when online orders have a good decline')}
									</p>
								</div>
								{show && (
									<div id='listHolder' className='pt-8'>
										<div className='flex items-center'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg font-semibold'>{t('Max 25 monthly orders')}</div>
										</div>
										<div className='flex items-center mt-2'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg'>{t('Real-time management of orders')}</div>
										</div>
										<div className='flex items-center mt-2'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg'>{t('No commission on orders')}</div>
										</div>
										<div className='flex items-center mt-2'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg'>{t('Support by email')}</div>
										</div>
									</div>
								)}
							</div>
							<div className='pt-8 lg:pt-0 flex items-center flex-col'>
								<h1 className='font-bold text-2xl lg:text-2xl'>
									€35.<sup>00</sup>
								</h1>
								<button
									onClick={() => subscribePlan(process.env.REACT_APP_PRICE_BASIC as string)}
									className='py-1 w-full text-black  lg:block px-3 rounded-sm text-normal font-bold mt-7 bg-gray-100 focus:outline-none hover:bg-gray-200  '
								>
									{t('Subscribe')}
								</button>
							</div>
						</div>
					</div>

					<div className='bg-white w-full px-6 py-6 xl:px-6 lg:py-6 shadow-lg mt-2'>
						<div className='lg:flex justify-between w-full'>
							<div>
								<div className='flex w-full justify-between lg:justify-start items-center'>
									<h1 className='font-black text-lg lg:text-xl lg:mr-2'>Pro</h1>
									<button
										className='w-6 h-6 bg-gray-100 focus:outline-none  flex items-center justify-center rounded-full'
										onClick={() => setShow2(!show2)}
									>
										{show2 ? (
											<svg
												id='andicators1'
												xmlns='http://www.w3.org/2000/svg'
												width={14}
												height={14}
												viewBox='0 0 18 12'
												fill='none'
											>
												<path
													d='M9.00002 4.73399L2.40001 11.334L0.514683 9.44865L9.00002 0.963319L17.4854 9.44865L15.6 11.334L9.00002 4.73399Z'
													fill='#4A5568'
												/>
											</svg>
										) : (
											<svg
												id='andicators'
												xmlns='http://www.w3.org/2000/svg'
												width={32}
												height={32}
												viewBox='0 0 32 32'
												fill='none'
											>
												<path
													d='M16 17.5626L22.6 10.9626L24.4853 12.848L16 21.3333L7.51465 12.848L9.39998 10.9626L16 17.5626Z'
													fill='#4A5568'
												/>
											</svg>
										)}
									</button>
								</div>
								<div className='pt-2'>
									<p className='text-lg lg:w-10/12'>
										{t(
											'Ideal in periods of high online demand, or for activities concentrated in take-away and home delivery'
										)}
									</p>
								</div>
								{show2 && (
									<div id='listHolder' className='pt-8'>
										<div className='flex items-center'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg font-semibold'>{t('No limit of orders')}</div>
										</div>
										<div className='flex items-center mt-2'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg'>{t('Real-time management of orders')}</div>
										</div>
										<div className='flex items-center mt-2'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg'>{t('No commission on orders')}</div>
										</div>
										<div className='flex items-center mt-2'>
											<div className='pr-3'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={24}
													height={24}
													viewBox='0 0 24 24'
													fill='none'
												>
													<path
														d='M5 12L10 17L20 7'
														stroke='#718096'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</div>
											<div className='text-lg'>{t('Support by email')}</div>
										</div>
									</div>
								)}
							</div>
							<div className='pt-8 lg:pt-0 flex items-center flex-col'>
								<h1 className='font-bold text-2xl lg:text-2xl'>
									€80.<sup>00</sup>
								</h1>
								<button
									onClick={() => subscribePlan(process.env.REACT_APP_PRICE_PRO as string)}
									className='py-1 w-full lg:block px-3 rounded-sm text-normal font-bold mt-7 bg-gray-100 focus:outline-none hover:bg-gray-200  '
								>
									{t('Subscribe')}
								</button>
							</div>
						</div>
					</div>

					<div>
						<p className='text-xl pt-10 text-center'>
							{t('The subscription plans are')} <strong>{t('monthly subscriptions')}</strong>
						</p>
						<p className='text-xl text-center'>{t('You can unsubscribe at any time')}</p>
					</div>
				</div>
			</div>
		</>
	);
};

const ActivePlanInformation = ({ setForm, formData }: any) => {
	const { t } = useTranslation();
	const [subscription, setSubscription] = useState<any>({
		name: '',
		price: '',
	});

	useEffect(() => {
		console.log(formData.subscription.product);
		switch (formData.subscription.product) {
			case process.env.REACT_APP_PRODUCT_ID_STARTER:
				return setSubscription({ name: 'Starter', price: '€25' });
			case process.env.REACT_APP_PRODUCT_ID_BASIC:
				return setSubscription({ name: 'Basic', price: '€35' });
			case process.env.REACT_APP_PRODUCT_ID_PRO:
				return setSubscription({ name: 'Pro', price: '€80' });
			default:
				return setSubscription('Error');
		}
	}, [formData.subscription.product]);

	const unsubscribe = async () => {
		const response = await serverApi.unsubscribe({ subscribe: formData.subscription.subscribe });
		if (response.delete) {
			let { subscription } = formData;
			subscription.active = false;
			setForm({ target: { name: 'subscription', value: subscription } });
			await serverApi.ShopUpdate(formData);
		}
	};

	const updateSubscription = async (priceId: string) => {
		let { subscription } = formData;
		const res = await serverApi.changeSubscription({ priceId: priceId });
		console.log(res);

		if (res?.subscription?.plan?.product) {
			subscription.product = res.subscription.plan.product;
			setForm({
				target: { name: 'subscription', value: subscription },
			});
		}
	};

	return (
		<React.Fragment>
			<div className='w-full px-1'>
				<div className='p-0 font-semibold text-lg'>{t('Active subscription')}</div>

				<div className='px-2 p-2 flex w-full items-center shadow'>
					<div className='flex-1 font-black text-green-900'>
						{subscription.name}{' '}
						<span className='font-normal text-gray-800 text-sm ml-2'>
							{t('Max 15 monthly orders')}
						</span>
					</div>
					<div className='flex justify-end font-bold text-green-900 mr-2'>
						{subscription.price}{' '}
					</div>
					<div className='flex justify-end font-bold '>
						<ButtonDecoration caption={t('Cancel')} styles='text-black' onClick={unsubscribe} />
					</div>
				</div>

				<div className='p-0 mt-5 font-semibold text-lg'>{t('Subscriptions available')}</div>
				{subscription.name !== 'Starter' && (
					<div className='px-2 p-2 flex w-full items-center shadow'>
						<div className='flex-1 font-black text-green-900'>
							Starter
							<span className='font-normal text-gray-800 text-sm ml-2'>
								{t('Max 15 monthly orders')}
							</span>
						</div>
						<div className='flex justify-end font-bold text-green-900 mr-2'>€25</div>
						<div className='flex justify-end font-bold '>
							<ButtonDecoration
								caption={t('Update')}
								styles='text-black'
								// 'price_1IxAjnEkyF8tEQEDm7g90gf0'
								onClick={() => updateSubscription(process.env.REACT_APP_PRICE_STARTER as string)}
							/>
						</div>
					</div>
				)}

				{subscription.name !== 'Basic' && (
					<div className='px-2 p-2 flex w-full items-center shadow'>
						<div className='flex-1 font-black text-green-900'>
							Basic
							<span className='font-normal text-gray-800 text-sm ml-2'>
								{t('Max 25 monthly orders')}
							</span>
						</div>
						<div className='flex justify-end font-bold text-green-900 mr-2'>€35</div>
						<div className='flex justify-end font-bold '>
							<ButtonDecoration
								caption={t('Update')}
								styles='text-black'
								onClick={() => updateSubscription(process.env.REACT_APP_PRICE_BASIC as string)}
							/>
						</div>
					</div>
				)}

				{subscription.name !== 'Pro' && (
					<div className='px-2 p-2 flex w-full items-center shadow'>
						<div className='flex-1 font-black text-green-900'>
							Pro
							<span className='font-normal text-gray-800 text-sm ml-2'>
								{t('No limit of orders')}
							</span>
						</div>
						<div className='flex justify-end font-bold text-green-900 mr-2'>€80</div>
						<div className='flex justify-end font-bold '>
							<ButtonDecoration
								caption={t('Update')}
								styles='text-black'
								onClick={() => updateSubscription(process.env.REACT_APP_PRICE_PRO as string)}
							/>
						</div>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

const ShopInformation = ({ setForm, formData, navigation, sections }: any) => {
	const { search } = useLocation();
	const { t } = useTranslation();
	const { coords } = useAppState().app;
	const [coordinates, setCoordinates] = useState<CenterProps>({
		lat: coords.latitude,
		lng: coords.longitude,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingMap, setLoadingMap] = useState<boolean>(false);
	const [selectedSections, setSelectedSections] = useState<any[]>(formData.sections);

	const [sessionId, setSessionId] = useState<string>();
	const props = { setForm, formData };

	useEffect(() => {
		const fetchingSubscribe = async (sessionId: string) => {
			const planInforamtion: any = await serverApi.getSubscribeInformation(sessionId);
			console.log(planInforamtion);
			if (planInforamtion?.info?.active) {
				setForm({
					target: {
						name: 'subscription',
						value: planInforamtion.info,
					},
				});
			}
		};

		const session: any = qs.parse(search);
		// Passing here only if user have subscribe a plan on the first time
		if (session.session_id) {
			setSessionId(session.session_id);
			fetchingSubscribe(session.session_id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	const handleSave = async () => {
		setLoading(true);
		await serverApi.ShopUpdate(formData);
		setTimeout(() => {
			setLoading(false);
		}, 500);
	};
	const handleSaveMapAddress = async () => {
		setForm({
			target: {
				name: 'location.address.coordinates',
				value: [coordinates.lat, coordinates.lng],
			},
		});
		setLoadingMap(true);

		await serverApi.ShopUpdate(formData);
		setTimeout(() => {
			setLoadingMap(false);
		}, 500);
	};

	const onSectionChangeHandle = (e: any, _context: any) => {
		setForm({ target: { name: 'sections', value: e } });
		console.log(formData.sections);
		setSelectedSections(e);
	};

	return (
		<div className='px-2 sm:px-0'>
			<div className='flex flex-wrap mt-3'>
				<div className='w-full md:w-2/5'>
					<div className='bg-white mr-4 p-4 shadow'>
						<div>
							<CommentMessage>
								{t('Enter the business name as it appears to customers in the real world.')}
							</CommentMessage>
						</div>
						<FormItem
							required={true}
							label={t('Business name')}
							fieldname='name'
							name='name'
							value={formData?.name || ''}
							onChange={setForm}
							type='text'
						/>
						<div className='mt-5'>
							<CommentMessage>
								{t(
									'Enter the exact address of your business. You will allow to be found more easily and receive relevant orders'
								)}
							</CommentMessage>
							<FormItem
								required={true}
								label={t('Address')}
								fieldname='location.address.street'
								name='location.address.street'
								value={formData?.location?.address?.street || ''}
								onChange={setForm}
								type='text'
							/>

							<div className='flex mt-1 space-x-1'>
								<div className='flex'>
									<FormItem
										required={true}
										placeholder={t('Zip code')}
										fieldname='location.address.zipCode'
										name='location.address.zipCode'
										value={formData.location?.address?.zipCode || ''}
										onChange={setForm}
										type='text'
									/>
								</div>
								<div className='flex w-full'>
									<FormItem
										required={true}
										placeholder={t('City')}
										fieldname='location.address.city'
										name='location.address.city'
										value={formData.location?.address?.city || ''}
										onChange={setForm}
										type='text'
									/>
								</div>
								<div className='flex'>
									<button
										onClick={async () => {
											const r = await AddressTooCoords(
												formData.location.address.zipCode +
													'' +
													formData.location.address.street +
													',' +
													formData.location.address.city
											);
											console.log(r);
											setCoordinates(r);
										}}
										type='button'
										style={{ border: '1px solid rgba(27,31,35,.15)' }}
										className=' pl-2 pr-2 flex items-center  rounded-sm bg-gray-50 text-red-800  focus:outline-none active:outline-none hover:bg-gray-100'
									>
										<span className='font-medium'>
											<MdGpsFixed size={20} />
										</span>
									</button>
								</div>
							</div>

							<div className='mt-3 mb-2'>
								<FuGoogleMap center={coordinates} zoom={12}>
									<Marker position={coordinates} />
								</FuGoogleMap>
							</div>
						</div>
						<FuSaveButton
							caption={t('Save your changes')}
							loading={loadingMap}
							onClick={handleSaveMapAddress}
						/>
					</div>

					<WorkingTimeManager {...props} />
				</div>
				<div className='w-full md:w-3/5'>
					<div className='bg-white mt-3 p-4 shadow'>
						<div className='mt-3'>
							<CommentMessage>{t('Add cooking types or business categories')}</CommentMessage>
							<FormItem
								required={true}
								label={t('Category')}
								type='select-dropdown'
								name='sections'
								isMulti
								styles={customStyles}
								getOptionLabel={(option: any) => option.name}
								getOptionValue={(option: any) => option._id}
								options={sections}
								value={selectedSections}
								onChange={onSectionChangeHandle}
							/>
						</div>
					</div>

					<div className='bg-white p-4 shadow mt-2'>
						<div className='flex flex-col w-full'>
							<p className='text-sm text-gray-600'>
								{' '}
								{t('Select the services you want to offer.')}{' '}
								<b>{t('At least one must be selected')}</b>.
							</p>
							<div className='flex flex-row items-center justify-start mt-1'>
								<FormItem
									label={t('Takeaway')}
									type='checkbox'
									name='takeAway'
									fieldname='takeAway'
									value={formData.takeAway || false}
									checked={formData.takeAway}
									onChange={setForm}
								/>
							</div>
							<FormItem
								label={t('Delivery service')}
								type='checkbox'
								name='delivery'
								fieldname='delivery'
								value={formData.delivery || false}
								checked={formData.delivery}
								onChange={setForm}
							/>
						</div>
					</div>

					<div className='bg-white p-4 shadow mt-2 mb-2'>
						<div className='flex flex-col w-full'>
							<CommentMessage>
								{t(
									'Do you have your own home delivery service? Indicates the rate per km, which will be calculated based on the distance from the customer. If you choose this option all the order sorting management is not managed by faitu.'
								)}
							</CommentMessage>
							<div className='flex flex-row items-center justify-start mt-3'>
								<FormItem
									label={t('Delivery service with own employees')}
									type='checkbox'
									name='customDeliveryService'
									fieldname='customDeliveryService'
									value={formData.customDeliveryService || false}
									checked={formData.customDeliveryService}
									onChange={setForm}
								/>
							</div>
							<div className='mt-2 flex'>
								<FormItem
									type='number'
									filedname='deliveryFees'
									name='deliveryFees'
									label={t('Rate per Km')}
									value={formData.deliveryFees || ''}
									onChange={setForm}
								/>
							</div>
						</div>
					</div>
					<FuSaveButton loading={loading} caption={t('Save your changes')} onClick={handleSave} />

					<div className=' mt-10'>
						{formData.subscription.active === true ? (
							<ActivePlanInformation {...props} />
						) : !sessionId ? (
							<SubscriptionPlan />
						) : (
							<div className='justify-center py-10'>
								<div className='w-full text-center justify-center flex '>
									<svg
										className='animate-spin -ml-1 mr-1 h-12 w-12 text-black'
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
									>
										<circle
											className='opacity-25'
											cx='12'
											cy='12'
											r='10'
											stroke='currentColor'
											strokeWidth='4'
										></circle>
										<path
											className='opacity-75'
											fill='currentColor'
											d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
										></path>
									</svg>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShopInformation;
