import React from 'react';
import { BsFillBriefcaseFill } from 'react-icons/bs';
import { MdAttachMoney } from 'react-icons/md';
import { useAppState } from '../../hooks/adsHooks';
import tw from 'twin.macro';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import DesignIllustrator from '../../images/advertising-home.webp';
import prototypeIllustrationImageSrc from '../../images/prototype-illustration.svg';
import marketingAudit from '../../images/digital-marketing-audit.png';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left ml-5`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
	${tw`relative max-w-md text-center mx-auto lg:mx-0`}
	input {
		${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
	}
	button {
		${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
	}
`;
const ContactButton = styled.div`
	${tw`right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-lg py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
`;
const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;

const TextColumn = styled(Column)<{ textOnLeft: boolean }>((props) => [
	tw`md:w-6/12 mt-16 md:mt-0 sm:ml-5 sm:mr-5 xl:ml-0 xl:mr-5`,
	props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img<{ imageRounded: boolean; imageBorder: boolean; imageShadow: boolean }>`
	${(props) => props.imageBorder && tw`rounded`}
	${(props) => props.imageBorder && tw`border`}
	${(props) => props.imageShadow && tw`shadow`}
`;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Subheading = tw.h5`font-bold text-primary-500 text-center md:text-left`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div<{
	iconRoundedFull: boolean;
	iconFilled: boolean;
	css: any;
}>`
	${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
	${(props) => [
		props.iconRoundedFull && tw`rounded-full`,
		props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`,
	]}
  svg {
		${tw`w-5 h-5`}
	}
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`;
const defaultFeatures = [
	{
		title: 'Annuncio',
		description: 'Fai girare il tuo annuncio in tutto il web. Dai social ai motori di ricerca',
		iconContainerCss: tw`bg-teal-300 text-teal-800`,
	},
	{
		title: 'Monetizza',
		description: 'Guadagna dalla tua pubblicità contatti e nuovi clienti',
		iconContainerCss: tw`bg-red-300 text-red-800`,
	},
];

const AdIt = ({ features = null, description = 'description', iconContainerCss = null }: any) => {
	if (!features) features = defaultFeatures;
	return (
		<>
			<Container>
				<TwoColumn>
					<LeftColumn>
						<Heading>
							Strategie Marketing creati <span tw='text-primary-500'>su misura</span>
						</Heading>
						<Paragraph>
							Come un sarto cuce un abito, faitu prepara una strategia di marketing per promuovere a
							360° il tuo annuncio o brand al di fuori della piattaforma.
						</Paragraph>
						<Actions>
							<ContactButton>
								<a href='mailto:admin@faitu.ch'>Contataci</a>
							</ContactButton>
						</Actions>
						{/* <Actions>
							<input type='text' placeholder='Indirizzo e-mail' />
							<button>Inizia Ora</button>
						</Actions> */}

						<Paragraph>
							Ti seguiremo passo passo per raggiungere i tuoi obiettivi. Dai social network alla
							carta stampata otterrai più visite, più contatti e più vendite
						</Paragraph>
					</LeftColumn>
					<RightColumn>
						<IllustrationContainer>
							<motion.div
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								transition={{ duration: 0.9 }}
							>
								<img
									tw='min-w-0 w-full max-w-lg xl:max-w-3xl'
									src={DesignIllustrator}
									alt='Design Illustrator'
								/>
							</motion.div>
						</IllustrationContainer>
					</RightColumn>
				</TwoColumn>
				<TwoColumn>
					<ImageColumn>
						<motion.div
							initial={{ scale: 0 }}
							animate={{ scale: 1 }}
							transition={{ duration: 0.9 }}
						>
							<Image
								src={marketingAudit}
								imageBorder={false}
								imageRounded={true}
								imageShadow={false}
							/>
						</motion.div>
					</ImageColumn>
					<TextColumn textOnLeft={false}>
						<TextContent>
							<Subheading>Targeting</Subheading>
							<Heading>
								Ricerca pubblicitaria <span tw='text-primary-500'>Targetizzata.</span>
							</Heading>
							<Description>
								Lavoriamo per far conoscere il tuo <strong>Brand</strong> dove server. Creare una
								linea di modello per perseguire i potenziali clienti realmente interessati al tuo
								prodotto e/o servizio.
							</Description>
						</TextContent>
					</TextColumn>
				</TwoColumn>
				<TwoColumn>
					<ImageColumn>
						<Image
							src={prototypeIllustrationImageSrc}
							imageBorder={false}
							imageShadow={false}
							imageRounded={false}
						/>
					</ImageColumn>
					<TextColumn textOnLeft={true}>
						<TextContent>
							<Subheading>A portata di tutti</Subheading>
							<Heading>
								Promuovi il tuo annuncio <span tw='text-primary-500'>a costi contenuti</span>
							</Heading>
							<Description>
								Con risultati efficaci. <strong>Se non vendi</strong> la tua pubblicità continuerà
								ad apparire <strong>gratuitamente</strong>
							</Description>
							<Features>
								{features.map((feature: any, index: number) => (
									<Feature key={index}>
										<FeatureHeadingContainer>
											<FeatureIconContainer
												iconFilled={true}
												iconRoundedFull={true}
												css={feature.iconContainerCss || iconContainerCss}
											>
												{feature.title === 'Annuncio' && <BsFillBriefcaseFill />}
												{feature.title === 'Monetizza' && <MdAttachMoney />}
											</FeatureIconContainer>

											<FeatureHeading>{feature.title}</FeatureHeading>
										</FeatureHeadingContainer>
										<FeatureDescription>{feature.description}</FeatureDescription>
									</Feature>
								))}
							</Features>
						</TextContent>
					</TextColumn>
				</TwoColumn>
			</Container>
		</>
	);
};

const AdEn = ({ features = null, description = 'description', iconContainerCss = null }: any) => {
	if (!features) features = defaultFeatures;
	return (
		<>
			<Container>
				<TwoColumn>
					<LeftColumn>
						<Heading>
							Strategie Marketing creati <span tw='text-primary-500'>su misura</span>
						</Heading>
						<Paragraph>
							Come un sarto cuce un abito, faitu prepara una strategia di marketing per promuovere a
							360° il tuo annuncio o brand al di fuori della piattaforma.
						</Paragraph>
						<Actions>
							<ContactButton>
								<a href='mailto:admin@faitu.ch'>Contataci</a>
							</ContactButton>
						</Actions>
						{/* <Actions>
							<input type='text' placeholder='Indirizzo e-mail' />
							<button>Inizia Ora</button>
						</Actions> */}

						<Paragraph>
							Ti seguiremo passo passo per raggiungere i tuoi obiettivi. Dai social network alla
							carta stampata otterrai più visite, più contatti e più vendite
						</Paragraph>
					</LeftColumn>
					<RightColumn>
						<IllustrationContainer>
							<motion.div
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								transition={{ duration: 0.9 }}
							>
								<img
									tw='min-w-0 w-full max-w-lg xl:max-w-3xl'
									src={DesignIllustrator}
									alt='Design Illustrator'
								/>
							</motion.div>
						</IllustrationContainer>
					</RightColumn>
				</TwoColumn>
				<TwoColumn>
					<ImageColumn>
						<motion.div
							initial={{ scale: 0 }}
							animate={{ scale: 1 }}
							transition={{ duration: 0.9 }}
						>
							<Image
								src={marketingAudit}
								imageBorder={false}
								imageRounded={true}
								imageShadow={false}
							/>
						</motion.div>
					</ImageColumn>
					<TextColumn textOnLeft={false}>
						<TextContent>
							<Subheading>Targeting</Subheading>
							<Heading>
								Ricerca pubblicitaria <span tw='text-primary-500'>Targetizzata.</span>
							</Heading>
							<Description>
								Lavoriamo per far conoscere il tuo <strong>Brand</strong> dove server. Creare una
								linea di modello per perseguire i potenziali clienti realmente interessati al tuo
								prodotto e/o servizio.
							</Description>
						</TextContent>
					</TextColumn>
				</TwoColumn>
				<TwoColumn>
					<ImageColumn>
						<Image
							src={prototypeIllustrationImageSrc}
							imageBorder={false}
							imageShadow={false}
							imageRounded={false}
						/>
					</ImageColumn>
					<TextColumn textOnLeft={true}>
						<TextContent>
							<Subheading>A portata di tutti</Subheading>
							<Heading>
								Promuovi il tuo annuncio <span tw='text-primary-500'>a costi contenuti</span>
							</Heading>
							<Description>
								Con risultati efficaci. <strong>Se non vendi</strong> la tua pubblicità continuerà
								ad apparire <strong>gratuitamente</strong>
							</Description>
							<Features>
								{features.map((feature: any, index: number) => (
									<Feature key={index}>
										<FeatureHeadingContainer>
											<FeatureIconContainer
												iconFilled={true}
												iconRoundedFull={true}
												css={feature.iconContainerCss || iconContainerCss}
											>
												{feature.title === 'Annuncio' && <BsFillBriefcaseFill />}
												{feature.title === 'Monetizza' && <MdAttachMoney />}
											</FeatureIconContainer>

											<FeatureHeading>{feature.title}</FeatureHeading>
										</FeatureHeadingContainer>
										<FeatureDescription>{feature.description}</FeatureDescription>
									</Feature>
								))}
							</Features>
						</TextContent>
					</TextColumn>
				</TwoColumn>
			</Container>
		</>
	);
};
const AdFr = ({ features = null, description = 'description', iconContainerCss = null }: any) => {
	if (!features) features = defaultFeatures;
	return (
		<>
			<Container>
				<TwoColumn>
					<LeftColumn>
						<Heading>
							Strategie Marketing creati <span tw='text-primary-500'>su misura</span>
						</Heading>
						<Paragraph>
							Come un sarto cuce un abito, faitu prepara una strategia di marketing per promuovere a
							360° il tuo annuncio o brand al di fuori della piattaforma.
						</Paragraph>
						<Actions>
							<ContactButton>
								<a href='mailto:admin@faitu.ch'>Contataci</a>
							</ContactButton>
						</Actions>
						{/* <Actions>
							<input type='text' placeholder='Indirizzo e-mail' />
							<button>Inizia Ora</button>
						</Actions> */}

						<Paragraph>
							Ti seguiremo passo passo per raggiungere i tuoi obiettivi. Dai social network alla
							carta stampata otterrai più visite, più contatti e più vendite
						</Paragraph>
					</LeftColumn>
					<RightColumn>
						<IllustrationContainer>
							<motion.div
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								transition={{ duration: 0.9 }}
							>
								<img
									tw='min-w-0 w-full max-w-lg xl:max-w-3xl'
									src={DesignIllustrator}
									alt='Design Illustrator'
								/>
							</motion.div>
						</IllustrationContainer>
					</RightColumn>
				</TwoColumn>
				<TwoColumn>
					<ImageColumn>
						<motion.div
							initial={{ scale: 0 }}
							animate={{ scale: 1 }}
							transition={{ duration: 0.9 }}
						>
							<Image
								src={marketingAudit}
								imageBorder={false}
								imageRounded={true}
								imageShadow={false}
							/>
						</motion.div>
					</ImageColumn>
					<TextColumn textOnLeft={false}>
						<TextContent>
							<Subheading>Targeting</Subheading>
							<Heading>
								Ricerca pubblicitaria <span tw='text-primary-500'>Targetizzata.</span>
							</Heading>
							<Description>
								Lavoriamo per far conoscere il tuo <strong>Brand</strong> dove server. Creare una
								linea di modello per perseguire i potenziali clienti realmente interessati al tuo
								prodotto e/o servizio.
							</Description>
						</TextContent>
					</TextColumn>
				</TwoColumn>
				<TwoColumn>
					<ImageColumn>
						<Image
							src={prototypeIllustrationImageSrc}
							imageBorder={false}
							imageShadow={false}
							imageRounded={false}
						/>
					</ImageColumn>
					<TextColumn textOnLeft={true}>
						<TextContent>
							<Subheading>A portata di tutti</Subheading>
							<Heading>
								Promuovi il tuo annuncio <span tw='text-primary-500'>a costi contenuti</span>
							</Heading>
							<Description>
								Con risultati efficaci. <strong>Se non vendi</strong> la tua pubblicità continuerà
								ad apparire <strong>gratuitamente</strong>
							</Description>
							<Features>
								{features.map((feature: any, index: number) => (
									<Feature key={index}>
										<FeatureHeadingContainer>
											<FeatureIconContainer
												iconFilled={true}
												iconRoundedFull={true}
												css={feature.iconContainerCss || iconContainerCss}
											>
												{feature.title === 'Annuncio' && <BsFillBriefcaseFill />}
												{feature.title === 'Monetizza' && <MdAttachMoney />}
											</FeatureIconContainer>

											<FeatureHeading>{feature.title}</FeatureHeading>
										</FeatureHeadingContainer>
										<FeatureDescription>{feature.description}</FeatureDescription>
									</Feature>
								))}
							</Features>
						</TextContent>
					</TextColumn>
				</TwoColumn>
			</Container>
		</>
	);
};
const AdDe = ({ features = null, description = 'description', iconContainerCss = null }: any) => {
	if (!features) features = defaultFeatures;
	return (
		<>
			<Container>
				<TwoColumn>
					<LeftColumn>
						<Heading>
							Strategie Marketing creati <span tw='text-primary-500'>su misura</span>
						</Heading>
						<Paragraph>
							Come un sarto cuce un abito, faitu prepara una strategia di marketing per promuovere a
							360° il tuo annuncio o brand al di fuori della piattaforma.
						</Paragraph>
						<Actions>
							<ContactButton>
								<a href='mailto:admin@faitu.ch'>Contataci</a>
							</ContactButton>
						</Actions>
						{/* <Actions>
							<input type='text' placeholder='Indirizzo e-mail' />
							<button>Inizia Ora</button>
						</Actions> */}

						<Paragraph>
							Ti seguiremo passo passo per raggiungere i tuoi obiettivi. Dai social network alla
							carta stampata otterrai più visite, più contatti e più vendite
						</Paragraph>
					</LeftColumn>
					<RightColumn>
						<IllustrationContainer>
							<motion.div
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								transition={{ duration: 0.9 }}
							>
								<img
									tw='min-w-0 w-full max-w-lg xl:max-w-3xl'
									src={DesignIllustrator}
									alt='Design Illustrator'
								/>
							</motion.div>
						</IllustrationContainer>
					</RightColumn>
				</TwoColumn>
				<TwoColumn>
					<ImageColumn>
						<motion.div
							initial={{ scale: 0 }}
							animate={{ scale: 1 }}
							transition={{ duration: 0.9 }}
						>
							<Image
								src={marketingAudit}
								imageBorder={false}
								imageRounded={true}
								imageShadow={false}
							/>
						</motion.div>
					</ImageColumn>
					<TextColumn textOnLeft={false}>
						<TextContent>
							<Subheading>Targeting</Subheading>
							<Heading>
								Ricerca pubblicitaria <span tw='text-primary-500'>Targetizzata.</span>
							</Heading>
							<Description>
								Lavoriamo per far conoscere il tuo <strong>Brand</strong> dove server. Creare una
								linea di modello per perseguire i potenziali clienti realmente interessati al tuo
								prodotto e/o servizio.
							</Description>
						</TextContent>
					</TextColumn>
				</TwoColumn>
				<TwoColumn>
					<ImageColumn>
						<Image
							src={prototypeIllustrationImageSrc}
							imageBorder={false}
							imageShadow={false}
							imageRounded={false}
						/>
					</ImageColumn>
					<TextColumn textOnLeft={true}>
						<TextContent>
							<Subheading>A portata di tutti</Subheading>
							<Heading>
								Promuovi il tuo annuncio <span tw='text-primary-500'>a costi contenuti</span>
							</Heading>
							<Description>
								Con risultati efficaci. <strong>Se non vendi</strong> la tua pubblicità continuerà
								ad apparire <strong>gratuitamente</strong>
							</Description>
							<Features>
								{features.map((feature: any, index: number) => (
									<Feature key={index}>
										<FeatureHeadingContainer>
											<FeatureIconContainer
												iconFilled={true}
												iconRoundedFull={true}
												css={feature.iconContainerCss || iconContainerCss}
											>
												{feature.title === 'Annuncio' && <BsFillBriefcaseFill />}
												{feature.title === 'Monetizza' && <MdAttachMoney />}
											</FeatureIconContainer>

											<FeatureHeading>{feature.title}</FeatureHeading>
										</FeatureHeadingContainer>
										<FeatureDescription>{feature.description}</FeatureDescription>
									</Feature>
								))}
							</Features>
						</TextContent>
					</TextColumn>
				</TwoColumn>
			</Container>
		</>
	);
};

const Advertising = () => {
	const { lang } = useAppState().app;

	switch (lang) {
		case 'it':
			return <AdIt />;
		case 'en':
			return <AdEn />;
		case 'fr':
			return <AdFr />;
		case 'de':
			return <AdDe />;
		default:
			return <AdIt />;
	}
};

export default Advertising;
