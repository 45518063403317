import React from 'react';

const ButtonDecoration = (props: any) => {
  const { caption, styles, onClick } = props;
  return (
    <span className='inline-flex rounded-md shadow-sm'>
      <button
        onClick={onClick}
        style={{ border: '1px solid rgba(27,31,35,.15)' }}
        type='button'
        // disabled={loading}
        className={` ${styles} py-1 px-3  text-sm flex items-center rounded-sm
          bg-gray-50 text-gray-800 focus:outline-none active:outline-none hover:text-black`}
      >
        <span className='font-medium'>{caption}</span>
      </button>
    </span>
  );
};

export default ButtonDecoration;
