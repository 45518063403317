import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppState } from '../../hooks/adsHooks';
import serverApi from '../../api/serverApi';
import { useTranslation } from 'react-i18next';

import NumberFormat from 'react-number-format';
import qs from 'query-string';

import { SectionDeskTopItem, SectionMobileItem } from './components/shopSection';
import ShopCard from './components/shopCard';

import { SeoTag } from '../../utils/Seo';

import Loader from '../../Components/loader';

// test redux counter
import { useDispatch } from 'react-redux';
import * as cartActions from '../../rdx/actions/cart';

import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

type ShopBranchMatch = {
	branch: string;
};

type ShopBranchMatchProps = RouteComponentProps<ShopBranchMatch>;

// const DeliveryHome: React.FC = () => {
const ShopList: React.FC<ShopBranchMatchProps> = ({ match, location, history }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { Handle } = Slider;

	const { branch } = match.params;
	const [isOpen, setOpen] = useState<boolean>(false);
	const [pickUp, setPickUp] = useState<boolean>(false);
	const { latitude, longitude } = useAppState().app.coords;
	const [lat] = useState<number>(latitude);
	const [lng] = useState<number>(longitude);

	const [sections, setSections] = useState<any[]>([]);
	const [shops, setShops] = useState<any[]>([]);

	const [selectedSection, setSelectedSection] = useState<string>('');
	const [distance, setDistance] = useState<number>(3);

	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		dispatch(cartActions.addShop(''));
		dispatch(cartActions.clearCart());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let mounted = true;
		async function fetching() {
			if (!mounted) {
				return;
			}

			let search: any;
			if (location.search) {
				search = qs.parse(location.search);
			}
			const { section } = search || {
				section: '',
			};

			const terms = {
				section,
				distance,
				pickUp,
			};
			console.log(`?${qs.stringify(terms)}`);

			try {
				const data = await serverApi.nearbyShops(
					`${lat},${lng}`,
					branch,
					`?${qs.stringify(terms)}`
				);
				console.log('🔥 🔥 ', data);
				if (data.status !== 'success') {
					throw new Error('Error');
				}
				setSections(data.sections);
				setShops(data.shops);
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		}
		setLoading(true);
		fetching();
		return () => {
			mounted = false;
		};
	}, [location.search, branch, lat, lng, distance, pickUp]);

	const selectedSectionHandleClick = (name: string) => {
		setSelectedSection(name);
	};

	const distanceAfterChangeHandle = (value: number) => {
		setDistance(value);
	};

	const wrapperStyle = {
		width: '100%',
		margin: 1,
	};
	const handle = (props: any) => {
		const { value, dragging, index, ...restProps } = props;

		return (
			<SliderTooltip
				prefixCls='rc-slider-tooltip'
				overlay={`${value} Km`}
				visible={dragging}
				placement='top'
				key={index}
			>
				<Handle value={value} {...restProps} />
			</SliderTooltip>
		);
	};

	return (
		<React.Fragment>
			<SeoTag
				title='Faitu'
				description={t('Order products, items or your favorite dinner and wait for home delivery')}
				introText={t('Order from your favorite restaurant or shop')}
				imageUrl={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/faitu200x200.png`}
			/>

			{/* Mobile only but ***hidden*** */}
			<section className='hidden sticky top-16 h-10 z-50 shadow-lg bg-gray-100 md:hidden lg:hidden xl:hidden'>
				<div className=' w-full px-4 py-1 items-center xl:hidden'>
					<div className='relative w-full'>
						<span className='items-center font-medium'>Menu</span>
						<button
							type='button'
							className={`ml-4  inline-flex float-right items-center  focus:outline-none focus:shadow-outline  pl-2 py-1 pr-3 ${
								isOpen ? 'bg-gray-300' : 'bg-gray-200'
							}`}
							onClick={() => setOpen((o) => !o)}
						>
							<svg
								className='h-4 w-4 fill-current text-gray-500'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path d='M3 6a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm3 6a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1zm4 5a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4z' />
							</svg>
							<span className='ml-1 text-black font-medium'>Filtra per città</span>
						</button>
					</div>
				</div>
				<nav
					className={` bg-gray-100 shadow-lg p-4 xl:h-full xl:flex xl:flex-col xl:justify-between 
          ${isOpen ? 'block' : 'hidden'}`}
				>
					<h2 className='font-semibold text-gray-600 uppercase tracking-wide'>City</h2>
					<div className='mt-3'>
						<a
							href='/'
							className='-mx-3  py-1 px-3 text-sm font-medium flex items-center justify-between bg-gray-200 rounded-sm'
						>
							<span>
								<span className=' text-gray-900'>Nelle vicinanze</span>
							</span>
						</a>
					</div>
					<div className='mt-3'>
						<a
							href='/'
							className='-mx-3  py-1 px-3 text-sm font-medium flex items-center justify-between hover:bg-gray-200 rounded-lg'
						>
							<span>
								<span className=' text-gray-900'>Paradiso</span>
							</span>
						</a>
					</div>
				</nav>
			</section>

			{/* Section Mobile */}
			<section className='sm:hidden pt-5  px-3 overflow-x-auto'>
				<div className='flex text-center'>
					{sections.map((section: any, index: number) => (
						<SectionMobileItem
							key={index}
							title={section.name}
							url={`${match.url}`}
							sectionId={section._id}
							image={`${section.thumbImage.mobile}`}
							onClick={selectedSectionHandleClick}
						/>
					))}
				</div>
			</section>

			<div className='max-w-screen-2xl mx-auto px-2 p-4'>
				<div className='hidden sm:grid lg:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-1 '>
					{sections.map((section: any, index: number) => (
						<SectionDeskTopItem
							onClick={selectedSectionHandleClick}
							key={index}
							title={section.name}
							url={`${match.url}`}
							sectionId={section._id}
							image={`${section.thumbImage.desktop}`}
						/>
					))}
				</div>

				<div className='flex-1 flex space-x-2'>
					{/* left navbar navigation filter */}
					<div className='hidden  sm:flex flex-col py-8 md:pl-6 md:pr-2 w-72  flex-shrink-0'>
						<nav className='sticky top-20 '>
							<h2 className='font-semibold text-gray-600 uppercase tracking-wide'>
								{t('Distance')}
								<span className='float-right font-medium lowercase'> {distance} Km </span>
							</h2>
							<div className='mt-3 pt-4 pb-2 sm:pl-3 md:pl-0' style={wrapperStyle}>
								<Slider
									trackStyle={{ backgroundColor: 'black', height: 7 }}
									handleStyle={{
										borderColor: 'black',
										borderWidth: 4,
										height: 18,
										width: 18,
										marginLeft: -7,
										marginTop: -6,
										backgroundColor: 'white',
									}}
									railStyle={{ backgroundColor: '#e9e9e9', height: 7 }}
									min={3}
									max={20}
									defaultValue={3}
									handle={handle}
									onAfterChange={distanceAfterChangeHandle}
								/>
							</div>
							<div className='mt-1 font-medium text-sm text-gray-600'>
								{t('Delivery cost')}{' '}
								<span>
									{distance > 3 ? (
										<NumberFormat
											value={distance * 2.15}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
											prefix='$'
										/>
									) : (
										<NumberFormat
											value={8.0}
											fixedDecimalScale={true}
											thousandSeparator=''
											decimalScale={2}
											displayType='text'
											prefix='$'
										/>
									)}
								</span>
							</div>

							<div className='mt-8'>
								<h2 className='font-semibold text-gray-600 uppercase tracking-wide'>{t('Type')}</h2>
								{sections.map((section, index) => (
									<div className='mt-2' key={index}>
										<Link
											className='uppercase -mx-3  py-1 px-3 text-sm font-medium flex items-center justify-between hover:bg-gray-200 rounded-sm'
											to={{
												pathname: match.url,
												search: '?section=' + section._id,
											}}
											onClick={() => setSelectedSection(section.name)}
										>
											{t(section.name)}
										</Link>
									</div>
								))}
							</div>
						</nav>
					</div>

					<div className='flex-1 flex flex-col overflow-hidden md:p-5 '>
						{/* Header section for other filter takeaway delivery options */}
						<header className='justify-between items-center sm:mt-6 p-1 px-0 '>
							{/* <div className='inline-flex font-medium mr-4'>Ristoranti nelle vicinanze </div> */}
							<div className='inline-flex font-medium mr-4 p-1 rounded-md bg-gray-100 transition duration-200 ease-in'>
								<button
									type='button'
									onClick={() => setPickUp(true)}
									className={`text-sm py-1 px-4 ${
										pickUp ? 'bg-white font-semibold' : ''
									}  rounded-md text-black  focus:outline-none transition duration-300 ease-linear`}
								>
									{t('Takeaway')}
								</button>
								<button
									type='button'
									onClick={() => setPickUp(false)}
									className={`text-sm py-1 px-4 ${
										!pickUp ? 'bg-white font-semibold' : ''
									}  rounded-md text-black  focus:outline-none transition duration-500 ease-out`}
								>
									{t('Delivery')}
								</button>
							</div>
							{selectedSection && (
								<div className='bg-indigo-100 inline-flex items-center text-sm rounded mt-2 mr-2 '>
									<span className='ml-2 mr-1 leading-relaxed truncate max-w-xs' x-text='tag'>
										{t(selectedSection)}
									</span>
									<button
										onClick={() => {
											setSelectedSection('');
											history.push(match.url);
										}}
										className='w-6 h-8 inline-block align-middle text-gray-500 hover:text-gray-600 focus:outline-none'
									>
										<svg
											className='w-6 h-6 fill-current mx-auto'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 24 24'
										>
											<path
												fillRule='evenodd'
												d='M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z'
											/>
										</svg>
									</button>
								</div>
							)}
						</header>

						{/* Main shops list founded */}
						<main className='flex flex-col antialiased '>
							{loading ? (
								<Loader />
							) : shops.length === 0 ? (
								<p className='text-center justify-items-center p-5 py-10 text-black'>
									<img
										loading='lazy'
										className='mx-auto  rounded-md overflow-hidden object-center object-none'
										alt='..'
										src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/no-product.png`}
									/>
									<span className='mt-4 text-lg font-medium'>{t('No shops found')}</span>
								</p>
							) : (
								<div className='grid grid-cols-1 sm:grid-cols-1  md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-1'>
									{shops.map((shop: any, index: number) => (
										<ShopCard
											key={index}
											shop={shop}
											distance={distance}
											deliveryCost={distance > 3 ? distance * 2.15 : 8}
											url={`${match.url}`}
										/>
									))}
								</div>
							)}
						</main>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default withRouter(ShopList);
