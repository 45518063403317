import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Router } from 'react-router';
import { createBrowserHistory } from 'history';

import { HelmetProvider } from 'react-helmet-async';

// redux
import { Provider } from 'react-redux';
import store from './rdx/store';

import './styles/tailwind.css';

import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

import App from './App';

const history = createBrowserHistory();

ReactDOM.render(
	<Suspense fallback={null}>
		<I18nextProvider i18n={i18n}>
			<Provider store={store}>
				<HelmetProvider>
					<Router history={history}>
						<App />
					</Router>
				</HelmetProvider>
			</Provider>
		</I18nextProvider>
	</Suspense>,
	document.getElementById('root')
);

// const app = (
// 	<HelmetProvider>
// 		<Suspense fallback={null}>
// 			<I18nextProvider i18n={i18n}>
// 				<Provider store={store}>
// 					<Router history={history}>
// 						<App />
// 					</Router>
// 				</Provider>
// 			</I18nextProvider>
// 		</Suspense>
// 	</HelmetProvider>
// );

// ReactDOM.hydrate(app, document.getElementById('root'));
