import React from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { CgDollar } from 'react-icons/cg';
import { IoMdAnalytics } from 'react-icons/io';
import { motion } from 'framer-motion';

import { useAppState } from '../../hooks/adsHooks';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

const DriverInfoIt = () => {
	return (
		<header className='mt-10 sm:mt-0'>
			<div className='flex flex-col max-w-6xl px-6  mx-auto md:flex-row'>
				<div className='flex flex-col justify-center pr-4 md:w-1/2'>
					{/* <div>
              <a
                className='inline-flex items-center pl-1 pr-2 mb-3 bg-indigo-100 rounded-full dark:bg-gray-800'
                href='/'
              >
                <span className='px-2 py-px mr-2 text-xs font-bold text-indigo-100 uppercase bg-indigo-700 rounded-full'>
                  New
                </span>
                <span className='text-sm leading-loose text-indigo-800 dark:text-gray-300'>
                  Visit our new products page →
                </span>
              </a>
            </div> */}
					<h1 className='text-5xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400'>
						Una nuova carriera con Faitu <span className='text-3xl'>Shop</span>
					</h1>
					<div className='prose prose-sm mt-6 mb-12 text-lg text-gray-700 dark:text-gray-400'>
						<p className=''>
							Consegna prodotti a domicilio in completa autonomia, per guadagnare in modo semplice
							senza gli oneri di un lavoro full-time
						</p>
					</div>
				</div>

				<motion.div
					className='md:w-1/2'
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ duration: 0.5 }}
				>
					<img
						className='w-full'
						// src='https://freepikpsd.com/media/2019/11/delivery-man-png-Images.png'
						src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/delivery-man.png`}
						alt='Registration form header'
					/>
				</motion.div>
			</div>

			<main>
				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16  md:flex-row'>
					<div className='grid gap-8 md:grid-cols-3'>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                  <path
                    fillRule='evenodd'
                    d='M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z'
                    clipRule='evenodd'
                  ></path>
                </svg> */}
								<BiTimeFive size={32} />
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>Decidi tu quando lavorare</h3>
								<p className=''>Basterà accedere al modulo ordini per iniziare</p>
							</div>
						</div>
						<div className='flex theme-dark'>
							<div className='flex items-center w-full p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
								<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
									{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                    <path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'></path>
                  </svg> */}
									<CgDollar size={32} />
								</div>
								<div>
									<h3 className='text-2xl font-semibold'>Guadagni immediati</h3>
									<p className=''>Guadagni ad ogni consegna effettuata</p>
								</div>
							</div>
						</div>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                  <path
                    fillRule='evenodd'
                    d='M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg> */}
								<IoMdAnalytics size={32} />
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>Commissioni altissime</h3>
								<p>
									Da 3 km di raggio guadagni già <strong>$12</strong> ad ogni consegna
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16 mt-36 '>
					<div className='mx-auto text-4xl font-extrabold text-center mb-10'>
						Come diventare corriere
					</div>

					<div className='grid gap-6 mb-8 md:grid-cols-2'>
						<div className='min-w-0 p-4 bg-red-600 text-white rounded-lg shadow-xs '>
							<h4 className='mb-4 font-semibold text-xl dark:text-gray-300'>Registrati su Faitu</h4>
							<p>
								Crea il tuo account su faitu. In seguito il tuo account verrà collegato con la
								funzione di corriere
							</p>
						</div>
						<div className='min-w-0 p-4 text-white bg-purple-600 rounded-lg shadow-xs'>
							<h4 className='mb-4 font-semibold text-xl'>Inizia il servizio</h4>
							<p>
								dal menu profilo apri la pagina <strong>Delivery manager</strong>. Attendi che
								arrivino le richieste di consegna
							</p>
						</div>
					</div>
				</section>

				<section className='w-full mx-auto max-w-6xl px-6 '>
					<div className='min-w-0 p-4 bg-green-600 text-white rounded-md shadow '>
						<h4 className='mb-4 font-semibold text-xl dark:text-gray-300'>
							Indispensabile per iniziare
						</h4>
						<p>
							Procurati un sistema di pagamento che accetti le carte di credito, e un pò di moneta
							in caso i clienti volessero pagare a contanti. È opportuno che tu abbia un blocco{' '}
							<strong>per le ricevute</strong> da consegnare al cliente.
						</p>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16 mt-36 '>
					<div className=' text-4xl font-extrabold  mb-10'>Come funziona</div>

					<div className='grid gap-6 mb-8 md:grid-cols-2'>
						<div className='min-w-0 p-4  text-gray-800 '>
							<h4 className='mb-4 font-semibold text-xl dark:text-gray-300'>Entrata in servizio</h4>
							<div>
								<p>
									Dovrai collegarti a Faitu tramite ed accedere tramite le tue credenziali. Sarà
									quindi sufficiente aprire la pagina <strong>Driver Manager</strong> e premere il
									tasto inizio turno.
								</p>
								<p className='mt-2'>
									Ad ogni ordine in entrata nel raggio di 10km riceverai una notifica. Potrai quindi
									scegliere se accettare la richiesta di consegna.
								</p>
							</div>
						</div>
						<div className='min-w-0 p-4 text-gray-800 '>
							<h4 className='mb-4 font-semibold text-xl'>I tuoi guadagni</h4>
							<div>
								<p>
									Ogni ordine di consegna contiene tutti i dettagli, compresi i costi di consegna, i
									tempi di preparazione, dettagli del cliente e del negozio.
								</p>
								<p className='mt-2'>
									Quando ritiri l'ordine presso il negozio dovrai <strong>pagare</strong> l'importo
									tottale meno i costi di consegna.{' '}
									<strong>
										Ad ogni ritiro, il negozio ti concederà $4 di sconto dal totale della fattura.
									</strong>
								</p>
								<p className='italic font-bold mt-2'>
									Il segno $ è indicativo. Viene applicata la valuta locale.
								</p>
								<p className='font-bold mt-2 text-red-800'>
									Prima di iniziare il turno, prepara un fondo cassa per poter iniziare l'attività.
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className='mx-auto max-w-6xl mt-16 mb-16'>
					<section className='p-2  rounded'>
						<div className='flex flex-col md:flex-row'>
							<div className='md:w-1/2 p-4 text-sm'>
								<div className='text-3xl'>
									Domande <span className='font-medium'>Frequenti</span>
								</div>
								<div className='my-2'>Alcune delle domande più frequenti</div>
								{/* <div className='mb-2'>Confused about how we can improve your business ?</div>
                <div className='text-xs text-gray-600'>Dive into our FAQ for more details</div> */}
							</div>
							<div className='md:w-1/2'>
								<div className='p-4'>
									<Accordion>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg text-gray-800 cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>I clienti sono sicuri?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p className='text-gray-700'>
													Si. Ogni cliente per poter effettuare ordini su Faitu deve procedere con
													la verifica del numero di telefono.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>Non ho un contratto di lavoro?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													No. Non sei un dipendente di Faitu, ma sei un{' '}
													<strong>lavoratore indipendente.</strong> Dovrai quindi segnalare alle
													autorità locali la tua attività e registrarti.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>Come dichiaro i miei guadagni?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													Faitu ti mette a disposizione tutti gli strumenti necessari per estrarre
													tutte le consegne effettaute. A tal proposito dovrai tenere le ricevute
													delle spese conseguite durante i turni di lavoro. Come per esempio i costi
													di benzina.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>
													Non ho una borsa termica. Me la consegnate voi?
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													Regaliamo la borsa termica a tutti i corrieri che hanno raggiunto le 100
													consegne. Se desideri, è possibile acquistarla al costo di €75.00
												</p>
											</AccordionItemPanel>
										</AccordionItem>
									</Accordion>
								</div>
							</div>
						</div>
					</section>
				</section>
			</main>
		</header>
	);
};

const DriverInfoEn = () => {
	return (
		<header className='mt-10 sm:mt-0'>
			<div className='flex flex-col max-w-6xl px-6  mx-auto md:flex-row'>
				<div className='flex flex-col justify-center pr-4 md:w-1/2'>
					{/* <div>
              <a
                className='inline-flex items-center pl-1 pr-2 mb-3 bg-indigo-100 rounded-full dark:bg-gray-800'
                href='/'
              >
                <span className='px-2 py-px mr-2 text-xs font-bold text-indigo-100 uppercase bg-indigo-700 rounded-full'>
                  New
                </span>
                <span className='text-sm leading-loose text-indigo-800 dark:text-gray-300'>
                  Visit our new products page →
                </span>
              </a>
            </div> */}
					<h1 className='text-5xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400'>
						A new career with Faitu <span className='text-3xl'>Shop</span>
					</h1>
					<div className='prose prose-sm mt-6 mb-12 text-lg text-gray-700 dark:text-gray-400'>
						<p className=''>
							Home delivery of products in complete autonomy, to earn easily without the burdens of
							a full-time job
						</p>
					</div>
				</div>

				<motion.div
					className='md:w-1/2'
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ duration: 0.5 }}
				>
					<img
						className='w-full'
						// src='https://freepikpsd.com/media/2019/11/delivery-man-png-Images.png'
						src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/delivery-man.png`}
						alt='Registration form header'
					/>
				</motion.div>
			</div>

			<main>
				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16  md:flex-row'>
					<div className='grid gap-8 md:grid-cols-3'>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                  <path
                    fillRule='evenodd'
                    d='M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z'
                    clipRule='evenodd'
                  ></path>
                </svg> */}
								<BiTimeFive size={32} />
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>Decide when to work</h3>
								<p className=''>
									Just log into <strong>order management</strong> to get started
								</p>
							</div>
						</div>
						<div className='flex theme-dark'>
							<div className='flex items-center w-full p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
								<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
									{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                    <path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'></path>
                  </svg> */}
									<CgDollar size={32} />
								</div>
								<div>
									<h3 className='text-2xl font-semibold'>Immediate earnings</h3>
									<p className=''>Earnings for each delivery made</p>
								</div>
							</div>
						</div>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                  <path
                    fillRule='evenodd'
                    d='M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg> */}
								<IoMdAnalytics size={32} />
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>high commissions</h3>
								<p>
									From 3 km of radius you already gain <strong>$12</strong> at each delivery
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16 mt-36 '>
					<div className='mx-auto text-4xl font-extrabold text-center mb-10'>
						How to become a delivery boy
					</div>

					<div className='grid gap-6 mb-8 md:grid-cols-2'>
						<div className='min-w-0 p-4 bg-red-600 text-white rounded-lg shadow-xs '>
							<h4 className='mb-4 font-semibold text-xl dark:text-gray-300'>Register on Faitu</h4>
							<p>
								Create your account on faitu. Log in to your profile and check the box{' '}
								<strong>bellboy boy for home deliveries</strong>
							</p>
						</div>
						<div className='min-w-0 p-4 text-white bg-purple-600 rounded-lg shadow-xs'>
							<h4 className='mb-4 font-semibold text-xl'>The service begins</h4>
							<p>
								from the profile menu open the page <strong>Delivery manager</strong>. Wait for that
								requests for delivery arrive.
							</p>
						</div>
					</div>
				</section>

				<section className='w-full mx-auto max-w-6xl px-6 '>
					<div className='min-w-0 p-4 bg-green-600 text-white rounded-md shadow '>
						<h4 className='mb-4 font-semibold text-xl dark:text-gray-300'>
							Essential to get started
						</h4>
						<p>
							Get a payment system that accepts credit cards, and some money in case customers want
							to pay in cash. It is appropriate that you have a block <strong>for receipts</strong>{' '}
							to be delivered to the customer.
						</p>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16 mt-36 '>
					<div className=' text-4xl font-extrabold  mb-10'>How does it work</div>

					<div className='grid gap-6 mb-8 md:grid-cols-2'>
						<div className='min-w-0 p-4  text-gray-800 '>
							<h4 className='mb-4 font-semibold text-xl dark:text-gray-300'>Entrata in servizio</h4>
							<div>
								<p>
									Dovrai collegarti a Faitu tramite ed accedere tramite le tue credenziali. Sarà
									quindi sufficiente aprire la pagina <strong>Driver Manager</strong> e premere il
									tasto inizio turno.
								</p>
								<p className='mt-2'>
									Ad ogni ordine in entrata nel raggio di 10km riceverai una notifica. Potrai quindi
									scegliere se accettare la richiesta di consegna.
								</p>
							</div>
						</div>
						<div className='min-w-0 p-4 text-gray-800 '>
							<h4 className='mb-4 font-semibold text-xl'>I tuoi guadagni</h4>
							<div>
								<p>
									Ogni ordine di consegna contiene tutti i dettagli, compresi i costi di consegna, i
									tempi di preparazione, dettagli del cliente e del negozio.
								</p>
								<p className='mt-2'>
									Quando ritiri l'ordine presso il negozio dovrai <strong>pagare</strong> l'importo
									tottale meno i costi di consegna.{' '}
									<strong>
										Ad ogni ritiro, il negozio ti concederà $4 di sconto dal totale della fattura.
									</strong>
								</p>
								<p className='italic font-bold mt-2'>
									Il segno $ è indicativo. Viene applicata la valuta locale.
								</p>
								<p className='font-bold mt-2 text-red-800'>
									Prima di iniziare il turno, prepara un fondo cassa per poter iniziare l'attività.
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className='mx-auto max-w-6xl mt-16 mb-16'>
					<section className='p-2  rounded'>
						<div className='flex flex-col md:flex-row'>
							<div className='md:w-1/2 p-4 text-sm'>
								<div className='text-3xl'>
									Domande <span className='font-medium'>Frequenti</span>
								</div>
								<div className='my-2'>Alcune delle domande più frequenti</div>
								{/* <div className='mb-2'>Confused about how we can improve your business ?</div>
                <div className='text-xs text-gray-600'>Dive into our FAQ for more details</div> */}
							</div>
							<div className='md:w-1/2'>
								<div className='p-4'>
									<Accordion>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg text-gray-800 cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>I clienti sono sicuri?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p className='text-gray-700'>
													Si. Ogni cliente per poter effettuare ordini su Faitu deve procedere con
													la verifica del numero di telefono.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>Non ho un contratto di lavoro?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													No. Non sei un dipendente di Faitu, ma sei un{' '}
													<strong>lavoratore indipendente.</strong> Dovrai quindi segnalare alle
													autorità locali la tua attività e registrarti.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>Come dichiaro i miei guadagni?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													Faitu ti mette a disposizione tutti gli strumenti necessari per estrarre
													tutte le consegne effettaute. A tal proposito dovrai tenere le ricevute
													delle spese conseguite durante i turni di lavoro. Come per esempio i costi
													di benzina.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>
													Non ho una borsa termica. Me la consegnate voi?
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													Regaliamo la borsa termica a tutti i corrieri che hanno raggiunto le 100
													consegne. Se desideri, è possibile acquistarla al costo di €75.00
												</p>
											</AccordionItemPanel>
										</AccordionItem>
									</Accordion>
								</div>
							</div>
						</div>
					</section>
				</section>
			</main>
		</header>
	);
};

const DriverInfoFr = () => {
	return (
		<header className='mt-10 sm:mt-0'>
			<div className='flex flex-col max-w-6xl px-6  mx-auto md:flex-row'>
				<div className='flex flex-col justify-center pr-4 md:w-1/2'>
					{/* <div>
              <a
                className='inline-flex items-center pl-1 pr-2 mb-3 bg-indigo-100 rounded-full dark:bg-gray-800'
                href='/'
              >
                <span className='px-2 py-px mr-2 text-xs font-bold text-indigo-100 uppercase bg-indigo-700 rounded-full'>
                  New
                </span>
                <span className='text-sm leading-loose text-indigo-800 dark:text-gray-300'>
                  Visit our new products page →
                </span>
              </a>
            </div> */}
					<h1 className='text-5xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400'>
						Una nuova carriera con Faitu <span className='text-3xl'>Shop</span>
					</h1>
					<div className='prose prose-sm mt-6 mb-12 text-lg text-gray-700 dark:text-gray-400'>
						<p className=''>
							Consegna prodotti a domicilio in completa autonomia, per guadagnare in modo semplice
							senza gli oneri di un lavoro full-time
						</p>
					</div>
				</div>

				<motion.div
					className='md:w-1/2'
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ duration: 0.5 }}
				>
					<img
						className='w-full'
						// src='https://freepikpsd.com/media/2019/11/delivery-man-png-Images.png'
						src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/delivery-man.png`}
						alt='Registration form header'
					/>
				</motion.div>
			</div>

			<main>
				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16  md:flex-row'>
					<div className='grid gap-8 md:grid-cols-3'>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                  <path
                    fillRule='evenodd'
                    d='M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z'
                    clipRule='evenodd'
                  ></path>
                </svg> */}
								<BiTimeFive size={32} />
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>Decidi tu quando lavorare</h3>
								<p className=''>Basterà accedere al modulo ordini per iniziare</p>
							</div>
						</div>
						<div className='flex theme-dark'>
							<div className='flex items-center w-full p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
								<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
									{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                    <path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'></path>
                  </svg> */}
									<CgDollar size={32} />
								</div>
								<div>
									<h3 className='text-2xl font-semibold'>Guadagni immediati</h3>
									<p className=''>Guadagni ad ogni consegna effettuata</p>
								</div>
							</div>
						</div>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                  <path
                    fillRule='evenodd'
                    d='M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg> */}
								<IoMdAnalytics size={32} />
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>Commissioni altissime</h3>
								<p>
									Da 3 km di raggio guadagni già <strong>$12</strong> ad ogni consegna
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16 mt-36 '>
					<div className='mx-auto text-4xl font-extrabold text-center mb-10'>
						Come diventare corriere
					</div>

					<div className='grid gap-6 mb-8 md:grid-cols-2'>
						<div className='min-w-0 p-4 bg-red-600 text-white rounded-lg shadow-xs '>
							<h4 className='mb-4 font-semibold text-xl dark:text-gray-300'>Registrati su Faitu</h4>
							<p>
								Crea il tuo account su faitu. In seguito il tuo account verrà collegato con la
								funzione di corriere
							</p>
						</div>
						<div className='min-w-0 p-4 text-white bg-purple-600 rounded-lg shadow-xs'>
							<h4 className='mb-4 font-semibold text-xl'>Accedi al tuo profilo personale</h4>
							<p>
								Fatta la registrazione, accedi al tuo profilo. Nella sezione{' '}
								<strong>Panoramica</strong> puoi selezionare il controllo{' '}
								<strong>Driver per le consegne a domicilio.</strong> Fatti questi passaggi le
								funzioni di gestione driver verranno automaticamente abilitate.
							</p>
						</div>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16 mt-36 '>
					<div className=' text-4xl font-extrabold  mb-10'>Come funziona</div>

					<div className='grid gap-6 mb-8 md:grid-cols-2'>
						<div className='min-w-0 p-4  text-gray-800 '>
							<h4 className='mb-4 font-semibold text-xl dark:text-gray-300'>Entrata in servizio</h4>
							<div>
								<p>
									Dovrai collegarti a Faitu tramite ed accedere tramite le tue credenziali. Sarà
									quindi sufficiente aprire la pagina <strong>Driver Manager</strong> e premere il
									tasto inizio turno.
								</p>
								<p className='mt-2'>
									Ad ogni ordine in entrata nel raggio di 10km riceverai una notifica. Potrai quindi
									scegliere se accettare la richiesta di consegna.
								</p>
							</div>
						</div>
						<div className='min-w-0 p-4 text-gray-800 '>
							<h4 className='mb-4 font-semibold text-xl'>I tuoi guadagni</h4>
							<div>
								<p>
									Ogni ordine di consegna contiene tutti i dettagli, compresi i costi di consegna, i
									tempi di preparazione, dettagli del cliente e del negozio.
								</p>
								<p className='mt-2'>
									Quando ritiri l'ordine presso il negozio dovrai <strong>pagare</strong> l'importo
									tottale meno i costi di consegna.{' '}
									<strong>
										Ad ogni ritiro, il negozio ti concederà $4 di sconto dal totale della fattura.
									</strong>
								</p>
								<p className='italic font-bold mt-2'>
									Il segno $ è indicativo. Viene applicata la valuta locale.
								</p>
								<p className='font-bold mt-2 text-red-800'>
									Prima di iniziare il turno, prepara un fondo cassa per poter iniziare l'attività.
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className='mx-auto max-w-6xl mt-16 mb-16'>
					<section className='p-2  rounded'>
						<div className='flex flex-col md:flex-row'>
							<div className='md:w-1/2 p-4 text-sm'>
								<div className='text-3xl'>
									Domande <span className='font-medium'>Frequenti</span>
								</div>
								<div className='my-2'>Alcune delle domande più frequenti</div>
								{/* <div className='mb-2'>Confused about how we can improve your business ?</div>
                <div className='text-xs text-gray-600'>Dive into our FAQ for more details</div> */}
							</div>
							<div className='md:w-1/2'>
								<div className='p-4'>
									<Accordion>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg text-gray-800 cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>I clienti sono sicuri?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p className='text-gray-700'>
													Si. Ogni cliente per poter effettuare ordini su Faitu deve procedere con
													la verifica del numero di telefono.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>Non ho un contratto di lavoro?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													No. Non sei un dipendente di Faitu, ma sei un{' '}
													<strong>lavoratore indipendente.</strong> Dovrai quindi segnalare alle
													autorità locali la tua attività e registrarti.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>Come dichiaro i miei guadagni?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													Faitu ti mette a disposizione tutti gli strumenti necessari per estrarre
													tutte le consegne effettaute. A tal proposito dovrai tenere le ricevute
													delle spese conseguite durante i turni di lavoro. Come per esempio i costi
													di benzina.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>
													Non ho una borsa termica. Me la consegnate voi?
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													Regaliamo la borsa termica a tutti i corrieri che hanno raggiunto le 100
													consegne. Se desideri, è possibile acquistarla al costo di €75.00
												</p>
											</AccordionItemPanel>
										</AccordionItem>
									</Accordion>
								</div>
							</div>
						</div>
					</section>
				</section>
			</main>
		</header>
	);
};

const DriverInfoDe = () => {
	return (
		<header className='mt-10 sm:mt-0'>
			<div className='flex flex-col max-w-6xl px-6  mx-auto md:flex-row'>
				<div className='flex flex-col justify-center pr-4 md:w-1/2'>
					{/* <div>
              <a
                className='inline-flex items-center pl-1 pr-2 mb-3 bg-indigo-100 rounded-full dark:bg-gray-800'
                href='/'
              >
                <span className='px-2 py-px mr-2 text-xs font-bold text-indigo-100 uppercase bg-indigo-700 rounded-full'>
                  New
                </span>
                <span className='text-sm leading-loose text-indigo-800 dark:text-gray-300'>
                  Visit our new products page →
                </span>
              </a>
            </div> */}
					<h1 className='text-5xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400'>
						Una nuova carriera con Faitu <span className='text-3xl'>Shop</span>
					</h1>
					<div className='prose prose-sm mt-6 mb-12 text-lg text-gray-700 dark:text-gray-400'>
						<p className=''>
							Consegna prodotti a domicilio in completa autonomia, per guadagnare in modo semplice
							senza gli oneri di un lavoro full-time
						</p>
					</div>
				</div>

				<motion.div
					className='md:w-1/2'
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ duration: 0.5 }}
				>
					<img
						className='w-full'
						// src='https://freepikpsd.com/media/2019/11/delivery-man-png-Images.png'
						src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/delivery-man.png`}
						alt='Registration form header'
					/>
				</motion.div>
			</div>

			<main>
				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16  md:flex-row'>
					<div className='grid gap-8 md:grid-cols-3'>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                  <path
                    fillRule='evenodd'
                    d='M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z'
                    clipRule='evenodd'
                  ></path>
                </svg> */}
								<BiTimeFive size={32} />
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>Decidi tu quando lavorare</h3>
								<p className=''>Basterà accedere al modulo ordini per iniziare</p>
							</div>
						</div>
						<div className='flex theme-dark'>
							<div className='flex items-center w-full p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
								<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
									{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                    <path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'></path>
                  </svg> */}
									<CgDollar size={32} />
								</div>
								<div>
									<h3 className='text-2xl font-semibold'>Guadagni immediati</h3>
									<p className=''>Guadagni ad ogni consegna effettuata</p>
								</div>
							</div>
						</div>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								{/* <svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
                  <path
                    fillRule='evenodd'
                    d='M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg> */}
								<IoMdAnalytics size={32} />
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>Commissioni altissime</h3>
								<p>
									Da 3 km di raggio guadagni già <strong>$12</strong> ad ogni consegna
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16 mt-36 '>
					<div className='mx-auto text-4xl font-extrabold text-center mb-10'>
						Come diventare corriere
					</div>

					<div className='grid gap-6 mb-8 md:grid-cols-2'>
						<div className='min-w-0 p-4 bg-red-600 text-white rounded-lg shadow-xs '>
							<h4 className='mb-4 font-semibold text-xl dark:text-gray-300'>Registrati su Faitu</h4>
							<p>
								Crea il tuo account su faitu. In seguito il tuo account verrà collegato con la
								funzione di corriere
							</p>
						</div>
						<div className='min-w-0 p-4 text-white bg-purple-600 rounded-lg shadow-xs'>
							<h4 className='mb-4 font-semibold text-xl'>Accedi al tuo profilo personale</h4>
							<p>
								Fatta la registrazione, accedi al tuo profilo. Nella sezione{' '}
								<strong>Panoramica</strong> puoi selezionare il controllo{' '}
								<strong>Driver per le consegne a domicilio.</strong> Fatti questi passaggi le
								funzioni di gestione driver verranno automaticamente abilitate.
							</p>
						</div>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl px-6 mx-auto pt-16 mt-36 '>
					<div className=' text-4xl font-extrabold  mb-10'>Come funziona</div>

					<div className='grid gap-6 mb-8 md:grid-cols-2'>
						<div className='min-w-0 p-4  text-gray-800 '>
							<h4 className='mb-4 font-semibold text-xl dark:text-gray-300'>Entrata in servizio</h4>
							<div>
								<p>
									Dovrai collegarti a Faitu tramite ed accedere tramite le tue credenziali. Sarà
									quindi sufficiente aprire la pagina <strong>Driver Manager</strong> e premere il
									tasto inizio turno.
								</p>
								<p className='mt-2'>
									Ad ogni ordine in entrata nel raggio di 10km riceverai una notifica. Potrai quindi
									scegliere se accettare la richiesta di consegna.
								</p>
							</div>
						</div>
						<div className='min-w-0 p-4 text-gray-800 '>
							<h4 className='mb-4 font-semibold text-xl'>I tuoi guadagni</h4>
							<div>
								<p>
									Ogni ordine di consegna contiene tutti i dettagli, compresi i costi di consegna, i
									tempi di preparazione, dettagli del cliente e del negozio.
								</p>
								<p className='mt-2'>
									Quando ritiri l'ordine presso il negozio dovrai <strong>pagare</strong> l'importo
									tottale meno i costi di consegna.{' '}
									<strong>
										Ad ogni ritiro, il negozio ti concederà $4 di sconto dal totale della fattura.
									</strong>
								</p>
								<p className='italic font-bold mt-2'>
									Il segno $ è indicativo. Viene applicata la valuta locale.
								</p>
								<p className='font-bold mt-2 text-red-800'>
									Prima di iniziare il turno, prepara un fondo cassa per poter iniziare l'attività.
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className='mx-auto max-w-6xl mt-16 mb-16'>
					<section className='p-2  rounded'>
						<div className='flex flex-col md:flex-row'>
							<div className='md:w-1/2 p-4 text-sm'>
								<div className='text-3xl'>
									Domande <span className='font-medium'>Frequenti</span>
								</div>
								<div className='my-2'>Alcune delle domande più frequenti</div>
								{/* <div className='mb-2'>Confused about how we can improve your business ?</div>
                <div className='text-xs text-gray-600'>Dive into our FAQ for more details</div> */}
							</div>
							<div className='md:w-1/2'>
								<div className='p-4'>
									<Accordion>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg text-gray-800 cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>I clienti sono sicuri?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p className='text-gray-700'>
													Si. Ogni cliente per poter effettuare ordini su Faitu deve procedere con
													la verifica del numero di telefono.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>Non ho un contratto di lavoro?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													No. Non sei un dipendente di Faitu, ma sei un{' '}
													<strong>lavoratore indipendente.</strong> Dovrai quindi segnalare alle
													autorità locali la tua attività e registrarti.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>Come dichiaro i miei guadagni?</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													Faitu ti mette a disposizione tutti gli strumenti necessari per estrarre
													tutte le consegne effettaute. A tal proposito dovrai tenere le ricevute
													delle spese conseguite durante i turni di lavoro. Come per esempio i costi
													di benzina.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>
													Non ho una borsa termica. Me la consegnate voi?
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													Regaliamo la borsa termica a tutti i corrieri che hanno raggiunto le 100
													consegne. Se desideri, è possibile acquistarla al costo di €75.00
												</p>
											</AccordionItemPanel>
										</AccordionItem>
									</Accordion>
								</div>
							</div>
						</div>
					</section>
				</section>
			</main>
		</header>
	);
};

const DriverInfoPage: React.FC = () => {
	const { lang } = useAppState().app;

	switch (lang) {
		case 'it':
			return <DriverInfoIt />;
		case 'en':
			return <DriverInfoEn />;
		case 'fr':
			return <DriverInfoFr />;
		case 'de':
			return <DriverInfoDe />;
		default:
			return <DriverInfoIt />;
	}
};

export default DriverInfoPage;
