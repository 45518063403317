import React from 'react';

import Select from 'react-select';

const FormItem = ({
  label,
  type = 'text',
  fieldname = '',
  required = false,
  hidden = false,
  ...otherProps
}: any) => (
  <>
    {type === 'text' || type === 'number' ? (
      label === undefined ? (
        <input
          type={type}
          // autoComplete='off'
          {...otherProps}
          className=' focus:ring-black focus:border-black border-2 h-10  w-full  border-gray-200   '
        />
      ) : (
        <label
          htmlFor={fieldname}
          className={`uppercase tracking-wide text-black text-xs ${hidden ? 'hidden' : ''}
        ${required ? 'font-bold' : 'font-semibold'} mb-2`}
        >
          {label}
          {required ? '*' : ''}

          <input
            type={type}
            autoComplete='off'
            {...otherProps}
            className=' focus:ring-black focus:border-black border-2 h-10  w-full  border-gray-200  '
          />
        </label>
      )
    ) : type === 'select-dropdown' ? (
      <React.Fragment>
        <label
          htmlFor={fieldname}
          className={`uppercase tracking-wide text-black text-xs
        ${required ? 'font-bold' : 'font-semibold'} mb-2`}
        >
          {label}
          {required ? '*' : ''}
        </label>
        <Select
          name='Brand'
          className='ring-0 ring-offset-0 tracking-wide text-sm focus:ring-0 focus:ring-offset-0 '
          {...otherProps}
        />
      </React.Fragment>
    ) : type === 'text-area' ? (
      <label
        htmlFor={fieldname}
        className={`uppercase tracking-wide text-black text-xs
    ${required ? 'font-bold' : 'font-semibold'} mb-2`}
      >
        {label}
        {required ? '*' : ''}

        <textarea
          type={type}
          cols={40}
          rows={6}
          autoComplete='off'
          {...otherProps}
          className='mt-1 focus:ring-black focus:border-black border-2  w-full  border-gray-300  '
        />
      </label>
    ) : type === 'checkbox' ? (
      <label
        htmlFor={fieldname}
        className={` ${
          required ? 'font-bold' : ''
        } flex justify-start items-center uppercase tracking-wide text-black text-xs`}
      >
        <div className='border-2 rounded-sm border-gray-400 focus:border-black active:border-black w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 '>
          <input type={type} autoComplete='off' {...otherProps} className='opacity-0 absolute ' />
          <svg
            className='fill-current hidden w-4 h-4  text-black pointer-events-none bg-gray-400 '
            viewBox='0 0 20 20'
          ></svg>
        </div>
        <div className='text-sm'>
          {label} {required ? '*' : ''}
        </div>
      </label>
    ) : (
      ''
    )}
  </>
);

export default FormItem;
