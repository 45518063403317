import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import SideBarFilter from '../UI/classFied/sideBarFilter';
import Pagination from '../UI/classFied/pagination';
import Loader from '../Components/loader';
import { FuSmallCard } from '../Components/fu-cards';
// redux stuff
import { useDispatch } from 'react-redux';
import * as filtersAction from '../rdx/actions/filters.actions';
import { useAppState, useCategories } from '../hooks/adsHooks';
import { IAd } from '../Interfaces/dataModel';

import ClassFiedItem from '../UI/classFied/classFiedItem';
import { SeoTag } from '../utils/Seo';

const NoListingFound: React.FC = () => {
	return (
		<div className='text-center flext w-full  py-20'>
			<img
				className='mx-auto bg-amber-300 bg-stripes bg-stripes-white h-72 rounded-md overflow-hidden object-center object-fit '
				src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/not-found.jpeg`}
				alt='..'
			/>

			<h1 className='text-2xl font-semibold text-gray-700'>
				Nessun risultato trovato nella tua zona.
			</h1>
		</div>
	);
};

const ClassFied: React.FC = () => {
	const { t } = useTranslation();
	const [listing, setListing] = useState<IAd[]>([]);
	const appState = useAppState();
	const location = useLocation();
	const { category } = useParams<any>();
	// const [query, setQuery] = useState<string>();

	const dispatch = useDispatch();

	const menu: any[] = useCategories().menu;
	const [mobileCategories, setMobileCategories] = useState<any>();

	useEffect(() => {
		const m = menu.filter((m: any) => m._id === category);
		setMobileCategories(m[0]);

		// if (category === 'find') {
		// 	setQuery(location.search.split('=')[1]);
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let filters: any;
		const [priceFrom, priceTo] = appState.filters.priceRange;

		const priceObjectRange = { priceFrom, priceTo };

		filters = {
			...priceObjectRange,
			distance: appState.filters.distance,
			unit: appState.filters.unit,
			page: appState.filters.page,
		};

		console.log(filters);

		const href = location.pathname + '?' + queryString.stringify(filters, { encode: false });

		window.history.replaceState(
			{
				...window.history.state,
			},
			'',
			href
		);
	}, [appState.filters, location.pathname]);

	useEffect(() => {
		if (appState.filters.listings && !appState.filters.loading) {
			console.log('UseEffect classfied set data', appState.filters.listings);
			setListing(appState.filters.listings);
		}
	}, [appState.filters.loading, appState.filters.listings]);

	const onPaginationClick = (page: number) => {
		dispatch(filtersAction.setPage(page));
	};

	return (
		<>
			<SeoTag
				title={`Faitu marketplace`}
				description='Su faitu potrai inserire annunci gratuiti per vendere, affittare o scambiare. Dai mobili agli appartamenti, all&#39;elettronica, il lavoro o un&#39;auto - con faitu faiprima'
			/>
			<div className='max-w-screen-2xl mx-auto md:mt-5'>
				{/* Mobile only */}
				<section className='sticky top-16 pb-2 z-50  md:hidden lg:hidden xl:hidden bg-white shadow-md'>
					{/* Section Mobile */}
					{mobileCategories && (
						<div className='flex justify-items-center px-3 overflow-y-auto overflow-x-auto space-x-3 whitespace-nowrap'>
							{/* <div className='flex text-center items-center justify-items-center whitespace-nowrap mr-2 font-semibold'><HiHome /> Home</div> */}
							{mobileCategories?.children?.map((el: any, index: number) => (
								<div key={index} className='flex font-medium  text-center whitespace-nowrap  '>
									<a className='pb-1' href={`/classfied/${el._id}`}>
										{t(el.name)}
									</a>
								</div>
							))}
						</div>
					)}
				</section>
				{/* </div> */}

				<div className='mb-10 min-w-0 w-full flex-auto mt-5 lg:static lg:max-h-full lg:overflow-visible lg:px-4'>
					{/* New listing layout */}
					<div className='flex'>
						{/* sidebar */}
						<SideBarFilter />
						{/* Content-ads */}
						{/* {appState.filters.loading && <Loader />} */}
						<div
							className={`flex-1 px-1 md:px-4 ${appState.filters.loading ? 'opacity-25' : ''}  `}
						>
							<div className='w-full flex flex-col'>
								{appState.filters.total > 10 && (
									<div className='w-full flex space-x-1'>
										<div className='flex text-sm text-gray-500 px-2'>
											{appState.filters.total} risultati
										</div>
									</div>
								)}
								{/* 
							<div className='p-1'>
								{appState.filters.loading ? (
									<Loader />
								) : listing.length > 0 ? (
									listing.map((item: IAd, index: number) => (
										<ClassFiedItem key={index} item={item} />
									))
								) : (
									<div className='text-center flext w-full  py-20'>
										<img
											className='mx-auto bg-amber-300 bg-stripes bg-stripes-white h-72 rounded-md overflow-hidden object-center object-fit '
											src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/not-found.jpeg`}
											alt='..'
										/>

										<h1 className='text-2xl font-semibold text-gray-700'>
											Nessun risultato trovato nella tua zona.
										</h1>
									</div>
								)}
							</div> */}

								<div className='p-1'>
									{appState.filters.loading ? (
										<Loader />
									) : appState.filters.listings.length > 0 ? (
										listing.map((item: IAd, index: number) => (
											<ClassFiedItem key={index} item={item} />
										))
									) : (
										<NoListingFound />
									)}
								</div>

								{listing.length > 10 && !appState.filters.loading && (
									// paginate
									// mobile
									<>
										<div className='w-full block sm:hidden'>
											<Pagination
												total={appState.filters.total}
												current={appState.filters.page}
												onPageChange={onPaginationClick}
												mobile={true}
											/>
										</div>

										{/* Desktop */}
										<div className='w-full hidden sm:block'>
											<Pagination
												total={appState.filters.total}
												current={appState.filters.page}
												onPageChange={onPaginationClick}
												mobile={false}
											/>
										</div>
									</>
								)}
							</div>
						</div>

						{/* right-side-bar-ads */}
						<div className='hidden lg:flex w-1/5'>
							<div className='mb-1 w-full flex-col'>
								<div className='uppercase font-semibold text-xs tracking-wider flex flex-row items-center justify-start w-full py-2'>
									{t('Sponsored')}
								</div>

								{appState.filters.sideBar?.map((ad: IAd, index: number) => (
									<div className='mt-1' key={index}>
										<FuSmallCard
											title={ad.title}
											price={ad.price.amount}
											titleOverlay={false}
											currency={ad.price.currency}
											unitPrice={ad.price.unit ? ad.price.unit : ''}
											badge={t(ad.adType)}
											badgeBgColor='bg-yellow-400'
											image={
												ad.gallery.length > 0
													? ad.gallery[0]
													: `${process.env.REACT_APP_SERVER_URL_ROOT}/images/no-thumbnail.png`
											}
											url={`/${appState.app.lang ? appState.app.lang : 'it'}/ads-detail/${ad._id}`}
										/>
									</div>
								))}

								<div className='p-0 pt-0 pl-0 mt-5'>
									<div className='c-card block bg-white border border-gray-200  overflow-hidden'>
										<div className='relative pb-32 overflow-hidden'>
											<img
												className=' border-none absolute inset-0 h-full w-full object-cover transition duration-500 ease-in-out transform hover:scale-110'
												src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/food-banner.jpg`}
												alt=''
											/>
										</div>
										<div className='p-1 mx-1 leading-normal'>
											<h2 className='font-semibold text-xl'>{t('Are you hungry?')}</h2>

											<p className='text-gray-800'>
												{t('Order your lunch or dinner directly from your favorite restaurant')}
											</p>

											<div className='text-sm font-medium mb-1 mt-3'>
												<a
													href={`/${appState.app.lang}/shop/food`}
													className='text-sm text-gray-700 pb-1  hover:border-gray-100 hover:text-black uppercase underline-effect relative'
												>
													{t('Show more')}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ClassFied;
