import { CategoryTypes } from '../types';
import serverApi from '../../api/serverApi';

export const loadingCategories = () => async (dispatch: any) => {
  dispatch({ type: CategoryTypes.SET_LOADING, payload: true });
  // setLoading(true)
  const categories = await serverApi.getCategories();

  if (categories.status === 'success') {
    dispatch({ type: CategoryTypes.SET_CATEGORIES, payload: categories.menu });
    setTimeout(() => {
      dispatch({ type: CategoryTypes.SET_LOADING, payload: false });
      // setLoading(false)
    }, 500);
  }
};

