import React from 'react';

import { Helmet } from 'react-helmet-async';
// import { SomeProducts } from 'schema-dts';
// import { JsonLd, helmetJsonLdProp } from 'react-schemaorg';
// import qs from 'querystring';

// i18n
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

export function SeoTag(props: any) {
	const { i18n } = useTranslation();
	const { title, description, introText, imageUrl } = props;

	const logo = `${process.env.REACT_APP_SERVER_URL_ROOT}/logo.png`;

	const domain = window.location.href; //process.env.REACT_APP_URL + location.pathname;
	// {`${process.env.REACT_APP_SERVER_URL_ROOT}/radar.ico`}
	return (
		<React.Fragment>
			<Helmet>
				<title>{title}</title>
				<meta charSet='utf-8' />
				<link href='https://faitu.ch' rel='canonical' />
				<meta content='IE=edge' httpEquiv='X-UA-Compatible' />
				<meta content='follow, index' name='robots' />
				<meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />

				<meta property='type' content='website' />
				<meta property='url' content={domain} />
				<meta property='title' content={title} />
				<meta property='quote' content={introText} />
				<meta name='description' content={description} />
				<meta property='image' content={imageUrl} />

				{/* TODO:add language */}
				<meta property='fb:appid' content='189174982016894' />
				<meta property='og:locale' content={i18n.language} />
				<meta property='og:title' content={title} />
				<meta property='og:description' content={description} />
				<meta property='og:quote' content={introText} />
				<meta property='og:url' content={domain} />
				<meta property='og:site_name' content='Faitu.ch' />
				<meta property='og:type' content='website' />
				<meta property='og:image' content={imageUrl} />
				<meta content='image/*' property='og:image:type' />

				{/* TODO: add faitulogo 180X180 */}
				<link
					rel='apple-touch-icon'
					// href='https://cdn.tutti.ch/204403378/apple-touch-icon.png?v=bOMMdm5yrO'
					href={logo}
					sizes='180x180'
				/>

				<meta name='twitter:title' content={title} />
				<meta name='twitter:description' content={description} />
				<meta name='twitter:image' content={imageUrl} />
				<meta name='twitter:card' content='summary_large_image' />
			</Helmet>
		</React.Fragment>
	);
}

export function ISomeProducts(props: any) {
	return (
		<Helmet>
			<script type='application/ld+json'>{JSON.stringify(props.jsonld)}</script>
		</Helmet>
	);
}

export function RestaurantJsonld(props: any) {
	const { shop, t }: any = props;
	let data;
	const location = useLocation();
	const domain = process.env.REACT_APP_URL + location.pathname;
	const servicesCuisines = shop.sections.map((service: any) => t(service.name));

	console.log(location);
	console.log(location.pathname.split('/')[1]);
	data = {
		'@context': 'http://schema.org',
		'@type': 'Restaurant',
		'@id': domain,
		name: shop.name,
		servesCuisine: servicesCuisines,
		image: shop.logo,
		telephone: shop.phone,
		potentialAction: {
			'@type': 'OrderAction',
			deliveryMethod: 'http://purl.org/goodrelations/v1#DeliveryModeOwnFleet',
			target: {
				'@type': 'EntryPoint',
				urlTemplate: domain,
				inLanguage: location.pathname.split('/')[1],
				actionPlatform: [
					'http://schema.org/DesktopWebPlatform',
					'http://schema.org/MobileWebPlatform',
				],
			},
		},
		address: {
			'@type': 'PostalAddress',
			addressLocality: shop.location.address.city,
			addressRegion: {},
			postalCode: shop.location.address.zipCode,
			streetAddress: shop.location.address.street,
			addressCountry: {
				'@type': 'Country',
				name: shop.location.address.country,
			},
		},
		geo: {
			'@type': 'GeoCoordinates',
			latitude: shop.location.coordinates[0],
			longitude: shop.location.coordinates[1],
		},
		aggregateRating: {
			'@type': 'AggregateRating',
			ratingValue: 5,
			reviewCount: 1,
		},
		priceRange: '$$',
		openingHoursSpecification: [
			{
				'@type': 'openingHoursSpecification',
				closes: shop.workingTime[0]?.openHours[0]?.to,
				dayOfWeek: 'https://schema.org/Sunday',
				opens: shop.workingTime[0]?.openHours[0]?.from,
			},
			{
				'@type': 'openingHoursSpecification',
				closes: shop.workingTime[1]?.openHours[0]?.to,
				dayOfWeek: 'https://schema.org/Monday',
				opens: shop.workingTime[1]?.openHours[0]?.from,
			},
			{
				'@type': 'openingHoursSpecification',
				closes: shop.workingTime[2]?.openHours[0]?.to,
				dayOfWeek: 'https://schema.org/Tuesday',
				opens: shop.workingTime[2]?.openHours[0]?.from,
			},
			{
				'@type': 'openingHoursSpecification',
				closes: shop.workingTime[3]?.openHours[0]?.to,
				dayOfWeek: 'https://schema.org/Wednesday',
				opens: shop.workingTime[3]?.openHours[0]?.from,
			},
			{
				'@type': 'openingHoursSpecification',
				closes: shop.workingTime[4]?.openHours[0]?.to,
				dayOfWeek: 'https://schema.org/Thursday',
				opens: shop.workingTime[4]?.openHours[0]?.from,
			},
			{
				'@type': 'openingHoursSpecification',
				closes: shop.workingTime[5]?.openHours[0]?.to,
				dayOfWeek: 'https://schema.org/Friday',
				opens: shop.workingTime[5]?.openHours[0]?.from,
			},
			{
				'@type': 'openingHoursSpecification',
				closes: shop.workingTime[6]?.openHours[0]?.to,
				dayOfWeek: 'https://schema.org/Saturday',
				opens: shop.workingTime[6]?.openHours[0]?.from,
			},
		],
	};

	console.log(data);

	return (
		<Helmet>
			<script type='application/ld+json'>{JSON.stringify(data)}</script>
		</Helmet>
	);
}

export function SingleAdJsonld(props: any) {
	const { ad }: any = props;

	const location = useLocation();
	const domain = process.env.REACT_APP_URL + location.pathname;

	let data;
	let salary = {};
	if (ad.category === 'job') {
		if (ad.price.amount > 0) {
			salary = {
				'@type': 'MonetaryAmount',
				currency: ad.price.currency,
				value: {
					'@type': 'QuantityValue',
					value: ad.price.amount,
					unitText: ad.price.unit,
				},
			};
		}
		data = {
			'@context': 'http://schema.org/',
			'@type': 'JobPosting',
			name: `${ad.title}`,
			image: [`${ad.gallery[0]}`],
			title: `${ad.title}`,
			description: `${ad.description}`,
			url: domain,
			datePosted: `${ad.createdAt}`,
			hiringOrganization: {
				'@type': 'Organization',
				name: ad.contactInfo.company
					? `${ad.contactInfo.contact}`
					: `${ad.user.lastName} ${ad.user.name}`,
			},
			jobLocation: {
				'@type': 'Place',
				address: {
					'@type': 'PostalAddress',
					streetAddress: ad.location.address.street,
					addressLocality: ad.location.address.city,
					postalCode: ad.location.address.zipCode,
					addressRegion: ad.location.address.city,
				},
			},
			baseSalary: salary,
			employmentType: {},
			validThrough: `${ad.unpublishedAt}`,
		};
	}
	if (ad.category === 'vehicles') {
		data = {
			'@context': 'http://schema.org',
			'@type': 'Person',
			makesOffer: {
				'@type': 'Offer',
				priceSpecification: {
					'@type': 'UnitPriceSpecification',
					priceCurrency: ad.price.currency,
					price: ad.price.amount,
				},
				itemOffered: {
					'@type': ad.subCategory === 'cars' ? 'car' : 'vehicle',
					name: ad.title,
					description: ad.description,
					vehicleEngine: {
						'@type': 'EngineSpecification',
						name: ad.ad.Transmission,
					},
					fuelType: ad.ad.Fuel,
					image: ad.gallery[0],
					brand: ad.ad.Brand,
					sku: {},
					offers: {},
				},
			},
		};
	}
	if (!data) {
		data = {
			'@context': 'http://schema.org/',
			'@type': 'WebSite',
			url: domain,
		};
	}
	return (
		<Helmet>
			<script type='application/ld+json'>{JSON.stringify(data)}</script>
		</Helmet>
	);
}

/** JSL-D */
export function SingleClassfiedJsonld(props: any) {
	const { title, description, imageUrl, currency, price, seller } = props;
	// const { locale, cat, slug } = useParams<Record<string, string | undefined>>();
	// console.log(locale, cat, slug);

	const location = useLocation();
	const domain = process.env.REACT_APP_URL + location.pathname;

	let data = {
		'@context': 'http://schema.org/',
		'@type': 'Product',
		name: `${title}`,
		image: [{ imageUrl }],
		description: { description },
		url: domain,
		offers: {
			'@type': 'Offer',
			priceCurrency: `${currency || ''}`,
			price: `${price ? price : 0}`,
			seller: {
				'@type': 'Organization',
				name: { seller },
			},
		},
	};
	return (
		<Helmet>
			<script type='application/ld+json'>{JSON.stringify(data)}</script>
		</Helmet>
	);
}
