import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FcShop } from 'react-icons/fc';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
// import CreatableSelect from 'react-select/creatable';

import { CurrenciesOptions } from '../api/localData';
import { customStyles } from '../utils/utility';
import { useAppState } from '../hooks/adsHooks';
import { CommentMessage } from '../Components/messages';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

import { Spinner } from 'react-awesome-spinners';
import AddressTooCoords from '../utils/addressToCoord';
import serverApi from '../api/serverApi';

// redux stuff to set reducer
import { useDispatch } from 'react-redux';
import * as authAction from '../rdx/actions/auth';

import { SeoTag } from '../utils/Seo';

const ShopRegistration: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [currency, setCurrency] = useState<any>();
	const [branch, setBranch] = useState<any>();
	const { auth, app } = useAppState();
	const [progressMessage, setProgressMessage] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [branches, setBranches] = useState<any[]>([]);
	const {
		register,
		formState: { errors },
		setValue,
		handleSubmit,
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		criteriaMode: 'all',
	});

	useEffect(() => {
		let isCancelled = false;
		const fetch = async () => {
			try {
				if (!isCancelled) {
					const res = await serverApi.branches();
					if (res.status === 'success') {
						setBranches(res.branches);
					}
				}
			} catch (e) {
				if (!isCancelled) {
					throw e;
				}
			}
		};
		fetch();
		return () => {
			isCancelled = true;
		};
	}, []);

	// https://codesandbox.io/s/github/estevanmaito/tailwindcss-multi-theme/tree/master/?file=/examples/with-react/package.json
	const onRegisterSubmitHandle = async (formData: any) => {
		const r = await AddressTooCoords(formData.zipCode + '' + formData.street + ',' + formData.city);

		const data = {
			currencySymbol: formData.currency,
			branch: formData.branch,
			name: formData.name,
			phone: formData.phone,
			location: {
				address: {
					city: formData.city,
					street: formData.street,
					zipCode: formData.zipCode,
				},
				coordinates: [r.lat, r.lng],
			},
			workingTime: [
				{ day: 'Sunday', openHours: [{ from: '06:00', to: '23:00' }] },
				{ day: 'Monday', openHours: [{ from: '06:00', to: '23:00' }] },
				{ day: 'Tuesday', openHours: [{ from: '06:00', to: '23:00' }] },
				{ day: 'Wednesday', openHours: [{ from: '06:00', to: '23:00' }] },
				{ day: 'Thursday', openHours: [{ from: '06:00', to: '23:00' }] },
				{ day: 'Friday', openHours: [{ from: '06:00', to: '23:00' }] },
				{ day: 'Saturday', openHours: [{ from: '06:00', to: '23:00' }] },
			],
		};
		console.log('🔥 🔥 ', data);
		setProgressMessage(t('Waiting'));
		setLoading(true);
		const createShop = await serverApi.createShop(data);
		if (createShop.status === 'success') {
			console.log(createShop, '🔥 🔥 ');
			await serverApi.updateMe({ shop: createShop.shop._id });
			const user = { ...auth.user, shop: createShop.shop._id };
			console.log(user);
			dispatch(authAction.userDataUpdate(user));
		}
		setTimeout(() => {
			setProgressMessage(t('Update user Profile'));
		}, 1000);
		setTimeout(() => {
			setProgressMessage(t('Configuring your shop'));
		}, 3000);
		setTimeout(() => {
			setProgressMessage(t('Save data'));
		}, 5000);

		setTimeout(() => {
			setLoading(false);
			history.push(`/${app.lang}/shopmanager`);
		}, 7000);
	};

	return (
		<div>
			<SeoTag
				title='Faitu'
				description='Permetti ai tuoi clienti di ordinare il loro pasto preferito, la spesa direttamente dal tuo negozio con faitu. Che sia da asporto o consegna a domicilio con faitu faiprima'
				imageUrl={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/faitu200x200.png`}
			/>

			{loading && (
				<div
					className='transition opacity-70 bg-white z-50 fixed w-full h-full p-6
                  pointer-events-none flex flex-col items-center
                  justify-center content-container'
				>
					<div className='mx-auto block'>
						<div className='flex'>
							<Spinner
								color='#080808'
								size={64}
								style={{
									display: 'block',
									marginLeft: 'auto',
									marginRight: 'auto',
									width: '50%',
								}}
							/>
						</div>
					</div>
					<h1 className=' font-semibold text-center  mt-2 mx-auto block'>{progressMessage}</h1>
				</div>
			)}
			<header className='mt-10 sm:mt-0'>
				<div className='flex flex-col max-w-6xl px-6  mx-auto md:flex-row'>
					<div className='flex flex-col justify-center pr-4 md:w-1/2'>
						{/* <div>
              <a
                className='inline-flex items-center pl-1 pr-2 mb-3 bg-indigo-100 rounded-full dark:bg-gray-800'
                href='/'
              >
                <span className='px-2 py-px mr-2 text-xs font-bold text-indigo-100 uppercase bg-indigo-700 rounded-full'>
                  New
                </span>
                <span className='text-sm leading-loose text-indigo-800 dark:text-gray-300'>
                  Visit our new products page →
                </span>
              </a>
            </div> */}
						<h1 className='text-5xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400 mt-5'>
							{t('Takeaway or delivery, with')} <br /> Faitu <span className='text-3xl'>Shop</span>
						</h1>
						<div className='prose prose-sm mt-6 mb-12 text-lg text-gray-700 dark:text-gray-400'>
							<span className='text-2xl'>
								{t('with')} <span className='text-3xl font-bold'>Faitu</span>
								<span className='text-primary-400 font-semibold'>Shop</span> {t('you can manage')}:
							</span>
							<ul>
								<li>
									<span className='font-semibold'>{t('Basic information')}.</span>{' '}
									{t('Tell customers where your store is, choose your business line')}
								</li>

								<li>
									<span className='font-semibold'>{t('Hours of service')}.</span>{' '}
									{t('Set the times available to receive orders')}
								</li>
								<li>
									<span className='font-semibold'>{t('Product management')}.</span>{' '}
									{t('Complete management of articles and article groups')}
								</li>
							</ul>
						</div>
					</div>
					<div className='md:w-1/2'>
						<img
							className='w-full'
							// src='https://images.prismic.io/ddplatform/372e90b7-2a8d-4bb0-84a1-505ed0135922_footer_image_Desktop_550x380.png?auto=compress,format&rect=0,0,550,380&w=1080&h=746'
							src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/header-registration-shop.png`}
							alt='Registration form header'
						/>
					</div>
				</div>
			</header>

			<main>
				<div className='flex flex-col max-w-6xl px-6 mx-auto  md:flex-row'>
					<div className='grid gap-8 md:grid-cols-3'>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								<svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
									<path
										fillRule='evenodd'
										d='M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z'
										clipRule='evenodd'
									></path>
								</svg>
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>{t('Register your business')}</h3>
								<p>{t('Create an account, and register your business')}</p>
							</div>
						</div>
						<div className='flex theme-dark'>
							<div className='flex items-center w-full p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
								<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
									<svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
										<path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'></path>
									</svg>
								</div>
								<div>
									<h3 className='text-2xl font-semibold'>{t('Add products')}</h3>
									<p>{t('Create categories add articles with details')}</p>
								</div>
							</div>
						</div>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								<svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
									<path
										fillRule='evenodd'
										d='M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z'
										clipRule='evenodd'
									></path>
								</svg>
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>{t('Order management')}</h3>
								<p>{t('A completely centralized order management')}</p>
							</div>
						</div>
					</div>
				</div>
				<div className='flex flex-col max-w-6xl px-6 mx-auto md:flex-row mt-16'>
					<div className='w-full bg-indigo-800'>
						<div className='w-full p-4 space-x-4 text-white'>
							<div className='text-center'>
								<h3 className='text-2xl font-semibold'>
									6 mesi gratuiti ai primi cento esercizi registrati
								</h3>
								<p>
									Registra la tua attività e ti invieremo il coupon gratuito. Senza impegno, senza
									contratto
								</p>
							</div>
						</div>
					</div>
				</div>
				<section className='flex flex-col max-w-6xl px-6 mt-16 lg:mt-52  mx-auto md:flex-row '>
					<div className='flex flex-col text-center justify-center pr-4 md:w-1/2'>
						<h1 className='block mx-auto '>
							<FcShop size={'6rem'} />
						</h1>
						<h1 className='text-4xl font-bold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400'>
							{t('Subscribe')}
						</h1>
						<p className='mt-6 mb-12 text-lg text-gray-700 dark:text-gray-400'>
							{t('Increase your sales by')} <b>60%</b> {t('with our management platform orders')}{' '}
							{t('Without sales commissions')}
						</p>
					</div>
					<div className='md:w-1/2'>
						<div className='shadow-xl p-3 '>
							<h3 className='text-2xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-3xl'>
								{t("Let's start")}
							</h3>
							{!auth.authenticate && (
								<h2 className='text-xl font-semibold text-red-800'>
									{t('To register your shop you need to log in first')}
								</h2>
							)}
							{auth.user.shop && !loading && (
								<h2 className='text-xl font-semibold text-red-800'>
									{t('Shop already registered')}
								</h2>
							)}
							<form onSubmit={handleSubmit(onRegisterSubmitHandle)} className='mt-3'>
								<div className='space-y-1'>
									<label
										htmlFor='name'
										className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 mt-2'
									>
										{t('Shop name')}
									</label>
									<div className='relative'>
										<input
											{...register('name', {
												required: `${t('Name required')}`,
											})}
											type='text'
											name='name'
											id='nameId'
											autoComplete='off'
											className=' focus:ring-black focus:border-2 focus:border-black block   border-2 h-10  w-full  border-gray-200  '
										/>
										{errors.name && (
											<span className='text-red-500 text-sm font-medium'>
												{errors.name.message}
											</span>
										)}
									</div>
								</div>

								<div className='space-y-1'>
									<label
										htmlFor='name'
										className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 mt-2'
									>
										{t('Phone number')}
									</label>
									<div className='relative'>
										<input
											{...register('phone', {
												required: `${t('Phone required')}`,
											})}
											type='text'
											name='phone'
											id='phoneId'
											autoComplete='off'
											className=' focus:ring-black focus:border-2 focus:border-black block   border-2 h-10  w-full  border-gray-200  '
										/>

										{errors.phone && (
											<span className='text-red-500 text-sm font-medium'>
												{errors.phone.message}
											</span>
										)}
										<CommentMessage>
											{t('Please use international format example')}: +414453398
										</CommentMessage>
									</div>
								</div>
								<div className='space-y-1 mt-2'>
									<label
										htmlFor='branch'
										className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 mt-2'
									>
										{t('Branches')}
									</label>
									<div className='relative'>
										{/* <Controller
											render={(props) => (
												<Select
													options={branches}
													getOptionLabel={(option) => t(option.name)}
													getOptionValue={(option) => option._id}
													{...props}
													styles={customStyles}
													onChange={(e: any) => {
														setValue('branch', e._id);
														setBranch(e);
														console.log(e);
													}}
												/>
											)}
											control={control}
											name='branch'
											defaultValue={branch}
											// rules={{
											// 	required: true,
											// }}
										/> */}

										<Select
											{...register('branch', { required: true })}
											value={branch}
											styles={customStyles}
											placeholder={t('Select')}
											options={branches}
											getOptionLabel={(option) => t(option.name)}
											getOptionValue={(option) => option._id}
											onChange={(e: any) => {
												setValue('branch', e._id);
												setBranch(e);
											}}
										/>

										{errors.branch && (
											<span className='text-red-500 text-sm font-medium'>
												{t('Branch is required')}
											</span>
										)}
									</div>
								</div>

								<div className='space-y-1 mt-2'>
									<label
										htmlFor='street'
										className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 mt-2'
									>
										{t('Address')}
									</label>
									<div className='relative'>
										<input
											{...register('street', {
												required: `${t('Address required')}`,
											})}
											type='text'
											name='street'
											id='streetId'
											autoComplete='off'
											className='focus:ring-black focus:border-2 focus:border-black block  border-2 h-10  w-full  border-gray-200  '
										/>
										{errors.street && (
											<span className='text-red-500 text-sm font-medium'>
												{errors.street.message}
											</span>
										)}
									</div>
								</div>
								<div className='-mx-3 md:flex'>
									<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
										<label
											className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 mt-2'
											htmlFor='zipCode'
										>
											{t('Zip Code')}
										</label>
										<input
											{...register('zipCode', {
												required: `${t('Zip code required')}`,
											})}
											id='zipCodeId'
											type='text'
											name='zipCode'
											className='focus:ring-black focus:border-black focus:border-2 block border-2 h-10  w-full  border-gray-200  px-4 mb-3'
										/>
										{errors.zipCode && (
											<span className='text-red-500 text-sm font-medium'>
												{errors.zipCode.message}
											</span>
										)}
									</div>
									<div className='md:w-1/2 px-3'>
										<label
											className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 mt-2'
											htmlFor='city'
										>
											{t('City')}
										</label>
										<input
											{...register('city', {
												required: `${t('City required')}`,
											})}
											name='city'
											id='cityId'
											type='text'
											className='focus:ring-black focus:border-2 focus:border-black block border-2 h-10  w-full  border-gray-200 '
										/>
										{errors.city && (
											<span className='text-red-500 text-sm font-medium'>
												{errors.city.message}
											</span>
										)}
									</div>
								</div>
								<div className='space-y-1 mt-2'>
									<label
										htmlFor='address'
										className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 mt-2'
									>
										{t('Currency')}
									</label>
									<div className='relative'>
										{/* <Controller
											render={(props) => (
												<Select
													options={CurrenciesOptions}
													{...props}
													styles={customStyles}
													onChange={(e: any) => {
														setValue('currency', e.value);
														setCurrency(e);
														console.log(e);
													}}
												/>
											)}
											control={control}
											name='currency'
											defaultValue={currency}
											rules={{
												required: true,
											}}
										/> */}

										<Select
											{...register('currency', { required: true })}
											value={currency}
											styles={customStyles}
											placeholder={t('Select')}
											options={CurrenciesOptions}
											onChange={(e: any) => {
												setValue('currency', e.value);
												setCurrency(e);
												console.log(e);
											}}
										/>

										{errors.currency && (
											<span className='text-red-500 text-sm font-medium'>
												{t('Currency is required')}
											</span>
										)}
									</div>
								</div>

								<div className='space-y-1 mt-3'>
									<CommentMessage>
										{t('By registering your shop you agree to all of faitu terms of service')}
									</CommentMessage>
									<button
										type='submit'
										className='btn-black disabled:opacity-70 '
										disabled={!auth.authenticate || auth.user.shop !== undefined}
									>
										{t('Register')}
									</button>
								</div>
							</form>
						</div>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl   mt-52 mx-auto md:flex-row'>
					<div className='md:w-1/2 mr-4 mb-10 justify-center '>
						<img
							className='w-full'
							src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/shop-information.png`}
							alt='Shop information'
						/>
					</div>
					<div className='flex flex-col justify-center px-6 pl-4 md:w-1/2'>
						<h1 className='text-5xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400'>
							{t('Management of articles registry')}
						</h1>
						<p className='mt-6 mb-12 text-lg text-gray-700 dark:text-gray-400'>
							{t('A complete article management, grouped by categories to simplify the search')}
						</p>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl px-6 mt-52  mx-auto md:flex-row'>
					<div className='flex flex-col justify-center pr-4 md:w-1/2'>
						<h1 className='text-5xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400'>
							{t('Order management in real time')}
						</h1>
						<div className='prose prose-sm mt-6 text-lg text-gray-700 dark:text-gray-400'>
							{/* Quando la tua attività risulta aperta, i clienti possono ordinare da faitu. Ogni
							ordinazione viene inviata al tuo negozio, con tutti i dettagli dell'ordine. */}
							<p>
								{t(
									'Receive all orders in real time, in a clear and detailed way. The management orders allows'
								)}
								:
							</p>
							<ul>
								<li>{t('Online / Offline of the ordering service')}</li>
								<li>{'Order detail control'}</li>
								<li>{t('Accept or reject orders')}</li>
								<li>{t('Customer and deliveryman detail')}</li>
							</ul>
						</div>
					</div>
					<div className='md:w-1/2'>
						<img
							className='w-full'
							src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/order-manager.png`}
							alt='Order manager'
						/>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl px-6 mt-52  mx-auto md:flex-row'>
					<div className='flex flex-col justify-center pr-4 md:w-1/2'>
						<h1 className='text-5xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400'>
							{t('Progress of orders')}
						</h1>
						<div className='prose prose-sm mt-6 text-lg text-gray-700 dark:text-gray-400'>
							{t(
								'There are several statuses of order progress. From request to delivery, orders are updated automatically'
							)}
						</div>
					</div>
					<div className='md:w-1/2'>
						<div className='text leading-0  text-gray-700 dark:text-gray-400'>
							{t('Below are the steps of each order status for each event transmitted')}.
						</div>

						<ol className='p-4'>
							<li className='feed-item relative pb-5 pl-6 border-l-2 border-red-300 last:border-transparent'>
								<time className='block font-extrabold text-gray-500 relative uppercase text-sm -top-1" dateTime="9-25'>
									{' '}
									{t('Request')}{' '}
								</time>
								<span className='text'>
									{/* Il cliente ha acquistato presso il vostro shop e confermato tutti gli articoli. La
									scheda di richiesta d'ordine lampeggerà cambiando colore ed un suono continuo vi
									allerterà. Tramite il pulsante di dettaglio potrete controllare l'intero ordine.
									Avete la possibilità di contattare il cliente in caso di necessità */}

									{t(
										'The customer has purchased from your shop and confirmed all items. There order request card will flash changing color and a continuous sound vi will alert. Through the detail button you can check the entire order. You have the possibility to contact the customer in case of need'
									)}
								</span>
							</li>
							<li className='feed-item relative pb-5 pl-6 border-l-2 border-red-300 last:border-transparent'>
								<time className='block font-extrabold text-gray-500 relative uppercase text-sm -top-1" dateTime="9-25'>
									{' '}
									{'Order confirmation'}{' '}
								</time>
								<span className='text'>
									{/* Eseguite le verifiche necessarie. Inserite il tempo stimato di preparazione della									
									richiesta d'ordine. Serve per informare i corrieri in quanto tempo l'ordine sarà
									pronto per la consegna dal momento che viene accettato da un corriere. Quando
									confermi l'ordine esso viene notificato a tutti i corrieri in zona.{' '} */}
									{t(
										'Carry out the necessary checks. Enter the estimated preparation time for the order request. It serves to inform the couriers how soon the order will be ready for delivery since it is accepted by a courier. When confirm the order it is notified to all couriers in the area'
									)}
									.<br />
									<strong>
										{t('Wait for confirmation from the delivery man before preparing the order')}.
									</strong>
								</span>
							</li>
							<li className='feed-item relative pb-5 pl-6 border-l-2 border-red-300 last:border-transparent'>
								<time className='block font-extrabold text-gray-500 relative uppercase text-sm -top-1" dateTime="9-25'>
									{' '}
									{t('Ready for delivery')}{' '}
								</time>
								<span className='text'>
									{/* Un fattorino in zona ha accettato l'ordine. Verrai informato tramite un suono
									continuo e la scheda dell'ordine lampeggerà. */}
									{t(
										'A local delivery boy accepted the order. You will be informed via a sound continuous and the order card will flash'
									)}
									.
								</span>
							</li>
							<li className='feed-item relative pb-5 pl-6 border-l-2 border-red-300 last:border-transparent'>
								<time className='block font-extrabold text-gray-500 relative uppercase text-sm -top-1" dateTime="9-25'>
									{' '}
									{t('Closing the order')}{' '}
								</time>
								<span className='text'>
									{t('Close the order only when you have delivered the goods to the delivery man')}.
								</span>
							</li>
						</ol>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl  mt-52 mx-auto md:flex-row'>
					<div className='md:w-1/2 justify-end float-right text-right '>
						<img
							className='w-72 h-72 block mx-auto'
							// src='https://furnacefinancing.ca/wp-content/uploads/2013/11/Zero-Percent.png'
							src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/Zero-Percent.png`}
							alt='0% commission'
						/>
					</div>
					<div className='flex flex-col justify-center px-6 pl-4 md:w-1/2'>
						<h1 className='text-5xl font-extrabold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400'>
							{t('Sales commissions')}
						</h1>
						<p className='mt-6 mb-12 text-lg text-gray-700 dark:text-gray-400'>
							{t('With Faitu you pay no commission on sales. You will need to apply a discount of')}{' '}
							<span className='font-bold'>$4</span>{' '}
							<span className='italic'>{t('In local currency')}</span>{' '}
							{t('to the delivery man for each delivery')}.
						</p>
					</div>
				</section>

				<section className='flex flex-col max-w-6xl  mt-52 mx-auto md:flex-row'>
					<div className='flex flex-col justify-center px-6 pl-4 w-full'>
						<h1 className='text-5xl text-center font-extrabold leading-none tracking-tight text-gray-800 lg:text-6xl dark:text-gray-400'>
							{t('A tailor-made subscription')}
						</h1>
						<p className='mt-6  text-lg text-center text-gray-700 dark:text-gray-400'>
							{t(
								'No duration constraints, no conditions. You can unsubscribe when you want or change your subscription at any time'
							)}
						</p>
					</div>
				</section>

				<div className='flex flex-col max-w-6xl px-6 mx-auto mt-16 md:flex-row'>
					<div className='grid gap-8 md:grid-cols-3'>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								<svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
									<path
										fillRule='evenodd'
										d='M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z'
										clipRule='evenodd'
									></path>
								</svg>
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>
									Starter <span className='text-lg'>€</span>25
									<span className='text-sm'>/{t('Month')}</span>
								</h3>
								<p>
									{t('You can receive up to')} 15 {t('monthly orders')}
								</p>
							</div>
						</div>
						<div className='flex bg-indigo-800 text-white'>
							<div className='flex text-white items-center w-full p-4 space-x-4  rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
								<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
									<svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
										<path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'></path>
									</svg>
								</div>
								<div>
									<h3 className='text-2xl font-semibold'>
										Basic <span className='text-lg'>€</span>35
										<span className='text-sm'>/{t('Month')}</span>
									</h3>
									<p>
										{t('You can receive up to')} 25 {t('monthly orders')}
									</p>
								</div>
							</div>
						</div>
						<div className='flex items-center p-4 space-x-4 text-gray-700 rounded-lg shadow-xl dark:bg-gray-800 dark:text-gray-300'>
							<div className='p-2 text-indigo-700 bg-indigo-100 rounded-full dark:text-indigo-100 dark:bg-indigo-700'>
								<svg className='w-8 h-8' fill='currentColor' viewBox='0 0 20 20'>
									<path
										fillRule='evenodd'
										d='M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z'
										clipRule='evenodd'
									></path>
								</svg>
							</div>
							<div>
								<h3 className='text-2xl font-semibold'>
									Pro <span className='text-lg'>€</span>80
									<span className='text-sm'>/{t('Month')}</span>
								</h3>
								<p>{t('Unlimited orders')}</p>
							</div>
						</div>
					</div>
				</div>

				<div className='mx-auto max-w-6xl mt-16 mb-16'>
					<section className='p-2  rounded'>
						<div className='flex flex-col md:flex-row'>
							<div className='md:w-1/3 p-4 text-sm'>
								<div className='text-3xl'>
									<span className='font-medium'>{t('Frequently Asked Questions')}</span>
								</div>
								{/* <div className='my-2'>Alcune delle domande più frequenti</div> */}
								{/* <div className='mb-2'>Confused about how we can improve your business ?</div>
                <div className='text-xs text-gray-600'>Dive into our FAQ for more details</div> */}
							</div>
							<div className='md:w-2/3'>
								<div className='p-4'>
									<Accordion>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg text-gray-800 cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>
													{t('How many times can I change my subscription?')}
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p className='text-gray-700'>
													{t(
														'There are no limits. You can change subscription when you feel like it most appropriate'
													)}
													.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>
													{t('How can I cancel my subscription?')}
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													{t(
														'Log in to Faitu with your credentials. From your profile menu, select'
													)}{' '}
													<strong>{t('Manage')}.</strong>{' '}
													{t(
														'Finally from the information page of yours shop you can delete your subscription'
													)}
													.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>
													{t('Why do I have to apply a $ 4 discount to the deliveryman?')}
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													{/* È un contributo spese allo scopo di incentivare i fattorini a lavorare meglio, nel contempo si contengono i costi applicati ai clienti. */}
													{t(
														'It is an expense contribution with the aim of encouraging the deliverymen to work better, at the same time reducing the costs applied to customers'
													)}
													<br />
													<span className='italic font-medium'>
														{t('the $ sign is indicative. Local currency is applied')}.
													</span>
												</p>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading className='font-medium text-lg  text-gray-800  cursor-pointer  bg-white hover:bg-white'>
												<AccordionItemButton>
													{/* Ho delle penali in caso di ritardo o mancato ordine? */}
													{t('Do I have penalties in case of delay or failure to order?')}
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<p>
													{t('There are no penalties. Order management is part of your business')}.
												</p>
											</AccordionItemPanel>
										</AccordionItem>
									</Accordion>
								</div>
							</div>
						</div>
					</section>
				</div>
			</main>
		</div>
	);
};
export default ShopRegistration;
