import { FilterTypes } from '../types';
import { ISection } from '../../Interfaces/dataModel';
import { AppState } from '../rootReducer';
import serverApi from '../../api/serverApi';
import qs from 'query-string';

export const setPriceRange = (range: Array<number>) => (dispatch: any) => {
  dispatch({ type: FilterTypes.SET_PRICE_RANGE, payload: range });
  dispatch(executeQuery());
};

export const setDistance = (distance: number) => (dispatch: any) => {
  dispatch({ type: FilterTypes.SET_DISTANCE, payload: distance });
  dispatch(executeQuery());
};

export const setUnit = (unit: string) => (dispatch: any) => {
  dispatch({ type: FilterTypes.SET_UNIT, payload: unit });
  dispatch(executeQuery());
};

export const setPage = (page: number) => async (dispatch: any) => {
  dispatch({ type: FilterTypes.SET_PAGE, payload: page });
  dispatch(executeQuery());
};

export const setSection = (section: ISection) => (dispatch: any) => {
  dispatch({ type: FilterTypes.SET_SECTION, payload: section });
  dispatch(executeQuery());
};
export const setReset = () => (dispatch: any) => {
  dispatch({ type: FilterTypes.SET_RESET, payload: null });
  dispatch(executeQuery());
};

export const setTitleFind = (q:string) => (dispatch:any) => {
  dispatch({type: FilterTypes.SET_TITLE_QUERY,payload:q});
  // dispatch(executeQuery());
}
// export const setEmptyListing = () => (dispatch:any) => {
//   dispatch({ type: FilterTypes.EXECUTE_QUERY, payload: [] });
// }



export const executeQuery = () => async (dispatch: any, getState: () => AppState) => {
  /**
   * Format
   * {{URL}}/classfied/ads/
   * distance 100/
   * latitude,longitude 46.005621,8.957592/
   * km or mi
   * ?published=true&
   * title[regex]=AUDI&
   * ad[Brand]=ALFA ROMEO,AUDI&
   * limit=100&
   * category=vehicles&
   * priceFrom=20000&priceTo=30000
   */

  const { distance, unit } = getState().filters;
  const { latitude, longitude } = getState().app.coords;
  const { category, subCategory } = getState().filters.section;
  const [priceFrom, priceTo] = getState().filters.priceRange;
  const title = getState().filters.title

  console.log(priceFrom, priceTo,category,title);
  const priceObjectRange = { priceFrom, priceTo };
  let url:string;
  if (category !== 'find') {
    url = `${distance}/${latitude},${longitude}/${unit}?published=true&limit=1000&category=${category}&`;
  } else {
    url = `${distance}/${latitude},${longitude}/${unit}?published=true&limit=1000&title=${title}&`;
  }
  url = url + qs.stringify(priceObjectRange);
  if (subCategory !== undefined) {
    url = url + `&subCategory=${subCategory}`;
  }

  dispatch(setLoading(true));
  dispatch({ type: FilterTypes.EXECUTE_QUERY, payload: [] });

  const listingData = await serverApi.executeQueryListing(url);
  if (listingData.status === 'success') {
    dispatch({ type: FilterTypes.EXECUTE_QUERY, payload: listingData.query });
    dispatch({ type: FilterTypes.SET_TOTAL, payload: listingData.total });
    dispatch(setSideBar(listingData.sideBar))
  }
  setTimeout(() => {
    dispatch(setLoading(false));
  }, 500);
};


export const setSideBar = (ads:any[]) => {
  return { type: FilterTypes.SET_SIDE_BAR, payload: ads };

};

export const setLoading = (loading: boolean) => {
  return { type: FilterTypes.SET_LOADING, payload: loading };
};
