import fuxios from './fuxios';
import { IUser, IPasswordChange } from '../Interfaces/dataModel';

// let headers = new Headers();
// headers.append('Content-Type', 'application/json');

const headers = { 'Content-Type': 'application/json' };
const serverApi = {
  /**
   *
   * @param credential {JSON Object} email | password
   * @returns token {String}
   */
  login: async (credential: any): Promise<any> => {
    return await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(credential),
    })
      .then((res) => res.json())
      .catch((err) => err.json());
  },
  logout: async (): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/users/logout`)
      .then((res) => res.data)
      .catch((err) => err);
  },
  /** Sign Up or user form registration */
  signUp: async (data: any): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/users/signup`, JSON.stringify(data), {
        // headers: { 'Content-Type': 'application/json' },
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  me: async (): Promise<IUser> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/users/me`)
      .then((res) => res.data.user)
      .catch((err) => err);
  },
  updateMe: async (data: any): Promise<any> => {
    return await fuxios
      .put(`${process.env.REACT_APP_API_URL}/users/me`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => res.data.user)
      .catch((err) => err);
  },
  uploadAvatar: async (data: any): Promise<IUser> => {
    return await fuxios
      .put(`${process.env.REACT_APP_API_URL}/users/me`, data)
      .then((res) => res.data.user)
      .catch((err) => err);
  },
  passwordChange: async (data: IPasswordChange): Promise<{ user: IUser; token: string }> => {
    return await fuxios
      .put(`${process.env.REACT_APP_API_URL}/users/updateMyPassword`, data, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res) => {
        return { user: res.data.user, token: res.data.token };
      })
      .catch((err) => err);
  },
  getUserAds: async (): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/classfied/me`, {
        headers: { 'Content-Type': 'Application/json' },
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  checkOut: async (items: any, id: string): Promise<any> => {
    console.log(`${process.env.REACT_APP_API_URL}/users/checkout/${id}`);
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/users/checkout/${id}`, JSON.stringify(items), {
        //  headers: { 'Content-Type': 'Application/json' },
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  checkOutSession: async (sessionId: string, id: string): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/users/checkout/${id}?sessionId=` + sessionId, {
        // headers: { 'Content-Type': 'Application/json' },
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  /** Get all current categories array and subcategories data field */
  getCategories: async (): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/category`)
      .then((res) => res.data)
      .catch((err) => err);
  },


  motorCycleBrand: async (): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/category/motorcycle`)
      .then((res) => res.data)
      .catch((err) => err);
  },


  getFeaturedAd: async (): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/classfied/featured`)
      .then((res) => res.data)
      .catch((err) => err);
  },

  executeQueryListing: async (filters: any): Promise<any> => {
    console.log(`${process.env.REACT_APP_API_URL}/classfied/ads/${filters}`);
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/classfied/ads/${filters}`, {
        // headers: { 'Content-Type': 'Application/json' },
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  /**
   * When user add image to gallery stored to server
   * @param data Array of files can be uploaded
   * @returns Array of string with path name url to image
   */
  saveImagesAdsToGallery: async (data: any): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/classfied/ads/gallery`, data, {
        //  headers: { 'Content-Type': 'Application/json' },
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },

  /**
   * Can delete image from gallery only used when user populate a new ads
   * @param data filename can be deleted
   * @returns
   */
  removeImageFromGallery: async (data: any): Promise<any> => {
    return await fuxios
      .put(`${process.env.REACT_APP_API_URL}/classfied/ads/gallery`, data, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },

  postNewAd: async (data: any): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/classfied/me`, data, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },

  /** Get classfied detail */
  classFied: async (id: string): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/classfied/detail/${id}`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  /** Get All ads group by selected user */
  classFiedByUserId: async (id: string): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/classfied/user/${id}`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  updateClassFied: async (id: string, data: any): Promise<any> => {
    return await fuxios
      .put(`${process.env.REACT_APP_API_URL}/classfied/detail/${id}`, JSON.stringify(data), {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
/**user-ad-manager.tsx */
  deleteAd: async (id: string): Promise<any> => {
    return await fuxios
      .delete(`${process.env.REACT_APP_API_URL}/classfied/me/${id}`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },

  sendMessageToOwnerAd: async (id: string, data: any): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/classfied/detail/${id}`, JSON.stringify(data), {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },

  branches: async (): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/shops/branches`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  /** A branchId is a name of branch type shop example: Food , Pharmacy , Tobaccos */
  nearbyShops: async (coords: string, branchId: string, searchTerms: string): Promise<any> => {
    console.log(`${process.env.REACT_APP_API_URL}/shops/${coords}/${branchId}${searchTerms}`);
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/shops/${coords}/${branchId}${searchTerms}`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },

  /** Get shop details by shop _id */
  shopDetail: async (shopId: string): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/shops/${shopId}`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  /** Protected by authorization Shop Manager  */
  shopMe: async (): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/shops/me`)
      .then((res) => res.data)
      .catch((err) => err);
  },
  /**
   * Create a new Shop
   * @param data
   * @returns
   */
  createShop: async (data: any): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/shops/me`, data, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  uploadImageToShopMe: async (data: any): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/shops/me/upload`, data, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  ShopUpdate: async (data: any): Promise<any> => {
    return await fuxios
      .put(`${process.env.REACT_APP_API_URL}/shops/me`, data, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },

  getSubscribePlan: async (data: any): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/users/subscription/`, JSON.stringify(data), {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  getSubscribeInformation: async (sessionId: string): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/users/subscribetoplan/${sessionId}`)
      .then((res) => res.data)
      .catch((err) => err);
  },

  unsubscribe: async (data: any): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/users/unsubscribe`, JSON.stringify(data), {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  changeSubscription: async (data: any): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/users/changesubscription`, JSON.stringify(data), {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },

  /**
   * Used only by admin from e-mail check event button option `publish`
   * @param id is a primary key of ad  created
   * @returns selectedAd & status object 
   */
  publishedAd: async (id: string): Promise<any> => {
    return await fuxios
      .put(`${process.env.REACT_APP_API_URL}/classfied/${id}`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  rejectAd: async (id: string): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/classfied/${id}`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },

  userActivation: async (token: string): Promise<any> => {
    return await fuxios
      .put(`${process.env.REACT_APP_API_URL}/users/activation/${token}`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  resendActivationCode: async (): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/users/activationresend`)
      .then((res) => res.data)
      .catch((err) => err);
  },

  // OTP verification code
  createOTPService: async (): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/users/create-otp-service`, { headers })
      .then((res) => res.data)
      .catch((err) => err);
  },
  // data = {mobilePhone: xxxxx}
  requestOtpCode: async (service_sid: string, data: any): Promise<any> => {
    return await fuxios
      .post(
        `${process.env.REACT_APP_API_URL}/users/send-verification/${service_sid}`,
        JSON.stringify(data),
        { headers }
      )
      .then((res) => res.data)
      .catch((err) => err);
  },

  // data = {mobilePhone: xxxx, code:xxxx}
  sentOTPCode: async (service_sid: string, data: any): Promise<any> => {
    return await fuxios
      .post(
        `${process.env.REACT_APP_API_URL}/users/check-otp-code/${service_sid}`,
        JSON.stringify(data),
        { headers }
      )
      .then((res) => res.data)
      .catch((err) => err);
  },

  /** Order Manager */
  /**
   * Client send order request for delivery or pickup goods to Shop
   * @param data
   * @returns 200 if order is saved.
   * @trigger  Send e-mail to client for resume Order request ??
   */
  ClientOrderRequest: async (data: any): Promise<any> => {
    return await fuxios
      .post(`${process.env.REACT_APP_API_URL}/orders`, data, { headers })
      .then((res) => res.data)
      .catch((err) => err);
  },


  orderWaitingPickUp: async (lat: string,lng:string): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/orders/orderwaitingpickup/${lat},${lng}`, { headers })
      .then((res) => res.data)
      .catch((err) => err);
  },



  shopOrderRequests: async (shopId: string): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/orders/${shopId}`, { headers })
      .then((res) => res.data)
      .catch((err) => err);
  },
  orderUpdate: async (orderId: string, data: any): Promise<any> => {
    return await fuxios
      .put(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, data, { headers })
      .then((res) => res.data)
      .catch((err) => err);
  },
  orderReject: async (orderId: string): Promise<any> => {
    return await fuxios
      .delete(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, { headers })
      .then((res) => res.data)
      .catch((err) => err);
  },

  orderManagerStatistics: async (query: any): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/orders/orderman-statistics?${query}`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
  deliveryOrderStatistics: async (query: any): Promise<any> => {
    return await fuxios
      .get(`${process.env.REACT_APP_API_URL}/orders/driverman-statistics?${query}`, {
        headers,
      })
      .then((res) => res.data)
      .catch((err) => err);
  },
};

export default serverApi;
