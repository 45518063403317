import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LayoutPage from './UI/Layout';
import HomePage from './Pages/Home';
import ClassFied from './Pages/Classfieds';

import UserClassFieds from './Pages/userClassFieds';
import NewAd from './Pages/NewAd';
import UserProfile from './Pages/User/Profile';

import ShopManager from './Pages/User/shop-man/ShopManager';
import Activation from './Pages/Activation';
/** publish ad only from e-mail to admin when new ad has been created */
import PublishAd from './Pages/ClassFied-manager/PublishAd';
/** Reject Ad only form e-mail to admin when new ad has been created */
import RejectAdPublication from './Pages/ClassFied-manager/RejectAd';
/** Static page */
import DriverInfoPage from './Pages/static/driverInfoPage';
import Terms from './Pages/static/terms';
import Faqs from './Pages/static/faqs';
import Advertising from './Pages/static/advertising';

/** Delivery pages */
import RouteShop from './Pages/Shop/routeShop';
import ShopRegistration from './Pages/shopRegistration';

/** Driver Manager  */
import DeliveryOrdersManager from './Pages/Driver/deliveryOrdersManager';

// import DeliveryHome from './Pages/Delivery/Index';
// import ShopperPage from './Pages/Delivery/ShopperPage';
/** CheckOutResult information after paid */
import CheckOutResult from './Pages/CheckOutResult';
import { Error401 } from './errorPage';

// import ClassFiedDetail from './Pages/ClassFiedDetail';
const ClassFiedDetail = React.lazy(() => import('./Pages/ClassFiedDetail'));

const base = '/:lang(it|fr|en|de)?';
const Routes: React.FC = () => {
	return (
		<Switch>
			<Route
				exact
				path={[`${base}`, `${base}/home`]}
				render={(props) => <LayoutPage {...props} children={<HomePage />} />}
			/>

			<Route
				exact
				path={`${base}/activation/:token`}
				render={(props) => <LayoutPage {...props} children={<Activation />} />}
			/>
			<Route
				exact
				path={`${base}/published/:id`}
				render={(props) => <LayoutPage {...props} children={<PublishAd />} />}
			/>
			<Route
				exact
				path={`${base}/reject/:id`}
				render={(props) => <LayoutPage {...props} children={<RejectAdPublication />} />}
			/>

			<Route
				exact
				path={`${base}/classfied/:category/:subcategory?`}
				render={(props) => <LayoutPage {...props} children={<ClassFied />} />}
			/>
			<Route
				exact
				path={`${base}/user/classfied/:id`}
				render={(props) => <LayoutPage {...props} children={<UserClassFieds />} />}
			/>
			<Route
				exact
				path={`${base}/ads-detail/:id`}
				render={(props) => <LayoutPage {...props} children={<ClassFiedDetail />} />}
			/>

			<Route
				exact
				path={`${base}/ads/`}
				render={(props) => <LayoutPage {...props} children={<NewAd />} />}
			/>

			<Route
				exact
				path={`${base}/userprofile`}
				render={(props) => <LayoutPage {...props} children={<UserProfile />} />}
			/>
			{/* 
      <Route
        exact
        path={`${base}/businesspage`}
        render={(props) => <LayoutPage {...props} children={<BusinessPage />} />}
      /> */}

			<Route
				exact
				path={`${base}/shopmanager`}
				render={(props) => <LayoutPage {...props} children={<ShopManager />} />}
			/>

			<Route
				exact
				path={`${base}/check-out-result/:id`}
				render={(props) => <LayoutPage {...props} children={<CheckOutResult />} />}
			/>
			<Route
				exact
				path={`${base}/shop-registration`}
				render={(props) => <LayoutPage {...props} children={<ShopRegistration />} />}
			/>
			<Route path={`${base}/shop`} component={RouteShop} />

			<Route
				exact
				path={`${base}/driver-info`}
				render={(props) => <LayoutPage {...props} children={<DriverInfoPage />} />}
			/>

			<Route
				exact
				path={`${base}/driver-manager`}
				render={(props) => <LayoutPage {...props} children={<DeliveryOrdersManager />} />}
			/>

			<Route
				exact
				path={`${base}/terms`}
				render={(props) => <LayoutPage {...props} children={<Terms />} />}
			/>

			<Route
				exact
				path={`${base}/advertising`}
				render={(props) => <LayoutPage {...props} children={<Advertising />} />}
			/>

			<Route
				exact
				path={`${base}/faqs`}
				render={(props) => <LayoutPage {...props} children={<Faqs />} />}
			/>

			<Route exact path='/error' component={Error401} />
			<Route path='*'>
				<Error401
					status={401}
					title='Pagina non trovata'
					message='Opps! qualche cosa non ha funzionato'
				/>
			</Route>
		</Switch>
	);
};

export default Routes;
