import { AppTypes } from '../types';
import { ICoord } from '../../Interfaces/dataModel';

export const setLang = (lang: string) => (dispatch: any) => {
  return dispatch({ type: AppTypes.SET_LANG, payload: lang });
};

export const setCoords = (coords: ICoord) => (dispatch: any) => {
  return dispatch({ type: AppTypes.SET_COORDS, payload: coords });
};
