import React from 'react';
import { IDrawerProps } from '../../Interfaces/UI';

import { Transition } from '@headlessui/react';
import { CgClose } from 'react-icons/cg';
import { IoIosAdd, IoMdExit } from 'react-icons/io';
import { RiLockPasswordLine, RiPagesLine, RiUser3Line } from 'react-icons/ri';
import { SiGoogletagmanager } from 'react-icons/si';
import { AiFillShop } from 'react-icons/ai';
import { GiJigsawBox } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import { AppState } from '../../rdx/rootReducer';
import { useAppState } from '../../hooks/adsHooks';
import { useDispatch } from 'react-redux';
import * as authActions from '../../rdx/actions/auth';
import { Link, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const MenuProfileDrawer: React.FC<IDrawerProps> = ({ isOpen, closeHandle }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { user, authenticate } = useSelector((state: AppState) => state.auth);
	const { app } = useAppState();

	const logoutHandle = () => {
		dispatch(authActions.logout());
		closeHandle();
		history.push('/');
	};
	return (
		<div className={`${isOpen ? 'block' : 'hidden'} fixed inset-0 overflow-auto z-50`}>
			<div className='absolute inset-0 overflow-y-auto overflow-x-hidden'>
				<Transition
					show={isOpen}
					enter='transform transition ease-in-out'
					enterFrom='translate-x-full'
					enterTo='translate-x-0'
					leave='transform transition ease-in-out duration-500'
					leaveFrom='translate-x-0'
					leaveTo='translate-x-full'
				>
					<section className='absolute inset-y-0 right-0 w-9/12 max-w-xs sm:max-w-md'>
						<div className='bg-white shadow-xl h-full'>
							{authenticate && (
								<header className='space-y-1 py-6 px-4 bg-indigo-800'>
									<div className='relative w-full'>
										{/* <h2 className='text-xl leading-7 font-medium text-white'>Menu</h2> */}
										<div className='absolute right-0'>
											<button
												onClick={closeHandle}
												aria-label='Close panel'
												className=' text-indigo-200 outline-none hover:text-white focus:outline-none active:outline-none'
											>
												<CgClose size={'1.4em'} />
											</button>
										</div>
									</div>

									<a href={`${app.lang}/userprofile`}>
										<div className='pt-2'>
											<div className='flex text-white  '>
												<div className='inline-block  h-16 '>
													<img
														className='rounded-md float-left h-full'
														src={`${process.env.REACT_APP_SERVER_URL_ROOT}/${user.avatar}`}
														alt={`${user.lastName} ${user.name}`}
													/>{' '}
													<div className='flex flex-col'>
														<span className='ml-3 text-2xl font-semibold text-indigo-200 hover:underline '>
															{user.lastName} {user.name}
														</span>
														<p>
															<span className='ml-3 text-indigo-300 text-base'>{user.email}</span>
														</p>
													</div>
												</div>
											</div>
										</div>
									</a>
								</header>
							)}
							<div className='divide-y  divide-gray-200'>
								<div className='space-y-2'>
									<a href={`/${app.lang}/ads`} className='font-semibold leading-5 text-lg'>
										<div className='flex flex-between items-center space-x-2 p-4   hover:bg-black hover:text-white '>
											<span>
												<IoIosAdd size={'1.2em'} />
											</span>
											<span>{t('Ad')}</span>
										</div>
									</a>
								</div>
							</div>
							<div className='bg-gray-100 p-3 text-gray-600 font-medium'>{t('User')}</div>
							<div className='divide-y  divide-gray-200'>
								<div className='space-y-2'>
									<Link
										onClick={closeHandle}
										to={{ pathname: `/${app.lang}/userprofile`, state: { activeTab: 0 } }}
										className='font-semibold leading-5 text-lg'
									>
										<div className='flex flex-between items-center space-x-2 p-4   hover:bg-black hover:text-white '>
											<span>
												<RiUser3Line size={'1.2em'} />
											</span>
											<span>{t('Profile')}</span>
										</div>
									</Link>
								</div>
							</div>

							<div className='divide-y  divide-gray-200'>
								<div className='space-y-2'>
									<Link
										// href='/userprofile?activeTab=1'
										onClick={closeHandle}
										to={{ pathname: `/${app.lang}/userprofile`, state: { activeTab: 1 } }}
										className='font-semibold leading-5 text-lg'
									>
										<div className='flex flex-between items-center space-x-2 p-4   hover:bg-black hover:text-white '>
											<span>
												<RiPagesLine size={'1.2em'} />
											</span>
											<span>{t('Your ads')}</span>
										</div>
									</Link>
								</div>
							</div>

							<div className='divide-y  divide-gray-200'>
								<div className='space-y-2'>
									<Link
										// href='/userprofile?activeTab=2'
										onClick={closeHandle}
										to={{ pathname: `/${app.lang}/userprofile`, state: { activeTab: 2 } }}
										className='font-semibold leading-5 text-lg'
									>
										<div className='flex flex-between items-center space-x-2 p-4   hover:bg-black hover:text-white '>
											<span>
												<RiLockPasswordLine size={'1.2em'} />
											</span>
											<span>{t('Change password')}</span>
										</div>
									</Link>
								</div>
							</div>

							{user.shop && authenticate && (
								<div>
									<div className='bg-gray-100 p-3  text-gray-600 font-medium'>{t('Shop')}</div>
									<div className='divide-y  divide-gray-200'>
										<div className='space-y-2'>
											<Link
												onClick={closeHandle}
												to={{ pathname: `/${app.lang}/shopmanager` }}
												className='font-semibold leading-5 text-lg'
											>
												<div className='flex flex-between items-center space-x-2 p-4   hover:bg-black hover:text-white '>
													<span>
														<AiFillShop size={'1.2em'} />
													</span>
													<span>{t('Manage')}</span>
												</div>
											</Link>
										</div>
									</div>
									<div className='divide-y  divide-gray-200'>
										<div className='space-y-2'>
											<Link
												// href='/userprofile?activeTab=2'
												onClick={closeHandle}
												to={{ pathname: `/${app.lang}/shop/order-man/${user.shop}` }}
												className='font-semibold leading-5 text-lg'
											>
												<div className='flex flex-between items-center space-x-2 p-4   hover:bg-black hover:text-white '>
													<span>
														<SiGoogletagmanager size={'1.2em'} />
													</span>
													<span>{t('Order manager')}</span>
												</div>
											</Link>
										</div>
									</div>
								</div>
							)}
							{user.isDriver && authenticate && (
								<div>
									<div className='bg-gray-100 p-3  text-gray-600 font-medium'>
										{t('Delivery man')}
									</div>

									<div className='divide-y  divide-gray-200'>
										<div className='space-y-2'>
											<Link
												// href='/userprofile?activeTab=2'
												onClick={closeHandle}
												to={{ pathname: `/${app.lang}/driver-manager` }}
												className='font-semibold leading-5 text-lg'
											>
												<div className='flex flex-between items-center space-x-2 p-4   hover:bg-black hover:text-white '>
													<span>
														<GiJigsawBox size={'1.2em'} />
													</span>
													<span>{t('Delivery manager')}</span>
												</div>
											</Link>
										</div>
									</div>
								</div>
							)}
							<div className='divide-y  divide-gray-200'>
								<div className='space-y-2'>
									<button
										onClick={logoutHandle}
										className='font-semibold leading-5 text-lg w-full p-0 '
									>
										<div className='flex flex-between items-center space-x-2 p-4   hover:bg-black hover:text-white '>
											<span>
												<IoMdExit size={'1.2em'} />
											</span>
											<span>{'Exit'}</span>
										</div>
									</button>
								</div>
							</div>
						</div>
					</section>
				</Transition>
			</div>
		</div>
	);
};

export default MenuProfileDrawer;
