import React, { useEffect, useState } from 'react';
import { IDrawerProps } from '../../Interfaces/UI';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Transition } from '@headlessui/react';
import { CgClose } from 'react-icons/cg';
import { useAppState } from '../../hooks/adsHooks';
// redux stuff
import { useDispatch } from 'react-redux';
import * as authAction from '../../rdx/actions/auth';
import * as cartAction from '../../rdx/actions/cart';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import serverApi from '../../api/serverApi';

import { ICartItem } from '../../Interfaces/dataModel';

const OtpMobileVerification: React.FC<IDrawerProps> = ({ isOpen, closeHandle }) => {
	const dispatch = useDispatch();
	const [serviceSid, setServiceSid] = useState<string>('');
	const [showCodeCheck, setShowCodeCheck] = useState<boolean>(false);
	const [mobilePhone, setMobilePhone] = useState<string>('');
	const [otpCode, setOtpCode] = useState<string>('');
	const { auth } = useAppState();
	const { t } = useTranslation();
	const {
		register,
		reset,
		formState: { errors },
		handleSubmit,
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		criteriaMode: 'all',
	});

	useEffect(() => {
		reset({ mobilePhone: auth.user.mobilePhone });
	}, [reset, auth.user.mobilePhone]);

	const onSent = async (data: any) => {
		setMobilePhone(data.mobilePhone);
		const OtpService = await serverApi.createOTPService();
		console.log(OtpService);
		if (OtpService.status === 'success') {
			if (OtpService.service.sid) {
				setServiceSid(OtpService.service.sid);
				const sentOtpCode = await serverApi.requestOtpCode(OtpService.service.sid, data);
				console.log(sentOtpCode);
				if (sentOtpCode.status === 'success') {
					if (sentOtpCode.verification) {
						setShowCodeCheck(true);
					}
				}
			}
		}
	};

	const sentOtpCode = async () => {
		const data = { mobilePhone: mobilePhone, code: otpCode };
		const verificationResult = await serverApi.sentOTPCode(serviceSid, data);
		console.log(verificationResult);
		if (verificationResult.status === 'success') {
			if (verificationResult.verified.status === 'approved') {
				const user = verificationResult.user;
				console.log(user);
				dispatch(authAction.userDataUpdate(user));
			}
		}
	};

	return (
		<div className={`${isOpen ? 'block' : 'hidden'} fixed inset-0 overflow-auto z-50 `}>
			<div className='absolute inset-0 overflow-y-auto overflow-x-hidden z-50 '>
				<Transition
					show={isOpen}
					enter='transform transition ease-in-out'
					enterFrom='translate-x-full'
					enterTo='translate-x-0'
					leave='transform transition ease-in-out '
					leaveFrom='translate-x-0'
					leaveTo='translate-x-full'
				>
					<section className='absolute inset-y-0 right-0 max-w-xs sm:max-w-md z-50'>
						<div className='bg-white shadow-xl h-full'>
							<header className='space-y-1 py-6 px-4 bg-indigo-700'>
								<div className='flex items-center justify-between'>
									<h2 className='text-xl leading-7 font-medium text-white'>
										{t('OTP One time password code')}
									</h2>
									<button
										onClick={closeHandle}
										aria-label='Close panel'
										className='text-indigo-200 outline-none hover:text-white focus:outline-none active:outline-none'
									>
										<CgClose size={'1.4em'} />
									</button>
								</div>
								<div>
									<p className='text-md sm:text-base md:text-md lg:text-lg  text-indigo-300'>
										{t(
											'We send a Verification code by SMS. Check your mobile phone and confirm it'
										)}
									</p>
								</div>
							</header>
							{!showCodeCheck ? (
								<form onSubmit={handleSubmit(onSent)}>
									<div className=''>
										<div className='px-4 '>
											<div className='space-y-6 pt-6 pb-5'>
												<div className='space-y-1'>
													<label
														htmlFor='mobilePhone'
														className='block text-base font-medium leading-5 text-gray-900'
													>
														{t('Mobile phone')}
													</label>
													<div className='relative'>
														<input
															{...register('mobilePhone', {
																required: `${t('mobile phone required')}`,
															})}
															type='tel'
															name='mobilePhone'
															id='mobilePhoneId'
															autoComplete='off'
															className=' focus:ring-black focus:border-black block w-full  border-gray-300 '
														/>
														<p className='text-gray-800 text-sm'>
															{t('Use international phone format')} +337724567
														</p>
														{errors.mobilePhone && (
															<span className='text-red-500'>{errors.mobilePhone.message}</span>
														)}
													</div>
												</div>
											</div>

											<button
												type='submit'
												className='py-2 w-full px-3 bg-black text-white hover:bg-gray-700 focus:outline-none transition duration-300 font-semibold'
											>
												{t('Confirm phone and send verification code')}
											</button>
										</div>
									</div>
								</form>
							) : (
								<div className=''>
									<div className='px-4 '>
										<div className='space-y-6 pt-6 pb-5'>
											<div className='space-y-1'>
												<label
													htmlFor='code'
													className='block text-base font-medium leading-5 text-gray-900'
												>
													{t('Enter OTP sent to your mobile number')} {mobilePhone}
												</label>
												<div className='relative'>
													<input
														onChange={(e) => setOtpCode(e.target.value)}
														type='text'
														name='code'
														id='code'
														autoComplete='off'
														className=' focus:ring-black focus:border-black block w-full  border-gray-300 '
													/>
												</div>
											</div>
										</div>

										<button
											onClick={sentOtpCode}
											type='button'
											className='py-2 w-full px-3 bg-black text-white hover:bg-gray-700 focus:outline-none transition duration-300 font-semibold'
										>
											{t('Send')}
										</button>
									</div>
								</div>
							)}
						</div>
					</section>
				</Transition>
			</div>
		</div>
	);
};

const ShoppingCardFoodDrawer: React.FC<IDrawerProps> = ({ isOpen, closeHandle }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { auth, cart } = useAppState();

	const [disableOrder, setDisableOrder] = useState<boolean>(true);
	const [openOTP, setOpenOTP] = useState<boolean>(false);

	useEffect(() => {
		if (auth.token && auth.authenticate) {
			setDisableOrder(false);
		}
	}, [auth.token, auth.authenticate]);

	const addQtyClick = (item: ICartItem) => {
		dispatch(cartAction.addToCart(item));
	};

	const removeQtyClick = (item: ICartItem) => {
		dispatch(cartAction.removeFromCart(item));
	};

	const onConfirmOrder = () => {
		console.log('onConfirmOrder');
		if (!auth.user.mobilePhoneVerified || auth.user.mobilePhone === '') {
			console.log('passing here');
			setOpenOTP(true);
			closeHandle();
		} else {
			// Delivery address information or pickup date time information
			closeHandle();
			history.push('/shop/order-checkout');
		}
	};

	const onCloseOtpMobileVerification = () => {
		setOpenOTP(false);
	};

	const onCommentChange = (e: any) => {
		dispatch(cartAction.addComment(e.target.value));
	};

	return (
		<React.Fragment>
			<OtpMobileVerification isOpen={openOTP} closeHandle={onCloseOtpMobileVerification} />
			<div className={`${isOpen ? 'block' : 'hidden'} fixed inset-0 overflow-auto z-50`}>
				<div className='absolute inset-0 overflow-y-auto overflow-x-hidden'>
					<Transition
						show={isOpen}
						enter=' transform transition ease-in-out'
						enterFrom='translate-x-full'
						enterTo='translate-x-0'
						leave='transform transition ease-in-out duration-500'
						leaveFrom='translate-x-0'
						leaveTo='translate-x-full'
					>
						<section className='absolute bg-white shadow-xl inset-y-0 right-0 max-w-xs sm:max-w-md'>
							<div className='flex-col mb-10 justify-between'>
								<div className='min-h-screen flex flex-col'>
									<header className='p-4 bg-indigo-700  text-white '>
										<div className='flex items-center justify-between'>
											<h2 className='text-xl leading-7 capitalize font-medium text-white'>
												{t('Shopping cart')}
											</h2>
											<button
												onClick={closeHandle}
												aria-label='Close panel'
												className='text-indigo-200 outline-none hover:text-white focus:outline-none active:outline-none'
											>
												<CgClose size={'1.4em'} />
											</button>
										</div>
										<div>
											<p className='text-md sm:text-base md:text-md lg:text-lg  text-indigo-300'>
												{t(
													'Summary of your shopping cart. Check the added items, write any communications and add the quantities before confirming'
												)}
											</p>
										</div>
									</header>
									<main className='h-64 overflow-y-scroll  flex flex-col flex-grow justify-start text-white mb-auto'>
										{cart.products.length === 0 && (
											<p className='text-center justify-items-center p-5 text-black'>
												<img
													className='mx-auto  rounded-md overflow-hidden object-center object-none'
													alt='no-product'
													src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/no-product.png`}
												/>
												<span className='mt-4 text-lg font-medium'>{t('Cart is empty')}</span>
											</p>
										)}
										{cart.products.map((product: ICartItem, index: number) => (
											<div
												key={index}
												className='w-full h-auto flex justify-start items-start bg-white px-3  py-4 border-b border-gray-200 relative last:border-b-0'
											>
												{product.thumb && (
													<div className='flex w-20 object-scale-down  rounded overflow-hidden bg-gray-200  flex-shrink-0'>
														<img
															src={product.thumb}
															// src='https://res.cloudinary.com/redq-inc/image/upload/v1592404899/medsy/Syringe_k8uycq.jpg'
															alt={product.name}
														/>
													</div>
												)}

												<div className='flex flex-col w-full px-1 -mt-1'>
													<span className='text-sm text-gray-900'>
														<span className='font-bold'>{product.code}</span> {product.name}
													</span>
													<span className='text-sm text-gray-500 mt-0.5 mb-1'>
														{t('Unit Price')} {product.currency} {product.price}
													</span>
													{/* <textarea
                            rows={2}
                            placeholder='Testo aggiuntivo'
                            className='mb-2 text-sm border-gray-200'
                          /> */}
													<div className='flex items-center justify-end'>
														<div className='group flex items-center justify-between flex-shrink-0 rounded overflow-hidden bg-gray-900 shadow-floatingUp h-8 '>
															<button
																onClick={() => removeQtyClick(product)}
																className='flex items-center justify-center outline-none  duration-250 ease-in-out h-full
                          w-8 text-white bg-gray-900 transition duration-300 hover:bg-gray-600 focus:outline-none'
															>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='12px'
																	height='2px'
																	viewBox='0 0 12 1.5'
																>
																	<rect
																		data-name='Rectangle 970'
																		width='12px'
																		height='2px'
																		fill='currentColor'
																	></rect>
																</svg>
															</button>
															<span className='font-semibold text-13px text-white flex items-center justify-center h-full w-8 transition-colors duration-250 ease-in-out cursor-default'>
																{product.quantity}
															</span>
															<button
																onClick={() => addQtyClick(product)}
																className='flex items-center justify-center outline-none duration-250 ease-in-out h-full w-8 text-white bg-gray-900 transition duration-300 hover:bg-gray-600 focus:outline-none'
															>
																<svg
																	data-name='plus (2)'
																	xmlns='http://www.w3.org/2000/svg'
																	width='12px'
																	height='12px'
																	viewBox='0 0 12 12'
																>
																	<g data-name='Group 5367'>
																		<path
																			data-name='Path 17138'
																			d='M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z'
																			fill='currentColor'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
														<span className='font-semibold text-16px text-gray-900 flex-shrink-0 ml-2'>
															<NumberFormat
																className='font-semibold'
																value={product.sum}
																thousandSeparator=''
																fixedDecimalScale={true}
																decimalScale={2}
																displayType='text'
																prefix={`${cart.currency} `}
															/>
														</span>
													</div>
												</div>
											</div>
										))}
									</main>
									<footer className='p-4 bg-white text-center'>
										<textarea
											rows={4}
											onChange={onCommentChange}
											// onChange={(e:React.ChangeEvent<HTMLInputElement>) => dispatch(cartAction.addComment(e.target.value))}
											placeholder='Other request to send'
											className='mb-2 text-sm focus:ring-black focus:border-black block w-full  border-gray-300 '
										/>

										<div className='flex items-center justify-between'>
											<span className='text-gray-900'>{t('Subtotal')}</span>
											<span className=' text-base text-gray-900'>
												<NumberFormat
													className=''
													value={cart.totalAmount.toFixed(2)}
													thousandSeparator=''
													fixedDecimalScale={true}
													decimalScale={2}
													displayType='text'
													prefix={`${cart.currency} `}
												/>
											</span>
										</div>
										{cart.deliveryCost > 0 && (
											<div className='flex items-center justify-between'>
												<span className='text-gray-900'>{t('Delivery fees')}</span>
												<span className='text-base text-gray-900'>
													<NumberFormat
														className='text-base'
														value={cart.deliveryCost}
														thousandSeparator=''
														fixedDecimalScale={true}
														decimalScale={2}
														displayType='text'
														prefix={`${cart.currency} `}
													/>
												</span>
											</div>
										)}

										<div className='flex items-center justify-between'>
											<span className='font-semibold text-gray-900'>{'Total'}</span>
											<span className='font-semibold text-base text-gray-900'>
												<NumberFormat
													className='font-semibold'
													value={cart.deliveryCost + cart.totalAmount}
													thousandSeparator=''
													fixedDecimalScale={true}
													decimalScale={2}
													displayType='text'
													prefix={`${cart.currency} `}
												/>
											</span>
										</div>

										<button
											onClick={onConfirmOrder}
											disabled={disableOrder || cart.products.length === 0}
											className='disabled:opacity-50 flex items-center justify-center flex-shrink-0 font-normal w-full uppercase rounded outline-none transition duration-250 ease-in-out focus:outline-none text-white bg-gray-900 hover:bg-gray-900 h-11 px-3 big mt-1'
										>
											{t('Confirm')}
										</button>
									</footer>
								</div>
							</div>
						</section>
					</Transition>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ShoppingCardFoodDrawer;
