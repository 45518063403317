import React from 'react';

import { useTranslation } from 'react-i18next';

// redux stuff
import { useDispatch } from 'react-redux';
import * as filtersAction from '../../rdx/actions/filters.actions';
import { useFilters } from '../../hooks/adsHooks';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { FuSmallCard } from '../../Components/fu-cards';
import GroupMenuCategoryItems from './groupMenuCategoryItems';
import { useCategories } from '../../hooks/adsHooks';

const SideBarFilter: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { createSliderWithTooltip } = Slider;
	const Range = createSliderWithTooltip(Slider.Range);
	const RangeDistance = createSliderWithTooltip(Slider.Range);
	const { priceRange } = useFilters();
	const { distance, unit } = useFilters();

	const menu: any[] = useCategories().menu;

	const priceRangeAfterChangeHandle = (value: Array<number>) => {
		dispatch(filtersAction.setPriceRange(value));
	};
	const distanceAfterChangeHandle = (value: Array<number>) => {
		dispatch(filtersAction.setDistance(value[0]));
	};
	const unitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(filtersAction.setUnit(e.target.value));
	};

	const resetFilter = () => {
		dispatch(filtersAction.setReset());
	};

	return (
		<div className='hidden md:flex w-1/5 p-2'>
			<div className='w-full mb-4 '>
				<div className='uppercase font-semibold text-xs tracking-wider flex flex-row items-center justify-start w-full py-1'>
					{t('Categories')}
				</div>
				<div className='w-full'>
					{menu.map((m, index) => {
						return <GroupMenuCategoryItems links={m.children} title={m.name} key={index} />;
					})}

					{/* price filter or range filter */}
					<div className='w-full mb-4 mt-2'>
						<div className='uppercase font-semibold text-xs tracking-wider flex flex-row items-center justify-start w-full mb-2'>
							{t('Price')}
						</div>
						<div className='py-2 px-4'>
							<div className='max-w-xs'>
								<Range
									min={10}
									max={100000}
									defaultValue={priceRange}
									trackStyle={[{ backgroundColor: 'black', height: 8 }]}
									handleStyle={[
										{
											borderColor: 'white',
											height: 20,
											width: 20,
											marginLeft: -10,
											marginTop: -6,
											backgroundColor: 'black',
										},
									]}
									onAfterChange={priceRangeAfterChangeHandle}
									railStyle={{ backgroundColor: 'gray', height: 8 }}
								/>
							</div>
						</div>
						<div className='flex'>
							<div className='flex text-gray-400 text-sm'>
								{t('Price')} {priceRange[0]} - {priceRange[1]}
							</div>
						</div>
					</div>
					{/* Distance radius */}
					<div className='w-full mb-4 mt-2'>
						<div className='uppercase font-semibold text-xs tracking-wider flex flex-row items-center justify-start w-full mb-2'>
							{t('Distance')}
						</div>
						<div className='py-2 px-4'>
							<div className='max-w-xs'>
								<RangeDistance
									min={100}
									max={1000}
									// defaultValue={[100]}
									defaultValue={[distance]}
									trackStyle={[{ backgroundColor: 'black', height: 8 }]}
									handleStyle={[
										{
											borderColor: 'white',
											height: 20,
											width: 20,
											marginLeft: -10,
											marginTop: -6,
											backgroundColor: 'black',
										},
									]}
									onAfterChange={distanceAfterChangeHandle}
									railStyle={{ backgroundColor: 'gray', height: 8 }}
								/>
							</div>
						</div>
						<div className='flex'>
							<div className='flex text-gray-400 text-sm'>
								{distance} {unit}
							</div>
						</div>
					</div>
					{/* Unit option filter km miles */}
					<div className='w-full mb-4 mt-2'>
						<div className='flex justify-between'>
							<label className='inline-flex items-center'>
								<input
									type='radio'
									className='fu-checkbox'
									name='accountType'
									value='km'
									checked={unit === 'km' || false}
									onChange={unitChange}
								/>
								<span className='ml-1'>Km</span>
							</label>
							<label className='inline-flex  items-center'>
								<input
									type='radio'
									className='fu-checkbox'
									name='accountType'
									value='mi'
									checked={unit === 'mi' || false}
									onChange={unitChange}
								/>
								<span className='ml-1'>{t('Miles')}</span>
							</label>
						</div>
					</div>

					{/* Brand */}
					<div className='hidden'>
						<div className='mt-4 w-full'>
							<div className='uppercase font-semibold text-xs tracking-wider flex flex-row items-center justify-start w-full'>
								Brand
							</div>
						</div>
						<div className='py-2 px-2'>
							<div className='flex flex-col w-full'>
								<div className='flex flex-row items-center justify-start mt-1 '>
									<label className='flex justify-start items-center'>
										<div className='border-2 rounded-sm border-black w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 '>
											<input type='checkbox' className='opacity-0 absolute ' />
											<svg
												className='fill-current hidden w-4 h-4  text-white pointer-events-none bg-black'
												viewBox='0 0 20 20'
											>
												<path d='M0 11l2-2 5 5L18 3l2 2L7 18z' />
											</svg>
										</div>
										<div className='text-sm'>BMW</div>
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className='border-t '>
						<button onClick={resetFilter} className='btn-black py-1 px-2 rounded-sm'>
							Reset
						</button>
					</div>
					{/* Advertising */}
					<div className='mt-4 w-full hidden'>
						<div className='uppercase font-semibold text-xs tracking-wider flex flex-row items-center justify-start w-full'>
							sponsorizzati
						</div>

						<div className='mt-1'>
							<FuSmallCard
								title='Affitto app. Lugano'
								price={500}
								titleOverlay={false}
								currency='CHF'
								unitPrice='mese'
								badge='affitto'
								badgeBgColor='bg-yellow-400'
								image='https://c.tutti.ch/images/3669837978.jpg'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SideBarFilter;
