import React, { useEffect, useState } from 'react';
import {
	// ToastContainer, Zoom,
	toast,
} from 'react-toastify';

import { useTranslation } from 'react-i18next';

/** Google marker pin use for passing children component to FuGoogleMap  */
import { Marker } from '@react-google-maps/api';
import { useForm } from 'react-hook-form';

import serverApi from '../../api/serverApi';

import { IUser, IPasswordChange } from '../../Interfaces/dataModel';

import { useDispatch } from 'react-redux';
import * as authAction from '../../rdx/actions/auth';
import { useSelector } from 'react-redux';
import { AppState } from '../../rdx/rootReducer';
/** Component GoogleMap for watching a map of current coordinates and market pin when user set up address */
import FuGoogleMap from '../../Components/fu-googlemap';

import PasswordChange from './password-change';
import UserAdManager from './user-ad-manager';

const excludeFields = (obj: any[], ...notAllowedFields: any[]) => {
	const newObj: any = {};
	Object.keys(obj).forEach((el: any) => {
		if (!notAllowedFields.includes(el)) {
			newObj[el] = obj[el];
		}
	});
	return newObj;
};

/** Is a type of GoogleMap restful coordinates. On my server must be use latitude and longitude key name */
type CenterProps = {
	lat: number;
	lng: number;
};

type userContentProp = {
	userProfile: IUser;
	tab?: number;
};

const UserContent: React.FC<userContentProp> = ({ userProfile, tab = 0 }) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	/** grab coordinates from redux */
	const { coords } = useSelector((state: AppState) => state.app);
	/** active tab 0:`Overview` , 1:`Manage Your Ads` , 2:`change password`   */
	const [activeTab, setActiveTab] = useState<number>(tab);
	/** Store coordinates. On load set the current position coordinates @see useEffect  */
	const [coordinates, setCoordinates] = useState<CenterProps>({
		lat: coords.latitude,
		lng: coords.longitude,
	});

	/** form-hook initialization  */
	const {
		register,
		// formState: { errors },
		reset,
		handleSubmit,
	} = useForm({ mode: 'onBlur', reValidateMode: 'onChange', criteriaMode: 'all' });

	useEffect(() => {
		reset(userProfile);
		return () => {};
	}, [userProfile, reset]);

	/** Get to geolocation coordinates on base CompleteAddress. SetCoordinate state for update Google Map marker  */
	const pinAddressOnMap = async (completeAddress: string): Promise<CenterProps> => {
		/** Initial result */
		let result: CenterProps = { lat: 0, lng: 0 };

		/** Url Google end point with completeAddress variable. The format request is: zipCode+street,country. A country is a optional variable. @see https://developers.google.com/maps/documentation/geocoding/overview. */
		const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${completeAddress}&key=AIzaSyBuL1B4s4ZL1ErfUl8Ja0hhto3l3J5GWoI`;

		/** Store fetch result */
		const location: any = await fetch(url, { method: 'GET' }).then((response) => response.json());

		/** Check if found it */
		if (location.status === 'OK') {
			/** Set coordinate state with a founded coordinates */
			setCoordinates(location.results[0].geometry.location);
			/** set on result object a new location */
			result = location.results[0].geometry.location;
		}
		/** callback result location */
		return result;
	};

	/** Send request for update changes of user information */
	const updateUserInformation = async (form: any) => {
		console.log('🔥 ', 'passing here');
		console.log(form);
		let formData = excludeFields(form, 'email', 'password', 'passwordConfirm', 'currentPassword');
		if (formData.mobilePhone === '') {
			formData = excludeFields(form, 'mobilePhone');
		}
		console.log(formData);

		let completeAddress;
		/** Prepare string format for reverse geolocation on map */
		const formLocationAddress = form.location.address;
		if (formLocationAddress.zipCode !== '') {
			completeAddress = formLocationAddress.zipCode;
		}
		if (formLocationAddress.street !== '') {
			completeAddress = completeAddress + '+' + formLocationAddress.street.split(' ').join('+');
		}
		if (formLocationAddress.city !== '') {
			completeAddress = completeAddress + ',' + formLocationAddress.city;
		}
		if (formLocationAddress.country !== '') {
			completeAddress = completeAddress + ',' + formLocationAddress.country;
		}
		if (completeAddress !== '') {
			const latlng = await pinAddressOnMap(completeAddress);
			formData.location.coordinates = [latlng.lat, latlng.lng];
		}
		try {
			const user = await serverApi.updateMe(formData);
			if (user?.code >= 400) {
				throw new Error(user.message);
			}
			dispatch(authAction.userDataUpdate(user));
			toast.dark(t('Saved data'));
		} catch (err) {
			toast.error(err.message);
		}
	};

	const updateUserPassword = async (form: any) => {
		const pwd: IPasswordChange = {
			currentPassword: form.currentPassword,
			password: form.password,
			passwordConfirm: form.passwordConfirm,
		};
		try {
			if (pwd.password !== pwd.passwordConfirm) {
				throw new Error('Le password inserite non corrispondono');
			}
			const { user, token } = await serverApi.passwordChange(pwd);
			console.log(user, token);
			dispatch(authAction.updateToken(user, token));
			toast.dark(t('Saved data'));
		} catch (e) {
			console.log(e.message);
			toast.dark(e.message, { autoClose: false, closeOnClick: true });
		}
	};

	/** Dispatch state a different method on base activeTab variable state */
	const onSubmit = (form: any) => {
		if (activeTab === 0) {
			updateUserInformation(form);
		} else if (activeTab === 2) {
			updateUserPassword(form);
		}
	};
	// note: When I change tab form required rest save it. I need reset form when changing the tab
	return (
		<>
			{/* content */}
			{/* <ToastContainer
				position='bottom-left'
				transition={Zoom}
				autoClose={2000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={false}
			/> */}
			<div className='w-full p-2 md:w-3/4'>
				<div className='flex items-center font-thin text-gray-700 text-sm border-b'>
					<div
						onClick={() => setActiveTab(0)}
						className={`font-normal ${
							activeTab === 0 ? 'text-gray-600 bg-gray-200' : 'text-gray-500'
						}  p-1 rounded-sm cursor-pointer hover:bg-gray-200 `}
					>
						{t('Overview')}
					</div>
					<div className='p-1 flex items-center'>
						<div
							onClick={() => setActiveTab(1)}
							className={`font-normal ${
								activeTab === 1 ? 'text-gray-600 bg-gray-200' : 'text-gray-500'
							}  p-1 rounded-sm cursor-pointer hover:bg-gray-200 `}
						>
							<span className='p-0'>{t('Manage your ads')}</span>
						</div>
					</div>
					<div className='p-1 flex items-center'>
						<div
							onClick={() => setActiveTab(2)}
							className={`font-normal ${
								activeTab === 2 ? 'text-gray-600 bg-gray-200' : 'text-gray-500'
							}  p-1 rounded-sm cursor-pointer hover:bg-gray-200 `}
						>
							<span className='p-0'>{t('Change password')}</span>
						</div>
					</div>
				</div>
				{activeTab === 0 && (
					<div className='user-detail'>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className='grid grid-cols-1 md:grid-cols-2 gap-2 mt-4 '>
								<div className='col-span-2 text-sm text-gray-600 font-semibold'>
									<div className='font-medium text-gray-600'>
										{t('Do you want to work as a delivery boy?')}
									</div>
									<div className='text-sm font-normal mt-2'>
										{t('By selecting this option you accept all the terms and conditions of faitu')}{' '}
										<a className='underline' href={`/${i18n.language}/driver-info`}>
											{t('How does it work')}
										</a>
									</div>
								</div>
								<div className='flex flex-col w-full'>
									<div className='flex flex-row items-center justify-start mt-1'>
										<label className='flex justify-start items-start'>
											<div className='border-2 rounded-sm border-gray-600   w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 '>
												<input
													type='checkbox'
													value='true'
													className='opacity-0 absolute '
													{...register('isDriver')}
												/>
												<svg
													className='fill-current hidden w-4 h-4  text-white pointer-events-none bg-gray-600'
													viewBox='0 0 20 20'
												>
													<path d='M0 11l2-2 5 5L18 3l2 2L7 18z' />
												</svg>
											</div>
											<div className='select-none text-sm'>{t('Bellboy for home deliveries')}</div>
										</label>
									</div>
								</div>
								<div className='col-span-2 text-sm text-gray-600 font-semibold mt-4'>
									{userProfile.email}
								</div>
								<div>
									<label className='block text-sm '>
										<span className='text-gray-700'>{t('Last name')}</span>
										<input
											type='text'
											className='input-black'
											id='lastNameId'
											{...register('lastName')}
										/>
									</label>
								</div>

								<div>
									<label className='block text-sm'>
										<span className='text-gray-700'>{t('Name')}</span>
										<input type='text' className='input-black' id='nameId' {...register('name')} />
										{/* {errors.lastName?.type === 'required' && (
                      <span className='text-red-500'>Cognome obligatorio</span>
                    )} */}
									</label>
								</div>

								<div className='col-span-2 sm:col-span-1'>
									<label className='block text-sm'>
										<span className='text-gray-700'>{t('Mobile phone')}</span>
										<input
											type='text'
											className='input-black'
											id='mobilePhoneId'
											{...register('mobilePhone')}
										/>
									</label>
									<div className='flex flex-col w-full'>
										<div className='flex flex-row items-center justify-start mt-3'>
											<label className='flex justify-start items-start'>
												<div className='border-2 rounded-sm border-gray-600   w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 '>
													<input
														type='checkbox'
														className='opacity-0 absolute '
														{...register('hideMobilePhone')}
													/>
													<svg
														className='fill-current hidden w-4 h-4  text-white pointer-events-none bg-gray-600'
														viewBox='0 0 20 20'
													>
														<path d='M0 11l2-2 5 5L18 3l2 2L7 18z' />
													</svg>
												</div>
												<div className='select-none text-sm'>{t('Hide number')}</div>
											</label>
										</div>
									</div>
								</div>
								{/* <div>
                  <label className='block text-sm'>
                    <span className='text-gray-700'>Indirizzo web</span>
                    <input
                      type='text'
                      className='input-black'
                      id='webAddressId'
                      {...register('webAddress')}
                    />
                  </label>
                </div> */}

								<div className='col-span-2 mt-4'>
									<h1>{'Address'}</h1>
								</div>
								<div className='col-span-2 sm:col-span-1 md:col-span-1'>
									<label className='block text-sm '>
										<span className='text-gray-700'>{'Street Address'}</span>
										<input
											type='text'
											className='input-black'
											id='streetId'
											{...register('location.address.street')}
											// onChange={onAddressChange}
										/>
									</label>

									<label className='block text-sm mt-2 '>
										<span className='text-gray-700'>{'Zip code'}</span>
										<input
											type='text'
											className='input-black'
											id='capId'
											{...register('location.address.zipCode')}
											// onChange={onZipCodeChange}
										/>
										{/* {errors.lastName?.type === 'required' && (
                      <span className='text-red-500'>Cognome obligatorio</span>
                    )} */}
									</label>
									<label className='block text-sm mt-2 '>
										<span className='text-gray-700'>{'City'}</span>
										<input
											type='text'
											className='input-black'
											id='cityId'
											{...register('location.address.city')}
											// onChange={onCityChange}
										/>
									</label>
									<label className='block text-sm mt-2 '>
										<span className='text-gray-700'>{t('Country')}</span>
										<input
											type='text'
											className='input-black'
											id='countryId'
											{...register('location.address.country')}
											// onChange={onCountryChange}
											// onBlur={pinAddressOnMap}
										/>
									</label>
									<div className='flex flex-col w-full'>
										<div className='flex flex-row items-center justify-start mt-3'>
											<label className='flex justify-start items-start'>
												<div className='border-2 rounded-sm border-gray-600 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 '>
													<input
														type='checkbox'
														className='opacity-0 absolute '
														{...register('hideAddress')}
													/>
													<svg
														className='fill-current hidden w-4 h-4  text-white pointer-events-none bg-gray-600'
														viewBox='0 0 20 20'
													>
														<path d='M0 11l2-2 5 5L18 3l2 2L7 18z' />
													</svg>
												</div>
												<div className='select-none text-sm'>{t('Hide address')}</div>
											</label>
										</div>
									</div>
								</div>

								<div className='col-span-2 sm:col-span-1 md-col-span-1'>
									<FuGoogleMap center={coordinates} zoom={12}>
										<Marker position={coordinates} />
									</FuGoogleMap>
								</div>
							</div>
							<button type='submit' className='btn-black'>
								{t('Save')}
							</button>
						</form>
					</div>
				)}
				{activeTab === 1 && (
					<div className='user-ads-manager mt-4'>
						{/* <h1 className='font-semibold'>{t('Your ads')}</h1> */}
						<UserAdManager />
					</div>
				)}
				{activeTab === 2 && <PasswordChange />}
			</div>
		</>
	);
};
export default UserContent;
