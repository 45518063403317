import React from 'react';
import { useAppState } from '../../hooks/adsHooks';
import { IAd } from '../../Interfaces/dataModel';
import humanFriendlyDate from '../../utils/humanized.date';

import { useTranslation } from 'react-i18next';

type ListAdProp = {
	item: IAd;
};

// function ListItem(props: { imageUrl: string }) {
const ClassFiedItem: React.FC<ListAdProp> = ({ item }) => {
	const { t } = useTranslation();
	function capitalizeAndTranslate(value: string) {
		const str = value.charAt(0).toUpperCase() + value.slice(1);
		return t(str);
	}
	return (
		<React.Fragment>
			<div
				className={`block lg:hidden rounded overflow-hidden
        w-full p-3 px-3 md:mx-0 lg:mx-0  ${item.highlighted ? 'bg-indigo-50' : ''} `}
			>
				<a href={item.external ? `${item.externalUrl}` : `/ads-detail/${item._id}`}>
					<span className='pt-1 font-bold'>{item.title}</span>
					{/* `url(${process.env.REACT_APP_SERVER_URL_ROOT}/images/no-thumbnail.png)` */}
					<img
						className='w-full bg-cover rounded'
						src={
							item.gallery.length > 0
								? item.gallery[0]
								: `${process.env.REACT_APP_SERVER_URL_ROOT}/images/no-thumbnail.png`
						}
						alt={item.title}
						loading='lazy'
					/>
					<div className='px-1 pb-2'>
						<div className='pt-2'>
							<span className=' text-gray-500 font-bold'>{item.title}</span>
						</div>
						<div className='pt-1'>
							<div className='mb-2 text-gray-700'>{item.description}</div>
						</div>
						<div className='mb-2 text-gray-900 font-medium'>
							{item.price.currency} {item.price.amount}
						</div>
					</div>
				</a>
			</div>

			<div
				className={`p-2 mb-5 mr-0 rounded-md sm:mr-3 ${
					item.highlighted ? 'bg-indigo-50' : ''
				} hidden lg:block`}
			>
				<div className='flex flex-wrap items-center w-full'>
					<div className='flex w-full md:flex-1 flex-col leading-normal'>
						<div className='text-gray-800 font-semibold'>
							<a
								target='_blank'
								rel='noreferrer'
								href={item.external ? `${item.externalUrl}` : `/ads-detail/${item._id}`}
								className='hover:underline'
							>
								{item.title}
							</a>
						</div>
					</div>
					{item.price.amount > 0 && (
						<div className=' flex flex-wrap'>
							<div className='flex  text-sm  rounded-md bg-gray-200 font-normal'>
								<span className='text-gray-600 p-1 font-semibold'>
									{item.price.amount > 0 ? `${item.price.currency} ${item.price.amount}` : ''}
								</span>
							</div>
							<div className='flex'>
								<span className='text-sm ml-1 justify-items-center p-1 text-gray-600 font-semibold'>
									{item.price.unit ? item.price.unit : ''}
								</span>
							</div>
						</div>
					)}
				</div>
				<article className='p-0 mt-1 w-full lg:max-w-full lg:flex '>
					<a
						target='_blank'
						rel='noreferrer'
						href={item.external ? `${item.externalUrl}` : `/ads-detail/${item._id}`}
					>
						<div
							className='h-36 lg:w-36 mr-2 flex-none bg-cover no-repeat  rounded  text-center overflow-hidden transition duration-500 ease-in-out transform hover:scale-110'
							style={{
								backgroundImage: `${
									item?.gallery.length > 0
										? `url(${item.gallery[0]})`
										: `url(${process.env.REACT_APP_SERVER_URL_ROOT}/images/no-thumbnail.png)`
								}`,
							}}
							title={`${item.title}`}
						></div>
					</a>

					<div className='flex-1 mt-3 sm:mt-0'>
						<div className=' rounded-b lg:rounded-b-none lg:rounded-r  md:px-0 flex flex-col leading-normal'>
							<div className='relative h-full sm:h-36'>
								{/* <div className='text-gray-800 font-semibold'>
                <a href='/' className='hover:underline'>
                  Appartamento rinnovato 3.5 locali - Molino Nuovo
                </a>
              </div> */}
								{/* <div className='text-gray-500 text-sm'>6900 Lugano 23/02/2021 09:21</div> */}
								<div className='w-full justify-left items-center flex'>
									<div className='flex-1 text-gray-500 text-sm'>
										{item.location.address.zipCode} {item.location.address.city}
										{', '}
										{humanFriendlyDate(new Date(item.publishedAt), useAppState().app.lang)}
									</div>
									{item?.contactInfo?.brandThumb && (
										<div className='flex right-0'>
											<img
												className='h-6'
												src='https://c.tutti.ch/account_logo/8000007080.jpg'
												// src={`${item.contactInfo.brandThumb}`}
												alt='logo'
											/>
										</div>
									)}
								</div>
								{/* breadcrumb */}
								<div className='flex-1 text-indigo-800 text-sm'>
									<span className='border-b border-dotted'>
										<a
											href={`/${useAppState().app.lang}/classfied/${item.category}/${
												item.subCategory
											}`}
										>
											{' '}
											{capitalizeAndTranslate(item.subCategory)}
										</a>{' '}
									</span>
								</div>

								<p className='text-gray-700 line-clamp-2 mt-1 text-normal'>{item?.description}</p>
								{item?.ad && (
									<div className='text-sm mt-1'>
										<div className='flex justify-start space-x-2 sm:space-x-4'>
											{Object.keys(item.ad).map(
												(e, index: number) =>
													index < 3 && (
														<div key={e} className='inline-block space-x-1'>
															<div className='inline-block font-semibold'>{t(e)}</div>
															<div className='inline-block'>{t(item.ad[e])}</div>
														</div>
													)
											)}
										</div>
									</div>
								)}

								<div className='mt-1 sm:bottom-0 '>
									<div className='flex space-x-3 text-sm font-semibold mt-1'>
										<div className='flex'>
											<a
												href={item.external ? `${item.externalUrl}` : `/ads-detail/${item._id}`}
												className='  border-gray-300 border-b pb-1 hover:border-gray-100  underline-effect relative'
											>
												{t('Show more')}
											</a>
										</div>

										{item?.contactInfo?.website && (
											<div className='flex'>
												<a
													href={item.contactInfo.website}
													target='_blank'
													rel='noreferrer'
													className='  border-gray-300 border-b pb-1 hover:border-gray-100  underline-effect relative'
												>
													{t('Website')}
												</a>
											</div>
										)}
									</div>
								</div>
							</div>

							{/* <div className='flex items-center space-x-1'>
              <div className='flex text-sm px-1 py-1   rounded-md bg-gray-200 font-normal'>
                <p className='text-gray-600 font-semibold'>CHF 720</p>
              </div>
              <div className='flex'>
                <p className='text-sm text-gray-600 font-semibold'>Affitto</p>
              </div>
            </div> */}
						</div>
					</div>
				</article>
			</div>
		</React.Fragment>
	);
};

export const IClassFiedItem: React.FC<ListAdProp> = ({ item }) => {
	return (
		<div className=' rounded overflow-hidden border w-full lg:w-6/12 md:w-6/12 bg-white mx-3 md:mx-0 lg:mx-0'>
			<div className='w-full flex justify-between p-3'>
				<div className='flex'>
					<div className='rounded-full h-8 w-8 bg-gray-500 flex items-center justify-center overflow-hidden'>
						<img src='https://avatars0.githubusercontent.com/u/38799309?v=4' alt='profilepic' />
					</div>
					<span className='pt-1 ml-2 font-bold text-sm'>braydoncoyer</span>
				</div>
				<span className='px-2 hover:bg-gray-300 cursor-pointer rounded'>
					<i className='fas fa-ellipsis-h pt-2 text-lg'></i>
				</span>
			</div>
			<img className='w-full bg-cover' src={item.gallery[0]} alt='..' />
			<div className='px-3 pb-2'>
				<div className='pt-2'>
					<i className='far fa-heart cursor-pointer'></i>
					<span className='text-sm text-gray-400 font-medium'>12 likes</span>
				</div>
				<div className='pt-1'>
					<div className='mb-2 text-sm'>
						<span className='font-medium mr-2'>braydoncoyer</span> Lord of the Rings is my favorite
						film-series. One day I'll make my way to New Zealand to visit the Hobbiton set!
					</div>
				</div>
				<div className='text-sm mb-2 text-gray-400 cursor-pointer font-medium'>
					View all 14 comments
				</div>
				<div className='mb-2'>
					<div className='mb-2 text-sm'>
						<span className='font-medium mr-2'>razzle_dazzle</span> Dude! How cool! I went to New
						Zealand last summer and had a blast taking the tour! So much to see! Make sure you bring
						a good camera when you go!
					</div>
				</div>
			</div>
		</div>

		// <div className="grid grid-cols-1 sm:grid-cols-2 w-full">
		//   <div className="relative z-10 col-start-1 sm:col-start-2 row-start-1 pt-40 sm:pt-0 px-4 pb-3 bg-gradient-to-t from-black sm:bg-none">
		//     <p className="text-sm font-medium text-white sm:mb-1  sm:text-gray-500">Entire house</p>
		//     <h2 className="text-xl font-semibold text-white sm:text-2xl sm:leading-7 sm:text-black md:text-3xl">Beach House in Collingwood</h2>
		//   </div>
		//   <div className="col-start-1 row-start-2 px-4 sm:pb-16 sm:col-start-2">
		//     <div className="flex items-center text-sm font-medium my-5 sm:mt-2 sm:mb-4">
		//       <svg width="20" height="20" fill="currentColor" className="text-violet-600">
		//         <path d="M9.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118l-2.8-2.034c-.784-.57-.381-1.81.587-1.81H7.03a1 1 0 00.95-.69L9.05 3.69z" />
		//       </svg>
		//       <div className="ml-1">
		//         <span className="text-black">4.94</span>
		//         <span className="sm:hidden md:inline">(128)</span>
		//       </div>
		//       <div className="text-base font-normal mx-2">·</div>
		//       <div>Collingwood, Ontario</div>
		//     </div>
		//     <hr className="w-16 border-gray-300 hidden sm:block" />
		//   </div>
		//   <div className="col-start-1 row-start-3 space-y-3 px-4 sm:col-start-2">
		//     <p className="flex items-center text-black text-sm font-medium">
		//       <img src="/kevin-francis.jpg" alt="" className="w-6 h-6 rounded-full mr-2 bg-gray-100" />
		//       Hosted by Kevin Francis
		//     </p>
		//     <button type="button" className="bg-violet-100 text-violet-700 text-base font-semibold px-6 py-2 rounded-lg">Check availability</button>
		//   </div>
		//   {/* <div className="col-start-1 row-start-1 flex sm:col-start-2 sm:row-span-3"> */}
		//   <div className="col-start-1 row-start-1 flex  sm:row-span-3">
		//     <div className="w-full grid grid-cols-1 grid-rows-1 gap-2">
		//       <div className="relative col-span-3 row-span-2 md:col-span-2">
		//         <img src={item.gallery[0]} alt=""
		//           className="absolute inset-0 w-full h-full object-cover
		//           bg-gray-100 sm:rounded-lg sm:h-36" />
		//       </div>
		//       {/* <div className="relative hidden md:block">
		//         <img src={item.gallery[1]} alt="" className="absolute inset-0 w-full h-full object-cover rounded-lg bg-gray-100" />
		//       </div>
		//       <div className="relative hidden md:block">
		//         <img src={item.gallery[2]} alt="" className="absolute inset-0 w-full h-full object-cover rounded-lg bg-gray-100" />
		//       </div> */}
		//     </div>
		//   </div>
		// </div>
	);
};

export default ClassFiedItem;
