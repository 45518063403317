import React, { useEffect, useState } from 'react';
import { MdMonochromePhotos } from 'react-icons/md';
import { FuImageUpload } from '../../../../UI/classFied/Forms/galleryZone';
import { useTranslation } from 'react-i18next';
import serverApi from '../../../../api/serverApi';
import noCover from '../../../../images/no-cover.jpeg';

const ShopHeaderNav = ({ setForm, formData, navigation, children }: any) => {
	const { t } = useTranslation();
	const { go } = navigation;
	const [activeTab, setActiveTab] = useState<number>(0);
	const [coverImage, setCoverImage] = useState<any>(formData.coverThumb);

	const props = { setForm, formData };

	const onUploadCoverImage = async (event: any) => {
		const form = new FormData();
		form.append('image', event.target.files[0]);
		const response = await serverApi.uploadImageToShopMe(form);
		if (response.status === 'success') {
			setForm({ target: { name: 'coverThumb', value: response.pathUrl } });
			setCoverImage(response.pathUrl);
			await serverApi.ShopUpdate({ _id: formData._id, coverThumb: response.pathUrl });
		}
	};

	const onProfileImageUpload = async (event: any) => {
		if (event === undefined) return;
		const form = new FormData();
		form.append('image', event);

		const response = await serverApi.uploadImageToShopMe(form);
		if (response.status === 'success') {
			setForm({ target: { name: 'logo', value: response.pathUrl } });

			await serverApi.ShopUpdate({ _id: formData._id, logo: response.pathUrl });
		}
		console.log(response);
	};

	useEffect(() => {
		go(activeTab);
	}, [activeTab, go]);

	return (
		<div className='max-w-screen-xl mx-auto  antialiased  '>
			<div className='justify-center'>
				<div className='w-full'>
					<div className='bg-white relative overflow-hidden'>
						<div
							className='bg-cover bg-center h-64 flex overflow-hidden justify-start
                 items-end bg-gray-300 py-0  opacity-65 shadow-lg '
							style={{
								backgroundImage: `${
									coverImage !== undefined && coverImage !== null
										? `url(${coverImage})`
										: `url(${noCover})`
								}`,
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
							}}
						>
							<div className='w-full absolute ml-3 top-3'>
								<label
									// type='submit'
									style={{ border: '1px solid rgba(27,31,35,.15)' }}
									className='cursor-pointer float-right mr-5 py-1 px-3 mt-1 text-sm flex items-center rounded-md shadow-sm
                bg-gray-100 text-gray-800 focus:outline-none active:outline-none hover:text-black hover:bg-gray-50'
								>
									<div className='font-medium inline-flex text-md justify-center items-center space-x-2'>
										<span>
											<MdMonochromePhotos />
										</span>{' '}
										<span>{t('Upload cover image')}</span>
									</div>
									<input type='file' onChange={onUploadCoverImage} className='hidden' />
								</label>
							</div>

							<FuImageUpload
								// currentImage='https://tsh.io/wp-content/uploads/2021/03/tailwind-css-sing_.png'
								currentImage={formData.logo}
								styles='bg-white text-white w-32 '
								{...props}
								onImageChanged={onProfileImageUpload}
							/>

							<div className='flex flex-col px-2 bg-black opacity-65 w-full '>
								<div className='flex items-center justify-center'>
									<h1 className='text-xl sm:text-3xl font-semibold text-white  w-full flex-wrap '>
										{formData.name}
									</h1>
								</div>

								<p className='text-normal sm:text-lg font-medium text-white'>
									{formData.location.address.street}, {formData.location.address.zipCode}{' '}
									{formData.location.address.city}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='h-12 '>
					<div className='w-full  '>
						<ul className='list-reset w-full flex font-bold justify-end items-center h-12 shadow '>
							<li
								onClick={() => setActiveTab(0)}
								className={`${
									activeTab === 0
										? 'cursor-pointer py-1  px-4 border-l text-grey-darkest bg-black text-white'
										: 'cursor-pointer px-4 border-l text-blue hover:underline'
								} `}
							>
								{t('Settings')}
							</li>
							<li
								onClick={() => setActiveTab(1)}
								className={`mr-1 ${
									activeTab === 1
										? 'cursor-pointer py-1  px-4 border-l text-grey-darkest bg-black text-white'
										: 'cursor-pointer px-4 border-l text-blue hover:underline'
								} `}
							>
								{t('Product management')}
							</li>
						</ul>
					</div>
				</div>
				{!formData.subscription.active && (
					<div className='h-12 bg-red-800 text-white font-medium text-center'>
						<p className='p-3'>
							{t(
								'Your business is not yet published. To publish your business you need to sign up for a subscription'
							)}
							.
						</p>
					</div>
				)}
				{formData.subscription.active && !formData?.published && (
					<div className='h-12 bg-green-800 text-white font-medium text-center'>
						<p className='p-3'>
							{t(
								'Subscription completed successfully. If the information entered complies with the general conditions, your shop will be published in the next 48 hours.'
							)}
						</p>
					</div>
				)}
				{children}
			</div>
		</div>
	);
};

export default ShopHeaderNav;
