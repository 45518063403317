import React from 'react';

export const ErrorMessage = ({ message }: any) => {
	return (
		<p className='mt-1 text-sm text-red-500'>
			{/* <i className='fas fa-info-circle'></i> */}

			<i className='fas fa-exclamation-triangle'></i>
			<span className='text-sm ml-1'>{message}</span>
		</p>
	);
};

export const CommentMessage: React.FC = ({ children }) => {
	return (
		<p className='mt-1 mb-1 text-sm text-gray-500'>
			{/* <i className='fas fa-info-circle'></i> */}

			<i className='fas fa-info-circle'></i>
			<span className='text-sm ml-1'>{children}</span>
		</p>
	);
};
