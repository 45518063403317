import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	children: React.ReactNode;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	className?: string;
}

const IconButton = (props: Props) => {
	const {
		children,
		onClick = (event: React.MouseEvent<HTMLButtonElement>) => {},
		className = '',
	} = props;

	return (
		<button
			onClick={onClick}
			className={`focus:outline-none focus:border-none hover:bg-gray-400 hover:bg-opacity-25 p-2 rounded-sm inline-flex items-center ${className}`}
		>
			{children}
		</button>
	);
};

const ExitIcon = () => {
	return (
		<svg
			className='h-6 w-6 fill-current text-grey hover:text-grey-darkest'
			role='button'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 20 20'
		>
			<path d='M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z' />
		</svg>
	);
};

interface DialogProps {
	children: React.ReactNode;
	open: boolean;
	onClose: Function;
}

const Dialog = (props: DialogProps) => {
	const { open, onClose } = props;
	if (!open) {
		return <></>;
	}
	return (
		<div className='fixed flex inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-10 '>
			<div className='relative p-4 bg-white w-full max-w-md m-auto flex-col flex'>
				<div>{props.children}</div>
				<span className='absolute top-0 right-0 p-1'>
					<IconButton onClick={() => onClose()}>
						<ExitIcon />
					</IconButton>
				</span>
			</div>
		</div>
	);
};

interface ButtonProps {
	children: React.ReactNode;
	type?: 'submit' | 'button' | 'reset';
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	className?: string;
}

const Button = (props: ButtonProps) => {
	const { type = 'button', children, onClick, className = '' } = props;
	return (
		<button
			className={`font-bold py-2 px-4  focus:outline-none focus:shadow-lg ${className}`}
			type={type}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

interface ConfirmProps {
	title: string;
	children: React.ReactNode;
	open: boolean;
	onClose: Function;
	onConfirm: Function;
}

const DialogBox = (props: ConfirmProps) => {
	const { t } = useTranslation();
	const { open, onClose, title, children, onConfirm } = props;

	if (!open) {
		return <></>;
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<h2 className='text-xl'>{title}</h2>
			<div className='py-5'>{children}</div>
			<div className='flex justify-end'>
				<div className='p-1'>
					<Button onClick={() => onClose()} className='bg-white  text-black hover:bg-gray-100'>
						{t('No')}
					</Button>
				</div>
				<div className='p-1'>
					<Button
						className='bg-black text-white hover:bg-gray-700'
						onClick={() => {
							onClose();
							onConfirm();
						}}
					>
						{t('Yes')}
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default DialogBox;
