import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect } from 'react';
import { useAnalytics } from './hooks/useAnalytics';
import { Helmet } from 'react-helmet-async';
import Routes from './Routes';

const App: React.FC = () => {
	const { init, trackPageViewed } = useAnalytics();
	useEffect(() => {
		init('UA-141610617-1');
		trackPageViewed();
	}, [init, trackPageViewed]);

	useEffect(() => {
		console.log('passing from app 🔥 🔥 🔥 ');
	});

	return (
		<React.Suspense fallback=''>
			<Helmet>
				<meta property='og:url' content='https://faitu.ch' />
				<meta property='og:type' content='website' />
				<meta property='og:title' content='Faitu marketplace' />
				<meta
					property='og:description'
					content='Vendi e compra nuovo e usato online in modo semplice, rapido e sicuro. Ordina pasti dal tuo ristorante preferito, pronto per la consegna a domicilio o takeaway.'
				/>
				<meta
					property='og:image'
					content='https://api.faitu.ch/src/assets/images/faitu200x200.png'
				/>
			</Helmet>
			<Routes />
			{/*  
			<Switch>
				<Route
					exact
					path={[`${base}`, `${base}/home`]}
					render={(props) => <LayoutPage {...props} children={<HomePage />} />}
				/>

				<Route
					exact
					path={`${base}/activation/:token`}
					render={(props) => <LayoutPage {...props} children={<Activation />} />}
				/>
				<Route
					exact
					path={`${base}/published/:id`}
					render={(props) => <LayoutPage {...props} children={<PublishAd />} />}
				/>
				<Route
					exact
					path={`${base}/reject/:id`}
					render={(props) => <LayoutPage {...props} children={<RejectAdPublication />} />}
				/>

				<Route
					exact
					path={`${base}/classfied/:category/:subcategory?`}
					render={(props) => <LayoutPage {...props} children={<ClassFied />} />}
				/>
				<Route
					exact
					path={`${base}/user/classfied/:id`}
					render={(props) => <LayoutPage {...props} children={<UserClassFieds />} />}
				/>
				<Route
					exact
					path={`${base}/ads-detail/:id`}
					render={(props) => <LayoutPage {...props} children={<ClassFiedDetail />} />}
				/>

				<Route
					exact
					path={`${base}/ads/`}
					render={(props) => <LayoutPage {...props} children={<NewAd />} />}
				/>

				<Route
					exact
					path={`${base}/userprofile`}
					render={(props) => <LayoutPage {...props} children={<UserProfile />} />}
				/>
			

				<Route
					exact
					path={`${base}/shopmanager`}
					render={(props) => <LayoutPage {...props} children={<ShopManager />} />}
				/>

				<Route
					exact
					path={`${base}/check-out-result/:id`}
					render={(props) => <LayoutPage {...props} children={<CheckOutResult />} />}
				/>
				<Route
					exact
					path={`${base}/shop-registration`}
					render={(props) => <LayoutPage {...props} children={<ShopRegistration />} />}
				/>
				<Route path={`${base}/shop`} component={RouteShop} />

				<Route
					exact
					path={`${base}/driver-info`}
					render={(props) => <LayoutPage {...props} children={<DriverInfoPage />} />}
				/>

				<Route
					exact
					path={`${base}/driver-manager`}
					render={(props) => <LayoutPage {...props} children={<DeliveryOrdersManager />} />}
				/>

				<Route
					exact
					path={`${base}/terms`}
					render={(props) => <LayoutPage {...props} children={<Terms />} />}
				/>

				<Route
					exact
					path={`${base}/advertising`}
					render={(props) => <LayoutPage {...props} children={<Advertising />} />}
				/>

				<Route
					exact
					path={`${base}/faqs`}
					render={(props) => <LayoutPage {...props} children={<Faqs />} />}
				/>

				<Route exact path='/error' component={Error401} />
				<Route path='*'>
					<Error401
						status={401}
						title='Pagina non trovata'
						message='Opps! qualche cosa non ha funzionato'
					/>
				</Route>
			</Switch>
		*/}
		</React.Suspense>
	);
};

export default App;
