import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
// redux stuf
import { useCategories } from '../../../../hooks/adsHooks';
import { ErrorMessage } from '../../../../Components/messages';

import { adTypes } from '../../../../api/localData';
import Stepper, { NextPage } from '../stepper';
import FormItem from '../formItem';
// style for react-select
import { customStyles } from '../../../../utils/utility';

/**
 * Reading data from Redux useCategories().menu and groupping with a subcategories element
 * adding adType option [sales,rent] and store on field formData with setForm()
 * note: Not retreive data when edit or came back
 */
const CategoryStep = ({ setForm, formData, navigation }: any) => {
	const { t } = useTranslation();
	const { next } = navigation;
	/** Shortcut passing default field from formData. On `category` stored the subCategory,
	 * because component contain all groupping data between children. On Category value
	 * passing the parent of values options */
	const { category = '', adType = '', enableNext, subCategory } = formData;
	/** State to passing complete object value {label:'' , value:'', parent} */
	const [categoryItem, setCategoryItem] = useState<any>('');
	/** State to passing complete object {label:'', value:''} */
	const [adTypeItem, setAdTypeItem] = useState<any>('');
	/** state to error, Check if the component or state item is empty */
	const [categoryError, setCategoryError] = useState<boolean>(false);

	const menu: any[] = useCategories().menu;

	/** Preparing the field key pair to storing on react-select. Group by categories */
	const categoryOptions = menu.map((item: any) => {
		let rt;
		const cat = item.children.map((sibling: any) => {
			return { label: t(sibling.name), value: sibling._id, parent: sibling.parent };
		});
		if (cat.length === 0) {
			rt = { label: t(item.name), value: item._id };
		} else {
			rt = { label: t(item.name), options: cat };
		}
		return rt;
	});

	useEffect(() => {
		if (subCategory) {
			/** Find on nested object (options) array a subcategory and return it */
			const item = categoryOptions
				.find((cat: any) => cat.options.find((itm: any) => itm.value === subCategory))
				?.options?.find((el: any) => el.value === subCategory);

			if (item) {
				setCategoryItem(item);
			}
		}
		if (adType) {
			/** Find only a object root with adType value t for translatine need it */
			const item = adTypes(t).find((typ: any) => typ.value === adType);
			setAdTypeItem(item);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/** Enable a next step button. Check if all condition is verified and set to
	 * true a private field */
	useEffect(() => {
		if (!formData.enableNext) {
			if (formData.category !== '' && formData.adType !== '') {
				setForm({ target: { name: 'enableNext', value: true } });
			}
		}
	}, [formData.adType, formData.category, formData.enableNext, setForm]);

	/** Internal check error if a component or field is empty */
	const enableNextStep = () => {
		setCategoryError(false);

		if (category !== '' || adType !== '') {
			setForm({ target: { name: 'enableNext', value: false } });
		}

		if (category === '' || categoryItem === '') {
			setCategoryError(true);
		}
	};
	return (
		<React.Fragment>
			<div className=''>
				<Stepper step={0} />
				<div className='md:mt-10 md:py-5'>
					<h1 className='text-center text-xl tracking-tight font-semibold text-gray-700 '>
						{t('Select the most appropriate category for your ad')}
					</h1>
				</div>
				{/* <span>{JSON.stringify(formData, null, 2)}</span> */}
				<div className='mt-5'>
					<FormItem
						required={true}
						label={t('Category')}
						type='select-dropdown'
						name='category'
						styles={customStyles}
						placeholder={t('Select')}
						options={categoryOptions}
						onChange={(e: any) => {
							setForm({ target: { name: 'category', value: e.parent } });
							setForm({ target: { name: 'subCategory', value: e.value } });
							setCategoryItem(e);
							/** Set `adType` field when RealEstate classfied selected */
							if (e.parent === 'propertyrentals') {
								setForm({ target: { name: 'adType', value: adTypes(t)[1].value } });
							} else if (e.parent === 'propertysales') {
								setForm({ target: { name: 'adType', value: adTypes(t)[0].value } });
							}
						}}
						value={categoryItem || undefined}
						onBlur={enableNextStep}
					/>
					{categoryError && <ErrorMessage message={t('Select a category')} />}
				</div>
				<div
					className={`mt-2 
          //  hide component adType When is a property category selected
          ${
						categoryItem.parent === 'propertyrentals' || categoryItem.parent === 'propertysales'
							? 'hidden'
							: 'block'
					}`}
				>
					<FormItem
						required={true}
						label={t('Ad type')}
						type='select-dropdown'
						name='adType'
						styles={customStyles}
						placeholder={t('Select')}
						options={adTypes(t)}
						onChange={(e: any) => {
							setForm({ target: { name: 'adType', value: e.value } });
							setAdTypeItem(e);
						}}
						value={adTypeItem || undefined}
					/>
				</div>
				{/*
        <div className='mt-10'>
          <GalleryZone onChange={(e: any) => setForm({ target: { name: 'gallery', value: e } })} />
        </div> */}

				{enableNext && (
					<div className='mt-2'>
						<NextPage next={next} title={t('Next')} />
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default CategoryStep;
