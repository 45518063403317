interface IFuSmallCard {
  title: string;
  titleOverlay: boolean;
  description?: string;
  currency?: string;
  price?: number;
  unitPrice?: string;
  badge: string;
  badgeBgColor: string;
  image?: string;
  url?: string;
}
export const FuSmallCard: React.FC<IFuSmallCard> = (props: IFuSmallCard) => {
  const {
    title,
    titleOverlay,
    description,
    currency,
    price,
    unitPrice,
    badge,
    badgeBgColor,
    image,
    url,
  } = props;
  return (
    <div className='mr-1 min-h-'>
      <a
        href={url}
        className='block bg-white border border-gray-200 overflow-hidden'
      >
        <div className='relative pb-32 overflow-hidden'>
          {image && (
            <img
              className=' border-none absolute inset-0 h-full w-full object-cover transition duration-500 ease-in-out transform hover:scale-110'
              src={image}
              alt=''
            />
          )}

          {badge && (
            <div className={`absolute  ${badgeBgColor} bg-opacity-60 top-1 rounded-r px-1`}>
              <h2 className='text-white text-xs leading-relaxed uppercase '>{badge}</h2>
            </div>
          )}
          {titleOverlay && title !== '' && (
            <div className='w-full absolute  bg-gray-900 bg-opacity-60 bottom-0'>
              <h2 className='text-white text-sm font-semibold antialiased p-2 '>{title}</h2>
            </div>
          )}
        </div>
        <div className='p-1 mx-1'>
          {!titleOverlay && <h2 className='font-bold text-sm mb-2 line-clamp-2'>{title}</h2>}

          {description && <p className=' text-black text-sm line-clamp-2'>{description}</p>}

          {/* <div className='mt-3 mx-1 flex items-center text-black'>
            <span className='text-sm font-semibold'>affitto</span>&nbsp;
            <span className='font-bold text-xl'>45,00</span>&nbsp;
            <span className='text-sm font-semibold'>CHF</span>
          </div> */}
          {price && (
            <div className='mt-3  flex items-center text-black'>
              <span className='text-gray-700 text-sm'>{currency}</span>&nbsp;
              <span className='text-gray-700 text-sm'>{price}</span>&nbsp;
              {unitPrice && <span className='text-gray-700 text-sm'>{unitPrice}</span>}
            </div>
          )}
        </div>
      </a>
    </div>
  );
};
