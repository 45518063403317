export const carBrands = [
  { label: 'ABARTH', value: 'ABARTH' },
  { label: 'ACURA', value: 'ACURA' },
  { label: 'ALFA ROMEO', value: 'ALFA ROMEO' },
  { label: 'AUDI', value: 'AUDI' },
  { label: 'BMW', value: 'BMW' },
  { label: 'BENTLEY', value: 'BENTLEY' },
  { label: 'BUICK', value: 'BUICK' },
  { label: 'CADILLAC', value: 'CADILLAC' },
  { label: 'CITROEN', value: 'CITROEN' },
  { label: 'CHEVROLET', value: 'CHEVROLET' },
  { label: 'CHRYSLER', value: 'CHRYSLER' },
  { label: 'DACIA', value: 'DACIA' },
  { label: 'DODGE', value: 'DODGE' },
  { label: 'FERRARI', value: 'FERRARI' },
  { label: 'FIAT', value: 'FIAT' },
  { label: 'FORD', value: 'FORD' },
  { label: 'GMC', value: 'GMC' },
  { label: 'GENESIS', value: 'GENESIS' },
  { label: 'HONDA', value: 'HONDA' },
  { label: 'HYUNDAI', value: 'HYUNDAI' },
  { label: 'INFINITI', value: 'INFINITI' },
  { label: 'JAGUAR', value: 'JAGUAR' },
  { label: 'JEEP', value: 'JEEP' },
  { label: 'KIA', value: 'KIA' },
  { label: 'LAND ROVER', value: 'LAND ROVER' },
  { label: 'LEXUS', value: 'LEXUS' },
  { label: 'LINKOLN', value: 'LINKOLN' },
  { label: 'LOTUS', value: 'LOTUS' },
  { label: 'MASERATI', value: 'MASERATI' },
  { label: 'MAZDA', value: 'MAZDA' },
  { label: 'MERCEDES-BENZ', value: 'MERCEDES-BENZ' },
  { label: 'MERCURY', value: 'MERCURY' },
  { label: 'MINI', value: 'MINI' },
  { label: 'MITSUBISHI', value: 'MITSUBISHI' },
  { label: 'NIKOLA', value: 'NIKOLA' },
  { label: 'NISSAN', value: 'NISSAN' },
  { label: 'OPEL', value: 'OPEL' },
  { label: 'PEUGEOT', value: 'PEUGEOT' },
  { label: 'POLESTAR', value: 'POLESTAR' },
  { label: 'PONTIAC', value: 'PONTIAC' },
  { label: 'PORSCHE', value: 'PORSCHE' },
  { label: 'RAM', value: 'RAM' },
  { label: 'RIVIAM', value: 'RIVIAM' },
  { label: 'RENAULT', value: 'RENAULT' },
  { label: 'ROLLS-ROYCE', value: 'ROLLS-ROYCE' },
  { label: 'SAAB', value: 'SAAB' },
  { label: 'SATURN', value: 'SATURN' },
  { label: 'SEAT', value: 'SEAT' },
  { label: 'SCION', value: 'SCION' },
  { label: 'SKODA', value: 'SKODA' },
  { label: 'SMART', value: 'SMART' },
  { label: 'SUBARU', value: 'SUBARU' },
  { label: 'SUZUKI', value: 'SUZUKI' },
  { label: 'TESLA', value: 'TESLA' },
  { label: 'TOYOTA', value: 'TOYOTA' },
  { label: 'VOLKSWAGEN', value: 'VOLKSWAGE' },
  { label: 'VOLVO', value: 'VOLVO' },
];

// export const motorCycleBrands = [
//   { label: 'A&M', value: 'A&M' },
//   { label: 'ACCESS', value: 'ACCESS' },
//   { label: 'ADIVA', value: 'ADIVA' },
//   { label: 'ADLER', value: 'ADLER' },
//   { label: 'ADLY', value: 'ADLY' },
//   { label: 'AEON', value: 'AEON' },
//   { label: 'AERMACCHI', value: 'AERMACCHI' },
//   { label: 'AIE', value: 'AIE' },
//   { label: 'AJS', value: 'AJS' },
//   { label: 'ALPINO', value: 'ALPINO' },
//   { label: 'AME', value: 'AME' },
//   { label: 'AMERICAN IRONHORSE', value: 'AMERICAN IRONHORSE' },
//   { label: 'AMI', value: 'AMI' },
//   { label: 'ANSSEMS', value: 'ANSSEMS' },
//   { label: 'APRILIA', value: 'APRILIA' },
//   { label: 'ARCH', value: 'ARCH' },
//   { label: 'ARCTIC CAT', value: 'ARCTIC CAT' },
//   { label: 'ARDIE', value: 'ARDIE' },
//   { label: 'ARIEL', value: 'ARIEL' },
//   { label: 'ARIIC', value: 'ARIIC' },
//   { label: 'ARMEC', value: 'ARMEC' },
//   { label: 'ASA', value: 'ASA' },
//   { label: 'ASKOLL', value: 'ASKOLL' },
//   { label: 'ASPES', value: 'ASPES' },
//   { label: 'BAOTIAN', value: 'BAOTIAN' },
//   { label: 'BAROSSA', value: 'BAROSSA' },
//   { label: 'BASHAN', value: 'BASHAN' },
//   { label: 'BEELINE', value: 'BEELINE' },
//   { label: 'BELA B.', value: 'BELA B.' },
//   { label: 'BENELLI', value: 'BENELLI' },
//   { label: 'BERINGER', value: 'BERINGER' },
//   { label: 'BETA', value: 'BETA' },
// ];

export const adTypes = (t: any) => [
  { label: t('Sale'), value: 'sale' },
  { label: t('Rent'), value: 'rent' },
  { label: t('Offers'), value: 'offers'}
];
export const adNewOrUsed = (t: any) => [
  { label: t('Used'), value: 'Used' },
  { label: t('New'), value: 'New' },
];

export const Fuels = (t: any) => [
  { label: t('Diesel'), value: 'Diesel' },
  { label: t('Petrol'), value: 'Petrol' },
];

export const carDoors = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
];

export const Transmission = (t: any) => [
  { label: t('Manual'), value: 'Manual' },
  { label: t('Automatic'), value: 'Automatic' },
];

export const UnitOptionTypes = (t: any) => [
  { label: t('Day'), value: 'day' },
  { label: t('Month'), value: 'month' },
  { label: t('Year'), value: 'year' },
];

export const CurrenciesOptions = [
  { label: '€', value: '€' },
  { label: '$', value: '$' },
  { label: '£', value: '£' },
  { label: 'CHF', value: 'CHF' },
];

export const dayOfWeeks = (t: any) => [
  { label: t('Sunday'), value: 0 },
  { label: t('Monday'), value: 1 },
  { label: t('Tuesday'), value: 2 },
  { label: t('Wednesday'), value: 3 },
  { label: t('Thursday'), value: 4 },
  { label: t('Friday'), value: 5 },
  { label: t('Saturday'), value: 6 },
];
