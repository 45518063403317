import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

const fuxios = axios.create({});

/** handle all request before sending to server endpoint */
const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  /** grab all localstorage cookie redux */
  const serializedState: any = localStorage.getItem('state');
  /** convert to JSON-Object  */
  const unserilized = JSON.parse(serializedState);
  /** grab the token  */
  const token = unserilized.auth.token;

  /** new Instance header base settings and append the current language */
  let headers = {
    ...config.headers,
    'Content-Language': unserilized.app.lang,
    // this for sync to nodejs webserver current language use on client
    'Accept-Language': unserilized.app.lang,
  };
  /** Check if token exist for append token information before sending request */
  if (token !== '') {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }
  
  /** apply the change and passing to the config axios */
  config.headers = headers;

  // console.info(`[request] [${JSON.stringify(config.headers)}]`);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[response error] [${JSON.stringify(error)}]`);
  console.log(error.response);
  const errorHandle = {
    code: error.response?.data.error.statusCode,
    message: error.response?.data.message,
  };

  return Promise.reject(errorHandle);
};

fuxios.interceptors.request.use(onRequest, onRequestError);
fuxios.interceptors.response.use(onResponse, onResponseError);

export default fuxios;
