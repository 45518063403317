import AnchorLink from 'react-anchor-link-smooth-scroll';
import { IoMdTime } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

export const ShopHeader = (props: any) => {
	const { t } = useTranslation();
	const { name, logo, coverThumb, location, isClosed } = props;
	return (
		<div className='w-full'>
			<div className='bg-white  overflow-hidden'>
				<div
					className='bg-cover bg-center h-64 flex overflow-hidden justify-start items-end bg-black py-0  opacity-65 shadow-lg '
					style={{
						backgroundImage: `url(${coverThumb})`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}
				>
					<img className='h-14 sm:h-24 object-scale-down bg-white' src={logo} alt={name} />

					<div className='flex flex-col px-2 bg-black opacity-65 w-full '>
						<div className='flex items-center justify-center'>
							<h1 className='text-xl sm:text-3xl font-semibold text-white  w-full flex-wrap '>
								{name}
							</h1>
							{isClosed && (
								<div className='hidden sm:flex mt-1'>
									<div className='text-white font-semibold px-5 inline-flex justify-end items-center'>
										<span>
											<IoMdTime size={30} />
										</span>
										<span className='m-1 font-semibold'>{t('Closed')}</span>
									</div>
								</div>
							)}
						</div>

						<p className='text-normal sm:text-lg font-medium text-white'>
							{location.address.street} , {location.address.zipCode} {location.address.city}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export const TopMenuNavBar = (props: any) => {
	const { menu } = props;
	return (
		<div className='sticky top-16 z-50  bg-white w-full h-10 flex  justify-items-center items-center px-4  shadow-md'>
			<nav className='pb-3 pt-3 w-full overflow-x-scroll'>
				<ul className='flex space-x-3  text-lg antialiased font-sans'>
					{menu.categories.map((cat: any, index: number) => (
						<li key={index} className='whitespace-nowrap  px-2 hover:bg-gray-100 hover:rounded-lg'>
							<AnchorLink offset='100' href={`#${cat.name}`}>
								{cat.name}
							</AnchorLink>
						</li>
					))}
				</ul>
			</nav>
		</div>
	);
};
