/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useAppState } from '../../hooks/adsHooks';

import { CgChevronUpO } from 'react-icons/cg';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import tw from 'twin.macro';

const SectionHeading = tw.h2`text-4xl sm:text-5xl text-secondary-500  font-black tracking-wide text-center `;
const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-500 max-w-xl`;

const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto px-1 py-20 lg:py-24`;

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`mx-auto text-center`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0 px-2 sm:px-0`;
const Faq = tw.div`cursor-pointer select-none border mt-5 px-4 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900  hover:bg-gray-100 transition duration-300`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg  font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
	${tw`ml-2 transition duration-300`}
	svg {
		${tw`w-6 h-6`}
	}
`;
const Answer = tw(motion.div)`hidden font-normal mt-4 text-gray-800`;

/** test condition */

const Faqs = () => {
	const { t } = useTranslation();
	// const { lang } = useAppState().app;

	const heading = `${t('You have Questions ?')}`;
	const description = `${t('Select the answers from the most frequently asked questions or')}`;

	const faqs = [
		{
			question: `${t("I don't see my newly created ad")}`,
			answer: `${t(
				"If your ad is not visible yet, it is because it has not been published. It means it's still under control. It normally takes 24 to 48 hours to post."
			)}`,
		},
		{
			question: `${t('How do i publish an ad ?')}`,
			answer: `${t('Click on')} <strong>+${t('Ad')}</strong>, ${t(
				'select the category and type of ad. Fill in all the required form fields, select the options and send the request.'
			)}`,
		},
		{
			question: `${t('Where can I find my ads ?')}`,
			answer: `${t('Once logged in, click on your profile icon, and select')} <strong>${t(
				'your ads'
			)}</strong> ${t('from menu')}. `,
		},
		{
			question: `${t('How can I change my ad ?')}`,
			answer: `${t(
				'Once the announcement is published, it can no longer be changed. If you make a mistake, you can delete it and create a new advertisement.'
			)}`,
		},
		{
			question: `${t('How can I delete my ad')}`,
			answer: `${t('Once logged in, click on your profile icon, and select')} <strong>${t(
				'your ads'
			)}</strong> ${t('from menu')}. ${t('Finally click on')} <strong>${t('delete')}</strong> `,
		},
		{
			question: `${t('Is opening a shop free ?')}`,
			answer: `${t(
				'Yes. You can open your own online store for free. However, it will not be published until you have selected a subscription plan.'
			)}`,
		},
		{
			question: `${t('Why do I have to enter my phone number to order?')}`,
			answer: `${t(
				'Faitu is a platform that connects users. We need to ensure the stores that contact is verified. Your telephone number will be sent to the merchant and possibly to the delivery man who brings you the purchased goods.'
			)}`,
		},
		{
			question: `${t('Why is my shop not yet public ?')}`,
			answer: `${t(
				'Check that you meet all the conditions for publication. Check that you have entered the business hours, that you have created the articles correctly. You can contact us to check if there are any problems.'
			)}`,
		},
	];

	const faqCol1: any = [];
	const faqCol2: any = [];
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

	const toggleQuestion = (questionIndex: any) => {
		if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
		else setActiveQuestionIndex(questionIndex);
	};

	faqs.map((faq, index) => {
		const renderedFaq = (
			<Faq key={index} onClick={() => toggleQuestion(index)}>
				<Question>
					<QuestionText>{faq.question}</QuestionText>
					<QuestionToggleIcon
						variants={{
							collapsed: { rotate: 0 },
							open: { rotate: -180 },
						}}
						initial='collapsed'
						animate={activeQuestionIndex === index ? 'open' : 'collapsed'}
						transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
					>
						{/* <ChevronDownIcon /> */}
						<CgChevronUpO color='gray' className='text-gray-500 bg-gray700' />
					</QuestionToggleIcon>
				</Question>
				<Answer
					variants={{
						open: { opacity: 1, height: 'auto', marginTop: '16px', display: 'block' },
						collapsed: { opacity: 0, height: 0, marginTop: '0px', display: 'none' },
					}}
					initial='collapsed'
					animate={activeQuestionIndex === index ? 'open' : 'collapsed'}
					transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
				>
					<span dangerouslySetInnerHTML={{ __html: `${faq.answer}` }}></span>
				</Answer>
			</Faq>
		);

		if (index % 2 === 0) faqCol1.push(renderedFaq);
		else faqCol2.push(renderedFaq);

		return null;
	});

	return (
		<ContentWithPaddingXl>
			<HeadingContainer>
				<Heading>{heading}</Heading>
				<Description>{description}</Description>
				<Description className='hover:text-black'>
					<a href='mailto:admin@faitu.ch'>{t('Contact Us')} admin@faitu.ch</a>
				</Description>
			</HeadingContainer>
			<FaqsContainer>
				<FaqsColumn>{faqCol1}</FaqsColumn>
				<FaqsColumn>{faqCol2}</FaqsColumn>
			</FaqsContainer>
		</ContentWithPaddingXl>
	);
};

export default Faqs;
