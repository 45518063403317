export enum ArticleActionTypes {
  ADD_ARTICLE = 'ADD_ARTICLE',
  DELETE_ARTICLE = 'DELETE_ARTICLE',
  UPDATE_ARTICLE = 'UPDATE_ARTICLE',
}
export enum CartTypes {
  ADD_TO_CART = 'ADD_TO_CART',
  REMOVE_ARTICLE_FROM_CART = 'REMOVE_ARTICLE_FROM_CART',
  CLEAR_CART = 'CLEAR_CART',
  EMPTY_CART = 'EMPTY_CART',
  ADD_DELIVERY_COST = 'ADD_DELIVERY_COST',
  ADD_SHOP = 'ADD_SHOP',
  ADD_PICKUP = 'ADD_PICKUP',
  ADD_COMMENT = 'ADD_COMMENT',
}

export enum AuthTypes {
  AUTHENTICATE = 'AUTHENTICATE',
  LOGIN_FAIL = 'LOGIN_FAIL',
  UPDATE_USER = 'UPDATE_USER',
  LOG_OUT = 'LOG_OUT',
  CLEAR_MESSAGE = 'CLEAR_MESSAGE',
}

export enum AppTypes {
  SET_LANG = 'SET_LANG',
  SET_COORDS = 'SET_COORDS',
}

export enum FilterTypes {
  SET_PRICE_RANGE = 'SET_PRICE_RANGE',
  SET_DISTANCE = 'SET_DISTANCE',
  SET_UNIT = 'SET_UNIT',
  EXECUTE_QUERY = 'EXECUTE_QUERY',
  SET_SIDE_BAR = 'SET_SIDE_BAR',
  SET_LOADING = 'SET_LOADING',
  SET_LOADING_DATA = 'SET_LOADING_DATA',
  SET_PAGE = 'SET_PAGE',
  SET_SECTION = 'SET_SECTION',
  SET_RESET = 'SET_RESET',
  SET_TOTAL = 'SET_TOTAL',
  SET_TITLE_QUERY = 'SET_TITLE_QUERY'
}

export enum CategoryTypes {
  SET_LOADING = 'SET_CATEGORY_LOADING',
  SET_CATEGORIES = 'SET_CATEGORIES',
}
