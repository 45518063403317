import React from 'react';

type FuAlertProps = {
  message: string;
  title?: string;
  alertType: 'warning' | 'info';
};

const FuAlert: React.FC<FuAlertProps> = ({ message, alertType, title }) => {
  let css: string = '';
  if (alertType === 'info') {
    css = 'text-gray-500 ';
  } else {
    css = 'bg-red-100 border-t border-b border-red-500 text-red-700';
  }
  return (
    <div className={` text-sm  ${css}`}>
      <p className=''>
        <i
          className={`${
            alertType === 'warning' ? 'fas fa-exclamation-triangle' : 'fas fa-exclamation-circle'
          }`}
        ></i>{' '}
        {title && (
          <>
            <span className='font-bold text-base'>{title}</span> <br />{' '}
          </>
        )}
        <span className='text-sm' dangerouslySetInnerHTML={{ __html: message }}></span>
      </p>
    </div>
  );
};

export default FuAlert;
