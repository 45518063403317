
export const IsoDateToUtcDate = (offset: number, dt: Date): number => {
  let rs = 0;
  if (offset < 0) {
    rs = dt.getUTCHours() + Math.abs(dt.getTimezoneOffset()) / 60;
  } else {
    rs = dt.setUTCHours(dt.getUTCHours() - Math.abs(dt.getTimezoneOffset()) / 60);
  }

  return rs;
};

export const convertUTCDateToLocalDate = (date: Date): Date => {
  let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  let offset = date.getTimezoneOffset() / 60;
  let hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};

