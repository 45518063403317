import React, { useState } from 'react';

import { useAppState } from '../../hooks/adsHooks';
import tw from 'twin.macro';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import { RiCloseFill } from 'react-icons/ri';
const Container = tw.div`relative mx-4`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Text = styled.div`
	${tw`text-lg text-gray-800`}
	p {
		${tw`mt-2 leading-relaxed`}
	}
	h1 {
		${tw`text-3xl font-bold mt-10`}
	}
	h2 {
		${tw`text-2xl font-bold mt-8`}
	}
	h3 {
		${tw`text-xl font-bold mt-6`}
	}
	ul {
		${tw`list-disc list-inside`}
		li {
			${tw`ml-2 mb-3`}
			p {
				${tw`mt-0 inline leading-normal`}
			}
		}
	}
`;

type SideBarProps = {
	sideBar: boolean;
	setSideBar: (value: boolean) => void;
};

const Sidebar: React.FC<SideBarProps> = ({ sideBar, setSideBar }) => {
	return (
		<AnimatePresence>
			{sideBar && (
				<React.Fragment>
					<motion.div
						initial={{ x: '100%' }}
						animate={{ x: 0 }}
						exit={{ x: '100%' }}
						transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
						className='fixed bg-indigo-600 text-white shadow-lg top-0 right-0 w-full max-w-sm h-screen p-5 '
					>
						<div className='w-full mt-10 h-10 pt-2 '>
							<button
								onClick={() => setSideBar(!sideBar)}
								className=' float-right h-8 w-8 block mb-2 rounded-full  right-0 '
							>
								<RiCloseFill color='white' size={32} />
							</button>
						</div>
						<h2 className='text-4xl capitalize leading-loose'>hello!</h2>
						<p className='leading-relaxed'>
							Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
							has been the industry's standard dummy text ever since the 1500s.
						</p>
					</motion.div>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{
							opacity: 1,
						}}
						exit={{
							opacity: 0,
						}}
						transition={{ type: 'spring', bounce: 0, duration: 0.2 }}
						onClick={() => setSideBar(!sideBar)}
						className='bg-transparent px-5 fixed h-full w-full flex items-center justify-center top-0 left-0'
					/>
				</React.Fragment>
			)}
		</AnimatePresence>
	);
};

// const TermsIT = () => {
const TermsIT: React.FC<SideBarProps> = ({ sideBar, setSideBar }) => {
	return (
		<motion.div
			animate={{ scale: sideBar ? 0.8 : 1, opacity: sideBar ? 0.5 : 1 }}
			transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
		>
			<Container>
				<ContentWithPaddingXl>
					<HeadingRow>
						<Heading>Termini e Condizioni</Heading>
					</HeadingRow>
					<Text>
						<p>Ultimo aggiornamento: 05 Luglio 2021 </p>
						{/* <p>
							<button
								className='bg-indigo-600 my-3 text-white uppercase text-sm px-4 h-10 rounded'
								onClick={() => setSideBar(!sideBar)}
							>
								{sideBar ? 'Close menu' : 'Show menu'}
							</button>
						</p> */}
						<p>
							Si prega di leggere attentamente questi termini e condizioni prima di utilizzare il
							nostro servizio.
						</p>
						<h1>Contenuto del servizio</h1>
						<h2>Servizio</h2>
						<p>
							faitu.ch piattaforma di servizi informatici e telematici, mette a disposizione degli
							utenti che intendano avvalersene un servizio (da ora in poi il "Servizio Base" e/o
							"Servizio") web based che consente di pubblicare e consultare annunci e inserzioni di
							soggetti privati o professionali che intendano alienare o acquistare beni o prestare e
							ricevere servizi. Il Servizio consente altresì agli utenti inserzionisti ed agli
							utenti interessati a quanto pubblicato di entrare in contatto tra di loro.
						</p>
						<h1>Applicabilità del servizio</h1>
						<p>
							Le presenti Condizioni Generali del Servizio si applicano sia agli utenti che
							utilizzino il Servizio in consultazione degli annunci pubblicati sia agli utenti
							inserzionisti privati o professionali (d‘ora in poi collettivamente “utente/i”).
						</p>
						<h1>Termini per l'uso del servizio </h1>
						<p>
							faitu.ch è in continua mutazione, sia nell'aspetto che nelle funzionalità, per questo
							motivo ci riserviamo il diritto di modificare o eliminare le funzioni del Sito in
							qualsiasi momento e senza preannunciarlo. Cercheremo comunque di informare gli utenti
							di tutte le modifiche o eliminazioni mettendo una comunicazione in merito sul Sito
							stesso.
						</p>
						<p>
							Il Sito e tutti i suoi contenuti vengono messi a disposizione senza garanzia di
							assenza di difetti. Non assicuriamo né garantiamo che le informazioni e i contenuti
							del Sito o sul Sito (nostri o degli utenti) sono completi o privi di errori. Gli
							utenti riconoscono che lo scaricamento o il rilevamento di informazioni e contenuti
							dal Sito avvengono a propria discrezione e a proprio rischio.
						</p>
						<p>
							Facciamo del nostro meglio per evitare un uso illecito della nostra piattaforma da
							parte di alcuni utenti, ma non possiamo escludere a priori che ciò avvenga. Ogni
							nostra responsabilità legata a utilizzi di questo genere viene pertanto esclusa sin
							d'ora.
						</p>
						<h1>Responsabilità dell'utente</h1>
						<p>
							L'utente è totalmente ed esclusivamente responsabile dell'uso del Servizio (da
							intendersi espressamente con riguardo alle funzioni di pubblicazione, di
							consultazione, di gestione delle inserzioni e di contatto tra utenti) ed è pertanto
							l'unico garante e responsabile dei beni e dei servizi offerti per il tramite del
							Servizio nonchè della correttezza, completezza e liceità delle inserzioni e del
							proprio comportamento nell'ambito del contatto tra utenti. L'utente garantisce la
							disponibilità e/o la titolarità del bene/servizio oggetto delle inserzioni medesime.
							L'utente garantisce altresì che i propri annunci non violano alcun diritto d'autore né
							diritto di proprietà industriale né altro diritto di terzi. In caso di contestazione
							da parte di terzi riguardo a qualsiasi annuncio o condotta ad esso legata, l'utente se
							ne assume la piena responsabilità e si impegna a tenere manlevata e indenne faitu.ch
							da qualsiasi danno, perdita o spesa. L'utente si impegna ad utilizzare il form di
							risposta all’annuncio al solo scopo di prendere contatto e scambiare informazioni con
							gli altri utenti relativamente agli annunci, utilizzando un linguaggio consono, nel
							rispetto della legge, dell’etica e della netiquette. L'utente, inoltre, si assume ogni
							responsabilità per eventuali danni che possano derivare al suo sistema informatico
							dall'uso del Servizio o a quello di terzi. Resta infine inteso che ogni eventuale
							utilizzo di robot, spider, scraper e/o ulteriori strumenti automatici per accedere al
							sito e/o per estrapolare i relativi dati, contenuti, informazioni è espressamente
							vietato.
						</p>
						<p>
							faitu.ch non presta alcuna garanzia circa il contenuto, la completezza e la
							correttezza delle inserzioni pubblicate nè con riguardo ai dati pubblicati, né
							relativamente alle informazioni successivamente fornite dall’utente, nè con
							riferimento al numero o alla qualità dei risultati ottenuti tramite il Servizio. In
							ogni caso faitu.ch. si riserva, in qualsiasi momento, il diritto di valutare,
							approvare, eliminare o impedire l'inserzione ovvero il diritto di inibire la
							consultazione o il contatto per il tramite del form di risposta all’annuncio nel caso
							in cui, a proprio insindacabile giudizio, l'uso del Servizio da parte dell'utente si
							riferisca a particolari sezioni merceologiche o possa considerarsi lesivo di diritti o
							delle prerogative di faitu.ch. o di terzi. Resta inteso che anche in caso di
							valutazione ed approvazione delle inserzioni (siano esse automatiche o manuali)
							faitu.ch. non presta alcuna garanzia circa il contenuto, la completezza e la
							correttezza delle stesse. faitu.ch è altresì estranea alle trattative eventualmente
							nascenti dall'uso del Servizio e pertanto non garantisce nè la bontà nè l'esito delle
							stesse, di conseguenza nessuna richiesta di restituzione, compensazione, riparazione
							e/o risarcimento a qualunque titolo potrà essere indirizzata nei confronti di
							faitu.ch.
						</p>
						<h1>Limitazione di responsabilità</h1>
						<p>
							faitu.ch si riserva il diritto di modificare, sospendere o interrompere, in tutto o in
							parte, il Servizio in qualsiasi momento anche senza preavviso e senza che la stessa
							sia tenuta ad indicare le ragioni sottese alle predette azioni. Faitu.ch. non potrà
							quindi in nessun caso essere ritenuta parte o responsabile dello scambio di
							comunicazioni tra gli utenti e delle trattative da esse nascenti. Faitu.ch non potrà
							parimenti essere ritenuta responsabile dei danni conseguenti alla mancata prestazione
							del Servizio oggetto del presente contratto a causa dell'errato o mancato
							funzionamento dei mezzi elettronici di comunicazione per cause estranee alla sfera del
							proprio prevedibile controllo.
						</p>
						<p>
							A titolo esemplificativo, ma non esaustivo, il malfunzionamento dei server ed altri
							dispositivi elettronici anche non facenti parte integrante della rete Internet,
							malfunzionamento dei software installati, virus informatici, nonchè da azioni di
							hacker o altri utenti aventi accesso alla rete.
						</p>
						<h1>Contenuti e comportamenti degli utenti</h1>
						<p>
							Faitu.ch non è obbligata, ma autorizzata a verificare o controllare le informazioni e
							i materiali, inseriti o trasmessi o messi a disposizione nel Sito da parte degli
							utenti.
						</p>
						<p>
							Inoltre non rispondiamo del comportamento in rete o fuori rete degli utenti e non ci
							assumiamo la responsabilità né morale né materiale per il loro comportamento o per le
							informazioni/materiali messi a disposizione dagli utenti.
						</p>
						<p>
							Ci riserviamo in ogni caso il diritto, a nostra esclusiva discrezione, di condurre
							indagini in relazione all'utilizzo del nostro Sito e di prendere provvedimenti
							adeguati contro tutte le persone che
						</p>
						<ul>
							<li>
								violano le disposizioni relative a comportamenti proibiti e contenuti inammissibili
								della piattaforma;
							</li>
							<li>violano effettivamente o presumibilmente le leggi vigenti;</li>
							<li>
								rtecipano a comportamenti scandalosi, illegali o tali da comportare violazioni dei
								diritti di altre persone o da compromettere o minacciare la loro sicurezza.
							</li>
						</ul>
						<p>
							In tali casi ci riserviamo espressamente di togliere tempestivamente e senza preavviso
							le informazioni/i materiali inseriti dagli utenti e di impedire all'utente interessato
							di utilizzare successivamente in qualsiasi modo il Sito.
						</p>
						<h1>Diritti e doveri degli utenti</h1>
						<h2>Dati completi e attuali</h2>
						<p>
							L'utente si impegna a fornire tutti i dati relativi alla sua persona aggiornati,
							corretti e completi. L'utente deve avere almeno 18 anni. Inoltre l'utente si impegna
							ad aggiornare tempestivamente tutti i dati determinanti per l'utilizzo del Sito.
						</p>
						<p>
							Noi permettiamo agli utenti di inserire informazioni/materiali nel Sito in forma di
							annunci ai quali possono accedere gli altri utenti del Sito.
						</p>
						<h1>Servizi a pagamento</h1>
						<p>
							faitu.ch offre sulla propria piattaforma servizi a pagamento, i prezzi e la durata del
							contratto sono riportati sulla piattaforma. Il pagamento dell'annuncio non implica la
							sua pubblicazione immediata. Se la pubblicazione non viene concessa per mancato
							rispetto delle norme di faitu.ch in parte o integralmente, l'annuncio non verrà
							pubblicato. L'importo pagato non verrà restituito.
						</p>
						<h1>Abbonamenti</h1>
						<p>
							faitu.ch offre un servizio di abbonamento rivolto ai negozi che desiderano pubblicare
							i loro prodotti e offerte sulla piattaforma. L'annullamento del contratto può essere
							effettuato in qualsiasi momento, il cambiamento del piano di abbonamento può essere
							effettuato in qualsiasi momento.
						</p>
						<h1>Licenza sui contenuti dell'utente</h1>
						<p>
							L'utente concede una licenza gratuita, illimitata, irrevocabile, a tempo
							indeterminato, semplice, trasferibile e valida in tutto il mondo (con il diritto di
							concedere sottolicenze) per qualsiasi uso (inclusa la riproduzione, la divulgazione e
							qualsiasi elaborazione, compresa la traduzione) sui contenuti dell'utente o su parte
							di essi per qualsiasi scopo commerciale (attualmente conosciuto o sconosciuto) o altri
							scopi (inclusa la pubblicità) in correlazione con il Sito.
						</p>
						<p>
							L'utente può togliere in qualsiasi momento i contenuti che ha inserito lui stesso nel
							Sito. Se l'utente toglie i suoi contenuti dal Sito, la licenza sopra concessa rimane
							in vigore. In particolare l'utente riconosce che noi possiamo conservare copie
							archiviate dei suoi contenuti e che la licenza precedentemente concessa rimane in
							vigore per i suoi contenuti archiviati.
						</p>
						<h1>Disdetta e modifiche</h1>
						<p>
							Il rapporto contrattuale tra faitu.ch e l'utente può essere disdetto in qualsiasi
							momento da ognuna delle parti, a libera discrezione, con effetto immediato. I Prezzi
							dei Servizi a pagamento non vengono restituiti in nessun caso all'utente.
						</p>
						<p>
							Ci riserviamo il diritto di interrompere temporaneamente, togliere o sostituire tutti
							i servizi del Sito o alcuni di essi. Inoltre faitu.ch si riserva sia il diritto di
							dichiarare che certi servizi gratuiti in futuro saranno a pagamento, sia il diritto di
							modificare in qualsiasi momento i Servizi a pagamento o i Prezzi dei Servizi a
							pagamento.
						</p>
						<h1>Disposizioni finali</h1>
						<p>
							faitu.ch si riserva il diritto di modificare occasionalmente le condizioni d'uso. È
							determinante la versione aggiornata in questa pagina. La nullità o l'inefficacia di
							una o più disposizioni non pregiudica la validità delle restanti disposizioni.
						</p>
						<p>
							Le presenti condizioni d'uso sono disciplinate dal diritto svizzero, con esclusione
							delle norme ddi diritto internazionale privato. Foro competente esclusivo per tutte le
							controversie derivanti dalle o correlate alle presenti condizioni d'uso è la sede di
							faitu.ch (Lugano).
						</p>
					</Text>
				</ContentWithPaddingXl>
			</Container>
		</motion.div>
	);
};

const TermsEN = () => {
	return (
		<div className='max-w-screen-2xl mx-auto'>
			<div className='flex-auto'>
				<div
					className='relative pt-16 pb-32 flex content-center items-center justify-center'
					style={{
						minHeight: '35vh',
					}}
				>
					<div
						className='absolute top-0 w-full h-full bg-center bg-cover'
						style={{
							backgroundImage:
								"url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')",
						}}
					>
						<span id='blackOverlay' className='w-full h-full absolute opacity-75 bg-black'></span>
					</div>
					<div className='container relative mx-auto'>
						<div className='items-center flex flex-wrap'>
							<div className='w-full lg:w-6/12 px-4 ml-auto mr-auto text-center'>
								<div className='pr-12'>
									<h1 className='text-white font-semibold text-5xl'>Terms and conditions</h1>
									{/* <p className='mt-4 text-lg text-gray-300'>
										This is a simple example of a Landing Page you can build using Tailwind Starter
										Kit. It features multiple CSS components based on the Tailwindcss design system.
									</p> */}
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className=''>
					<div className='container mx-auto '>
						<ol className='list-decimal text-lg list-inside my-5 pl-2'>
							<li className='font-bold my-5'>
								<span className='capitalize'>Contenuto del servizio</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									faitu.ch piattaforma di servizi informatici e telematici, mette a disposizione
									degli utenti che intendano avvalersene un servizio (da ora in poi il "Servizio
									Base" e/o "Servizio") web based che consente di pubblicare e consultare annunci e
									inserzioni di soggetti privati o professionali che intendano alienare o acquistare
									beni o prestare e ricevere servizi. Il Servizio consente altresì agli utenti
									inserzionisti ed agli utenti interessati a quanto pubblicato di entrare in
									contatto tra di loro.
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Applicabilità delle condizioni</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									Le presenti Condizioni Generali del Servizio si applicano sia agli utenti che
									utilizzino il Servizio in consultazione degli annunci pubblicati sia agli utenti
									inserzionisti privati o professionali (d‘ora in poi collettivamente “utente/i”).
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Termini per l'uso del servizio </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch è in continua mutazione, sia nell'aspetto che nelle funzionalità, per
										questo motivo ci riserviamo il diritto di modificare o eliminare le funzioni del
										Sito in qualsiasi momento e senza preannunciarlo. Cercheremo comunque di
										informare gli utenti di tutte le modifiche o eliminazioni mettendo una
										comunicazione in merito sul Sito stesso.
									</p>
									<p>
										Il Sito e tutti i suoi contenuti vengono messi a disposizione senza garanzia di
										assenza di difetti. Non assicuriamo né garantiamo che le informazioni e i
										contenuti del Sito o sul Sito (nostri o degli utenti) sono completi o privi di
										errori. Gli utenti riconoscono che lo scaricamento o il rilevamento di
										informazioni e contenuti dal Sito avvengono a propria discrezione e a proprio
										rischio.
									</p>
									<p>
										Facciamo del nostro meglio per evitare un uso illecito della nostra piattaforma
										da parte di alcuni utenti, ma non possiamo escludere a priori che ciò avvenga.
										Ogni nostra responsabilità legata a utilizzi di questo genere viene pertanto
										esclusa sin d'ora.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Responsabilità dell'utente </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										L'utente è totalmente ed esclusivamente responsabile dell'uso del Servizio (da
										intendersi espressamente con riguardo alle funzioni di pubblicazione, di
										consultazione, di gestione delle inserzioni e di contatto tra utenti) ed è
										pertanto l'unico garante e responsabile dei beni e dei servizi offerti per il
										tramite del Servizio nonchè della correttezza, completezza e liceità delle
										inserzioni e del proprio comportamento nell'ambito del contatto tra utenti.
										L'utente garantisce la disponibilità e/o la titolarità del bene/servizio oggetto
										delle inserzioni medesime. L'utente garantisce altresì che i propri annunci non
										violano alcun diritto d'autore né diritto di proprietà industriale né altro
										diritto di terzi. In caso di contestazione da parte di terzi riguardo a
										qualsiasi annuncio o condotta ad esso legata, l'utente se ne assume la piena
										responsabilità e si impegna a tenere manlevata e indenne faitu.ch da qualsiasi
										danno, perdita o spesa. L'utente si impegna ad utilizzare il form di risposta
										all’annuncio al solo scopo di prendere contatto e scambiare informazioni con gli
										altri utenti relativamente agli annunci, utilizzando un linguaggio consono, nel
										rispetto della legge, dell’etica e della netiquette. L'utente, inoltre, si
										assume ogni responsabilità per eventuali danni che possano derivare al suo
										sistema informatico dall'uso del Servizio o a quello di terzi. Resta infine
										inteso che ogni eventuale utilizzo di robot, spider, scraper e/o ulteriori
										strumenti automatici per accedere al sito e/o per estrapolare i relativi dati,
										contenuti, informazioni è espressamente vietato.
									</p>
									<p>
										faitu.ch non presta alcuna garanzia circa il contenuto, la completezza e la
										correttezza delle inserzioni pubblicate nè con riguardo ai dati pubblicati, né
										relativamente alle informazioni successivamente fornite dall’utente, nè con
										riferimento al numero o alla qualità dei risultati ottenuti tramite il Servizio.
										In ogni caso faitu.ch. si riserva, in qualsiasi momento, il diritto di valutare,
										approvare, eliminare o impedire l'inserzione ovvero il diritto di inibire la
										consultazione o il contatto per il tramite del form di risposta all’annuncio nel
										caso in cui, a proprio insindacabile giudizio, l'uso del Servizio da parte
										dell'utente si riferisca a particolari sezioni merceologiche o possa
										considerarsi lesivo di diritti o delle prerogative di faitu.ch. o di terzi.
										Resta inteso che anche in caso di valutazione ed approvazione delle inserzioni
										(siano esse automatiche o manuali) faitu.ch. non presta alcuna garanzia circa il
										contenuto, la completezza e la correttezza delle stesse. faitu.ch è altresì
										estranea alle trattative eventualmente nascenti dall'uso del Servizio e pertanto
										non garantisce nè la bontà nè l'esito delle stesse, di conseguenza nessuna
										richiesta di restituzione, compensazione, riparazione e/o risarcimento a
										qualunque titolo potrà essere indirizzata nei confronti di faitu.ch.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Limitazione di responsabilità </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch si riserva il diritto di modificare, sospendere o interrompere, in
										tutto o in parte, il Servizio in qualsiasi momento anche senza preavviso e senza
										che la stessa sia tenuta ad indicare le ragioni sottese alle predette azioni.
										Faitu.ch. non potrà quindi in nessun caso essere ritenuta parte o responsabile
										dello scambio di comunicazioni tra gli utenti e delle trattative da esse
										nascenti. Faitu.ch non potrà parimenti essere ritenuta responsabile dei danni
										conseguenti alla mancata prestazione del Servizio oggetto del presente contratto
										a causa dell'errato o mancato funzionamento dei mezzi elettronici di
										comunicazione per cause estranee alla sfera del proprio prevedibile controllo.
									</p>
									<p>
										A titolo esemplificativo, ma non esaustivo, il malfunzionamento dei server ed
										altri dispositivi elettronici anche non facenti parte integrante della rete
										Internet, malfunzionamento dei software installati, virus informatici, nonchè da
										azioni di hacker o altri utenti aventi accesso alla rete.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Contenuti e comportamenti degli utente </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										Faitu.ch non è obbligata, ma autorizzata a verificare o controllare le
										informazioni e i materiali, inseriti o trasmessi o messi a disposizione nel Sito
										da parte degli utenti.
									</p>
									<p>
										Inoltre non rispondiamo del comportamento in rete o fuori rete degli utenti e
										non ci assumiamo la responsabilità né morale né materiale per il loro
										comportamento o per le informazioni/materiali messi a disposizione dagli utenti.
									</p>
									<div>
										Ci riserviamo in ogni caso il diritto, a nostra esclusiva discrezione, di
										condurre indagini in relazione all'utilizzo del nostro Sito e di prendere
										provvedimenti adeguati contro tutte le persone che
										<ul>
											<li>
												violano le disposizioni relative a comportamenti proibiti e contenuti
												inammissibili della piattaforma;
											</li>
											<li>violano effettivamente o presumibilmente le leggi vigenti;</li>
											<li>
												rtecipano a comportamenti scandalosi, illegali o tali da comportare
												violazioni dei diritti di altre persone o da compromettere o minacciare la
												loro sicurezza.
											</li>
										</ul>
										In tali casi ci riserviamo espressamente di togliere tempestivamente e senza
										preavviso le informazioni/i materiali inseriti dagli utenti e di impedire
										all'utente interessato di utilizzare successivamente in qualsiasi modo il Sito.
									</div>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>DIRITTI E DOVERI DEGLI UTENTI</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<h5 className='font-bold'>Dati completi e attuali</h5>
									<p>
										L'utente si impegna a fornire tutti i dati relativi alla sua persona aggiornati,
										corretti e completi. L'utente deve avere almeno 18 anni. Inoltre l'utente si
										impegna ad aggiornare tempestivamente tutti i dati determinanti per l'utilizzo
										del Sito.
									</p>
									<p>
										Noi permettiamo agli utenti di inserire informazioni/materiali nel Sito in forma
										di annunci ai quali possono accedere gli altri utenti del Sito.
									</p>
									<h5 className='font-bold'>Norme di comportamento</h5>
									<p>
										L'utente in quanto tale riconosce di utilizzare la piattaforma in modo
										coscienzioso e conforme alle regole. L'utente assicura e garantisce di non
										violare le leggi in materia e in particolare di non mettere a disposizione nel
										Sito contenuti di qualsiasi tipo che violano i diritti di terzi o contengono
										materiale illegale. In particolare l'utente si impegna a non utilizzare al di
										fuori del suo scopo, quale la pubblicazione di annunci di vendita, di scambio o
										di servizi.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Servizi a pagamento</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch offre sulla propria piattaforma servizi a pagamento, i prezzi e la
										durata del contratto sono riportati sulla piattaforma. Il pagamento
										dell'annuncio non implica la sua pubblicazione immediata. Se la pubblicazione
										non viene concessa per mancato rispetto delle norme di faitu.ch in parte o
										integralmente, l'annuncio non verrà pubblicato. L'importo pagato non verrà
										restituito.
									</p>
									<div>
										<h5 className='font-bold'>Abbonamenti</h5>
										<p>
											faitu.ch offre un servizio di abbonamento rivolto ai negozi che desiderano
											pubblicare i loro prodotti e offerte sulla piattaforma. L'annullamento del
											contratto può essere effettuato in qualsiasi momento, il cambiamento del piano
											di abbonamento può essere effettuato in qualsiasi momento.
										</p>
									</div>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Licenza sui contenuti dell'utente</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										L'utente concede una licenza gratuita, illimitata, irrevocabile, a tempo
										indeterminato, semplice, trasferibile e valida in tutto il mondo (con il diritto
										di concedere sottolicenze) per qualsiasi uso (inclusa la riproduzione, la
										divulgazione e qualsiasi elaborazione, compresa la traduzione) sui contenuti
										dell'utente o su parte di essi per qualsiasi scopo commerciale (attualmente
										conosciuto o sconosciuto) o altri scopi (inclusa la pubblicità) in correlazione
										con il Sito.
									</p>
									<p>
										L'utente può togliere in qualsiasi momento i contenuti che ha inserito lui
										stesso nel Sito. Se l'utente toglie i suoi contenuti dal Sito, la licenza sopra
										concessa rimane in vigore. In particolare l'utente riconosce che noi possiamo
										conservare copie archiviate dei suoi contenuti e che la licenza precedentemente
										concessa rimane in vigore per i suoi contenuti archiviati.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Disdetta e modifiche</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										Il rapporto contrattuale tra faitu.ch e l'utente può essere disdetto in
										qualsiasi momento da ognuna delle parti, a libera discrezione, con effetto
										immediato. I Prezzi dei Servizi a pagamento non vengono restituiti in nessun
										caso all'utente.
									</p>
									<p>
										Ci riserviamo il diritto di interrompere temporaneamente, togliere o sostituire
										tutti i servizi del Sito o alcuni di essi. Inoltre faitu.ch si riserva sia il
										diritto di dichiarare che certi servizi gratuiti in futuro saranno a pagamento,
										sia il diritto di modificare in qualsiasi momento i Servizi a pagamento o i
										Prezzi dei Servizi a pagamento.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Disposizioni finali</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch si riserva il diritto di modificare occasionalmente le condizioni
										d'uso. È determinante la versione aggiornata consultabile all'indirizzo{' '}
										<a href='/'>faitu.ch</a>.
									</p>
									<p>
										La nullità o l'inefficacia di una o più disposizioni non pregiudica la validità
										delle restanti disposizioni.
									</p>
									<p>
										Le presenti condizioni d'uso sono disciplinate dal diritto svizzero, con
										esclusione delle norme ddi diritto internazionale privato.
									</p>
									<p>
										Foro competente esclusivo per tutte le controversie derivanti dalle o correlate
										alle presenti condizioni d'uso è la sede di faitu.ch (Lugano).
									</p>
								</article>
							</li>
						</ol>
					</div>
				</section>
			</div>
		</div>
	);
};

const TermsDE = () => {
	return (
		<div className='max-w-screen-2xl mx-auto'>
			<div className='flex-auto'>
				<div
					className='relative pt-16 pb-32 flex content-center items-center justify-center'
					style={{
						minHeight: '35vh',
					}}
				>
					<div
						className='absolute top-0 w-full h-full bg-center bg-cover'
						style={{
							backgroundImage:
								"url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')",
						}}
					>
						<span id='blackOverlay' className='w-full h-full absolute opacity-75 bg-black'></span>
					</div>
					<div className='container relative mx-auto'>
						<div className='items-center flex flex-wrap'>
							<div className='w-full lg:w-6/12 px-4 ml-auto mr-auto text-center'>
								<div className='pr-12'>
									<h1 className='text-white font-semibold text-5xl'>Terms and conditions</h1>
									{/* <p className='mt-4 text-lg text-gray-300'>
										This is a simple example of a Landing Page you can build using Tailwind Starter
										Kit. It features multiple CSS components based on the Tailwindcss design system.
									</p> */}
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className=''>
					<div className='container mx-auto '>
						<ol className='list-decimal text-lg list-inside my-5 pl-2'>
							<li className='font-bold my-5'>
								<span className='capitalize'>Contenuto del servizio</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									faitu.ch piattaforma di servizi informatici e telematici, mette a disposizione
									degli utenti che intendano avvalersene un servizio (da ora in poi il "Servizio
									Base" e/o "Servizio") web based che consente di pubblicare e consultare annunci e
									inserzioni di soggetti privati o professionali che intendano alienare o acquistare
									beni o prestare e ricevere servizi. Il Servizio consente altresì agli utenti
									inserzionisti ed agli utenti interessati a quanto pubblicato di entrare in
									contatto tra di loro.
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Applicabilità delle condizioni</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									Le presenti Condizioni Generali del Servizio si applicano sia agli utenti che
									utilizzino il Servizio in consultazione degli annunci pubblicati sia agli utenti
									inserzionisti privati o professionali (d‘ora in poi collettivamente “utente/i”).
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Termini per l'uso del servizio </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch è in continua mutazione, sia nell'aspetto che nelle funzionalità, per
										questo motivo ci riserviamo il diritto di modificare o eliminare le funzioni del
										Sito in qualsiasi momento e senza preannunciarlo. Cercheremo comunque di
										informare gli utenti di tutte le modifiche o eliminazioni mettendo una
										comunicazione in merito sul Sito stesso.
									</p>
									<p>
										Il Sito e tutti i suoi contenuti vengono messi a disposizione senza garanzia di
										assenza di difetti. Non assicuriamo né garantiamo che le informazioni e i
										contenuti del Sito o sul Sito (nostri o degli utenti) sono completi o privi di
										errori. Gli utenti riconoscono che lo scaricamento o il rilevamento di
										informazioni e contenuti dal Sito avvengono a propria discrezione e a proprio
										rischio.
									</p>
									<p>
										Facciamo del nostro meglio per evitare un uso illecito della nostra piattaforma
										da parte di alcuni utenti, ma non possiamo escludere a priori che ciò avvenga.
										Ogni nostra responsabilità legata a utilizzi di questo genere viene pertanto
										esclusa sin d'ora.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Responsabilità dell'utente </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										L'utente è totalmente ed esclusivamente responsabile dell'uso del Servizio (da
										intendersi espressamente con riguardo alle funzioni di pubblicazione, di
										consultazione, di gestione delle inserzioni e di contatto tra utenti) ed è
										pertanto l'unico garante e responsabile dei beni e dei servizi offerti per il
										tramite del Servizio nonchè della correttezza, completezza e liceità delle
										inserzioni e del proprio comportamento nell'ambito del contatto tra utenti.
										L'utente garantisce la disponibilità e/o la titolarità del bene/servizio oggetto
										delle inserzioni medesime. L'utente garantisce altresì che i propri annunci non
										violano alcun diritto d'autore né diritto di proprietà industriale né altro
										diritto di terzi. In caso di contestazione da parte di terzi riguardo a
										qualsiasi annuncio o condotta ad esso legata, l'utente se ne assume la piena
										responsabilità e si impegna a tenere manlevata e indenne faitu.ch da qualsiasi
										danno, perdita o spesa. L'utente si impegna ad utilizzare il form di risposta
										all’annuncio al solo scopo di prendere contatto e scambiare informazioni con gli
										altri utenti relativamente agli annunci, utilizzando un linguaggio consono, nel
										rispetto della legge, dell’etica e della netiquette. L'utente, inoltre, si
										assume ogni responsabilità per eventuali danni che possano derivare al suo
										sistema informatico dall'uso del Servizio o a quello di terzi. Resta infine
										inteso che ogni eventuale utilizzo di robot, spider, scraper e/o ulteriori
										strumenti automatici per accedere al sito e/o per estrapolare i relativi dati,
										contenuti, informazioni è espressamente vietato.
									</p>
									<p>
										faitu.ch non presta alcuna garanzia circa il contenuto, la completezza e la
										correttezza delle inserzioni pubblicate nè con riguardo ai dati pubblicati, né
										relativamente alle informazioni successivamente fornite dall’utente, nè con
										riferimento al numero o alla qualità dei risultati ottenuti tramite il Servizio.
										In ogni caso faitu.ch. si riserva, in qualsiasi momento, il diritto di valutare,
										approvare, eliminare o impedire l'inserzione ovvero il diritto di inibire la
										consultazione o il contatto per il tramite del form di risposta all’annuncio nel
										caso in cui, a proprio insindacabile giudizio, l'uso del Servizio da parte
										dell'utente si riferisca a particolari sezioni merceologiche o possa
										considerarsi lesivo di diritti o delle prerogative di faitu.ch. o di terzi.
										Resta inteso che anche in caso di valutazione ed approvazione delle inserzioni
										(siano esse automatiche o manuali) faitu.ch. non presta alcuna garanzia circa il
										contenuto, la completezza e la correttezza delle stesse. faitu.ch è altresì
										estranea alle trattative eventualmente nascenti dall'uso del Servizio e pertanto
										non garantisce nè la bontà nè l'esito delle stesse, di conseguenza nessuna
										richiesta di restituzione, compensazione, riparazione e/o risarcimento a
										qualunque titolo potrà essere indirizzata nei confronti di faitu.ch.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Limitazione di responsabilità </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch si riserva il diritto di modificare, sospendere o interrompere, in
										tutto o in parte, il Servizio in qualsiasi momento anche senza preavviso e senza
										che la stessa sia tenuta ad indicare le ragioni sottese alle predette azioni.
										Faitu.ch. non potrà quindi in nessun caso essere ritenuta parte o responsabile
										dello scambio di comunicazioni tra gli utenti e delle trattative da esse
										nascenti. Faitu.ch non potrà parimenti essere ritenuta responsabile dei danni
										conseguenti alla mancata prestazione del Servizio oggetto del presente contratto
										a causa dell'errato o mancato funzionamento dei mezzi elettronici di
										comunicazione per cause estranee alla sfera del proprio prevedibile controllo.
									</p>
									<p>
										A titolo esemplificativo, ma non esaustivo, il malfunzionamento dei server ed
										altri dispositivi elettronici anche non facenti parte integrante della rete
										Internet, malfunzionamento dei software installati, virus informatici, nonchè da
										azioni di hacker o altri utenti aventi accesso alla rete.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Contenuti e comportamenti degli utente </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										Faitu.ch non è obbligata, ma autorizzata a verificare o controllare le
										informazioni e i materiali, inseriti o trasmessi o messi a disposizione nel Sito
										da parte degli utenti.
									</p>
									<p>
										Inoltre non rispondiamo del comportamento in rete o fuori rete degli utenti e
										non ci assumiamo la responsabilità né morale né materiale per il loro
										comportamento o per le informazioni/materiali messi a disposizione dagli utenti.
									</p>
									<div>
										Ci riserviamo in ogni caso il diritto, a nostra esclusiva discrezione, di
										condurre indagini in relazione all'utilizzo del nostro Sito e di prendere
										provvedimenti adeguati contro tutte le persone che
										<ul>
											<li>
												violano le disposizioni relative a comportamenti proibiti e contenuti
												inammissibili della piattaforma;
											</li>
											<li>violano effettivamente o presumibilmente le leggi vigenti;</li>
											<li>
												rtecipano a comportamenti scandalosi, illegali o tali da comportare
												violazioni dei diritti di altre persone o da compromettere o minacciare la
												loro sicurezza.
											</li>
										</ul>
										In tali casi ci riserviamo espressamente di togliere tempestivamente e senza
										preavviso le informazioni/i materiali inseriti dagli utenti e di impedire
										all'utente interessato di utilizzare successivamente in qualsiasi modo il Sito.
									</div>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>DIRITTI E DOVERI DEGLI UTENTI</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<h5 className='font-bold'>Dati completi e attuali</h5>
									<p>
										L'utente si impegna a fornire tutti i dati relativi alla sua persona aggiornati,
										corretti e completi. L'utente deve avere almeno 18 anni. Inoltre l'utente si
										impegna ad aggiornare tempestivamente tutti i dati determinanti per l'utilizzo
										del Sito.
									</p>
									<p>
										Noi permettiamo agli utenti di inserire informazioni/materiali nel Sito in forma
										di annunci ai quali possono accedere gli altri utenti del Sito.
									</p>
									<h5 className='font-bold'>Norme di comportamento</h5>
									<p>
										L'utente in quanto tale riconosce di utilizzare la piattaforma in modo
										coscienzioso e conforme alle regole. L'utente assicura e garantisce di non
										violare le leggi in materia e in particolare di non mettere a disposizione nel
										Sito contenuti di qualsiasi tipo che violano i diritti di terzi o contengono
										materiale illegale. In particolare l'utente si impegna a non utilizzare al di
										fuori del suo scopo, quale la pubblicazione di annunci di vendita, di scambio o
										di servizi.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Servizi a pagamento</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch offre sulla propria piattaforma servizi a pagamento, i prezzi e la
										durata del contratto sono riportati sulla piattaforma. Il pagamento
										dell'annuncio non implica la sua pubblicazione immediata. Se la pubblicazione
										non viene concessa per mancato rispetto delle norme di faitu.ch in parte o
										integralmente, l'annuncio non verrà pubblicato. L'importo pagato non verrà
										restituito.
									</p>
									<div>
										<h5 className='font-bold'>Abbonamenti</h5>
										<p>
											faitu.ch offre un servizio di abbonamento rivolto ai negozi che desiderano
											pubblicare i loro prodotti e offerte sulla piattaforma. L'annullamento del
											contratto può essere effettuato in qualsiasi momento, il cambiamento del piano
											di abbonamento può essere effettuato in qualsiasi momento.
										</p>
									</div>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Licenza sui contenuti dell'utente</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										L'utente concede una licenza gratuita, illimitata, irrevocabile, a tempo
										indeterminato, semplice, trasferibile e valida in tutto il mondo (con il diritto
										di concedere sottolicenze) per qualsiasi uso (inclusa la riproduzione, la
										divulgazione e qualsiasi elaborazione, compresa la traduzione) sui contenuti
										dell'utente o su parte di essi per qualsiasi scopo commerciale (attualmente
										conosciuto o sconosciuto) o altri scopi (inclusa la pubblicità) in correlazione
										con il Sito.
									</p>
									<p>
										L'utente può togliere in qualsiasi momento i contenuti che ha inserito lui
										stesso nel Sito. Se l'utente toglie i suoi contenuti dal Sito, la licenza sopra
										concessa rimane in vigore. In particolare l'utente riconosce che noi possiamo
										conservare copie archiviate dei suoi contenuti e che la licenza precedentemente
										concessa rimane in vigore per i suoi contenuti archiviati.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Disdetta e modifiche</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										Il rapporto contrattuale tra faitu.ch e l'utente può essere disdetto in
										qualsiasi momento da ognuna delle parti, a libera discrezione, con effetto
										immediato. I Prezzi dei Servizi a pagamento non vengono restituiti in nessun
										caso all'utente.
									</p>
									<p>
										Ci riserviamo il diritto di interrompere temporaneamente, togliere o sostituire
										tutti i servizi del Sito o alcuni di essi. Inoltre faitu.ch si riserva sia il
										diritto di dichiarare che certi servizi gratuiti in futuro saranno a pagamento,
										sia il diritto di modificare in qualsiasi momento i Servizi a pagamento o i
										Prezzi dei Servizi a pagamento.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Disposizioni finali</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch si riserva il diritto di modificare occasionalmente le condizioni
										d'uso. È determinante la versione aggiornata consultabile all'indirizzo{' '}
										<a href='/'>faitu.ch</a>.
									</p>
									<p>
										La nullità o l'inefficacia di una o più disposizioni non pregiudica la validità
										delle restanti disposizioni.
									</p>
									<p>
										Le presenti condizioni d'uso sono disciplinate dal diritto svizzero, con
										esclusione delle norme ddi diritto internazionale privato.
									</p>
									<p>
										Foro competente esclusivo per tutte le controversie derivanti dalle o correlate
										alle presenti condizioni d'uso è la sede di faitu.ch (Lugano).
									</p>
								</article>
							</li>
						</ol>
					</div>
				</section>
			</div>
		</div>
	);
};
const TermsFR = () => {
	return (
		<div className='max-w-screen-2xl mx-auto'>
			<div className='flex-auto'>
				<div
					className='relative pt-16 pb-32 flex content-center items-center justify-center'
					style={{
						minHeight: '35vh',
					}}
				>
					<div
						className='absolute top-0 w-full h-full bg-center bg-cover'
						style={{
							backgroundImage:
								"url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')",
						}}
					>
						<span id='blackOverlay' className='w-full h-full absolute opacity-75 bg-black'></span>
					</div>
					<div className='container relative mx-auto'>
						<div className='items-center flex flex-wrap'>
							<div className='w-full lg:w-6/12 px-4 ml-auto mr-auto text-center'>
								<div className='pr-12'>
									<h1 className='text-white font-semibold text-5xl'>Terms and conditions</h1>
									{/* <p className='mt-4 text-lg text-gray-300'>
										This is a simple example of a Landing Page you can build using Tailwind Starter
										Kit. It features multiple CSS components based on the Tailwindcss design system.
									</p> */}
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className=''>
					<div className='container mx-auto '>
						<ol className='list-decimal text-lg list-inside my-5 pl-2'>
							<li className='font-bold my-5'>
								<span className='capitalize'>Contenuto del servizio</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									faitu.ch piattaforma di servizi informatici e telematici, mette a disposizione
									degli utenti che intendano avvalersene un servizio (da ora in poi il "Servizio
									Base" e/o "Servizio") web based che consente di pubblicare e consultare annunci e
									inserzioni di soggetti privati o professionali che intendano alienare o acquistare
									beni o prestare e ricevere servizi. Il Servizio consente altresì agli utenti
									inserzionisti ed agli utenti interessati a quanto pubblicato di entrare in
									contatto tra di loro.
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Applicabilità delle condizioni</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									Le presenti Condizioni Generali del Servizio si applicano sia agli utenti che
									utilizzino il Servizio in consultazione degli annunci pubblicati sia agli utenti
									inserzionisti privati o professionali (d‘ora in poi collettivamente “utente/i”).
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Termini per l'uso del servizio </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch è in continua mutazione, sia nell'aspetto che nelle funzionalità, per
										questo motivo ci riserviamo il diritto di modificare o eliminare le funzioni del
										Sito in qualsiasi momento e senza preannunciarlo. Cercheremo comunque di
										informare gli utenti di tutte le modifiche o eliminazioni mettendo una
										comunicazione in merito sul Sito stesso.
									</p>
									<p>
										Il Sito e tutti i suoi contenuti vengono messi a disposizione senza garanzia di
										assenza di difetti. Non assicuriamo né garantiamo che le informazioni e i
										contenuti del Sito o sul Sito (nostri o degli utenti) sono completi o privi di
										errori. Gli utenti riconoscono che lo scaricamento o il rilevamento di
										informazioni e contenuti dal Sito avvengono a propria discrezione e a proprio
										rischio.
									</p>
									<p>
										Facciamo del nostro meglio per evitare un uso illecito della nostra piattaforma
										da parte di alcuni utenti, ma non possiamo escludere a priori che ciò avvenga.
										Ogni nostra responsabilità legata a utilizzi di questo genere viene pertanto
										esclusa sin d'ora.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Responsabilità dell'utente </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										L'utente è totalmente ed esclusivamente responsabile dell'uso del Servizio (da
										intendersi espressamente con riguardo alle funzioni di pubblicazione, di
										consultazione, di gestione delle inserzioni e di contatto tra utenti) ed è
										pertanto l'unico garante e responsabile dei beni e dei servizi offerti per il
										tramite del Servizio nonchè della correttezza, completezza e liceità delle
										inserzioni e del proprio comportamento nell'ambito del contatto tra utenti.
										L'utente garantisce la disponibilità e/o la titolarità del bene/servizio oggetto
										delle inserzioni medesime. L'utente garantisce altresì che i propri annunci non
										violano alcun diritto d'autore né diritto di proprietà industriale né altro
										diritto di terzi. In caso di contestazione da parte di terzi riguardo a
										qualsiasi annuncio o condotta ad esso legata, l'utente se ne assume la piena
										responsabilità e si impegna a tenere manlevata e indenne faitu.ch da qualsiasi
										danno, perdita o spesa. L'utente si impegna ad utilizzare il form di risposta
										all’annuncio al solo scopo di prendere contatto e scambiare informazioni con gli
										altri utenti relativamente agli annunci, utilizzando un linguaggio consono, nel
										rispetto della legge, dell’etica e della netiquette. L'utente, inoltre, si
										assume ogni responsabilità per eventuali danni che possano derivare al suo
										sistema informatico dall'uso del Servizio o a quello di terzi. Resta infine
										inteso che ogni eventuale utilizzo di robot, spider, scraper e/o ulteriori
										strumenti automatici per accedere al sito e/o per estrapolare i relativi dati,
										contenuti, informazioni è espressamente vietato.
									</p>
									<p>
										faitu.ch non presta alcuna garanzia circa il contenuto, la completezza e la
										correttezza delle inserzioni pubblicate nè con riguardo ai dati pubblicati, né
										relativamente alle informazioni successivamente fornite dall’utente, nè con
										riferimento al numero o alla qualità dei risultati ottenuti tramite il Servizio.
										In ogni caso faitu.ch. si riserva, in qualsiasi momento, il diritto di valutare,
										approvare, eliminare o impedire l'inserzione ovvero il diritto di inibire la
										consultazione o il contatto per il tramite del form di risposta all’annuncio nel
										caso in cui, a proprio insindacabile giudizio, l'uso del Servizio da parte
										dell'utente si riferisca a particolari sezioni merceologiche o possa
										considerarsi lesivo di diritti o delle prerogative di faitu.ch. o di terzi.
										Resta inteso che anche in caso di valutazione ed approvazione delle inserzioni
										(siano esse automatiche o manuali) faitu.ch. non presta alcuna garanzia circa il
										contenuto, la completezza e la correttezza delle stesse. faitu.ch è altresì
										estranea alle trattative eventualmente nascenti dall'uso del Servizio e pertanto
										non garantisce nè la bontà nè l'esito delle stesse, di conseguenza nessuna
										richiesta di restituzione, compensazione, riparazione e/o risarcimento a
										qualunque titolo potrà essere indirizzata nei confronti di faitu.ch.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Limitazione di responsabilità </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch si riserva il diritto di modificare, sospendere o interrompere, in
										tutto o in parte, il Servizio in qualsiasi momento anche senza preavviso e senza
										che la stessa sia tenuta ad indicare le ragioni sottese alle predette azioni.
										Faitu.ch. non potrà quindi in nessun caso essere ritenuta parte o responsabile
										dello scambio di comunicazioni tra gli utenti e delle trattative da esse
										nascenti. Faitu.ch non potrà parimenti essere ritenuta responsabile dei danni
										conseguenti alla mancata prestazione del Servizio oggetto del presente contratto
										a causa dell'errato o mancato funzionamento dei mezzi elettronici di
										comunicazione per cause estranee alla sfera del proprio prevedibile controllo.
									</p>
									<p>
										A titolo esemplificativo, ma non esaustivo, il malfunzionamento dei server ed
										altri dispositivi elettronici anche non facenti parte integrante della rete
										Internet, malfunzionamento dei software installati, virus informatici, nonchè da
										azioni di hacker o altri utenti aventi accesso alla rete.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Contenuti e comportamenti degli utente </span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										Faitu.ch non è obbligata, ma autorizzata a verificare o controllare le
										informazioni e i materiali, inseriti o trasmessi o messi a disposizione nel Sito
										da parte degli utenti.
									</p>
									<p>
										Inoltre non rispondiamo del comportamento in rete o fuori rete degli utenti e
										non ci assumiamo la responsabilità né morale né materiale per il loro
										comportamento o per le informazioni/materiali messi a disposizione dagli utenti.
									</p>
									<div>
										Ci riserviamo in ogni caso il diritto, a nostra esclusiva discrezione, di
										condurre indagini in relazione all'utilizzo del nostro Sito e di prendere
										provvedimenti adeguati contro tutte le persone che
										<ul>
											<li>
												violano le disposizioni relative a comportamenti proibiti e contenuti
												inammissibili della piattaforma;
											</li>
											<li>violano effettivamente o presumibilmente le leggi vigenti;</li>
											<li>
												rtecipano a comportamenti scandalosi, illegali o tali da comportare
												violazioni dei diritti di altre persone o da compromettere o minacciare la
												loro sicurezza.
											</li>
										</ul>
										In tali casi ci riserviamo espressamente di togliere tempestivamente e senza
										preavviso le informazioni/i materiali inseriti dagli utenti e di impedire
										all'utente interessato di utilizzare successivamente in qualsiasi modo il Sito.
									</div>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>DIRITTI E DOVERI DEGLI UTENTI</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<h5 className='font-bold'>Dati completi e attuali</h5>
									<p>
										L'utente si impegna a fornire tutti i dati relativi alla sua persona aggiornati,
										corretti e completi. L'utente deve avere almeno 18 anni. Inoltre l'utente si
										impegna ad aggiornare tempestivamente tutti i dati determinanti per l'utilizzo
										del Sito.
									</p>
									<p>
										Noi permettiamo agli utenti di inserire informazioni/materiali nel Sito in forma
										di annunci ai quali possono accedere gli altri utenti del Sito.
									</p>
									<h5 className='font-bold'>Norme di comportamento</h5>
									<p>
										L'utente in quanto tale riconosce di utilizzare la piattaforma in modo
										coscienzioso e conforme alle regole. L'utente assicura e garantisce di non
										violare le leggi in materia e in particolare di non mettere a disposizione nel
										Sito contenuti di qualsiasi tipo che violano i diritti di terzi o contengono
										materiale illegale. In particolare l'utente si impegna a non utilizzare al di
										fuori del suo scopo, quale la pubblicazione di annunci di vendita, di scambio o
										di servizi.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Servizi a pagamento</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch offre sulla propria piattaforma servizi a pagamento, i prezzi e la
										durata del contratto sono riportati sulla piattaforma. Il pagamento
										dell'annuncio non implica la sua pubblicazione immediata. Se la pubblicazione
										non viene concessa per mancato rispetto delle norme di faitu.ch in parte o
										integralmente, l'annuncio non verrà pubblicato. L'importo pagato non verrà
										restituito.
									</p>
									<div>
										<h5 className='font-bold'>Abbonamenti</h5>
										<p>
											faitu.ch offre un servizio di abbonamento rivolto ai negozi che desiderano
											pubblicare i loro prodotti e offerte sulla piattaforma. L'annullamento del
											contratto può essere effettuato in qualsiasi momento, il cambiamento del piano
											di abbonamento può essere effettuato in qualsiasi momento.
										</p>
									</div>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Licenza sui contenuti dell'utente</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										L'utente concede una licenza gratuita, illimitata, irrevocabile, a tempo
										indeterminato, semplice, trasferibile e valida in tutto il mondo (con il diritto
										di concedere sottolicenze) per qualsiasi uso (inclusa la riproduzione, la
										divulgazione e qualsiasi elaborazione, compresa la traduzione) sui contenuti
										dell'utente o su parte di essi per qualsiasi scopo commerciale (attualmente
										conosciuto o sconosciuto) o altri scopi (inclusa la pubblicità) in correlazione
										con il Sito.
									</p>
									<p>
										L'utente può togliere in qualsiasi momento i contenuti che ha inserito lui
										stesso nel Sito. Se l'utente toglie i suoi contenuti dal Sito, la licenza sopra
										concessa rimane in vigore. In particolare l'utente riconosce che noi possiamo
										conservare copie archiviate dei suoi contenuti e che la licenza precedentemente
										concessa rimane in vigore per i suoi contenuti archiviati.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Disdetta e modifiche</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										Il rapporto contrattuale tra faitu.ch e l'utente può essere disdetto in
										qualsiasi momento da ognuna delle parti, a libera discrezione, con effetto
										immediato. I Prezzi dei Servizi a pagamento non vengono restituiti in nessun
										caso all'utente.
									</p>
									<p>
										Ci riserviamo il diritto di interrompere temporaneamente, togliere o sostituire
										tutti i servizi del Sito o alcuni di essi. Inoltre faitu.ch si riserva sia il
										diritto di dichiarare che certi servizi gratuiti in futuro saranno a pagamento,
										sia il diritto di modificare in qualsiasi momento i Servizi a pagamento o i
										Prezzi dei Servizi a pagamento.
									</p>
								</article>
							</li>
							<li className='font-bold my-5'>
								<span className='capitalize'>Disposizioni finali</span>
								<article className='prose font-normal max-w-none leading-6 mt-2'>
									<p>
										faitu.ch si riserva il diritto di modificare occasionalmente le condizioni
										d'uso. È determinante la versione aggiornata consultabile all'indirizzo{' '}
										<a href='/'>faitu.ch</a>.
									</p>
									<p>
										La nullità o l'inefficacia di una o più disposizioni non pregiudica la validità
										delle restanti disposizioni.
									</p>
									<p>
										Le presenti condizioni d'uso sono disciplinate dal diritto svizzero, con
										esclusione delle norme ddi diritto internazionale privato.
									</p>
									<p>
										Foro competente esclusivo per tutte le controversie derivanti dalle o correlate
										alle presenti condizioni d'uso è la sede di faitu.ch (Lugano).
									</p>
								</article>
							</li>
						</ol>
					</div>
				</section>
			</div>
		</div>
	);
};

const Terms = () => {
	const [sideBar, setSideBar] = useState(false);
	const { lang } = useAppState().app;

	switch (lang) {
		case 'it':
			return (
				<>
					<TermsIT {...{ sideBar, setSideBar }} />
					<Sidebar {...{ sideBar, setSideBar }} />
				</>
			);
		case 'en':
			return <TermsEN />;
		case 'de':
			return <TermsDE />;
		case 'fr':
			return <TermsFR />;
		default:
			return (
				<>
					<TermsIT {...{ sideBar, setSideBar }} />
					<Sidebar {...{ sideBar, setSideBar }} />
				</>
			);
	}
};

export default Terms;
