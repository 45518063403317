import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

/** Categories and subcategories managements */
type GroupMenuCategoryItemsProp = {
  links: Array<any>;
  title: string;
  // key?: number;
};
const GroupMenuCategoryItems: React.FC<GroupMenuCategoryItemsProp> = (props: any) => {
  const { t } = useTranslation();
  const { links, title } = props;
  const [selected, setSelected] = useState<boolean>(false);

  const handleMenuSelect = () => {
    setSelected(!selected);
  };

  return (
    <>
      <div className='flex items-center justify-start '>
        <div className='flex flex-col w-full'>
          <div className='flex flex-row items-center justify-between p-2 hover:bg-gray-100'>
            <button
              onClick={handleMenuSelect}
              className='bg-transparent  text-left text-gray-900 flex flex-row items-center
                justify-start w-full focus:outline-none'
            >
              <span className='font-bold text-sm leading-relaxed'>{t(title)}</span>

              <div className='ml-auto stroke-current transition ease-in-out duration-150 transform rotate-0'>
                {selected ? <IoIosArrowDown /> : <IoIosArrowForward />}
              </div>
            </button>
          </div>
          <div className={` w-full ${selected ? 'block' : 'hidden'} `}>
            {links.map((link: any, index: number) => {
              return (
                <div
                  key={index}
                  className='flex flex-row items-center justify-between py-1 px-4 text-sm'
                >
                  <span className='flex hover:underline hover:text-indigo-800 '>
                    <a href={`/classfied/${link.parent}/${link._id}`}>{t(link.name)}</a>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupMenuCategoryItems;
