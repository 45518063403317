/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

//todo: This is commented for sliding home page. I can used on the futured
// import HomeSlider from '../UI/Home/HomeSlider';

import { useAppState } from '../hooks/adsHooks';
import CookieConsent from 'react-cookie-consent';

import { IAd } from '../Interfaces/dataModel';
import serverApi from '../api/serverApi';
import { SeoTag } from '../utils/Seo';
// import { LoadingSpinner } from '../Components/loader';

// Home UI component
// import HomeMenuCategories from '../UI/Home/CategoriesHomeList';

const HomeMenuCategories = React.lazy(() => import('../UI/Home/CategoriesHomeList'));
const FaituMagTitle = React.lazy(() => import('../Components/MagTitle'));
const FeaturedSection = React.lazy(() => import('../UI/Home/FeaturedCategories'));

const FuCard = React.lazy(() => import('../Components/fu-card'));
const LoadingSpinner = React.lazy(() => import('../Components/loader'));

const HomePage: React.FC = () => {
	const { t } = useTranslation();
	const { app } = useAppState();

	const [featuredAd, setFeaturedAd] = useState<IAd[]>([]);

	useEffect(() => {
		let mounted = true;
		const fetchFeatured = async () => {
			if (mounted) {
				const ft = await serverApi.getFeaturedAd();
				if (ft.status === 'success') {
					setFeaturedAd(ft.featured);
				}
			}
		};
		fetchFeatured();
		return () => {
			mounted = false;
		};
	}, []);

	return (
		<React.Fragment>
			<SeoTag
				title='Faitu'
				description={t(
					'Sell ​​and buy new and used online in a simple, fast and secure way. Order meals from your favorite restaurant, ready for home delivery or takeaway.'
				)}
				introText={t(
					'Choose a meal from your favorite restaurant brought directly to your home, or search through thousands of listings on the faitu marketplace'
				)}
				imageUrl={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/faitu200x200.png`}
			/>
			<CookieConsent location='bottom' cookieName='faituCookiesConsent' expires={999} overlay>
				{t('This website uses cookies to enhance the user experience')}
			</CookieConsent>

			<div className='max-w-screen-2xl mx-auto px-4 '>
				<div className='flex-auto'>
					<section id='slide' className='my-8'>
						<div className='max-w-full mx-auto'>
							<div className='flex flex-wrap'>
								<div className='lg:w-2/3 w-full'>
									{/* <HomeSlider t={t} lang={app.lang} /> */}

									<div className='md:flex md:flex-row'>
										<div className='w-full p-1 md:flex md:w-1/2 md:pr-1'>
											<React.Suspense
												fallback={
													<div className='text-center w-full h-full mt-10'>
														<LoadingSpinner />
													</div>
												}
											>
												<FuCard
													url='driver-info'
													title={t('Start your new job today')}
													description={`${t('create your business as a delivery boy')} . ${t(
														'Earnings as low as $12 per delivery'
													)}`}
													imageUrl={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/driver-man-banner.jpg`}
													lang={app.lang}
												/>
											</React.Suspense>
											{/* <div className='c-card block bg-white border border-gray-200  overflow-hidden'>
												<div className='relative pb-64 overflow-hidden'>
													<a href={`/${app.lang}/driver-info`}>
														<img
															className='border-none absolute inset-0 h-full w-full object-cover transition duration-500 ease-in-out transform hover:scale-110'
															src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/driver-man-banner.jpg`}
															loading='lazy'
															alt='driver-banner'
														/>
													</a>
												</div>
												<div className='p-1 mx-1 leading-normal'>
													<h2 className='font-semibold text-xl'>{t('Start your new job today')}</h2>
													<p className='text-gray-800'>
														{t('create your business as a delivery boy')}
														{'. '}
														{t('Earnings as low as $12 per delivery')}
													</p>
													
												</div>
											</div> */}
										</div>

										<div className='w-full p-1 md:flex md:w-1/2 '>
											{/* <div className='c-card block bg-white border border-gray-200  overflow-hidden'>
												<div className='relative pb-64 overflow-hidden'>
													<a href={`${app.lang}/shop-registration`}>
														<img
															className='border-none absolute inset-0 h-full w-full object-cover transition duration-500 ease-in-out transform hover:scale-110'
															// src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/food-banner.jpg`}
															src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/become-a-partner.jpg`}
															loading='lazy'
															alt='driver-banner'
														/>
													</a>
												</div>
												<div className='p-1 mx-1 leading-normal'>
													<h2 className='font-semibold text-xl'>{t('Become a partner')}</h2>
													<p className='text-gray-800'>
														{t('No sales commissions, no contracts. Try it for 6 months for free')}
													</p>
													
												</div>
											</div> */}
											<React.Suspense
												fallback={
													<div className='text-center w-full h-full mt-10'>
														<LoadingSpinner />
													</div>
												}
											>
												<FuCard
													url='shop-registration'
													title={t('Become a partner')}
													description={t(
														'No sales commissions, no contracts. Try it for 6 months for free'
													)}
													imageUrl={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/become-a-partner.jpg`}
													lang={app.lang}
												/>
											</React.Suspense>
										</div>
									</div>

									{/* Coupon banner  */}
									<div className='mt-2'>
										<div className='flex flex-col md:flex-row '>
											<div className='w-full bg-indigo-800'>
												<div className='w-full p-4 space-x-4 text-white'>
													<div className='text-center'>
														<h3 className='text-2xl font-semibold'>
															{t('6 months free for the first hundred registered businesses')}
														</h3>
														<p>
															{t(
																"Register your business and we'll send you the free coupon. Without commitment, without contract"
															)}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='block sm:hidden'>
										<div className='c-card block bg-white border border-gray-200  overflow-hidden'>
											<div className='relative pb-32 overflow-hidden'>
												<a href={`/${app.lang}/shop/food`}>
													<img
														className=' border-none absolute inset-0 h-full w-full object-cover transition duration-500 ease-in-out transform hover:scale-110'
														src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/food-banner.jpg`}
														loading='lazy'
														alt='Food-banner'
													/>
												</a>
											</div>
											<div className='p-1 mx-1 leading-normal'>
												<h2 className='font-semibold text-xl'>{t('Are you hungry?')}</h2>
												<p className='text-gray-800'>
													{t('Order your lunch or dinner directly from your favorite restaurant')}
												</p>
												<div className='text-sm font-medium mb-1 mt-3'>
													<a
														href={`/${app.lang}/shop/food`}
														className='text-sm text-gray-700 pb-1  hover:border-gray-100 hover:text-black uppercase underline-effect relative'
													>
														{t('Show more')}
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='lg:w-1/3 w-full'>
									<div className='lg:px-8'>
										<div
											// href='/'
											className='flex pb-b border-b border-gray-300 hover:border-gray-100 underline-effect relative '
										>
											<h2 className='text-2xl font-semibold leading-10 tracking-tight text-gray-800  '>
												Faitu
												<span className='ml-1 font-normal text-base text-blue-500'>Links</span>
											</h2>
										</div>
										{/* FaituMag titles  */}
										<div className='list '>
											<FaituMagTitle
												title={t('Property rental')}
												intro={t('Apartments for rent nearby')}
												url={`${app.lang}/classfied/propertyrentals`}
											/>

											<FaituMagTitle
												title={t('Motorcycles')}
												intro={t('Find the bike of your dreams')}
												url={`${app.lang}/classfied/motorcycles`}
											/>

											<FaituMagTitle
												title={t('New Ad')}
												intro={t('Create your ad for free. with faitu do first')}
												url={`${app.lang}/ads`}
											/>

											<div>
												<div className='c-card block bg-white border border-gray-200  overflow-hidden'>
													<div className='relative pb-32 overflow-hidden'>
														<a href={`/${app.lang}/shop/food/60f7e7da2a7434dc0aae8bb7/3`}>
															<img
																className=' border-none absolute inset-0 h-full w-full object-cover transition duration-500 ease-in-out transform hover:scale-110'
																src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/corners-2.png`}
																loading='lazy'
																alt='Food-banner'
															/>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					{/* Categories  */}
					<section>
						<div className='max-w-full mx-auto'>
							<div className='flex flex-wrap'>
								<div className='lg:w-2/3 w-full'>
									<FeaturedSection title={t('Featured ads')} featured={featuredAd} />
									<h2 className='text-2xl mx-1 font-semibold border-b'>{t('Categories')}</h2>
									<HomeMenuCategories />
								</div>

								{/* Right bar Take Away Banner */}
								<div className='lg:w-1/3 w-full'>
									<div className='p-3 pt-0 pl-0 lg:px-8 '>
										<div className='item my-3 border leading-normal bg-yellow-100 border-gray-300 p-3 rounded hover:bg-indigo-100'>
											<div className='info text-sm font-semibold mb-1'>
												<span className='no-underline uppercase text-gray-700 text-xs'>
													{t('Open your shop on faitu')}
												</span>
												{/* </a> */}
											</div>
											<h2 className='text-lg font-semibold'>
												<span>{t('Increase your sales by 40%')}</span>
											</h2>
											<p>
												{t('For home deliveries, takeaways or online sales with faitu do it first')}
											</p>

											<div className='text-sm font-semibold mt-3'>
												<a
													href={`${app.lang}/shop-registration`}
													className='text-sm text-gray-700 pb-1  hover:border-gray-100 hover:text-black uppercase underline-effect relative'
												>
													{t('Register your shop today')}
												</a>
											</div>
										</div>

										<FaituMagTitle
											title={t('Delivery man')}
											intro={t('Earnings as low as $12 per delivery')}
											url={`/${app.lang}/driver-info`}
										/>
									</div>

									<div className='p-3 pt-0 pl-0 lg:px-8'>
										<div className='c-card block bg-white border border-gray-200  overflow-hidden'>
											<div className='relative pb-32 overflow-hidden'>
												<a href={`/${app.lang}/shop/food`}>
													<img
														className=' border-none absolute inset-0 h-full w-full object-cover transition duration-500 ease-in-out transform hover:scale-110'
														src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/food-banner.jpg`}
														loading='lazy'
														alt='Food-banner'
													/>
												</a>
											</div>
											<div className='p-1 mx-1 leading-normal'>
												<h2 className='font-semibold text-xl'>{t('Are you hungry?')}</h2>
												<p className='text-gray-800'>
													{t('Order your lunch or dinner directly from your favorite restaurant')}
												</p>
												<div className='text-sm font-medium mb-1 mt-3'>
													<a
														href={`/${app.lang}/shop/food`}
														className='text-sm text-gray-700 pb-1  hover:border-gray-100 hover:text-black uppercase underline-effect relative'
													>
														{t('Show more')}
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					{/* Featured */}
					{/* <section className='my-8'>
          <div className='max-w-full mx-auto'>
            <div className='flex flex-wrap'>
              <FeaturedSection title='Annunci in vetrina' />
              <div className='lg:w-1/3 w-full'></div>
            </div>
          </div>
        </section> */}

					{/* Delivery section promotion */}
					{/* <section className='my-8'>
          <div className='max-w-full mx-auto'>
            <div className='flex flex-wrap'>
              <div className='lg:w-2/3 w-full'>
                <div className='flex flex-col items-center mx-1 p-2 text-center border leading-normal border-gray-300'>
                  <h4 className='text-xl font-light text-center text-red-300 uppercase josefin'>
                    Gestisci annunci con consegna a domicilio
                  </h4>
                  <div className=''>
                    <img
                      src='https://www.satispay.com/static/564aa0d6c5f6fe5c237435b210fa9476/a35b0/cd7503ff-18c1-43f3-9fb5-0ba15613deed_delivery-header.png'
                      alt=''
                      className='object-contain h-48 w-full'
                    />
                  </div>
                  <h3 className='text center text-lg font-semibold'>
                    Ottimizza le tue consegne con gli annunci appositi
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section> */}
				</div>
			</div>
		</React.Fragment>
	);
};

export default HomePage;
