import { combineReducers } from 'redux';
// import countReducer from './reducers/countReducer';
import cartReducer from './reducers/cartReducer';
import authReducer from './reducers/authReducer';
import appReducer from './reducers/app.reducer';
import filterReducer from './reducers/filters.reducer';
import CategoryReducer from './reducers/category.reducer';

const rootReducer = combineReducers({
  // countReducer,
  cart: cartReducer,
  auth: authReducer,
  app: appReducer,
  filters: filterReducer,
  categories: CategoryReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
