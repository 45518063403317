import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { IoAddCircleOutline } from 'react-icons/io5';
import { TiDeleteOutline } from 'react-icons/ti';
import serverApi from '../../../../api/serverApi';
import { dayOfWeeks } from '../../../../api/localData';
import { customStyles } from '../../../../utils/utility';
import FuSaveButton from '../../../../Components/fu-saveButton';

const WorkingTimeManager = ({ setForm, formData }: any) => {
	const { t } = useTranslation();
	const { workingTime } = formData;
	const [selectedDay, setSelectedDay] = useState<number>(0);
	const [entryFrom, setEntryFrom] = useState<string>('06:00');
	const [entryTo, setEntryTo] = useState<string>('24:00');
	const [loading, setLoading] = useState<boolean>(false);

	const addHourItem = () => {
		let day: any = workingTime[selectedDay];
		day.openHours.push({ from: entryFrom, to: entryTo });
		workingTime[selectedDay] = day;

		setForm({
			target: {
				name: 'workingTime',
				value: workingTime,
			},
		});
	};

	const removeItemHandle = (indexDay: number, itemIndex: number) => {
		workingTime[indexDay].openHours.splice(itemIndex, 1);
		setForm({
			target: {
				name: 'workingTime',
				value: workingTime,
			},
		});
	};

	const handleSubmit = async () => {
		setLoading(true);

		await serverApi.ShopUpdate(formData);
		setTimeout(() => {
			setLoading(false);
		}, 500);
	};

	return (
		<div className='bg-white mr-4 p-4 shadow mt-2'>
			<div className='flex flex-col'>
				<div className='flex flex-row w-full space-x-2'>
					<div className='flex-grow w-full '>
						<Select
							styles={customStyles}
							onChange={(e: any) => {
								setSelectedDay(e.value);
								console.log(e);
							}}
							options={dayOfWeeks(t)}
							value={dayOfWeeks(t).find((el: any) => el.value === selectedDay)}
						/>
					</div>
					<div className='flex-shrink  '>
						<input
							value={entryFrom}
							onChange={(e: any) => setEntryFrom(e.target.value)}
							type='text'
							className=' focus:ring-black focus:border-black border-2 h-10  w-full  border-gray-200    '
							id='lastNameId'
							name='from'
						/>
					</div>
					<div className='flex-shrink '>
						<input
							value={entryTo}
							onChange={(e: any) => setEntryTo(e.target.value)}
							type='text'
							className=' focus:ring-black focus:border-black border-2 h-10  w-full  border-gray-200    '
							id='lastNameId'
							name='to'
						/>
					</div>
					<div className='flex'>
						<button
							onClick={addHourItem}
							type='button'
							style={{ border: '1px solid rgba(27,31,35,.15)' }}
							className=' pl-2 pr-2 flex items-center  rounded-sm bg-gray-50 text-red-800  focus:outline-none active:outline-none hover:bg-gray-100'
						>
							<span className='font-medium'>
								<IoAddCircleOutline size={20} />
							</span>
						</button>
					</div>
				</div>
			</div>

			<div className='mt-2'>
				<div className='flex flex-col'>
					{workingTime.map((day: any, index: number) => (
						<ul key={index} className='font-medium '>
							<div className='flex'>
								<span className='flex-1'>{t(day.day)}</span>
								{day.openHours.map((openh: any, idx: number) => (
									<div key={idx} className='flex justify-end'>
										<li className='flex font-normal text-sm ml-3 items-center'>
											<span>
												{openh.from} - {openh.to}
											</span>
											<button onClick={() => removeItemHandle(index, idx)} className='ml-1'>
												<TiDeleteOutline />
											</button>
										</li>
									</div>
								))}
							</div>
						</ul>
					))}
				</div>
			</div>
			<div className='mt-2'>
				<FuSaveButton caption={t('Save your changes')} loading={loading} onClick={handleSubmit} />
			</div>
		</div>
	);
};

export default WorkingTimeManager;
