import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Order from './Order';
import OrderDetail from './OrderDetail';

import { IOrder } from '../../../../Interfaces/dataModel';
import serverApi from '../../../../api/serverApi';

// import { io } from 'socket.io-client';
// const socket = io(`${process.env.REACT_APP_SERVER_URL_ROOT}/`, { autoConnect: false });

const OrderRequest: React.FC = () => {
	const { t } = useTranslation();
	const { shopper }: any = useParams();
	const [orders, setOrders] = useState<IOrder[]>([]);
	const [selectedOrder, setSelectedOrder] = useState<IOrder>();

	// const changeData = () => socket.emit('init_shop_order', shopper);
	// const getData = (data: any) => setOrders(data);

	// useEffect(() => {
	// 	let mounted = true;
	// 	if (mounted) {
	// 		socket.open();
	// 	}
	// 	return () => {};
	// }, []);

	// useEffect(() => {
	// 	console.log('passing to initialization socket', '🔥 🔥 🔥 ');
	// 	socket.emit('init_shop_order', shopper);
	// 	socket.on('get_order_shop', (docs: any) => getData(docs));
	// 	socket.on('change_data', changeData);

	// 	return () => {
	// 		socket.off('get_order_shop');
	// 		socket.off('change_data');
	// 		socket.close();
	// 		socket.disconnect();
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const fetchingOrder = async () => {
		const data = await serverApi.shopOrderRequests(shopper);
		if (data.status === 'success') {
			setOrders(data.orderList);
		}
	};

	useEffect(() => {
		fetchingOrder();
		const interval = setInterval(() => {
			console.log('a minute passed....');
			fetchingOrder();
		}, 60000);
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let mounted = true;
		console.log('orders is changed');
		if (selectedOrder) {
			if (mounted) {
				const so = orders.find((order: IOrder) => order._id === selectedOrder._id);
				setSelectedOrder(so);
			}
		}
		return () => {
			mounted = false;
		};
	}, [orders, selectedOrder]);

	const onOrderUpdateClick = async () => {
		setSelectedOrder(undefined);
		fetchingOrder();
	};

	const onOrderReject = async () => {
		if (selectedOrder) {
			await serverApi.orderReject(selectedOrder?._id);
			let newOrders: IOrder[] = orders;
			let index: number = -1;

			index = newOrders.findIndex((order: IOrder) => order._id === selectedOrder._id);
			if (index > -1) {
				newOrders.splice(index, 1);
				setOrders(newOrders);
			}
			setSelectedOrder(undefined);

			fetchingOrder();
		}
	};

	if (orders.length === 0)
		return (
			<p className='text-center justify-items-center p-5 py-10 text-black'>
				<img
					className='mx-auto  rounded-md overflow-hidden object-center object-none'
					alt='..'
					src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/no-product.png`}
				/>
				<span className='mt-4 text-lg font-medium'>{t('No order request found')}</span>
			</p>
		);

	return (
		<div className='flex flex-wrap'>
			<div className='flex  w-full md:w-8/12'>
				<div className='px-0 p-4 w-full'>
					<div className='grid gord-cols-1 lg:grid-cols-2 gap-x-2 gap-y-2 mt-2'>
						{orders.map((order: IOrder) => (
							<Order
								key={order._id}
								order={order}
								onClick={(order: IOrder) => {
									console.log(order);
									setSelectedOrder(order);
								}}
							/>
						))}
					</div>
				</div>
			</div>

			<div className='flex sticky top-20 bg-white  w-full md:w-4/12'>
				{selectedOrder ? (
					<OrderDetail
						selectedOrder={selectedOrder}
						onOrderUpdateClick={onOrderUpdateClick}
						onRejectOrderClick={onOrderReject}
					/>
				) : (
					<p className='text-center justify-items-center p-5 py-10 text-black'>
						<img
							className='mx-auto  rounded-md overflow-hidden object-center object-none'
							alt='..'
							src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/no-product.png`}
						/>
						<span className='mt-4 text-lg font-medium'>{t('No order selected')}</span>
					</p>
				)}
			</div>
		</div>
	);
};

export default OrderRequest;
