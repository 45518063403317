import { memo } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const libraries: any = ['places'];

const FuGoogleMap = (props: any) => {
  /** GoogleMap component default options */
  const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
  };
  /** size div map element */
  const containerStyle = {
    width: '100%',
    height: '280px',
  };
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBuL1B4s4ZL1ErfUl8Ja0hhto3l3J5GWoI',
    libraries,
  });
  if (loadError) {
    return <h1>loadError</h1>;
  }
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props.center}
      zoom={props.zoom}
      options={defaultMapOptions}
    >
      {props.children}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default memo(FuGoogleMap);
