import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const Error401 = (props: any) => {
	const { t } = useTranslation();
	const { status, title, message } = props;
	const { state }: any = useLocation();
	return (
		<>
			<div className='h-screen w-screen bg-gray-100 flex justify-center content-center flex-wrap'>
				<div className='flex-col border-l border-gray-300 p-2 '>
					<p className='text-5xl font-bold text-gray-700'>
						{status} {state?.status}{' '}
					</p>
					<p className='text-2xl text-gray-700 md:text-3xl font-light leading-normal'>
						{title} {state?.title}
					</p>
					<p className='mb-8'>
						{message} {state?.message}
					</p>

					<a href='/' className='btn-black'>
						{t('Home page')}
					</a>
				</div>
			</div>
		</>
	);
};

export const PageNotFound = () => {
	const { t, i18n } = useTranslation();
	return (
		<div className='flex content-container items-center justify-center   '>
			<div className='p-4 space-y-4'>
				<div className='flex flex-col items-start space-y-3 sm:flex-row sm:space-y-0 sm:items-center sm:space-x-3'>
					<p className='font-semibold text-indigo-800 text-7xl border-r pr-3'>404</p>
					<div className='space-y-0'>
						<h1 id='pageTitle' className='flex items-center space-x-2'>
							<svg
								aria-hidden='true'
								className='w-6 h-6 text-black '
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
								></path>
							</svg>
							<span className='text-xl font-medium text-gray-600 sm:text-2xl dark:text-light'>
								{t('Oops! Page not found.')}
							</span>
						</h1>
						<p className='text-base font-normal text-gray-600 dark:text-gray-300'>
							{t('The page you ara looking for was not found.')}
						</p>
						<p className='text-base font-normal text-gray-600 dark:text-gray-300'>
							{t('You may return to')}{' '}
							<a
								href={`/${i18n.language}`}
								className='text-blue-600 hover:underline dark:text-blue-500'
							>
								{t('Home page')}
							</a>{' '}
							{t('or try using the search form.')}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
