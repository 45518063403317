// import React from 'react';

/**
 * Past this number of days we'll no longer display the
 * day of the week and instead we'll display the date
 * with the month
 *
 * https://codepen.io/mashour/pen/OexRyM?editors=0010
 *
 */
const DATE_WITH_MONTH_THRESHOLD_IN_DAYS: number = 6;

/**
 * Past this number of seconds it's now longer "now" when
 * we're displaying dates
 */
const NOW_THRESHOLD_IN_SECONDS: number = 10;

/**
 * Past this number of hours we'll no longer display "hours
 * ago" and instead we'll display "today"
 */
const TODAY_AT_THRESHOLD_IN_HOURS: number = 12;

/**
 * Date formats for different human-friendly thresholds
 */
//  const FORMATS = {
//    YEARS_AGO: {
//      year: "numeric",
//      month: "short",
//      day: "numeric",
//    },

//    DAYS_AGO: {
//      weekday: "long",
//    },
//  };

// ----------------------------------------------------------------------
// TYPES
// ----------------------------------------------------------------------

/**
 * Supported locales
 */
type Locale = 'en' | 'ar' | 'it';

/**
 * Keys allowed in plural forms
 */
type PluralFormKey = '1' | '2' | '3-10' | 'other';

/**
 * Definition of a phrase's plural form
 */
interface PluralForms {
  '1'?: string;
  '2'?: string;
  '3-10'?: string;
  other: string;
}

/**
 * Translation key/value pairs for supported locales
 */
type Translations = {
  [locale in Locale]: {
    [key: string]: string | PluralForms;
  };
};

/**
 * Representation of a date & time in components
 */
interface DateTimeComponents {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

/**
 * Options when formatting a date
 */
interface DateFormatOptions {
  includeYear?: Boolean;
}

/**
 * A post in our data set
 */
// interface Post {
//   name: string;
//   photo: string;
//   post: string;
//   posted_at: Date;
// }

// ----------------------------------------------------------------------
// CONSTANTS
// ----------------------------------------------------------------------

const MILLISECONDS_TO_SECONDS: number = 0.001;

const SECONDS_IN_YEAR: number = 31557600;

const SECONDS_IN_MONTH: number = 2629800;

const SECONDS_IN_DAY: number = 86400;

const SECONDS_IN_HOUR: number = 3600;

const SECONDS_IN_MINUTE: number = 60;

// ----------------------------------------------------------------------
// i18n
// ----------------------------------------------------------------------

/**
 * Determines localized UI elements and data shown
 */
export let currentLocale: Locale = 'en';

/**
 * Localized UI strings
 */
const translations: Translations = {
  en: {
    "What's New": "What's New",
    at: 'at',
    posted: 'posted',
    'just now': 'just now',
    'today at': 'today at',
    yesterday: 'yesterday',
    'hours ago': {
      '1': '{count} hour ago',
      other: '{count} hours ago',
    },
    'minutes ago': {
      '1': '{count} minute ago',
      other: '{count} minutes ago',
    },
    'seconds ago': {
      '1': '{count} second ago',
      other: '{count} seconds ago',
    },
  },

  it: {
    "What's New": "What's New",
    at: 'alle',
    posted: 'posted',
    'just now': 'just now',
    'today at': 'today at',
    yesterday: 'Ieri',
    'hours ago': {
      '1': '{count} ora fa',
      other: '{count} hours ago',
    },
    'minutes ago': {
      '1': '{count} minute ago',
      other: '{count} minutes ago',
    },
    'seconds ago': {
      '1': '{count} second ago',
      other: '{count} seconds ago',
    },
  },

  ar: {
    "What's New": 'ما الجديد',
    posted: 'نشر/ت',
    'just now': 'الآن',
    'today at': 'اليوم الساعة',
    yesterday: 'الأمس',
    'hours ago': {
      '1': 'من ساعة',
      '2': 'من ساعتان',
      '3-10': 'من {count} ساعات',
      other: 'من {count} ساعة',
    },
    'minutes ago': {
      '1': 'من دقيقة',
      '2': 'من دقيقتين',
      '3-10': 'من {count} دقائق',
      other: 'من {count} دقيقة',
    },
    'seconds ago': {
      '1': 'من ثانية',
      '2': 'من ثانيتين',
      '3-10': 'من {count} ثواني',
      other: 'من {count} ثانية',
    },
  },
};

/**
 * Retrieve a localized string by key
 */
function __(key: string, locale: Locale = currentLocale): string {
  return translations[locale][key] as string;
}

/**
 * Retrieve a plural form for a phrase by the phrase's key
 */
function _p(key: string, count: number, locale: Locale = currentLocale): string {
  const forms = translations[locale][key] as PluralForms;

  const { other, ...definiteForms } = forms;

  const sortedDefiniteForms = Object.keys(definiteForms).sort();

  let hit: string = '';

  for (let i: number = 0; i < sortedDefiniteForms.length; i += 1) {
    const currentFormKey: PluralFormKey = sortedDefiniteForms[i] as PluralFormKey;

    if (currentFormKey.includes('-')) {
      const [lowerLimit, upperLimit] = currentFormKey.split('-').map((s) => parseInt(s.trim(), 10));

      if (lowerLimit <= count && count <= upperLimit) {
        hit = forms[currentFormKey]!;
      }
    } else {
      if (count === parseInt(currentFormKey, 10)) {
        hit = forms[currentFormKey]!;
      }
    }
  }

  if (hit === '') {
    hit = other || key;
  }

  const normalized: string = hit.replace('{count}', count.toString());

  return locale.toLowerCase().startsWith('ar')
    ? // Convert Arabic numerals to Indian numerals
      normalized.replace(/\d/g, (d) => '٠١٢٣٤٥٦٧٨٩'[parseInt(d, 10)])
    : normalized;
}

// ----------------------------------------------------------------------
// HUMAN-FRIENDLY DATES
// ----------------------------------------------------------------------

/**
 * Retrieve a human-friendly date string relative to now and in the
 * current locale e.g. "two minutes ago"
 */
function humanFriendlyDate(date: Date, locale: Locale = 'en'): string {
  currentLocale = locale;
  const unixTimestamp: number = millisecondsToSeconds(date.valueOf());

  const now: number = millisecondsToSeconds(Date.now());

  const diffComponents: DateTimeComponents = getDateTimeComponents(now - unixTimestamp);

  const { years, months, days, hours, minutes, seconds } = diffComponents;

  if (years > 0) {
    return formatLocalizedDateWithOrdinal(currentLocale, date, { includeYear: true });
  }

  if (months > 0 || days > DATE_WITH_MONTH_THRESHOLD_IN_DAYS) {
    return formatLocalizedDateWithOrdinal(currentLocale, date, { includeYear: false });
  }

  if (days > 1) {
    return date.toLocaleDateString(currentLocale, { weekday: 'long' , hour:'numeric', minute:'2-digit'});
  }

  if (days === 1) {
    return __('yesterday') + ' ' + __('at') + ' ' + hours + ':' + minutes;
  }

  if (hours > TODAY_AT_THRESHOLD_IN_HOURS) {
    return (
      __('today at') +
      ' ' +
      date.toLocaleTimeString(currentLocale, { hour: 'numeric', minute: '2-digit' })
    );
  }

  if (hours > 0) {
    return _p('hours ago', hours);
  }

  if (minutes > 0) {
    return _p('minutes ago', minutes);
  }

  if (seconds > NOW_THRESHOLD_IN_SECONDS) {
    return _p('seconds ago', seconds);
  }

  return __('just now');
}

/**
 * For English, format a date with given options, adding an ordinal
 * e.g. "May 1st, 1992" (note the "1st"). For non-English locales,
 * format a date with given options (and no ordinal);
 */
function formatLocalizedDateWithOrdinal(
  locale: Locale,
  date: Date,
  options: DateFormatOptions = { includeYear: false }
) {
  if (locale.toLowerCase().startsWith('en')) {
    return formatEnglishDateWithOrdinal(date, options);
  }

  return formatNonEnglishDate(locale, date, options);
}

/**
 * Format an English date with it ordinal e.g. "May 1st, 1992"
 */
function formatEnglishDateWithOrdinal(date: Date, { includeYear }: DateFormatOptions): string {
  const month: string = date.toLocaleDateString('en', { month: 'long' });

  const day: string = getOrdinal(date.getDate());

  let formatted: string = `${month} ${day}`;

  if (includeYear) {
    formatted += `, ${date.getFullYear()}`;
  }

  return formatted;
}

/**
 * Format a non-English date
 */
function formatNonEnglishDate(
  locale: Locale,
  date: Date,
  { includeYear }: DateFormatOptions
): string {
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long' };

  if (includeYear) {
    options.year = 'numeric';
  }

  return date.toLocaleDateString(locale, options);
}

/**
 * Retrieve an English ordinal for a number, e.g. "2nd" for 2
 */
function getOrdinal(n: number): string {
  // From https://community.shopify.com/c/Shopify-Design/Ordinal-Number-in-javascript-1st-2nd-3rd-4th/m-p/72156
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

/**
 * Convert milliseconds to seconds
 */
function millisecondsToSeconds(milliseconds: number): number {
  return Math.floor(milliseconds * MILLISECONDS_TO_SECONDS);
}

/**
 * Break up a unix timestamp into its date & time components
 */
function getDateTimeComponents(timestamp: number): DateTimeComponents {
  const components: DateTimeComponents = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  let remaining: number = timestamp;

  // years
  components.years = Math.floor(remaining / SECONDS_IN_YEAR);

  remaining -= components.years * SECONDS_IN_YEAR;

  // months
  components.months = Math.floor(remaining / SECONDS_IN_MONTH);

  remaining -= components.months * SECONDS_IN_MONTH;

  // days
  components.days = Math.floor(remaining / SECONDS_IN_DAY);

  remaining -= components.days * SECONDS_IN_DAY;

  // hours
  components.hours = Math.floor(remaining / SECONDS_IN_HOUR);

  remaining -= components.hours * SECONDS_IN_HOUR;

  // minutes
  components.minutes = Math.floor(remaining / SECONDS_IN_MINUTE);

  remaining -= components.minutes * SECONDS_IN_MINUTE;

  // seconds
  components.seconds = remaining;

  return components;
}

export default humanFriendlyDate;
