import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SiGoogletagmanager } from 'react-icons/si';
import { useParams, Redirect } from 'react-router-dom';
import { useAppState } from '../../../hooks/adsHooks';
import serverApi from '../../../api/serverApi';
import OrderManagerStatistics from './OrderStatistics';
import OrderRequest from './OrderBlock/OrderRequest';

const OrderManager: React.FC = () => {
	const { t } = useTranslation();
	const { auth } = useAppState();
	const { shopper }: any = useParams();
	const [activeTab, setActiveTab] = useState<number>(0);
	const [shopIsOpen, setShopIsOpen] = useState<boolean>(false);

	/** Set initial state open or close shop  */
	useEffect(() => {
		let mounted = true;
		const fetch = async () => {
			if (mounted) {
				const shop = await serverApi.shopMe();
				if (shop.status === 'success') {
					setShopIsOpen(!shop.shop.isClosed);
				}
			}
		};
		fetch();

		return () => {
			mounted = false;
		};
	}, []);

	const onSwitchOnlineOffLineClick = async () => {
		console.log(shopIsOpen);
		/** prevent the set state not ye apply when clicked */
		const shp = !shopIsOpen;

		const updateShop = await serverApi.ShopUpdate({
			_id: shopper,
			// set closed = true if the shop is offline (shopIsOpen = false)
			isClosed: !shp,
		});
		console.log(updateShop);
		setShopIsOpen(!shopIsOpen);
	};

	if (!auth.token || !shopper) {
		return (
			<Redirect
				to={{
					pathname: '/error',
					state: {
						status: 401,
						title: `${t('Unauthorized access')}`,
						message: `${t('To access the order management you must be registered')}`,
					},
				}}
			/>
		);
	}

	return (
		<div className='max-w-screen-xl mx-auto  antialiased py-10 px-8 bg-white '>
			<div className='flex w-full'>
				<div className='flex flex-1'>
					<div className='inline-flex justify-center items-center space-x-3'>
						<SiGoogletagmanager size={28} style={{ color: '#213b73' }} />
						<h1 className='text-base sm:text-xl font-semibold items-center'>
							{t('Order Manager')}
						</h1>
					</div>
				</div>

				<div className='inline-flex justify-end items-center font-medium mr-0 sm:mr-4 p-1 rounded-md bg-gray-100 transition duration-200 ease-in'>
					<button
						type='button'
						onClick={onSwitchOnlineOffLineClick}
						className={`text-sm py-1 px-4 ${
							shopIsOpen ? 'bg-white font-semibold' : ''
						}  rounded-md text-black  focus:outline-none transition duration-300 ease-linear`}
					>
						{t('On line')}
					</button>
					<button
						type='button'
						onClick={onSwitchOnlineOffLineClick}
						className={`text-sm py-1 px-4 ${
							!shopIsOpen ? 'bg-white font-semibold' : ''
						}  rounded-md text-black  focus:outline-none transition duration-500 ease-out`}
					>
						{t('Off line')}
					</button>
				</div>
			</div>

			<div className='flex items-center font-thin text-gray-700 text-sm border-b mt-5'>
				<div
					onClick={() => setActiveTab(0)}
					className={`font-normal ${
						activeTab === 0 ? 'text-gray-800 bg-gray-100 font-medium' : 'text-gray-500'
					}  py-1 px-2 rounded-sm cursor-pointer hover:bg-gray-100 `}
				>
					{t('Orders')}
				</div>
				<div className='p-1 flex items-center'>
					<div
						onClick={() => setActiveTab(1)}
						className={`font-normal ${
							activeTab === 1 ? 'text-gray-700 bg-gray-100 font-medium' : 'text-gray-500'
						}  py-1 px-2 rounded-sm cursor-pointer hover:bg-gray-100 `}
					>
						<span className='p-0'>{t('Statistics')}</span>
					</div>
				</div>
			</div>

			{activeTab === 0 && <OrderRequest />}
			{activeTab === 1 && <OrderManagerStatistics />}
		</div>
	);
};

export default OrderManager;
