import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import Loader from '../../../../Components/loader';
import { useHistory } from 'react-router-dom';
import Stepper, { BackPage } from '../stepper';
import AddressCoord, { IGoogleCoord } from '../../../../utils/addressToCoord';
import serverApi from '../../../../api/serverApi';
import { useAppState } from '../../../../hooks/adsHooks';
import { useTranslation } from 'react-i18next';

/** stripe */
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const ConfirmationStep = ({ setForm, formData, navigation }: any) => {
	const { t } = useTranslation();
	const { auth } = useAppState();
	const history = useHistory();
	const { line_items, location } = formData;
	const { previous } = navigation;
	const [selectedPlan, setSelectedPlan] = useState<string>('option_0');
	const [amountTotal, setAmountTotal] = useState<number>(0);
	const [serviceTotal, setServiceTotal] = useState<number>(0);

	const [loading, setLoading] = useState<boolean>(false);

	const handleChangeOption = (e: any) => {
		console.log(e.target);
		setSelectedPlan(e.target.id);
		switch (e.target.id) {
			case 'option_0':
				return setAmountTotal(0);
			case 'option_1':
				return setAmountTotal(4);
			case 'option_2':
				return setAmountTotal(12);
			case 'option_3':
				return setAmountTotal(35);
			default:
				return setAmountTotal(0);
		}
	};

	console.log(formData);

	useEffect(() => {
		if (line_items.length > 1) {
			let subTotal = 0;
			subTotal = line_items.reduce((prev: any, current: any) => prev.value + current.value);
			setServiceTotal(subTotal);
		} else if (line_items.length === 1) {
			setServiceTotal(line_items[0].value);
		}

		return () => {
			setLoading(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const publishHandle = async (e: any) => {
		setLoading(true);
		e.preventDefault();
		let coords: IGoogleCoord = { lat: 0, lng: 0 };
		let data = { ...formData, user: auth.user._id };

		delete data.enableNext;

		data.price.amount = +data.price.amount;
		if (data.price.unit === '') {
			data.price.unit = null;
		}
		if (location?.address) {
			const completeAddress =
				location?.address?.zipCode +
				'+' +
				location?.address?.street +
				',' +
				location?.address?.city;

			coords = await AddressCoord(completeAddress);
			if (coords.lat > 0) {
				data = {
					...data,
					location: { ...data.location, coordinates: [coords.lat, coords.lng] },
				};
			}
		}
		let planItem = {
			price_data: {
				currency: 'eur',
				product_data: {
					name: 'Annuncio in evidenza',
				},
				unit_amount: 400,
			},
			quantity: 1,
		};
		let carts = [];
		/** Plan attribute price */
		if (selectedPlan === 'option_1') {
			data.highlighted = true;
			planItem.price_data.product_data.name = 'Annuncio in evidenza';
			planItem.price_data.unit_amount = 4 * 100;
			carts.push(planItem);
		} else if (selectedPlan === 'option_2') {
			data.sideBar = true;
			planItem.price_data.product_data.name = 'Annuncio in Sidebar';
			planItem.price_data.unit_amount = 12 * 100;
			carts.push(planItem);
		} else if (selectedPlan === 'option_3') {
			data.featuredAd = true;
			data.highlighted = true;
			planItem.price_data.product_data.name = 'Annuncio in vetrina';
			planItem.price_data.unit_amount = 35 * 100;
			carts.push(planItem);
		}

		if (line_items?.length > 0) {
			line_items.forEach((el: any) => {
				carts.push({
					price_data: {
						currency: 'eur',
						product_data: {
							name: el.id,
						},
						unit_amount: el.value * 100,
					},
					quantity: 1,
				});
				console.log(el);
			});
		}
		console.log(carts);
		if (data?.contactInfo?.brandThumb?.length > 0) {
			data.contactInfo.brandThumb = data?.contactInfo?.brandThumb[0];
		}
		console.log(data);
		/** Send save a new Ad */
		const successAddingPost = await serverApi.postNewAd(data);
		console.log(successAddingPost);
		if (successAddingPost.status === 'success') {
			const stripe = await stripePromise;
			if (carts.length > 0) {
				const response = await serverApi.checkOut(carts, successAddingPost.ad._id);
				// setLoading(false);
				console.log(response);
				const result = await stripe!.redirectToCheckout({
					sessionId: response.id,
				});
				if (result.error) {
					console.log(result.error);
				}
			} else {
				history.push({ pathname: `/check-out-result/${successAddingPost.ad._id}` });
			}
		}
	};

	if (loading) {
		return <Loader />;
	}
	return (
		<React.Fragment>
			<Stepper step={4} />

			<div className='p-1 mt-2 py-10'>
				<h1 className='  text-xl tracking-tight  font-semibold text-gray-700 '>
					{t('Increase your chances of selling faster')}.
				</h1>

				<form onChange={handleChangeOption} className='flex items-center justify-center mt-10 '>
					<div className='grid grid-cols-1 gap-2 w-full'>
						<div>
							<input
								className='hidden'
								id='option_0'
								type='radio'
								name='radio'
								checked={selectedPlan === 'option_0'}
								onChange={() => {}}
							/>
							<label
								className='flex flex-col p-4 border-2 border-gray-400 cursor-pointer'
								htmlFor='option_0'
							>
								<span className='text-xs font-semibold uppercase'>{t('Free Ad')}</span>
								<span className='text-xl font-bold mt-2'>€ 0,00</span>
								<div className='mt-2'>
									<h3 className='font-semibold leading-relaxed'>{t('Post your ad for free')}</h3>
									<ul className=' mt-2'>
										<li>
											-{' '}
											{t(
												'Your ad will be displayed within the category chosen for the duration of 1 month'
											)}
										</li>
									</ul>
								</div>
							</label>
						</div>

						<div>
							<input
								className='hidden'
								id='option_1'
								type='radio'
								name='radio'
								checked={selectedPlan === 'option_1'}
								onChange={() => {}}
							/>
							<label
								className='flex flex-col p-4 border-2 border-gray-400 cursor-pointer'
								htmlFor='option_1'
							>
								<span className='text-xs font-semibold uppercase'>{t('Featured ad')}</span>
								<span className='text-xl font-bold mt-2'>€ 4,00</span>
								<div className='mt-2'>
									<h3 className='font-semibold leading-relaxed'>
										{t('Diversify from others, increase visibility')}
									</h3>
									<ul className=' mt-2'>
										<li>
											-{' '}
											{t(
												'Your announcement will be highlighted in relation to the others, in the list of results'
											)}{' '}
										</li>
									</ul>
								</div>
							</label>
						</div>
						<div>
							<input
								className='hidden'
								id='option_2'
								type='radio'
								name='radio'
								checked={selectedPlan === 'option_2'}
								onChange={() => {}}
							/>
							<label
								className='flex flex-col p-4 border-2 border-gray-400 cursor-pointer'
								htmlFor='option_2'
							>
								<span className='text-xs font-semibold uppercase'>{t('Ad In Side bar')}</span>
								<span className='text-xl font-bold mt-2'>€ 12,00</span>
								<div className='mt-2'>
									<h3 className='font-semibold leading-relaxed'>{t('Your ad always visible')}</h3>
									<ul className=' mt-2'>
										<li>
											-{' '}
											{t(
												'Your ad will appear in the left navigation bar, under the filters search for each relevant search'
											)}
										</li>
										<li className='mt-1'>
											- {t('In category results, your ad will appear on top of the others')}.
										</li>
									</ul>
								</div>
							</label>
						</div>
						<div>
							<input
								className='hidden'
								id='option_3'
								type='radio'
								name='radio'
								checked={selectedPlan === 'option_3'}
								onChange={() => {}}
							/>
							<label
								className='flex flex-col p-4 border-2 border-gray-400 cursor-pointer'
								htmlFor='option_3'
							>
								<span className='text-xs font-semibold uppercase'>{t('Featured Ad')}</span>
								<span className='text-xl font-bold mt-2'>€ 35,00</span>
								<div className='mt-2'>
									<h3 className='font-semibold leading-relaxed'>{t('Maximum visibility')}</h3>
									<ul className=' mt-2'>
										<li>
											- {t('Your ad will be featured in the')} <b>{t('first page')}</b>
										</li>
										<li className='mt-1'>- {t('Your ad will stand out from the others')}</li>
										<li className='mt-1'>
											- {t('In category results, your ad will appear on top of the others')}.
										</li>
									</ul>
								</div>
							</label>
						</div>
					</div>
				</form>
				<div className='text-right  mt-2'>
					{t('Total paid services')}{' '}
					<span className='font-semibold'>
						<NumberFormat
							value={serviceTotal}
							thousandSeparator=''
							fixedDecimalScale={true}
							decimalScale={2}
							displayType='text'
							prefix={'€ '}
						/>
					</span>
				</div>
				{/* <div className='text-right'>
          <input
            onChange={onCouponHandleChange}
            placeholder='coupon'
            className='uppercase p-1 text-right focus:ring-black focus:border-black border-2 h-10 outline-none focus:outline-none   border-gray-200  '
          />
        </div> */}
				<div className='text-right font-semibold text-xl mt-2'>
					{t('Total')}{' '}
					<NumberFormat
						value={amountTotal + serviceTotal}
						thousandSeparator=''
						fixedDecimalScale={true}
						decimalScale={2}
						displayType='text'
						prefix={'€ '}
					/>
					{/* {amountTotal + serviceTotal},00 */}
				</div>
			</div>

			<div className='py-5 flex justify-items-start items-center space-x-2 '>
				<BackPage previous={previous} title={t('Previous')} />
				<button
					onClick={publishHandle}
					style={{ border: '1px solid rgba(27,31,35,.15)' }}
					className=' h-9 pl-3 pr-3 flex items-center rounded-sm bg-black text-white
          hover:shadow-md hover:bg-gray-700 focus:outline-none active:outline-none'
				>
					<span className='font-medium'>{t('Publish')}</span>
				</button>
			</div>
		</React.Fragment>
	);
};

export default ConfirmationStep;
