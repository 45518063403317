import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import * as authAction from '../../rdx/actions/auth';
import { IPasswordChange } from '../../Interfaces/dataModel';
import serverApi from '../../api/serverApi';
import FuAlert from '../../Components/fu-alert';
import { useTranslation } from 'react-i18next';

const PasswordChange: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	/** form-hook initialization  */
	const {
		register,
		formState: { errors },

		handleSubmit,
	} = useForm({ mode: 'onBlur', reValidateMode: 'onChange', criteriaMode: 'all' });

	const onSubmit = async (form: any) => {
		const pwd: IPasswordChange = {
			currentPassword: form.currentPassword,
			password: form.password,
			passwordConfirm: form.passwordConfirm,
		};
		try {
			if (pwd.password !== pwd.passwordConfirm) {
				throw new Error(t('The passwords entered do not match'));
			}
			const { user, token } = await serverApi.passwordChange(pwd);
			console.log(user, token);
			dispatch(authAction.updateToken(user, token));
			toast.dark(t('Saved data'));
		} catch (e) {
			console.log(e.message);
			toast.dark(e.message, { autoClose: false, closeOnClick: true });
		}
	};

	return (
		<div className='password-change mt-4'>
			<FuAlert alertType='info' message={`<b>${t('Note')}:</b> ${t('All fields are required')}`} />

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-2 mt-4 '>
					<div className='col-span-2'>
						<label className='block text-sm'>
							<span className='text-gray-700 font-semibold'>{t('Current password')}*</span>
							<input
								type='password'
								className='input-black'
								id='currentPasswordId'
								{...register('currentPassword', { required: true })}
							/>
							{errors.currentPassword && (
								<span className='text-red-500'>{t('Enter the current password')}</span>
							)}
						</label>
					</div>

					<div>
						<label className='block text-sm'>
							<span className='text-gray-700 font-semibold'>{t('New password')}</span>
							<input
								type='password'
								className='input-black'
								id='passwordId'
								{...register('password', { required: true })}
							/>
							{errors.password && (
								<span className='text-red-500'>{t('New password required')}</span>
							)}
						</label>
					</div>

					<div>
						<label className='block text-sm'>
							<span className='text-gray-700 font-semibold'>{t('Repeat password')}</span>
							<input
								type='password'
								className='input-black'
								id='passwordConfirmId'
								{...register('passwordConfirm', { required: true })}
							/>
							{errors.passwordConfirm && (
								<span className='text-red-500'>{t('The password must be repeated')}</span>
							)}
						</label>
					</div>
				</div>

				<button type='submit' className='btn-black'>
					{t('Save')}
				</button>
			</form>
		</div>
	);
};

export default PasswordChange;
