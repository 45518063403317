import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import ShoppingCardFoodDrawer from '../UI/Drawers/ShoppingCardFoodDrawer';

import LoginDrawer from '../UI/Drawers/LoginDrawer';
import MenuHeaderNavDrawer from '../UI/Drawers/MenuHeaderNavDrawer';
import MenuProfileDrawer from '../UI/Drawers/MenuProfileDrawer';

import FooterContent from './Footer';
import TopHeaderBar from './Header';

import { useAppState } from '../hooks/adsHooks';

// redux stuff to set reducer
import { useDispatch } from 'react-redux';
import * as authAction from '../rdx/actions/auth';

import * as categoryAction from '../rdx/actions/category.actions';

const ApplicationLayout = (props: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { auth, app } = useAppState();

	const [openDrawerLogin, setOpenDrawerLogin] = useState<boolean>(false);

	const [openDrawerProfile, setOpenDrawerProfile] = useState<boolean>(false);

	const [openDrawerShoppingCart, setOpenDrawerShoppingCart] = useState<boolean>(false);

	const [openDrawerMobile, setOpenDrawerMobile] = useState<boolean>(false);

	const onLoginClickHandle = () => {
		setOpenDrawerLogin(!openDrawerLogin);
	};
	const onOpenDrawerProfileClick = () => {
		setOpenDrawerProfile(!openDrawerProfile);
	};
	const onShoppingCartClick = () => {
		setOpenDrawerShoppingCart(!openDrawerShoppingCart);
	};

	const onHamburgerMenuClick = () => {
		setOpenDrawerMobile(!openDrawerMobile);
	};

	const onPostNewAd = () => {
		history.push(`/${app.lang}/ads`);
	};

	useEffect(() => {
		console.log('passing here');
		dispatch(categoryAction.loadingCategories());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (auth.message !== '') {
			toast.dark(t(auth.message), { position: 'top-center' });
			dispatch(authAction.cleanMessage());
		}
	}, [auth.message, dispatch, t]);

	return (
		<>
			{/* {!lang ? <html lang={navigator.language.split('-')[0]} /> : <html lang={lang} />} */}
			<div className='min-h-screen flex flex-col'>
				<TopHeaderBar
					postNewAd={onPostNewAd}
					onLogin={onLoginClickHandle}
					onProfileClick={onOpenDrawerProfileClick}
					onShoppingCartClick={onShoppingCartClick}
					onHaumburgerClick={onHamburgerMenuClick}
				/>

				<main className='flex-grow'>
					<div>{props.children}</div>
					<LoginDrawer isOpen={openDrawerLogin} closeHandle={onLoginClickHandle} />

					<MenuProfileDrawer isOpen={openDrawerProfile} closeHandle={onOpenDrawerProfileClick} />
					<ShoppingCardFoodDrawer
						isOpen={openDrawerShoppingCart}
						closeHandle={onShoppingCartClick}
					/>
					<MenuHeaderNavDrawer
						isOpen={openDrawerMobile}
						closeHandle={onHamburgerMenuClick}
						onLogin={onLoginClickHandle}
					/>
				</main>

				<FooterContent />
			</div>
		</>
	);
};

export default ApplicationLayout;
