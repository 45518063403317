import React from 'react';
import { useTranslation } from 'react-i18next';
import Stepper, { BackPage, NextPage } from '../stepper';
import GalleryZone from '../galleryZone';

const GalleryStep = ({ setForm, formData, navigation }: any) => {
	const { t } = useTranslation();
	const { previous, next } = navigation;

	const props = { setForm, formData };

	return (
		<React.Fragment>
			<Stepper step={2} />

			<div className='p-1 mt-2 py-10'>
				<h1 className='  text-xl tracking-tight  font-semibold text-gray-700 '>
					{t('Choose the best images to upload. A picture is worth a thousand words')}
				</h1>
				<p className='mb-2 mt-5 text-gray-800'>
					{t(
						"Make sure you take quality photos with a white background. If there are imperfections, do it to see. Do not use other people's photos in violation of copyright"
					)}{' '}
					<b>{t('The first image will be the main one')}</b>
				</p>

				<GalleryZone
					{...props}
					onChange={(e: any) => setForm({ target: { name: 'gallery', value: e } })}
				/>
			</div>
			<div className='py-5 flex justify-items-start items-center space-x-2 '>
				<BackPage previous={previous} title={t('Previous')} />
				<NextPage next={next} title={t('Next')} />
			</div>
		</React.Fragment>
	);
};

export default GalleryStep;
