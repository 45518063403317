import React from 'react';
// test redux counter
// import { useDispatch } from 'react-redux';
// import * as cartActions from '../../../rdx/actions/cart';
import { Item } from '../../../Interfaces/shop.interface';

type ItemArticleProp = {
	items: Item[];
	onItemClick: (product: Item) => void;
};
const ShopItem: React.FC<ItemArticleProp> = ({ items, onItemClick }) => {
	// const dispatch = useDispatch();
	// const addToCart = (product: Item) => {
	//   dispatch(
	//     cartActions.addToCart({
	//       code: product.code,
	//       id: product._id,
	//       quantity: 1,
	//       price: product.price.amount,
	//       name: product.name,
	//       sum: product.price.amount,
	//       currency: product.price.currency,
	//       thumb: product.thumb,
	//     })
	//   );
	// };

	return (
		<div className='grid grid-cols-1 lg:grid-cols-2 gap-2 mt-2'>
			{items.map((itm: Item, index: number) => (
				<button
					key={index}
					onClick={() => onItemClick(itm)}
					className='text-left w-full border-2 border-gray-300
                    focus:outline-none   hover:border-black'
				>
					<div className='flex  w-full'>
						{itm.thumb && (
							<img
								className='w-1/5 object-cover overflow-hidden rounded-l-sm '
								src={itm.thumb}
								// src='https://tailwindcss.com/_next/static/media/jucy-beef-burger.346917d5ccb0b705233bb1665ffb63f6.jpg'
								alt='...'
							/>
						)}
						<div className='w-full flex flex-col p-2'>
							<div className='mx-2 pb-0'>
								<h3 className='font-semibold mb-1 text-gray-600'>
									<span className='font-bold'>{itm.code}</span> {itm.name}
								</h3>
								<div className='text-sm flex items-center mb-2 line-clamp-2'>{itm.description}</div>
								<span className='text-black font-semibold'>
									{itm.price.currency} {itm.price.amount}
								</span>
							</div>
							{/* <div className='bg-indigo-200 p-1 px-2 flex cursor-pointer items-center justify-between transition hover:bg-indigo-100'>
                          <button onClick={handleIncrement}> Acquista</button>
                          <i className='fas fa-chevron-right'></i>
                        </div> */}
						</div>
					</div>
				</button>
			))}
		</div>
	);
};

export default ShopItem;
