import React, { useEffect, useState } from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import { Redirect } from 'react-router-dom';

import { useAppState } from '../hooks/adsHooks';
import { customStyles } from '../utils/utility';

import {
	carBrands,
	adNewOrUsed,
	Fuels,
	carDoors,
	Transmission,
	UnitOptionTypes,
	CurrenciesOptions,
} from '../api/localData';

import { useTranslation } from 'react-i18next';

import { ErrorMessage, CommentMessage } from '../Components/messages';
import FormItem from '../UI/classFied/Forms/formItem';
import Stepper, { NextPage, BackPage } from '../UI/classFied/Forms/stepper';

import { BrandImage } from '../UI/classFied/Forms/galleryZone';
import CategoryStep from '../UI/classFied/Forms/Steps/category.step';
import GalleryStep from '../UI/classFied/Forms/Steps/gallery.step';
import SummaryStep from '../UI/classFied/Forms/Steps/summary.step';
import ConfirmationStep from '../UI/classFied/Forms/Steps/confirmation.step';
import serverApi from '../api/serverApi';

const CarContent = ({ setForm, formData }: any) => {
	const { t } = useTranslation();
	const { VehicleType, Brand, Model } = formData.ad;

	const [vehicleTypeObj, setVehicleTypeObj] = useState<any>('');
	const [vehicleTypeError, setErrorVehicleType] = useState<boolean>(false);

	const [brandObj, setBrandObj] = useState<any>('');
	const [brandError, setBrandError] = useState<boolean>(false);

	const [modelError, setModelError] = useState<boolean>(false);

	const [fuelObj, setFuelObj] = useState<any>('');
	const [doorObj, setDoorObj] = useState<any>('');
	const [transmissionObj, setTransmissionObj] = useState<any>('');

	useEffect(() => {
		if (VehicleType) {
			const item = adNewOrUsed(t).find((typ: any) => typ.value === VehicleType);
			console.log(item);
			setVehicleTypeObj(item);
		}
		if (Brand) {
			const item = carBrands.find((brand: any) => brand.value === Brand);
			setBrandObj(item);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			formData.ad.Brand !== undefined &&
			formData.ad.Model !== undefined &&
			formData.ad.VehicleType !== undefined &&
			!formData.enableNext
		) {
			setForm({ target: { name: 'enableNext', value: true } });
		}
	}, [setForm, formData]);

	const handleTitle = () => {
		let { Engine, Model } = formData.ad;
		if (Engine === undefined) {
			Engine = '';
		}
		if (Model === undefined) {
			Model = '';
		}

		const title = formData.ad.Brand + ' ' + Model + ' ' + Engine;
		setForm({
			target: {
				name: 'title',
				value: title.trim(),
			},
		});
	};

	return (
		<div>
			<div className='mt-1 -mx-3 md:flex mb-2'>
				<div className='md:w-full px-3'>
					<FormItem
						required={true}
						label={t('Vehicle type')}
						type='select-dropdown'
						name='ad.VehicleType'
						styles={customStyles}
						options={adNewOrUsed(t)}
						onChange={(e: any) => {
							setForm({ target: { name: 'ad.VehicleType', value: e.value } });
							setVehicleTypeObj(e);
						}}
						value={vehicleTypeObj}
						onBlur={() => {
							if (!vehicleTypeObj) {
								setErrorVehicleType(true);
							} else {
								setErrorVehicleType(false);
							}
						}}
					/>
					{vehicleTypeError && <ErrorMessage message={t('Select the vehicle type')} />}
				</div>
			</div>

			<div className='mt-1 -mx-3 md:flex mb-2'>
				<div className='md:w-full px-3'>
					<FormItem
						label={t('Brand')}
						type='select-dropdown'
						required={true}
						fieldname='ad.Brand'
						name='ad.Brand'
						styles={customStyles}
						options={carBrands}
						onChange={(e: any) => {
							setForm({ target: { name: 'ad.Brand', value: e.value } });
							setBrandObj(e);
						}}
						value={brandObj}
						onBlur={() => {
							if (!brandObj) {
								setBrandError(true);
							} else {
								setBrandError(false);
							}
							handleTitle();
						}}
					/>
					{brandError && <ErrorMessage message={t('Select the vehicle brand')} />}
				</div>
			</div>

			<div className='-mx-3 md:flex mb-2'>
				<div className='md:w-full px-3'>
					<FormItem
						required={true}
						label={t('Model')}
						fieldname='ad.Model'
						name='ad.Model'
						type='text'
						placeholder='Ex A4 Station Wagon 1.8 Turbo diesel'
						// onChange={(e: any) => {
						//   setForm({ target: { name: 'ad.Model', value: e.target.value } });

						// }}
						onChange={setForm}
						value={formData.ad.Model || ''}
						onBlur={() => {
							if (!Model) {
								setModelError(true);
							} else {
								setModelError(false);
							}
							handleTitle();
						}}
					/>

					{modelError && <ErrorMessage message={t('Enter the vehicle model')} />}
				</div>
			</div>

			<div className='-mx-3 md:flex mb-2'>
				<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
					<FormItem
						label='Km'
						fieldname='ad.Km'
						name='ad.Km'
						type='text'
						onChange={setForm}
						value={formData.ad?.Km || ''}
					/>
				</div>
				<div className='md:w-1/2 px-3'>
					<FormItem
						label='Cv'
						fieldname='ad.Cv'
						name='ad.Cv'
						type='text'
						onChange={setForm}
						value={formData.ad?.Cv || ''}
					/>
				</div>
			</div>

			<div className='-mx-3 md:flex mb-2'>
				<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
					<FormItem
						type='select-dropdown'
						label={t('Door')}
						fieldname='ad.Door'
						name='ad.Door'
						styles={customStyles}
						options={carDoors}
						placeholder='Select'
						value={doorObj}
						onChange={(e: any) => {
							setForm({ target: { name: 'ad.Door', value: e.value } });
							setDoorObj(e);
						}}
					/>
				</div>
				<div className='md:w-1/2 px-3'>
					<FormItem
						type='select-dropdown'
						label={t('Fuel type')}
						fieldname='ad.Fuel'
						name='ad.Fuel'
						styles={customStyles}
						placeholder={t('Select')}
						options={Fuels(t)}
						value={fuelObj}
						onChange={(e: any) => {
							setForm({ target: { name: 'ad.Fuel', value: e.value } });
							setFuelObj(e);
						}}
					/>
				</div>
			</div>

			<div className='-mx-3 md:flex mb-2'>
				<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
					<FormItem
						type='text'
						label={t('Engine')}
						fieldname='ad.Engine'
						name='ad.Engine'
						value={formData.ad.Engine || ''}
						onChange={setForm}
						placeholder={`${t('Example')} 2.8 Turbo`}
						onBlur={() => handleTitle()}
					/>
				</div>
				<div className='md:w-1/2 px-3'>
					<FormItem
						type='select-dropdown'
						label={t('Transmission')}
						fieldname='ad.Transmission'
						name='ad.Transmission'
						styles={customStyles}
						placeholder={t('Select')}
						options={Transmission(t)}
						value={transmissionObj}
						onChange={(e: any) => {
							setForm({ target: { name: 'ad.Transmission', value: e.value } });
							setTransmissionObj(e);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

const Motorcycle = ({ setForm, formData }: any) => {
	const { t } = useTranslation();
	const { VehicleType, Brand } = formData.ad;

	const [vehicleTypeObj, setVehicleTypeObj] = useState<any>('');
	const [vehicleTypeError, setErrorVehicleType] = useState<boolean>(false);

	const [brandObj, setBrandObj] = useState<any>('');
	const [brandError, setBrandError] = useState<boolean>(false);

	const [brands, setBrands] = useState<any[]>([]);

	useEffect(() => {
		const fetch = async () => {
			const data = await serverApi.motorCycleBrand();
			if (data.status === 'success') {
				setBrands(data.brands);

				if (Brand) {
					const item = data.brands.find((brand: any) => brand.value === Brand);
					setBrandObj(item);
				}
			}
		};
		fetch();
		if (VehicleType) {
			const item = adNewOrUsed(t).find((typ: any) => typ.value === VehicleType);
			console.log(item);
			setVehicleTypeObj(item);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className='mt-1 -mx-3 md:flex mb-2'>
				<div className='md:w-full px-3'>
					<FormItem
						required={true}
						label={t('Vehicle type')}
						type='select-dropdown'
						name='ad.VehicleType'
						styles={customStyles}
						options={adNewOrUsed(t)}
						onChange={(e: any) => {
							setForm({ target: { name: 'ad.VehicleType', value: e.value } });
							setVehicleTypeObj(e);
						}}
						value={vehicleTypeObj}
						onBlur={() => {
							if (!vehicleTypeObj) {
								setErrorVehicleType(true);
							} else {
								setErrorVehicleType(false);
							}
						}}
					/>
					{vehicleTypeError && <ErrorMessage message={t('Select the vehicle type')} />}
				</div>
			</div>

			<div className='mt-1 -mx-3 md:flex mb-2'>
				<div className='md:w-full px-3'>
					<FormItem
						label={t('Brand')}
						type='select-dropdown'
						required={true}
						fieldname='ad.Brand'
						name='ad.Brand'
						styles={customStyles}
						options={brands}
						onChange={(e: any) => {
							setForm({ target: { name: 'ad.Brand', value: e.value } });
							setBrandObj(e);
						}}
						value={brandObj}
						onBlur={() => {
							if (!brandObj) {
								setBrandError(true);
							} else {
								setBrandError(false);
							}
						}}
					/>
					{brandError && <ErrorMessage message={t('Select the vehicle brand')} />}
				</div>
			</div>

			<div className='-mx-3 md:flex mb-2'>
				<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
					<FormItem
						type='text'
						label='Km'
						fieldname='ad.Km'
						name='ad.Km'
						value={formData.ad.Km || ''}
						onChange={setForm}
					/>
				</div>
				<div className='md:w-1/2 px-3'>
					<FormItem
						type='text'
						label='Cv'
						fieldname='ad.Transmission'
						name='ad.Cv'
						value={formData.ad.Cv || ''}
						onChange={setForm}
					/>
				</div>
			</div>
		</div>
	);
};

const ContentAd = ({ setForm, formData, navigation }: any) => {
	const { t } = useTranslation();
	// const [showServicePrice, setShowServicePrice] = useState<boolean>(false);
	const [titleError, setTitleError] = useState<boolean>(false);
	const [addressError, setAddressError] = useState<boolean>(false);
	const [zipCodeError, setZipCodeError] = useState<boolean>(false);
	const [cityError, setCityError] = useState<boolean>(false);
	const {
		title = '',
		description = '',
		enableNext,
		// price,
	} = formData;

	const { previous, next } = navigation;
	const props = { setForm, formData };
	/** Initialize neableNext to false for logic required field manipulation on child component */
	useEffect(() => {
		setForm({ target: { name: 'enableNext', value: false } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/** Check if title is not empty to enable next step button */
	useEffect(() => {
		if (
			formData.title !== '' &&
			formData.location.address.street !== '' &&
			formData.location.address.zipCode !== '' &&
			formData.location.address.city !== ''
		) {
			if (!formData.enableNext) setForm({ target: { name: 'enableNext', value: true } });
		} else if (formData.enableNext) {
			setForm({ target: { name: 'enableNext', value: false } });
		}
	}, [formData.title, formData.location, formData.enableNext, setForm]);

	/** Update state where formData.items change */
	useEffect(() => {
		console.log('items is updated');
	}, [formData.items]);

	return (
		<div>
			<Stepper step={1} />
			<div className='mt-10 p-1'>
				{/* <span>{JSON.stringify(formData, null, 2)}</span> */}
				<div>
					{formData.subCategory === 'cars' && <CarContent {...{ formData, setForm }} />}
					{formData.subCategory === 'motorcycles' && <Motorcycle {...{ formData, setForm }} />}
					{formData.subCategory !== 'cars' && (
						<React.Fragment>
							<FormItem
								required={true}
								label={t('Title')}
								fieldname='title'
								name='title'
								value={title}
								onChange={setForm}
								type='text'
								onBlur={() => {
									if (formData.title === '') {
										setTitleError(true);
									} else {
										setTitleError(false);
									}
								}}
							/>
							{titleError && <ErrorMessage message={t('The title field is required')} />}
						</React.Fragment>
					)}
					<FormItem
						type='text-area'
						label={t('Description')}
						fieldname='description'
						value={description}
						onChange={setForm}
						name='description'
					/>
				</div>

				<div className=''>
					<FormItem
						type='text'
						label={t('E-commerce web address or item detail')}
						fieldname='adUrl'
						name='adUrl'
						value={formData.adUrl || ''}
						onChange={setForm}
						placeholder='https://...'
					/>
					<CommentMessage>
						{t(
							'If you own an e-commerce and the article is part of it, enter the address of the object here'
						)}
					</CommentMessage>
				</div>

				<div className='mt-1 py-2'>
					<div className='-mx-3 md:flex mb-2'>
						<div className='md:w-1/3 px-3 mb-6 md:mb-0'>
							<FormItem
								type='select-dropdown'
								label={t('Currency')}
								fieldname='price.currency'
								name='price.currency'
								styles={customStyles}
								options={CurrenciesOptions}
								placeholder='Select'
								value={{ label: formData.price.currency, value: formData.price.currency }}
								onChange={(e: any) =>
									setForm({ target: { name: 'price.currency', value: e.value } })
								}
							/>
						</div>
						<div className='md:w-1/3 px-3'>
							<FormItem
								type='text'
								label={t('Amount')}
								fieldname='price.amount'
								value={formData.price.amount}
								name='price.amount'
								onChange={setForm}
							/>
						</div>
						{formData.adType === 'rent' && (
							<div className='md:w-1/3 px-3 mb-6 md:mb-0'>
								<FormItem
									type='select-dropdown'
									label={t('Unit')}
									fieldname='price.unit'
									name='price.unit'
									styles={customStyles}
									options={UnitOptionTypes(t)}
									placeholder='Select'
									value={{ label: formData.price.unit, value: formData.price.unit }}
									onChange={(e: any) => setForm({ target: { name: 'price.unit', value: e.value } })}
								/>
							</div>
						)}
					</div>
				</div>

				<div className='mt-1 py-3'>
					<h2 className='border-b font-semibold tracking-wide uppercase leading-relaxed mb-2'>
						{t('Location of the object')}
					</h2>

					<FormItem
						type='text'
						required={true}
						label={t('Address')}
						fieldname='location.address.street'
						name='location.address.street'
						value={formData.location.address.street || ''}
						onChange={setForm}
						onBlur={() => {
							if (formData.location.address.street === '') {
								setAddressError(true);
							} else {
								setAddressError(false);
							}
						}}
					/>
					{addressError && <ErrorMessage message={t('Address required')} />}

					<div className='-mx-3 md:flex mb-2'>
						<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
							<FormItem
								type='text'
								required={true}
								label='zip Code'
								fieldname='location.address.zipCode'
								name='location.address.zipCode'
								value={formData.location.address.zipCode || ''}
								onChange={setForm}
								onBlur={() => {
									if (formData.location.address.zipCode === '') {
										setZipCodeError(true);
									} else {
										setZipCodeError(false);
									}
								}}
							/>
							{zipCodeError && <ErrorMessage message={t('Zip code required')} />}
						</div>
						<div className='md:w-1/2 px-3'>
							<FormItem
								type='text'
								required={true}
								label={t('City')}
								fieldname='location.address.city'
								name='location.address.city'
								value={formData.location.address.city || ''}
								onChange={setForm}
								onBlur={() => {
									if (formData.location.address.city === '') {
										setCityError(true);
									} else {
										setCityError(false);
									}
								}}
							/>
							{cityError && <ErrorMessage message={t('City required')} />}
						</div>
					</div>
				</div>
				<div className='mt-1 py-3'>
					<h2 className='border-b font-semibold tracking-wide uppercase leading-relaxed mb-2'>
						{t('Contacts')}
					</h2>
					<div className='mt-2'>
						<FormItem
							type='checkbox'
							label={t('Company')}
							fieldname='contactInfo.company'
							value={formData.contactInfo.company || ''}
							onChange={setForm}
							required={false}
							name='contactInfo.company'
						/>
					</div>

					<div className='-mx-3 md:flex mb-2'>
						<div className='md:w-1/2 px-3 mb-6 md:mb-0'>
							<FormItem
								type='text'
								label={t('Contact')}
								fieldname='contactInfo.contact'
								name='contactInfo.contact'
								value={formData.contactInfo.contact || ''}
								onChange={setForm}
								placeholder={t('Name and Last name contact')}
							/>
						</div>
						<div className='md:w-1/2 px-3'>
							<FormItem
								type='text'
								label={t('Phone')}
								fieldname='contactInfo.phone'
								name='contactInfo.phone'
								value={formData.contactInfo.phone || ''}
								onChange={setForm}
								placeholder='0039..'
							/>
						</div>
					</div>
					<div className='mt-1'>
						<FormItem
							type='checkbox'
							label={t('Hide number')}
							fieldname='contactInfo.hideNumber'
							value={formData.contactInfo.hideNumber || false}
							onChange={setForm}
							required={false}
							name='contactInfo.hideNumber'
						/>
					</div>

					<div className=''>
						<FormItem
							type='text'
							label={t('Website')}
							fieldname='contactInfo.website'
							name='contactInfo.website'
							value={formData.contactInfo.website || ''}
							onChange={setForm}
							placeholder='https://... or http://...'
						/>
						<CommentMessage>
							{t('Cost of € 2.00 to add your personal or company web address')}
						</CommentMessage>
					</div>

					<div className='mt-5'>
						<h2 className='border-b font-semibold tracking-wide uppercase leading-relaxed mb-2'>
							{t('Upload the logo to your ad')}
						</h2>
						<p className='text-gray-700'>{t('Cost 2.00 for ads with company logo')}</p>
						<BrandImage
							{...props}
							onChange={(e: any) =>
								setForm({ target: { name: 'contactInfo.brandThumb', value: e } })
							}
						/>
					</div>
				</div>

				<div className='py-10 flex justify-items-start items-center space-x-2 '>
					<BackPage previous={previous} title={t('Previous')} />
					{enableNext && <NextPage next={next} title={t('Next')} />}
				</div>
			</div>
		</div>
	);
};

const steps = [
	{ id: 'categories' },
	{ id: 'ad' },
	{ id: 'gallery' },
	{ id: 'preview' },
	{ id: 'confirm' },
];

const MultiStepForm = () => {
	const [formData, setForm] = useForm({
		enableNext: false,
		title: '',
		category: '',
		subCategory: '',
		adType: '',
		gallery: [],
		price: { amount: '', currency: '', unit: '' },
		ad: {},
		location: { address: { street: '', zipCode: '', city: '' } },
		contactInfo: {},
		line_items: [],
	});
	const { step, navigation } = useStep({ initialStep: 0, steps });
	// const { id } = step;
	const { id }: any = step;

	const props = { formData, setForm, navigation };

	switch (id) {
		case 'categories':
			return <CategoryStep {...props} />;
		case 'ad':
			return <ContentAd {...props} />;
		// <GalleryZone onChange={(e: any) => setForm({ target: { name: 'gallery', value: e } })} />
		case 'gallery':
			return <GalleryStep {...props} />;
		case 'preview':
			return <SummaryStep {...props} />;
		case 'confirm':
			return <ConfirmationStep {...props} />;
		default:
			return null;
	}
};

const NewAd: React.FC = () => {
	const { auth } = useAppState();
	const { t } = useTranslation();

	if (!auth.token) {
		return (
			<Redirect
				to={{
					pathname: '/error',
					state: {
						status: 401,
						title: t('Unauthorized access'),
						message: t('To create an advertisement you must be registered'),
					},
				}}
			/>
		);
	}
	return (
		<div className='max-w-screen-xl mx-auto px-4'>
			<div className='container w-full  flex flex-wrap px-1 pt-8 '>
				<div className='w-full lg:w-1/5 text-xl text-gray-800 leading-normal p-3'>
					{/* <h1 className='text-2xl'>Left Side bar</h1> */}
				</div>
				<section className='w-full  mx-auto  lg:w-3/6'>
					<MultiStepForm />
				</section>
				<div className='w-full lg:w-1/5  text-xl text-gray-800 leading-normal p-3'>
					{/* Right side bar */}
				</div>
			</div>
		</div>
	);
};

export default NewAd;
