import React from 'react';

interface FuSaveButtonProps {
  caption: string;
  onClick: () => void;
  loading: boolean;
}
const FuSaveButton: React.FC<FuSaveButtonProps> = ({ caption, onClick, loading }) => {
  return (
    <span className='inline-flex rounded-md shadow-sm'>
      <button
        onClick={onClick}
        style={{ border: '1px solid rgba(27,31,35,.15)' }}
        type='button'
        disabled={loading}
        className='py-1 px-3 mt-1 text-sm flex items-center rounded-sm
            bg-gray-50 text-gray-800 focus:outline-none active:outline-none hover:text-black'
      >
        {loading && (
          <svg
            className='animate-spin -ml-1 mr-1 h-5 w-5 text-black'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            ></circle>
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>
        )}
        <span className='font-medium'>{caption}</span>
      </button>
    </span>
  );
};

export default FuSaveButton;
