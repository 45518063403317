import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Select from 'react-select';
import Tour from 'reactour';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { IoMdTrash } from 'react-icons/io';

import { FuImageUpload } from '../../../../UI/classFied/Forms/galleryZone';
import { customStyles } from '../../../../utils/utility';
import serverApi from '../../../../api/serverApi';

import DialogBox from '../../../../Components/dialog-box';
import ButtonDecoration from '../../../../Components/buttonDecoration';

import PizzaTourImage from '../../../../images/pizza-tour.jpeg';

function ToTranslateStr(props: any) {
	const { t } = useTranslation();
	const { val } = props;
	return (
		<>
			<span>{t(val)}</span>
		</>
	);
}
const tourSteps = [
	{
		selector: '[data-tut="reactour__add_menu"]',
		content: () => (
			<div>
				<header className='text-2xl'>
					<ToTranslateStr val='Category or Group management' />
				</header>
				<p className='text-sm'>
					<ToTranslateStr val='Add or edit categories. In categories they are used to group your articles' />
				</p>
			</div>
		),
	},
	{
		selector: '[data-tut="reactour__menu_position"]',
		content: () => (
			<div>
				<header className='text-2xl'>
					<ToTranslateStr val='Category or Group management' />
				</header>
				<p className='text-sm'>
					<ToTranslateStr val='The created categories are added here. You can easily see all the articles grouped by category' />
				</p>
			</div>
		),
	},
	{
		selector: '[data-tut="reactour__menu_edit_button"]',
		content: () => (
			<div>
				<header className='text-2xl'>
					<ToTranslateStr val='Category or Group management' />
				</header>
				<p className='text-sm'>
					<ToTranslateStr val='This key allows you to change the name of the category' />
				</p>
			</div>
		),
	},
	{
		selector: '[data-tut="reactour__menu_edit_delete"]',
		content: () => (
			<div>
				<header className='text-2xl'>
					<ToTranslateStr val='Category or Group management' />
				</header>
				<p className='text-sm'>
					<ToTranslateStr val='This key allows you to delete the category and all associated articles' />
				</p>
			</div>
		),
	},

	{
		selector: '[data-tut="reactour__article_add"]',
		content: () => (
			<div>
				<header className='text-2xl'>
					<ToTranslateStr val='Product Management' />
				</header>
				<p className='text-sm'>
					<ToTranslateStr val='Upload the image of your product, write the name, description, add it to the category, finally decide the sale price.' />
				</p>
			</div>
		),
	},

	{
		selector: '[data-tut="reactour__eactour__article_list"]',
		content: () => (
			<div>
				<header className='text-2xl'>
					<ToTranslateStr val='Product Management' />
				</header>
				<p className='text-sm'>
					<ToTranslateStr val='The articles are displayed under their own category. To edit an article, simply select it' />
				</p>
			</div>
		),
	},

	{
		selector: '[data-tut="reactour__eactour__article_delete"]',
		content: () => (
			<div>
				<header className='text-2xl'>
					<ToTranslateStr val='Product Management' />
				</header>
				<p className='text-sm'>
					<ToTranslateStr val='Click the trash can button to delete the item' />
				</p>
			</div>
		),
	},
];

const ProductShopManager = ({ setForm, formData, navigation }: any) => {
	const { t } = useTranslation();
	const { menu } = formData;
	const [showArticleNotFound, setShowArticleNotFound] = useState<boolean>(true);
	const [isTourOpen, setIsTourOpen] = useState(false);
	// showing the dialogBox for confirm delete item
	const [showMsgBoxMenu, setShowMsgBoxMenu] = useState<boolean>(false);
	// set Item Menu for deleting
	const [deleteMenu, setDeleteMenu] = useState<any>();

	const [editMenu, setEditMenu] = useState<{ menu: any; index: number }>({ menu: {}, index: -1 });
	const [menuName, setMenuName] = useState<string>('');

	// showing state article DialogBox
	const [showConfirmItemBox, setShowConfirmItemBox] = useState<boolean>(false);

	const [editItem, setEditItem] = useState<{ item: any; menuIndex: number; itemIndex: number }>({
		item: {},
		menuIndex: -1,
		itemIndex: -1,
	});

	const [articleName, setArticleName] = useState<string>('');
	const [articleCode, setArticleCode] = useState<string>('');
	const [articleDescription, setArticleDescription] = useState<string>('');
	// const [articleCurrencySymbol, setArticleCurrencySymbol] = useState<string>('');
	const [articlePrice, setArticlePrice] = useState<string>('');
	const [articleThumb, setArtcileThumb] = useState<string>();
	const [menuDropDown, setMenuDropDown] = useState<{ label: string; value: string }>({
		label: '',
		value: '',
	});

	useEffect(() => {
		const saveChange = async () => {
			await serverApi.ShopUpdate(formData);
		};

		saveChange();
	}, [formData]);

	const reset = () => {
		setArtcileThumb(undefined);
		setEditItem({ item: undefined, menuIndex: -1, itemIndex: -1 });
		setArticleName('');
		setArticlePrice('');
		setArticleDescription('');
		setMenuDropDown({ label: '', value: '' });
	};

	// set state Item Menu before deleting
	const onDeleteMenuClick = (menu: any) => {
		setDeleteMenu(menu);
		setShowMsgBoxMenu(true);
	};
	// reset all when user don't want delete Menu
	const dialogMessageMenuClose = () => {
		setShowMsgBoxMenu(false);
		setDeleteMenu(null);
	};

	// Apply delete item menu
	const handleDeleteMenu = async () => {
		// find index on array of object
		const index = formData.menu.categories.map((el: any) => el._id).indexOf(deleteMenu._id);
		let categories = formData.menu.categories;
		categories.splice(index, 1);
		setForm({ target: { name: 'menu', value: formData.menu } });
		console.log(formData);
	};

	const onDeleteItemClick = () => {
		setShowConfirmItemBox(true);
	};
	const dialogMessageItemClose = () => {
		setShowConfirmItemBox(false);
		// setDeleteItem(null);
	};
	const handleDeleteItem = async () => {
		let categories = formData.menu.categories[editItem.menuIndex];
		categories.items.splice(editItem.itemIndex, 1);
		setForm({ target: { name: 'menu', value: formData.menu } });
		reset();
	};

	const handleItemClick = (item: any, menuIndex: number, itemIndex: number) => {
		console.log(item);
		setEditItem({ item, menuIndex, itemIndex });
		setArticleName(item.name);
		setArticleDescription(item.description);

		setArticlePrice(item.price.amount);
		setArtcileThumb(item?.thumb || undefined);
		setArticleCode(item?.code || '');
		console.log(formData.menu.categories[menuIndex]);
		setMenuDropDown({
			label: formData.menu.categories[menuIndex].name,
			value: formData.menu.categories[menuIndex]._id,
		});
	};

	/** Grab categories menu for dropDown menu on Insert article */
	const categories = menu.categories.map((cat: any) => ({
		label: cat.name,
		value: cat._id,
	}));

	const onArticleSaveClick = () => {
		try {
			if (articleName === '' || +articlePrice === 0) {
				throw new Error('The price or article name is empty');
			}
			// update changes
			if (editItem.itemIndex > -1) {
				let newItem = formData.menu.categories[editItem.menuIndex].items[editItem.itemIndex];

				newItem.name = articleName;
				newItem.code = articleCode;
				newItem.description = articleDescription;
				newItem.price.currency = formData.currencySymbol;
				newItem.price.amount = articlePrice;
				newItem.thumb = articleThumb !== undefined ? articleThumb : null;
				// add new
			} else {
				let category = formData.menu.categories.find((el: any) => menuDropDown.value === el._id);

				category.items.push({
					description: articleDescription,
					thumb: articleThumb,
					name: articleName,
					code: articleCode,
					price: { currency: formData.currencySymbol, amount: +articlePrice },
				});
				console.log(category);
				setEditItem({ item: undefined, menuIndex: -1, itemIndex: -1 });
			}

			setForm({ target: { name: 'menu', value: formData.menu } });

			reset();
		} catch (e) {
			toast.dark(e.message);
		}
	};

	const onMenuSave = () => {
		let newMenu: any;
		if (editMenu.index > -1) {
			newMenu = formData.menu.categories[editMenu.index];
			newMenu.name = menuName;
		} else {
			let cat = formData.menu.categories;
			cat.unshift({ name: menuName, items: [] });
		}

		setForm({ target: { name: 'menu', value: formData.menu } });
		console.log(formData.menu.categories);
	};

	const uploadImageArticle = async (event: any) => {
		if (event === undefined) return;
		const form = new FormData();
		form.append('image', event);
		const response = await serverApi.uploadImageToShopMe(form);
		setArtcileThumb(response.pathUrl);
	};

	return (
		<div className='flex flex-wrap -mx-2 mb-8 mt-5'>
			<ToastContainer
				position='bottom-center'
				transition={Zoom}
				autoClose={2000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={false}
			/>
			<Tour
				steps={tourSteps}
				isOpen={isTourOpen}
				onRequestClose={() => {
					setShowArticleNotFound(true);
					setIsTourOpen(false);
				}}
			/>
			<DialogBox
				title={t('Delete category')}
				open={showMsgBoxMenu}
				onClose={dialogMessageMenuClose}
				onConfirm={handleDeleteMenu}
			>
				{t(
					'Are you sure you want to delete the category ?. By deleting the category you delete all the articles Associates.'
				)}
			</DialogBox>

			<DialogBox
				title={t('Delete article')}
				open={showConfirmItemBox}
				onClose={dialogMessageItemClose}
				onConfirm={handleDeleteItem}
			>
				{t('Are you sure you want to delete the article?')}
			</DialogBox>
			<div className='w-full mx-2 lg:w-3/5 lg:mx-0 px-2'>
				{menu.categories.length > 0 ? (
					menu.categories.map((cat: any, index: number) => (
						<div
							key={index}
							className='p-2 shadow text-sm flex flex-col flex-start items-stretch  '
						>
							<div className='flex flex-row mb-2'>
								<div className='flex-1 font-semibold text-lg'>{cat.name}</div>
								<div className='flex text-sm items-center'>
									<ButtonDecoration
										onClick={() => {
											setMenuName(cat.name);
											setEditMenu({ menu: cat, index: index });
										}}
										caption={t('Edit')}
										styles='text-indigo-600'
									/>
								</div>
								<div className='flex text-sm items-center ml-1'>
									<ButtonDecoration
										onClick={() => onDeleteMenuClick(cat)}
										caption='Delete'
										styles='text-indigo-600'
									/>
								</div>
							</div>
							<div className='block'>
								{cat.items.map((item: any, itemIndex: number) => (
									<div
										onClick={() => handleItemClick(item, index, itemIndex)}
										key={itemIndex}
										// className='flex flex-row p-2 cursor-pointer
										// hover:bg-indigo-200 rounded-md'
										className={`${
											editItem.menuIndex === index && editItem.itemIndex === itemIndex
												? 'bg-indigo-200'
												: ''
										} flex flex-row p-1 cursor-pointer hover:bg-indigo-200 mb-1 rounded-md`}
									>
										{item.thumb && (
											<div className='flex pr-1'>
												<img className='rounded-md h-10' src={item.thumb} alt={item.name} />
											</div>
										)}
										<div className='flex w-full flex-col -mt-1 '>
											<div className='flex w-full flex-row items-center'>
												<div className='flex-1 font-medium'>
													<span className='font-bold'>{item.code} </span>
													{item.name}
												</div>
												<div className='flex justify-end font-medium'>
													{item.price.currency} {item.price.amount}
												</div>
												<div className='flex justify-end font-medium ml-1'>
													<button
														// onClick={() => onDeleteItemClick(item)}
														onClick={onDeleteItemClick}
														className='py-1 px-2 rounded-md hover:bg-gray-800 hover:text-white'
													>
														<IoMdTrash size={20} />
													</button>
												</div>
											</div>
											<div className='flex w-full italic -mt-1'>{item.description}</div>
										</div>
									</div>
								))}
							</div>
						</div>
					))
				) : (
					<React.Fragment>
						<div className={`text-center ${showArticleNotFound ? 'block' : 'hidden'} `}>
							<img
								className='mx-auto  rounded-md overflow-hidden object-center object-none'
								alt='no-product'
								src={`${process.env.REACT_APP_SERVER_URL_ROOT}/images/no-product.png`}
							/>
							<span className='block text-5xl font-bold leading-none'>
								{t('No articles found')}
							</span>
							<p className='font-medium text-gray-800'>
								{t('Take a')} <strong>{t('guided tour')}</strong>{' '}
								{t('to learn how to manage your products')}
							</p>
							<button
								onClick={() => {
									setShowArticleNotFound(false);
									setIsTourOpen(true);
								}}
								className='bg-gray-200 py-2 px-3 font-medium hover:bg-gray-300 outline-none rounded-md'
							>
								{t('Start tour')}
							</button>
						</div>

						<div
							className={`${
								isTourOpen ? 'flex' : 'hidden'
							}  p-2 shadow text-sm  flex-col flex-start items-stretch`}
						>
							<div className='flex flex-row mb-2'>
								<div data-tut='reactour__menu_position' className='flex-1 font-semibold text-lg '>
									{t('Categry name')}
								</div>
								<div data-tut='reactour__menu_edit_button' className='flex text-sm items-center'>
									<ButtonDecoration caption={t('Edit')} styles='text-indigo-600' />{' '}
								</div>
								<div
									data-tut='reactour__menu_edit_delete'
									className='flex text-sm items-center ml-1'
								>
									<ButtonDecoration caption={t('Delete')} styles='text-indigo-600' />{' '}
								</div>
							</div>
							<div className='block'>
								<div
									data-tut='reactour__eactour__article_list'
									className='flex flex-row p-2 cursor-pointer hover:bg-indigo-200 rounded-md'
								>
									<div className='flex pr-1'>
										<img className='rounded-md h-10' src={PizzaTourImage} alt='' />
									</div>
									<div className='flex w-full flex-col -mt-1 ml-1 '>
										<div className='flex w-full flex-row items-center'>
											<div className='flex-1'>{t('Item name')}</div>
											<div className='flex justify-end font-medium'>$ 12.90</div>
											<div
												data-tut='reactour__eactour__article_delete'
												className='flex justify-end font-medium ml-1'
											>
												<button className='py-1 px-2 rounded-md hover:bg-gray-800 hover:text-white'>
													<IoMdTrash size={20} />
												</button>
											</div>
										</div>
										<div className='flex w-full italic'>{t('Item description')}</div>
									</div>
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
			{/* right bar box */}
			<div className='w-full mx-2 lg:mx-0  sticky top-10 lg:w-2/5 px-2 mb-4'>
				<div className='sticky top-20'>
					<div className=' p-1  text-sm text-grey-dark flex '>
						<div data-tut='reactour__add_menu' className='mb-1 -mt-1 w-full'>
							<div className='flex w-full flex-col mt-2 '>
								<label
									htmlFor='price'
									className='uppercase tracking-wide text-black text-xs font-bold mb-2'
								>
									{t('Category name')}
								</label>
								<div className='flex flex-row w-full space-x-2'>
									<input
										type='text'
										className=' focus:ring-black focus:border-black border-2 h-10  w-full  border-gray-200'
										id='lastNameId'
										name='from'
										value={menuName}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setMenuName(e.target.value)
										}
									/>
									<div className='flex'>
										<ButtonDecoration
											onClick={onMenuSave}
											caption={t('Save')}
											styles='text-indigo-600 h-10 '
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='p-1 text-sm mt-3  w-full'>
						<article data-tut='reactour__article_add' className='pt-1 w-full'>
							<div className='w-full'>
								<div className='flex text-black space-x-2 '>
									<span className='flex  font-semibold'>
										<label
											htmlFor='code'
											className='uppercase tracking-wide text-black text-xs font-bold mb-2'
										>
											{t('Item code')}
											<input
												type='text'
												autoComplete='off'
												name='code'
												className=' focus:ring-black focus:border-black border-2 h-9  w-full  border-gray-200 font-medium'
												value={articleCode || ''}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													setArticleCode(e.target.value);
												}}
											/>
										</label>
									</span>

									<span className='flex flex-shrink  font-semibold'>
										<label
											htmlFor='name'
											className='uppercase tracking-wide text-black text-xs font-bold mb-2'
										>
											{t('Item name')}
											<input
												type='text'
												autoComplete='off'
												name='articleName'
												className=' focus:ring-black focus:border-black border-2 h-9  w-full  border-gray-200'
												value={articleName || ''}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													setArticleName(e.target.value);
												}}
											/>
										</label>
									</span>
								</div>
							</div>
							<div className='w-full'>
								<div className='text-black mt-2 '>
									<span className='font-semibold'>
										<label
											htmlFor='name'
											className='uppercase tracking-wide text-black text-xs font-bold mb-2'
										>
											{t('Item description')}
											<input
												type='text'
												autoComplete='off'
												name='name'
												className=' focus:ring-black focus:border-black border-2 h-9  w-full  border-gray-200'
												value={articleDescription || ''}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													setArticleDescription(e.target.value);
												}}
											/>
										</label>
									</span>
								</div>
							</div>
							<div className='pt-1 flex'>
								<div className='mr-2 flex-none bg-cover rounded text-center overflow-hidden'>
									<FuImageUpload
										currentImage={articleThumb}
										styles='bg-white text-white '
										onImageChanged={uploadImageArticle}
										height='w-32'
										text={t('Upload image')}
									/>
								</div>
								<div className='flex-1'>
									<div className='mb-1 -mt-1'>
										<div className='text-black mt-1 '>
											<span className='font-semibold'>
												<label
													htmlFor='name'
													className='uppercase tracking-wide text-black text-xs font-bold mb-2'
												>
													{t('Category')}
												</label>

												<Select
													styles={customStyles}
													placeholder='Seleziona'
													isDisabled={editItem.menuIndex > -1}
													options={categories}
													value={menuDropDown}
													onChange={(e: any) => setMenuDropDown(e)}
												/>
											</span>
										</div>

										<div className='flex flex-col mt-2'>
											<label
												htmlFor='price'
												className='uppercase tracking-wide text-black text-xs font-bold '
											>
												{t('Price')}
											</label>
											<div className='flex flex-row w-full space-x-2'>
												<div className='flex-shrink w-full '>
													<div className='relative rounded-md shadow-sm'>
														<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
															<span className='text-gray-500 sm:text-sm'>
																{formData.currencySymbol}
															</span>
														</div>
														<input
															type='text'
															className=' focus:ring-black focus:border-black border-2 h-10 block w-full text-right  border-gray-200    '
															placeholder='0.0'
															id='price'
															name='from'
															value={articlePrice}
															onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
																setArticlePrice(e.target.value);
															}}
														/>
													</div>
												</div>
												<div className='flex'>
													<ButtonDecoration
														onClick={onArticleSaveClick}
														caption={t('Save')}
														styles='text-indigo-600 h-10 '
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</article>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductShopManager;
