import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';

function saveToLocalStorage(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const middleware = [thunk];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

const persistedState = loadFromLocalStorage();

let store: any;

if (process.env.NODE_ENV === 'development') {
  store = createStore(
    rootReducer,
    persistedState,
    compose(
      applyMiddleware(...middleware),
      (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) as any
    )
  );
} else {
  store = createStore(rootReducer, persistedState, compose(applyMiddleware(...middleware)));
}

// store = createStore(rootReducer, persistedState, compose(applyMiddleware(...middleware)));
store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
