export interface IGoogleCoord {
  lat: number;
  lng: number;
}

//fixme: store the ka to the process.env
const key = 'AIzaSyBuL1B4s4ZL1ErfUl8Ja0hhto3l3J5GWoI';

const AddressToCooords = async (completeAddress: string): Promise<{ lat: number; lng: number }> => {
  let result: { lat: number; lng: number } = { lat: 0, lng: 0 };

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${completeAddress}&key=AIzaSyBuL1B4s4ZL1ErfUl8Ja0hhto3l3J5GWoI`;

  /** Store fetch result */
  const location: any = await fetch(url, { method: 'GET' }).then((response) => response.json());

  /** Check if found it */
  if (location.status === 'OK') {
    /** Set coordinate state with a founded coordinates */
    // setCoordinates(location.results[0].geometry.location);
    /** set on result object a new location */
    result = location.results[0].geometry.location;
  }
  return result;
};

// https://maps.googleapis.com/maps/api/distancematrix/json?
//     origins=46.0071501,8.9574939
//     &destinations=46.0081997,8.9508315
//     &mode=bicycling
//     &key=AIzaSyBuL1B4s4ZL1ErfUl8Ja0hhto3l3J5GWoI

export const DistanceCalculation = async (
  origin: IGoogleCoord,
  destination: IGoogleCoord
): Promise<{ distance: string; duration: string; meters: number }> => {
  let result: { distance: string; duration: string; meters: 0 } = {
    distance: '0',
    duration: '0',
    meters: 0,
  };
  console.log('🔥 🔥 ');
  const url = 'https://maps.googleapis.com/maps/api/distancematrix/json';

  console.log(
    `${url}?origins=${origin.lat},${origin.lng}&destinations=${destination.lat},${destination.lng}&mode=bicycling&key=${key}`
  );

  const distance: any = await fetch(
    `${url}?origins=${origin.lat},${origin.lng}&destinations=${destination.lat},${destination.lng}&mode=bicycling&key=${key}`,
    { method: 'GET' }
  ).then((response) => response.json());

  if (distance) {
    console.log(distance);
    result = {
      distance: distance.rows[0].elements[0].distance.text || '0',
      duration: distance.rows[0].elements[0].duration.text || '0',
      meters: distance.rows[0].elements[0].distance.value,
    };
  }

  return result;
};

export const CalculationDistance = (
  origin: IGoogleCoord,
  destination: IGoogleCoord
): { distance: string; duration: string; meters: number } => {
  let result: { distance: string; duration: string; meters: number } = {
    distance: '0',
    duration: '0',
    meters: 0,
  };

  let R = 6371; // km hearth
  let dLat = toRoad(destination.lat - origin.lat);
  let dLon = toRoad(destination.lng - origin.lng);
  let originLat = toRoad(origin.lat);
  let destinationLat = toRoad(destination.lat);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(originLat) * Math.cos(destinationLat);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  let d: number = R * c * 1000;

  result = {
    distance: '0',
    duration: '0',
    meters: d,
  };

  return result;
};

function toRoad(value: number) {
  return (value * Math.PI) / 180;
}

export default AddressToCooords;
