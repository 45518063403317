import React from 'react';
import {
	// BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import ShopList from './ShopList';
import ShopperPage from './ShopperPage';
import LayoutPage from '../../UI/Layout';
import OrderCheckOut from './OrderCheckOut';
import OrderManager from './OrderManager/OrderManager';
import { PageNotFound } from '../../errorPage';

const RouteShop: React.FC<RouteComponentProps> = ({ match }) => {
	const { path } = match;
	const url = match.url;
	/** On the index page first routing I need to adding the shop list categories branches (tobacco , food, pharmacy) */
	return (
		<Switch>
			<Route
				exact
				path={`${url}`}
				render={(props) => <LayoutPage {...props} children={<PageNotFound />} />}
			/>
			<Route
				exact
				path={`${url}/order-checkout`}
				render={(props) => <LayoutPage {...props} children={<OrderCheckOut />} />}
			/>

			<Route
				exact
				path={`${path}/:branch`}
				render={(props) => <LayoutPage {...props} children={<ShopList />} />}
			/>
			<Route
				exact
				path={`${path}/:branch/:shopper/:distance`}
				render={(props) => <LayoutPage {...props} children={<ShopperPage />} />}
			/>

			<Route
				exact
				path={`${path}/order-man/:shopper`}
				render={(props) => <LayoutPage {...props} children={<OrderManager />} />}
			/>

			<Route path='*' render={(props) => <LayoutPage {...props} children={<PageNotFound />} />} />
		</Switch>
	);
};

export default withRouter(RouteShop);
