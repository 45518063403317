import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Stepper, { BackPage, NextPage } from '../stepper';

const ItemPreview = ({ formData }: any) => {
	const { t } = useTranslation();

	return (
		<div className='p-1 mb-5 mr-0 '>
			<div className='flex flex-wrap items-center w-full'>
				<div className='flex w-full md:flex-1 flex-col leading-normal'>
					<div className='text-gray-800 font-semibold'>
						<a target='_blank' rel='noreferrer' href='/' className='hover:underline'>
							{formData?.title ? formData?.title : ''}
						</a>
					</div>
				</div>
				{/* <div className='flex right-0 leading-normal text-base'> */}
				<div className=' flex flex-wrap'>
					<div className='flex  text-sm  rounded-md bg-gray-200 font-normal'>
						<span className='text-gray-600 p-1 font-semibold'>
							{formData?.price?.amount > 0
								? `${formData.price.currency} ${formData.price.amount}`
								: ''}
						</span>
					</div>
					<div className='flex'>
						<span className='text-sm ml-1 justify-items-center p-1 text-gray-600 font-semibold'>
							{formData?.price.unit ? formData.price?.unit : ''}
						</span>
					</div>
				</div>
			</div>
			<article className='p-0 mt-1 w-full lg:max-w-full lg:flex '>
				{formData?.gallery.length > 0 && (
					<div
						className='h-36 lg:w-36 mr-2 flex-none bg-cover no-repeat  rounded  text-center overflow-hidden transition duration-500 ease-in-out transform hover:scale-110'
						style={{
							// backgroundImage: 'url(https://c.tutti.ch/images/2369164256.jpg)',
							backgroundImage: `url(${formData.gallery[0]})`,
						}}
						title='Mountain'
					></div>
				)}

				<div className='flex-1 mt-3 sm:mt-0'>
					<div className=' rounded-b lg:rounded-b-none lg:rounded-r  md:px-0 flex flex-col leading-normal'>
						<div className='relative h-36'>
							<div className='w-full justify-left items-center flex'>
								<div className='flex-1 text-gray-500 text-sm'>
									{formData?.location?.address?.zipCode} {formData?.location?.address?.city}{' '}
								</div>
								{formData?.contactInfo?.brandThumb && (
									<div className='flex right-0'>
										<img className='h-6' src={`${formData.contactInfo.brandThumb}`} alt='logo' />
									</div>
								)}
							</div>

							<p className='text-gray-700 line-clamp-2 mt-1 text-normal '>
								{formData?.description}
							</p>
							<div className='text-sm mb-1 mt-2'>
								<div className='flex justify-start space-x-2 sm:space-x-4'>
									{Object.keys(formData?.ad).map(
										(e, index: number) =>
											index < 3 && (
												<div key={e} className='inline-block space-x-1'>
													<div className='inline-block font-semibold'>{t(e)}</div>
													<div className='inline-block'>{formData.ad[e]}</div>
												</div>
											)
									)}
								</div>
							</div>

							<div className='mt-3 absolute bottom-0 '>
								<div className='flex space-x-3 text-sm font-semibold'>
									<div className='flex'>
										<a
											href='category-page.html'
											className='  border-gray-300 border-b pb-1 hover:border-gray-100  underline-effect relative'
										>
											Scopri di più
										</a>
									</div>
									{formData?.contactInfo?.website && (
										<div className='flex'>
											<a
												href={formData.contactInfo.website}
												target='_blank'
												rel='noreferrer'
												className='  border-gray-300 border-b pb-1 hover:border-gray-100  underline-effect relative'
											>
												sito web
											</a>
										</div>
									)}
									{formData?.booking && (
										<div className='flex'>
											<a
												href='category-page.html'
												className='  border-gray-300 border-b pb-1 hover:border-gray-100  underline-effect relative'
											>
												prenota
											</a>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</article>
		</div>
	);
};

const SummaryStep = ({ setForm, formData, navigation }: any) => {
	const { t } = useTranslation();
	// const [total, setTotal] = useState<number>(0);
	const { previous, next, go } = navigation;
	const { line_items } = formData;
	const props = { formData, setForm, navigation };
	console.log(formData);
	let total = 0;

	// /** Check if user adding the business web site */
	// if (formData.contactInfo?.website) {
	// 	const priceSection = { id: 'website', value: 2 };
	// 	let items = [...line_items, ...[priceSection]];
	// 	setForm({ target: { name: 'line_items', value: items } });
	// 	total += 2;
	// }

	useEffect(() => {
		/** Check if user adding the business web site */
		if (formData.contactInfo?.website) {
			const priceSection = { id: 'website', value: 2 };
			let newLineItems = line_items.filter((el: any) => el.id !== 'website');
			let items = [...newLineItems, ...[priceSection]];
			setForm({ target: { name: 'line_items', value: items } });
			// setTotal(+2);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<Stepper step={3} />
			<div className='p-1 mt-2 py-10'>
				<h1 className='text-xl tracking-tight font-semibold text-gray-700 '>{t('Summary')}</h1>
				<p className='mb-10 mt-1 text-gray-800'>
					{t('Check the information entered before confirming')}
				</p>

				<section id='preview-ad'>
					<h2 className='border-b font-semibold tracking-wide uppercase leading-relaxed mb-2'>
						{t('Ad')}
					</h2>

					<button onClick={() => go(1)} className='underline'>
						{t('Edit')}
					</button>
					<ItemPreview {...props} />
				</section>
				{formData.line_items?.length > 0 && (
					<section id='detail-cost'>
						<h2 className='border-b font-semibold tracking-wide uppercase leading-relaxed mb-2'>
							{t('Services')}
						</h2>
						<table className='table-fixed w-full'>
							<thead></thead>
							<tbody>
								{formData.line_items.map((item: any, index: number) => {
									total += item.value;
									return (
										<tr className='w-full' key={index}>
											<td className='w-10/12 text-right'>{t(item.id)}</td>
											<td className='w-2/12 text-right'>€ {item.value}</td>
										</tr>
									);
								})}
								{/* 
								{formData.contactInfo.website && (
									<tr className=' w-full'>
										<td className='w-10/12 text-right '>sito web aziendale</td>
										<td className='w-2/12 text-right'>€ 2</td>
									</tr>
								)} */}
								<tr className=' w-full '>
									<td className='w-10/12 text-right font-semibold'>{t('Total')}</td>
									<td className='w-2/12 text-right font-semibold'>€ {total}</td>
								</tr>
							</tbody>
						</table>
					</section>
				)}
				{formData.location && (
					<section id='contact-info'>
						<h2 className='border-b font-semibold tracking-wide uppercase leading-relaxed mb-2'>
							{t('Personal informations')}
						</h2>
						<table className='table-auto w-full'>
							<thead></thead>
							<tbody>
								<tr className=' w-full'>
									<td>{t('Address')}</td>
									<td className='text-right'>
										{formData.location.address.street}, {formData.location.address.zipCode}{' '}
										{formData.location.address.city}{' '}
									</td>
								</tr>
								{formData.contactInfo && (
									<React.Fragment>
										<tr className='w-full'>
											<td>{t('Phone number')}</td>
											<td className='text-right'>{formData.contactInfo.phone}</td>
										</tr>
										<tr className=' w-full '>
											<td>{t('Phone visible')}</td>
											<td className='text-right'>
												{formData.contactInfo?.hideNumber !== undefined
													? formData.contactInfo?.hideNumber
													: `${t('Yes')}`}
											</td>
										</tr>
									</React.Fragment>
								)}
							</tbody>
						</table>
					</section>
				)}
			</div>
			<div className='py-5 flex justify-items-start items-center space-x-2 '>
				<BackPage previous={previous} title={t('Previous')} />
				<NextPage next={next} title={t('Next')} />
			</div>
		</React.Fragment>
	);
};

export default SummaryStep;
