import React from 'react';
import { useTranslation } from 'react-i18next';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

export const NextPage = ({ next, title }: any) => {
	return (
		<button
			onClick={next}
			style={{ border: '1px solid rgba(27,31,35,.15)' }}
			className=' h-9 pl-3 pr-3 flex items-center rounded-sm bg-gray-50 text-black hover:text-gray-700 hover:shadow-sm focus:outline-none active:outline-none'
		>
			<span className='font-medium ml-2'>{title}</span>
			<span>
				<GrFormNext size={18} className='mt-0.5 ' />
			</span>
		</button>
	);
};

export const BackPage = ({ previous, title }: any) => {
	return (
		<button
			onClick={previous}
			style={{ border: '1px solid rgba(27,31,35,.15)' }}
			className=' h-9 pl-3 pr-3 flex items-center rounded-sm bg-gray-50 text-black hover:text-gray-700 hover:shadow-sm focus:outline-none active:outline-none'
		>
			<span>
				<GrFormPrevious size={18} className='mt-0.5 ' />
			</span>
			<span className='font-medium ml-1'>{title}</span>
		</button>
	);
};

type stepperProp = {
	step: number;
};

const Stepper: React.FC<stepperProp> = ({ step }) => {
	const { t } = useTranslation();
	return (
		<div className='mx-1 hidden md:block'>
			<div className='flex items-center '>
				<div className='flex items-center text-gray-600 relative'>
					<div className='rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-green-600 bg-green-600 text-white'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='100%'
							height='100%'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
							className='feather feather-layers'
						>
							<polygon points='12 2 2 7 12 12 22 7 12 2'></polygon>
							<polyline points='2 17 12 22 22 17'></polyline>
							<polyline points='2 12 12 17 22 12'></polyline>
						</svg>
					</div>
					<div className='absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-medium uppercase text-gray-500'>
						{t('Start')}
					</div>
				</div>
				<div
					className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
						step === 0 ? 'border-gray-300' : 'border-green-500'
					}`}
				></div>
				<div className='flex items-center text-gray-500 relative'>
					<div
						className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-500 ${
							step > 0 ? 'bg-green-600 text-white' : ''
						} `}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='100%'
							height='100%'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
							className='feather feather-edit-3'
						>
							<path d='M12 20h9'></path>
							<path d='M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z'></path>
						</svg>
					</div>
					<div className='absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-medium uppercase text-gray-500'>
						{t('Ad')}
					</div>
				</div>
				<div
					className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
						step >= 2 ? 'border-green-500' : 'border-gray-300'
					}`}
				></div>

				<div className='flex items-center text-gray-500 relative'>
					<div
						className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-500 ${
							step > 1 ? 'bg-green-600 text-white' : ''
						} `}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='100%'
							height='100%'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
							className='feather feather-download-cloud'
						>
							<polyline points='8 17 12 21 16 17'></polyline>
							<line x1='12' y1='12' x2='12' y2='21'></line>
							<path d='M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29'></path>
						</svg>
					</div>
					<div className='absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-medium uppercase text-gray-500'>
						{t('Upload images')}
					</div>
				</div>
				<div
					className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
						step >= 3 ? 'border-green-500' : 'border-gray-300'
					}`}
				></div>

				<div className='flex items-center text-gray-500 relative'>
					<div
						className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-500 ${
							step > 2 ? 'bg-green-600 text-white' : ''
						} `}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='100%'
							height='100%'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
							className='feather feather-file-text'
						>
							<path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'></path>
							<polyline points='14 2 14 8 20 8'></polyline>
							<line x1='16' y1='13' x2='8' y2='13'></line>
							<line x1='16' y1='17' x2='8' y2='17'></line>
							<polyline points='10 9 9 9 8 9'></polyline>
						</svg>
					</div>
					<div className='absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-medium uppercase text-gray-500'>
						{t('Summary')}
					</div>
				</div>
				<div
					className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
						step >= 4 ? 'border-green-500' : 'border-gray-300'
					}`}
				></div>

				<div className='flex items-center text-gray-500 relative'>
					<div
						className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-500 ${
							step === 4 ? 'bg-green-600 text-white' : ''
						} `}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='100%'
							height='100%'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
							className='feather feather-check'
						>
							<polyline points='20 6 9 17 4 12'></polyline>
						</svg>
					</div>
					<div className='absolute top-0 -ml-10 text-center mt-14 w-32 text-xs font-medium uppercase text-gray-500'>
						{t('Confirmation')}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Stepper;
