import React, { useEffect, useState } from 'react';

import serverApi from '../../../api/serverApi';
import { useTranslation } from 'react-i18next';

type uploadBoxProp = {
	onChange: (e: any) => void;
};
const UploadBox: React.FC<uploadBoxProp> = ({ onChange }) => {
	const { t } = useTranslation();

	return (
		<header className='border-dashed border-2 p-2 h-full border-gray-400 flex flex-col justify-center items-center'>
			<p className='mb-3  text-sm font-semibold text-gray-900 flex flex-wrap justify-center'>
				<span>Aggiungi</span>&nbsp;<span>Immagine</span>
			</p>
			<label>
				<input id='hidden-input' type='file' onChange={onChange} multiple className='hidden' />
				<span
					id='button'
					className='mt-2 text-sm rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none'
				>
					{t('Upload file')}
				</span>
			</label>
		</header>
	);
};

type ImagePreviewProp = {
	onDelete: (e: any) => void;
	fileThumb: any;
};
const ImagePreview: React.FC<ImagePreviewProp> = ({ onDelete, fileThumb }) => {
	return (
		<article className='group hasImage h-full w-full focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent hover:text-white shadow-sm'>
			<img
				src={fileThumb}
				alt='upload preview'
				className='img-preview h-full w-full sticky object-cover  bg-fixed'
			/>

			<section className='flex flex-col  text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3'>
				{/* <h1 className='flex-1'>h1</h1> */}
				<div className='flex'>
					<p className='p-1 size text-xs'></p>
					<button
						onClick={(e: any) => {
							e.preventDefault();
							onDelete(fileThumb);
						}}
						className='delete rounded-lg ml-auto focus:outline-none bg-none hover:bg-gray-300 p-1 '
					>
						<svg
							className='pointer-events-none fill-current w-4 h-4 ml-auto'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
						>
							<path
								className='pointer-events-none'
								d='M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z'
							/>
						</svg>
					</button>
				</div>
			</section>
		</article>
	);
};

const GalleryZone = (props: any) => {
	const { t } = useTranslation();
	const { onChange, formData } = props;
	/** assign content gallery */
	const { gallery } = formData;
	const [files, setFiles] = useState<any[]>([]);

	/** if gallery array contain a url path of image set initialstate files list */
	useEffect(() => {
		if (gallery.length > 0) {
			// showing initialstate content path
			setFiles(gallery);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uploadMultipleFiles = async (e: any) => {
		let fileObj: Array<any> = [];
		fileObj.push(e.target.files);
		let data = new FormData();

		for (let i = 0; i < fileObj[0].length; i++) {
			if (i < 6) {
				data.append('gallery', fileObj[0][i], fileObj[0][i].name);
			}
		}
		const gallery = await serverApi.saveImagesAdsToGallery(data);
		if (gallery.status === 'success') {
			setFiles([...files, ...gallery.gallery]);
			onChange([...files, ...gallery.gallery]);
		}
	};

	const removeImage = async (imageIndex: string) => {
		const urlImage = imageIndex.split('/');
		serverApi.removeImageFromGallery({ image: urlImage[urlImage.length - 1] });
		const img = files.filter((image: any) => image !== imageIndex);
		setFiles(img);
		onChange(img);
	};
	return (
		<div className='w-full'>
			<article aria-label='file upload modal'>
				{files.length === 0 ? (
					<section className='overflow-auto  w-full h-full flex flex-col'>
						<header className='border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center'>
							<p className='mb-3 font-semibold text-gray-900 flex flex-wrap justify-center'>
								<span>{t('Upload up to')} 6</span>&nbsp;<span>{t('images')}</span>
							</p>
							<label>
								<input
									id='hidden-input'
									type='file'
									onChange={uploadMultipleFiles}
									multiple
									className='hidden'
								/>
								<span
									id='button'
									className='mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none'
								>
									{t('Upload file')}
								</span>
							</label>
						</header>
					</section>
				) : (
					<section className=''>
						<div className=' flex  items-center justify-start p-1  '>
							<div className='grid  grid-cols-3 gap-1'>
								<div className='col-span-2 row-span-2  p-1 rounded'>
									{files[0] ? (
										<ImagePreview onDelete={removeImage} fileThumb={files[0]} />
									) : (
										<UploadBox onChange={uploadMultipleFiles} />
									)}
								</div>
								<div className='bg-white p-1 rounded'>
									{files[1] ? (
										<ImagePreview onDelete={removeImage} fileThumb={files[1]} />
									) : (
										<UploadBox onChange={uploadMultipleFiles} />
									)}
								</div>
								<div className='bg-white p-1 rounded'>
									{files[2] ? (
										<ImagePreview onDelete={removeImage} fileThumb={files[2]} />
									) : (
										<UploadBox onChange={uploadMultipleFiles} />
									)}
								</div>
								<div className='bg-white p-1 rounded'>
									{files[3] ? (
										<ImagePreview onDelete={removeImage} fileThumb={files[3]} />
									) : (
										<UploadBox onChange={uploadMultipleFiles} />
									)}
								</div>
								<div className='bg-white p-1 rounded'>
									{files[4] ? (
										<ImagePreview onDelete={removeImage} fileThumb={files[4]} />
									) : (
										<UploadBox onChange={uploadMultipleFiles} />
									)}
								</div>
								<div className='bg-white p-1 rounded'>
									{files[5] ? (
										<ImagePreview onDelete={removeImage} fileThumb={files[5]} />
									) : (
										<UploadBox onChange={uploadMultipleFiles} />
									)}
								</div>
								{/* <div className=' bg-white p-1 rounded'>
                  {files[6] ? (
                    <ImagePreview onDelete={removeImage} fileThumb={files[6]} />
                  ) : (
                    <UploadBox onChange={uploadMultipleFiles} />
                  )}
                </div> */}
								{/* <div className='bg-white p-10 rounded'>8</div> */}
							</div>
						</div>
					</section>
				)}
			</article>
		</div>
	);
};

export const BrandImage = (props: any) => {
	const { t } = useTranslation();
	const { formData, onChange, setForm, styles } = props;
	const { brandThumb, line_items } = formData;
	const [prices, setPrices] = useState<any[]>([]);
	const [files, setFiles] = useState<any[]>([]);
	useEffect(() => {
		if (brandThumb !== undefined) {
			setFiles([brandThumb]);
		}
		if (line_items?.length > 0) {
			setPrices(line_items);
		}
	}, [brandThumb, line_items]);

	const uploadImage = async (e: any) => {
		let fileObj: Array<any> = [];
		fileObj.push(e.target.files);
		let data = new FormData();

		data.append('gallery', fileObj[0][0], fileObj[0][0].name);
		const gallery = await serverApi.saveImagesAdsToGallery(data);
		if (gallery.status === 'success') {
			setFiles([...files, ...gallery.gallery]);
			onChange([...files, ...gallery.gallery]);
		}
		const priceSection = { id: 'brand', value: 2 };
		// update line_items for stripe
		let items = [...prices, ...[priceSection]];
		console.log(items);
		setPrices(items);
		setForm({ target: { name: 'line_items', value: items } });
	};

	const removeImage = async (imageIndex: string) => {
		const urlImage = imageIndex.split('/');
		serverApi.removeImageFromGallery({ image: urlImage[urlImage.length - 1] });
		const img = files.filter((image: any) => image !== imageIndex);
		setFiles(img);

		let filter_items = prices.filter((item: any) => item.id !== 'brand');
		console.log(filter_items);
		setForm({ target: { name: 'line_items', value: filter_items } });
		onChange(img);
	};

	return (
		<label
			className={`w-64 flex flex-col items-center   text-gray-400 border-dashed  shadow-md tracking-wide uppercase border cursor-pointer  hover:bg-gray-100 mt-2 ${styles} ${
				files.length > 0 ? 'px-0 py-0' : 'px-4 py-6'
			}`}
		>
			{files.length > 0 ? (
				<ImagePreview onDelete={removeImage} fileThumb={files[0]} />
			) : (
				<React.Fragment>
					<svg
						className='w-8 h-8'
						fill='currentColor'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 20 20'
					>
						<path d='M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z' />
					</svg>
					<span className='mt-2 text-base leading-normal'>{t('Upload logo')}</span>
					<input type='file' onChange={uploadImage} className='hidden' />
				</React.Fragment>
			)}
		</label>
	);
};

export const FuImageUpload = (props: any) => {
	const { currentImage, onImageChanged, text = 'upload', height = 'w-64' } = props;
	const [file, setFile] = useState<any>(currentImage);

	const uploadImage = async (e: any) => {
		setFile(URL.createObjectURL(e.target.files[0]));
		onImageChanged(e.target.files[0]);
	};

	const removeImage = async () => {
		setFile(undefined);
		onImageChanged(undefined);
	};

	useEffect(() => {
		if (currentImage !== undefined) setFile(currentImage);
		if (currentImage === undefined) setFile(undefined);
	}, [currentImage]);

	return (
		<label
			className={`${height} mt-0  flex flex-col items-center text-gray-400  shadow-md tracking-wide uppercase border cursor-pointer  hover:bg-gray-100 ${
				file !== undefined && file !== null
					? 'px-0 py-0'
					: 'px-4 py-6 bg-gray-100 border-dashed border-2 '
			}`}
		>
			{file !== undefined && file !== null ? (
				<ImagePreview onDelete={removeImage} fileThumb={file} />
			) : (
				<React.Fragment>
					<svg
						className='w-8 h-8'
						fill='currentColor'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 20 20'
					>
						<path d='M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z' />
					</svg>
					<span className='mt-2 text-sm font-medium'>{text}</span>
					<input type='file' onChange={uploadImage} className='hidden' />
				</React.Fragment>
			)}
		</label>
	);
};

export default GalleryZone;
