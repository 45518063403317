import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IoCall } from 'react-icons/io5';
import { MdDirections } from 'react-icons/md';
import { GiJigsawBox } from 'react-icons/gi';

import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { IOrder } from '../../Interfaces/dataModel';
import DialogBox from '../../Components/dialog-box';

// import { io } from 'socket.io-client';
import { useAppState } from '../../hooks/adsHooks';

import serverApi from '../../api/serverApi';
import qs from 'query-string';
import { Line, Bar } from 'react-chartjs-2';

import { IsoDateToUtcDate } from '../../utils/isoDateToUtcDate';

// const socket = io(`${process.env.REACT_APP_SERVER_URL_ROOT}/`);

const options = {
	options: {
		responsive: true,
	},
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
};

type statisticType = {
	_id: string | number;
	total: number;
	delivered: number;
};
const DeliveryDriverStatistics: React.FC = () => {
	const { t } = useTranslation();
	const [year, setYear] = useState<number>(2021);
	const [totalByYear, setTotalByYear] = useState<statisticType[]>([]);
	const [totalByMonthOfYear, setTotalByMonthOfYear] = useState<statisticType[]>([]);
	const [graphData, setGraphData] = useState<any>();
	const [totalToday, setTotalToday] = useState<statisticType>();
	const [totalDayOfMonth, setTotalDayOfMonth] = useState<statisticType[]>([]);
	const [graphDataDayOfMonth, setGraphDataDayOfMonth] = useState<any>();
	const [totalCurrentMonth, setTotalCurrentMonth] = useState<statisticType>();
	// const [shop, setShop] = useState<IShop>();

	useEffect(() => {
		if (!totalDayOfMonth) return;

		const labels = totalDayOfMonth.map((el: statisticType) => el._id);
		console.log(labels);
		const dataRevenue = totalDayOfMonth.map((el: statisticType) => el.total);
		const dataDelivered = totalDayOfMonth.map((el: statisticType) => el.delivered);
		console.log(dataDelivered);
		const iGraph = {
			labels: labels,
			datasets: [
				{
					type: 'bar',
					label: 'Revenue',
					backgroundColor: `rgb(162, 150, 240)`,
					data: dataRevenue,
				},
				{
					type: 'bar',
					label: 'Delivery',
					backgroundColor: `rgb(223, 249, 172)`,
					data: dataDelivered,
					borderColor: 'white',
					borderWidth: 2,
				},
			],
		};

		setGraphDataDayOfMonth(iGraph);

		return () => {};
	}, [totalDayOfMonth]);

	useEffect(() => {
		if (!totalByMonthOfYear) return;

		const labels = totalByMonthOfYear.map((el: statisticType) => el._id);
		console.log(labels);
		const dataRevenue = totalByMonthOfYear.map((el: statisticType) => el.total);
		const dataDelivered = totalByMonthOfYear.map((el: statisticType) => el.delivered);
		console.log(dataDelivered);
		const iGraph = {
			labels: labels,
			datasets: [
				{
					type: 'bar',
					label: 'Revenue',
					backgroundColor: `rgb(98, 76, 240)`,
					data: dataRevenue,
				},
				{
					type: 'bar',
					label: 'Delivery',
					backgroundColor: `rgb(214, 97, 247)`,
					data: dataDelivered,
					borderColor: 'white',
					borderWidth: 2,
				},
			],
		};

		setGraphData(iGraph);

		return () => {};
	}, [totalByMonthOfYear]);

	// useEffect(() => {
	//   let mounted = true;
	//   const fetch = async () => {
	//     if (mounted) {
	//       const srvShop = await serverApi.shopMe();
	//       if (srvShop.status === 'success') {
	//         setShop(srvShop.shop);
	//       }
	//     }
	//   };
	//   fetch();
	//   return () => {
	//     mounted = false;
	//   };
	// }, []);

	useEffect(() => {
		console.log('mounted delivery Orders Statistics for driver');
		let mounted = true;

		const fetch = async () => {
			if (mounted) {
				const queryString = qs.stringify({ qyear: year });
				const statistics = await serverApi.deliveryOrderStatistics(queryString);
				console.log(statistics);
				if (statistics.status === 'success') {
					setTotalByYear(statistics.totalByYear);
					setTotalByMonthOfYear(statistics.totalByMonthOfYear);
					setTotalToday(statistics.totalToday[0]);
					setTotalDayOfMonth(statistics.totalDayOfMonth);
					setTotalCurrentMonth(statistics.totalCurrentMonth[0]);
				}
			}
		};
		fetch();

		return () => {
			mounted = false;
		};
	}, [year]);

	return (
		<div className='px-0 p-4 w-full'>
			<div className='mt-1 mb-3 grid grid-cols-12 sm:gap-10'>
				<div className='col-span-12 sm:col-span-6 md:col-span-4 py-6 sm:pl-5 md:pl-0 lg:pl-5 relative text-center sm:text-left'>
					<div className='flex flex-wrap items-center'>
						<div className='flex items-center w-full sm:w-auto justify-center sm:justify-start mr-auto mb-5 xxl:mb-0'>
							<div className='w-2 h-2  -mt-4'></div>
							<div className=''>
								<div className='relative text-xl xxl:text-2xl font-medium leading-6 xxl:leading-5 '>
									{/* <span className='absolute text-base xxl:text-xl top-0 left-0 xxl:-mt-1.5'>$</span>{' '} */}
									{t('Monthly Revenue')}
								</div>
								{/* <div className='text-gray-600 dark:text-gray-600 mt-2'> Yearly budget </div> */}
							</div>
						</div>
						<select
							onChange={(e: any) => console.log(setYear(e.target.value))}
							className='form-select bg-transparent outline-none border-black border-opacity-10  mx-auto sm:mx-0 py-1 px-3 w-24 -mt-3 focus:outline-none'
						>
							{totalByYear?.map((year: statisticType) => (
								<option key={year._id} value={year._id}>
									{year._id}
								</option>
							))}
						</select>
					</div>

					{/* <Line type={Bar} data={data} options={options} /> */}
					<Line type={Bar} data={graphData} options={options} />

					<div className='flex w-full mt-2'>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Earnings')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6 pl-3 xxl:pl-4  '>
										<span className='absolute text-xl xxl:text-2xl top-0 left-0 -mt-1 xxl:mt-0'>
											$
										</span>
										<NumberFormat
											value={totalByYear[0]?.total}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Deliveries')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6 pl-3 xxl:pl-4'>
										<NumberFormat
											value={totalByYear[0]?.delivered}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='row-start-2 md:row-start-auto col-span-12 md:col-span-4 py-6 border-black border-opacity-10 border-t md:border-t-0 md:border-l md:border-r border-dashed px-10 sm:px-28 md:px-5 -mx-5'>
					<div className='flex flex-wrap items-center'>
						<div className='flex items-center w-full sm:w-auto justify-center sm:justify-start mr-auto mb-5 xxl:mb-0'>
							<div className='w-2 h-2 -mt-4'></div>
							<div className=''>
								<div className='relative text-xl xxl:text-2xl font-medium leading-6 xxl:leading-5 '>
									{/* <span className='absolute text-base xxl:text-xl top-0 left-0 xxl:-mt-1.5'>$</span>{' '} */}
									{t('Current month revenue')}
								</div>
								{/* <div className='text-gray-600 dark:text-gray-600 mt-2'> Yearly budget </div> */}
							</div>
						</div>
					</div>

					{/* <Line type={Bar} data={data} options={options} /> */}
					<Line type={Bar} data={graphDataDayOfMonth} options={options} />

					<div className='flex w-full'>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Earnings')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6 pl-3 xxl:pl-4  '>
										<span className='absolute text-xl xxl:text-2xl top-0 left-0 -mt-1 xxl:mt-0'>
											$
										</span>
										<NumberFormat
											value={totalCurrentMonth?.total}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Deliveries')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6 pl-3 xxl:pl-4'>
										<NumberFormat
											value={totalCurrentMonth?.delivered}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='col-span-12 sm:col-span-6 md:col-span-4 py-6 border-black border-opacity-10 border-t sm:border-t-0 border-l md:border-l-0 border-dashed -ml-4 pl-4 md:ml-0 md:pl-0'>
					<div className='relative text-xl xxl:text-2xl font-medium leading-6 xxl:leading-5 '>
						{t('Daily summary')}
					</div>

					<div className='flex w-full'>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Earnings')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6 pl-3 xxl:pl-4  '>
										<span className='absolute text-xl xxl:text-2xl top-0 left-0 -mt-1 xxl:mt-0'>
											$
										</span>
										<NumberFormat
											value={totalToday?.total}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='flex flex-col w-1/2'>
							<div className='text-base xxl:text-lg justify-center sm:justify-start flex items-center text-gray-700 leading-3 mt-8'>
								{t('Deliveries')}
							</div>
							<div className='xxl:flex mt-5 mb-3'>
								<div className='flex items-center justify-center sm:justify-start'>
									<div className='relative text-2xl xxl:text-3xl font-medium leading-6 pl-3 xxl:pl-4'>
										<NumberFormat
											value={totalToday?.delivered}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

type deliveryOrderItemProp = {
	orderItem: IOrder;
	onAccept: (order: IOrder) => void;
	onOrderComplete: (order: IOrder) => void;
};
const DeliveryOrderItem: React.FC<deliveryOrderItemProp> = ({
	orderItem,
	onAccept,
	onOrderComplete,
}) => {
	const { t } = useTranslation();
	const { auth } = useAppState();

	return (
		<div
			className={`flex text-left  border ${
				orderItem.driver?._id === auth.user._id ? 'bg-yellow-50' : ''
			}  `}
		>
			<ReactTooltip place='top' type='dark' effect='float' />
			<div className='flex w-full '>
				<div className='w-full flex flex-col  '>
					<div className='pb-0'>
						<div className='flex  p-1'>
							<h3 className='flex-1 font-semibold  leading-3'>{orderItem.orderId}</h3>
							<span className='flex text-sm justify-end leading-3 pr-2'>
								{moment.utc(orderItem.updatedAt).format('DD-MM-YY HH:mm:ss')}
							</span>
						</div>

						<div className='flex p-1 mt-2'>
							<h3 className='flex-1 font-semibold  leading-3'>
								{t('Ready for pickup in between')}
							</h3>
							<span className='flex text-sm justify-end leading-3 pr-2'>
								<a href='#tooltip' data-tip={t('When the goods will be ready for collection')}>
									{' '}
									<i className='fas fa-info-circle'></i>{' '}
								</a>
								<span className='ml-1'>
									{orderItem.preparationTime ? orderItem.preparationTime : '-.-'} {t('min')}
								</span>
							</span>
						</div>
						{/* https://www.google.com/maps/dir/?api=1&origin=Space+Needle+Seattle+WA&destination=Pike+Place+Market+Seattle+WA&travelmode=bicycling */}
						<div className='mt-1 p-1'>
							<div className='text-sm pb-2 '>
								<div className='flex space-x-2 items-center'>
									<span className='flex-1 font-bold uppercase'>{t('Pickup')}</span>
									<a
										href={`https://www.google.com/maps/dir/?api=1&origin=&destination=${orderItem.shop.location.address.street}+
                    ${orderItem.shop.location.address.city}`}
										target='_blank'
										rel='noreferrer'
										className='pr-2 bg-gray-100 px-1 flex justify-items-end items-center focus:outline-none hover:bg-gray-200'
									>
										<span>
											<MdDirections size={18} />
										</span>
										<span className='ml-1 text-base'>{t('Direction')}</span>
									</a>
								</div>
							</div>
							<div className='mt-1'>
								<p className='text-sm font-bold'>{orderItem.shop.name}</p>
								<p className='text-sm'>{orderItem.shop.location.address.street}</p>
								<p className='text-sm'>
									{orderItem.shop.location.address.zipCode} {orderItem.shop.location.address.city}
								</p>
								<p className='text-sm inline-flex items-center space-x-1 mt-1'>
									<IoCall className='text-gray-500' />
									<a href={`tel:${orderItem.shop.phone}`}>{orderItem.shop.phone}</a>{' '}
								</p>
							</div>
						</div>
						<hr />
						<div className='mt-1 p-1'>
							<div className='text-sm pb-2'>
								<div className='flex space-x-2 items-center'>
									<span className='flex-1 font-bold uppercase'>{t('Delivery')}</span>
									<a
										href={`https://www.google.com/maps/dir/?api=1&origin=&destination=${orderItem.delivery.address.street}+
                     ${orderItem.delivery.address.city}`}
										target='_blank'
										rel='noreferrer'
										className='pr-2 bg-gray-100 px-1 flex justify-items-end items-center focus:outline-none hover:bg-gray-200'
									>
										<span>
											<MdDirections size={18} />
										</span>
										<span className='ml-1 text-base'>{t('Direction')}</span>
									</a>
								</div>
							</div>
							<div className='flex mt-1'>
								<div className='w-1/2'>
									<p className='text-sm font-bold'>
										{orderItem.client.lastName} {orderItem.client.name}
									</p>
									<p className='text-sm'>{orderItem.delivery.address.street}</p>
									<p className='text-sm'>
										{orderItem.delivery.address.zipCode} {orderItem.delivery.address.city}
									</p>
									<p className='text-sm inline-flex items-center space-x-1 mt-2'>
										<IoCall className='text-gray-500' />
										<a href={`tel:${orderItem.client.mobilePhone}`}>
											{orderItem.client.mobilePhone}
										</a>{' '}
									</p>
								</div>

								<div className='w-1/2'>
									<p className='text-sm text-right w-full pr-2'>
										<NumberFormat
											value={orderItem.total.amount}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
											prefix={`${orderItem.total.currency} `}
										/>
									</p>
									<p className='text-sm text-right w-full pr-2'>
										<a href='#tooltip' data-tip={t('Fixed fees for driver discount')}>
											{' '}
											<i className='fas fa-info-circle ml-1'></i>{' '}
										</a>

										<NumberFormat
											value={-4}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
											prefix={`${orderItem.total.currency} `}
										/>
									</p>
									<p className='text-sm text-right w-full pr-2 font-semibold'>
										<NumberFormat
											value={orderItem.extraFees.deliveryFees}
											thousandSeparator=''
											fixedDecimalScale={true}
											decimalScale={2}
											displayType='text'
											prefix={`${orderItem.total.currency} `}
										/>
									</p>

									{/* <p className='text-red-800 font-bold uppercase text-right mt-2 text-sm pr-2'>
										order status
									</p> */}
								</div>
							</div>
						</div>
						<div className='flex items-center text-white'>
							{orderItem.driver?._id !== auth.user._id ? (
								<button
									onClick={() => onAccept(orderItem)}
									className='w-full btn-black rounded-none font-medium uppercase'
								>
									<span>{t('Accept')} </span>
									<NumberFormat
										className='font-extrabold'
										value={orderItem.total.amount + orderItem.extraFees.deliveryFees}
										thousandSeparator=''
										fixedDecimalScale={true}
										decimalScale={2}
										displayType='text'
										prefix={`${orderItem.total.currency} `}
									/>
								</button>
							) : (
								<button
									onClick={() => onOrderComplete(orderItem)}
									className='w-full btn-black bg-green-600 rounded-none font-medium uppercase'
								>
									<span>{t('Complete')}</span>
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const OpenDeliveryRequest: React.FC = () => {
	const { t } = useTranslation();
	const [orders, setOrders] = useState<IOrder[]>([]);
	// const [shopIsOpen, setShopIsOpen] = useState<boolean>(false);
	const { app } = useAppState();
	const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
	const [orderComplete, setOrderComplete] = useState<IOrder>();

	// const changeData = () => socket.emit('initial_data', app.coords);

	// const getData = (data: any) => {
	// 	console.log(data);
	// 	setOrders(data);
	// };
	// useEffect(() => {
	// 	let mounted = true;
	// 	if (mounted) {
	// 		socket.emit('initial_data', app.coords, auth.user._id);
	// 		socket.on('get_new_data', (data: any) => getData(data));
	// 		socket.on('change_data', changeData);
	// 	}

	// 	return () => {
	// 		socket.off('get_new_data');
	// 		socket.off('change_data');
	// 		mounted = false;
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const fetchingPendingOrder = async () => {
		const data = await serverApi.orderWaitingPickUp(app.coords.latitude, app.coords.longitude);
		if (data.status === 'success') {
			setOrders(data.query);
		}
	};

	useEffect(() => {
		fetchingPendingOrder();
		const interval = setInterval(() => {
			fetchingPendingOrder();
			console.log('a minute passed');
		}, 60000);
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onAcceptHandleClick = async (order: IOrder) => {
		order.driverAcceptOn = new Date();
		order.driverAcceptOn.setUTCHours(
			IsoDateToUtcDate(order.driverAcceptOn.getTimezoneOffset(), order.driverAcceptOn)
		);
		await serverApi.orderUpdate(order._id, order);
		console.log(order);
		fetchingPendingOrder();
	};

	const onOrderCompleteConfirmation = (orderToComplete: IOrder) => {
		setConfirmOpen(true);
		setOrderComplete(orderToComplete);
		fetchingPendingOrder();
	};
	const onCompleteOrderHandleConfirmation = async () => {
		if (orderComplete === undefined) return;
		const deliveryCompleteDate = new Date();
		deliveryCompleteDate.setUTCHours(
			IsoDateToUtcDate(deliveryCompleteDate.getTimezoneOffset(), deliveryCompleteDate)
		);
		const completeOrder = { ...orderComplete, deliveryComplete: deliveryCompleteDate };
		await serverApi.orderUpdate(orderComplete!._id, completeOrder);
		console.log(completeOrder);
		fetchingPendingOrder();
	};

	return (
		<div className='px-0 p-4 w-full'>
			<DialogBox
				title='Order Complete'
				open={confirmOpen}
				onClose={() => setConfirmOpen(false)}
				onConfirm={onCompleteOrderHandleConfirmation}
			>
				{t('This order has ben completed?')}
			</DialogBox>
			<div className='grid gord-cols-1 lg:grid-cols-3 gap-x-2 gap-y-2 mt-2'>
				{orders.map((order: IOrder) => (
					<DeliveryOrderItem
						key={order._id}
						orderItem={order}
						onAccept={onAcceptHandleClick}
						onOrderComplete={onOrderCompleteConfirmation}
					/>
				))}
			</div>
		</div>
	);
};

const DeliveryOrdersManager: React.FC = () => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState<number>(0);

	return (
		<div className='max-w-screen-xl mx-auto w-full  antialiased py-10 px-8 bg-white '>
			<div className='flex w-full'>
				<div className='flex flex-1'>
					<div className='inline-flex justify-center items-center space-x-3'>
						<GiJigsawBox size={28} style={{ color: '#213b73' }} />
						<h1 className='text-xl font-semibold items-center'>{t('Delivery Orders Manager')}</h1>
					</div>
				</div>
			</div>

			<div className='flex items-center font-thin text-gray-700 text-sm border-b mt-5'>
				<div
					onClick={() => setActiveTab(0)}
					className={`font-normal ${
						activeTab === 0 ? 'text-gray-800 bg-gray-200 font-medium' : 'text-gray-600'
					}  py-1 px-2 rounded-sm cursor-pointer hover:bg-gray-200 `}
				>
					{t('Delivery requests')}
				</div>
				<div className='p-1 flex items-center'>
					<div
						onClick={() => setActiveTab(1)}
						className={`font-normal ${
							activeTab === 1 ? 'text-gray-700 bg-gray-200 font-medium' : 'text-gray-600'
						}  py-1 px-2 rounded-sm cursor-pointer hover:bg-gray-200 `}
					>
						<span className='p-0'>{t('Statistics')}</span>
					</div>
				</div>
			</div>

			{activeTab === 0 && <OpenDeliveryRequest />}
			{activeTab === 1 && <DeliveryDriverStatistics />}
		</div>
	);
};

export default DeliveryOrdersManager;
