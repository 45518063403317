import React from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NoCoverImg from '../../../images/no-cover.jpeg';

const ShopCard = (props: any) => {
	const { t } = useTranslation();
	const { shop, distance, url } = props;
	return (
		<div className='col-span-1 items-center'>
			<div className='p-1'>
				<Link
					to={{
						pathname: `${url}/${shop._id}/${distance}`,
						state: { distance: distance },
					}}
					// href={`${url}/${shop._id}`}
					className='block overflow-hidden'
				>
					<div
						className='relative pb-36 overflow-hidden'
						style={{
							backgroundImage: `url(${shop.coverThumb ? shop.coverThumb : NoCoverImg})`,
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
						}}
					>
						{shop.isClosed && (
							<div className='absolute z-30 flex w-full h-full'>
								<div className='relative z-30 py-12 w-full h-full  text-white opacity-100'>
									<h2 className='text-xl text-center font-semibold '>{t('Closed')}</h2>
									<span></span>
								</div>
								<div className='absolute top-0 right-0 flex w-full h-full'>
									<div className='w-full h-full bg-gray-800 bg-opacity-60'></div>
								</div>
							</div>
						)}
						{/* <img
              className=' border-none absolute inset-0 h-full w-full object-cover transition duration-500 ease-in-out transform hover:scale-110'
              src={shop.coverThumb}
              alt={shop.name}
            /> */}
						{shop.deliveryFees > 0 && (
							<div className='absolute bg-green-600 top-4 rounded-r p-1'>
								<h2 className='text-white text-sm font-medium'>
									{t('Owner driver ')}
									<NumberFormat
										value={distance * shop.deliveryFees}
										thousandSeparator=''
										decimalScale={2}
										fixedDecimalScale={true}
										displayType='text'
										prefix='$'
									/>
								</h2>
							</div>
						)}
					</div>
					<div className=''>
						<p className='text-black text-xl font-semibold leading-snug line-clamp-1 '>
							{shop.name}
						</p>

						<div className='flex items-center text-black'>
							<span className='flex w-full text-gray-600 text'>
								{shop.location.address.street}, {shop.location.address.zipCode}{' '}
								{shop.location.address.city}
							</span>
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
};
export default ShopCard;
