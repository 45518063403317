import React from 'react';

type PaginationProps = {
  current?: number;
  total?: number;
  limit?: number;
  mobile?: boolean;
  onPageChange?: (page: number) => void;
};
const Pagination: React.FC<PaginationProps> = ({
  current = 1,
  total = 1,
  limit = 10,
  mobile = false,
  onPageChange,
}) => {
  const setPage = (value: number) => {
    if (value < 1 || value > total || value === current) {
      return;
    }
    if (onPageChange) {
      onPageChange(value);
    }
  };
  const getPages = () => {
    const pages = [];

    const min = 1; //Math.max(1, current - siblings - Math.max(0, siblings - total + current));
    const max = Math.ceil(total / limit || 1); //Math.min(total, min - siblings * 2);
    console.log(min, max, total);
    for (let i = min; i <= max; i += 1) {
      pages.push(i);
    }
    return pages;
  };

  const listLink =
    'text-sm mx-1 px-2 py-2 bg-gray-200 text-gray-700 hover:bg-gray-700 hover:text-gray-200 rounded-sm';

  const pages = getPages().map((page, index) => {
    return (
      <li key={index} className={`${listLink} ${mobile ? 'hidden' : 'block'}`}>
        <button
          onClick={() => setPage(page)}
          className={`focus:outline-none ${page === current ? 'font-bold' : 'font-normal'}`}
        >
          {page}
          {page === current && <span className='sr-only'>(current)</span>}
        </button>
      </li>
    );
  });

  return (
    <ul className='flex justify-center items-center'>
      <li className={listLink}>
        <button
          type='button'
          className=' disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none'
          disabled={current <= 1}
          onClick={() => setPage(current - 1)}
        >
          Precedente
        </button>
      </li>
      {pages}
      <li className={listLink}>
        <button
          type='button'
          className='disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none '
          disabled={current >= total}
          onClick={() => setPage(current + 1)}
        >
          Prossimo
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
