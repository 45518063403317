import { CategoryTypes } from '../types';

export interface CategoryState {
  loading: boolean;
  menu: Array<any>;
}

const initialState: CategoryState = {
  loading: false,
  menu: [],
};

const CategoryReducer = (state = initialState, action: { type: CategoryTypes; payload: any }) => {
  switch (action.type) {
    case CategoryTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CategoryTypes.SET_CATEGORIES:
      return {
        ...state,
        menu: action.payload,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
