import { AuthTypes } from '../types';
import serverApi from '../../api/serverApi';
import { IUser } from '../../Interfaces/dataModel';

export const authenticate = (user: IUser, token: string) => {
  return { type: AuthTypes.AUTHENTICATE, user, token };
};

export const signup = (email: string, password: string) => async (dispatch: any) => {
  // dispatch(authenticate('d', 'd'));
};

export const login = (email: string, password: string) => async (dispatch: any) => {
  console.log('passing to login action');
  const response = await serverApi.login({ email, password });
  console.log(response);
  if (response.status !== 'success') {
    return dispatch({ type: AuthTypes.LOGIN_FAIL });
  }
  const user = response.user;
  const token = response.token;
  dispatch(authenticate(user, token));
};

export const logout = () => async (dispatch: any) => {
  try {
    const response = await serverApi.logout();
    console.log('🔥 ', response);
    if (response.status === 'success') {
      dispatch({ type: AuthTypes.LOG_OUT });
    }
  } catch (e) {
    console.log(e.message);
  }
};

export const updateToken = (user: IUser, token: string) => (dispatch: any) => {
  return dispatch({ type: AuthTypes.AUTHENTICATE, user, token });
};

export const userDataUpdate = (user: IUser) => (dispatch: any) => {
  return dispatch({ type: AuthTypes.UPDATE_USER, user });
};

export const cleanMessage = () => (dispatch: any) => {
  return dispatch({ type: AuthTypes.CLEAR_MESSAGE });
};
