import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';

import { Helmet } from 'react-helmet-async';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ToastContainer, toast } from 'react-toastify';

// geolocation staff
import useWatchLocation from '../hooks/useWatchLocation';
// import useCurrentLocation from '../hooks/useCurrentLocation';

import { useSelector } from 'react-redux';
import { AppState } from '../rdx/rootReducer';
import { useAppState } from '../hooks/adsHooks';

// redux stuff to set reducer
import { useDispatch } from 'react-redux';
// import * as cartActions from '../rdx/actions/cart';
import * as appActions from '../rdx/actions/app.actions';
import * as filterAction from '../rdx/actions/filters.actions';

import serverApi from '../api/serverApi';

// Icon set
import { GiHamburgerMenu } from 'react-icons/gi';
import { RiGlobalLine, RiShoppingBag3Line, RiAccountCircleLine } from 'react-icons/ri';
import { GoPlus } from 'react-icons/go';

const Avatar = lazy(() => import('../UI/Home/Avatar'));
const LoadingButtonSpinner = lazy(() => import('../Components/ButtonSpinnerLoader'));

const geolocationOptions = {
	enableHighAccuracy: true,
	timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
	maximumAge: 1000 * 3600 * 24, // 24 hour
};

/**
 *
 * @param callback
 * @returns
 */
function useOuterClick(callback: any) {
	const innerRef: any = useRef();
	const callbackRef: any = useRef();

	// set current callback in ref, before second useEffect uses it
	useEffect(() => {
		// useEffect wrapper to be safe for concurrent mode
		callbackRef.current = callback;
	});

	useEffect(() => {
		document.addEventListener('click', handleClick);
		return () => document.removeEventListener('click', handleClick);

		// read most recent callback and innerRef dom node from refs
		function handleClick(e: any) {
			if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
				callbackRef.current(e);
			}
		}
	}, []); // no need for callback + innerRef dep

	return innerRef; // return ref; client can omit `useRef`
}

// const LoadingSpinner = () => (
// 	<span className='inline-flex rounded-md shadow-sm'>
// 		<svg
// 			className='animate-spin h-5 w-5 text-gray-700'
// 			xmlns='http://www.w3.org/2000/svg'
// 			fill='none'
// 			viewBox='0 0 24 24'
// 		>
// 			<circle
// 				className='opacity-25'
// 				cx='12'
// 				cy='12'
// 				r='10'
// 				stroke='currentColor'
// 				strokeWidth='4'
// 			></circle>
// 			<path
// 				className='opacity-75'
// 				fill='currentColor'
// 				d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
// 			></path>
// 		</svg>
// 	</span>
// );

function TopHeaderBar(props: any) {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const history = useHistory();
	const { itemCount } = useSelector((state: AppState) => state.cart);
	const { user, authenticate } = useSelector((state: AppState) => state.auth);

	const { app } = useAppState();
	const { lang } = useParams<{ lang: string }>();
	const { category, subcategory } = useParams<{ category: string; subcategory: string }>();
	// console.log('😻 ', params);

	const { t, i18n } = useTranslation();

	// location declaration
	// const { location: currentLocation, error: currentError } = useCurrentLocation(geolocationOptions);
	const { location, cancelLocationWatch } = useWatchLocation(geolocationOptions);

	// current language
	const [language, setLanguage] = useState<string>();

	const [openLang, setOpenLang] = useState<boolean>(false);

	const innerRef = useOuterClick(() => {
		setOpenLang(false);
	});

	useEffect(() => {
		if (category) {
			dispatch(filterAction.setSection({ category, subCategory: subcategory }));
		}
		//note check if is correctly passing here
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// grab location hook
	useEffect(() => {
		if (!location) return;
		const interval = setTimeout(() => {
			cancelLocationWatch();
			// setIsWatchForLocation(false);
			if (app.coords.latitude !== location.latitude) {
				if (location) {
					console.log(location);
					dispatch(appActions.setCoords(location));
				}
			}
		}, 3000);

		return () => clearInterval(interval);
		// Check if error here and store on redux data
	}, [location, cancelLocationWatch, dispatch, app.coords]);

	// Setting the language hook
	useEffect(() => {
		// check if user not digit language params on url
		if (!lang || !i18n.language) {
			// settings a default browser language
			console.log('⛱ ', lang);
			setLanguage(navigator.language.split('-')[0]);
			i18n.changeLanguage(navigator.language.split('-')[0]);
			dispatch(appActions.setLang(navigator.language.split('-')[0]));
		} else {
			console.log(i18n.language, 'active langauge', 'lang variable', lang);
			setLanguage(lang);
			dispatch(appActions.setLang(i18n.language));
		}
		return () => {};
	}, [i18n, lang, dispatch]);

	const resendActivationToken = async () => {
		const res = await serverApi.resendActivationCode();
		if (res.status === 'success') {
			toast.dark(t('Check your inbox or spam. We have sent you the activation link'), {
				position: 'top-center',
			});
		}
	};

	return (
		<React.Fragment>
			<Helmet>
				{!lang ? <html lang={navigator.language.split('-')[0]} /> : <html lang={lang} />}
			</Helmet>

			<div className='pt-16'>
				<ToastContainer
					position='bottom-center'
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss={false}
					draggable={false}
					pauseOnHover={false}
				/>
				<nav className='fixed top-0 left-0 z-40 w-full h-16 tranistion duration-500 ease-in-out bg-white shadow-sm'>
					<div className='relative  z-50 max-w-screen-2xl px-4 mx-auto bg-white shadow-sm  lg:shadow-none lg:bg-transparent'>
						<div className='relative z-50 flex items-center justify-between h-16'>
							<div className='flex items-center px-0  lg:px-0'>
								<a
									href={`/${language}`}
									aria-label='Home'
									className='flex text-lg text-black items-center flex-shring-0 outline-none  '
								>
									<span className='hidden sm:block'>
										{/* <TiRadarOutline size={'2.5em'} color='gray' /> */}
										<img
											className='h-6'
											src={`${process.env.REACT_APP_SERVER_URL_ROOT}/radar.ico`}
											alt='logo'
										/>
									</span>
									{/* <span className='font-bold text-3xl sm:text-4xl font-ubuntu'>Faitu</span>
                  <span className='font-bold font-ubuntu text-base'>Shop</span> */}
									<h1 className='ml-0.5 text-3xl font-bold leading-10 '>
										<span className='font-black'>F</span>aitu
										{pathname.indexOf('/shop') === 0 && (
											<span className='ml-1 font-medium text-base text-blue-500'>Shop</span>
										)}
									</h1>
								</a>
								{/* Header Menu Items */}
								<div className='hidden lg:block lg:ml-6 '>
									<div className='flex'>
										<div className='relative'>
											<button
												ref={innerRef}
												onClick={() => setOpenLang(!openLang)}
												className='flex flex-row uppercase items-center w-full px-2 h-9 py-1 mt-2 text-sm font-semibold text-left bg-transparent rounded-sm md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
											>
												<div className='flex space-x-1 justify-center items-center py-0.5'>
													<span>
														<RiGlobalLine />
													</span>
													<span>{language}</span>
												</div>
											</button>

											{openLang && (
												<div className='absolute right-0 w-20 mt-2 origin-top-right rounded-md shadow-md '>
													<div className=' bg-white rounded-sm shadow'>
														<a
															className='block px-4 py-2 mt-2 text-sm uppercase font-semibold bg-transparent rounded-sm dark-mode:bg-transparent  md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
															href='/en'
														>
															EN
														</a>
														<a
															className='block px-4 py-2 mt-2 text-sm uppercase font-semibold bg-transparent rounded-sm dark-mode:bg-transparent  md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
															href='/it'
														>
															IT
														</a>
														<a
															className='block px-4 py-2 mt-2 text-sm uppercase font-semibold bg-transparent rounded-sm dark-mode:bg-transparent  md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
															href='/fr'
														>
															FR
														</a>
														<a
															className='block px-4 py-2 mt-2 text-sm uppercase font-semibold bg-transparent rounded-sm dark-mode:bg-transparent  md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
															href='/de'
														>
															DE
														</a>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>

							{/* Input box search  */}
							<div className='flex justify-center flex-1 px-2 lg:ml-1 lg:justify-end'>
								<label htmlFor='search' className='sr-only'>
									{t('Search')}
								</label>
								<div className='relative w-full'>
									<div className='absolute inset-y-0 left-0 z-10 flex items-center pl-3 pointer-events-none'>
										<svg fill='currentColor' viewBox='0 0 20 20' className='w-5 h-5 text-gray-700'>
											<path
												fillRule='evenodd'
												d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
												clipRule='evenodd'
											></path>
										</svg>
									</div>
									<span className='w-full'>
										<input
											onChange={(e) => {
												dispatch(filterAction.setTitleFind(e.target.value));
											}}
											onKeyDown={(e) => {
												if (e.code === 'Enter') {
													dispatch(filterAction.executeQuery());
													history.push(`/${language}/classfied/find`);
												}
											}}
											type='search'
											id='search'
											placeholder={t('What are you looking for?')}
											className='w-full h-10 pl-10 px-4  text-gray-900 placeholder-gray-500  border-2 border-gray-100  outline-none bg-gray-100  transition duration-200 hover:ring-2 hover:border-black focus:border-black focus:ring-black focus:placeholder-gray-900 focus:bg-white focus:shadow-xl'
										/>
									</span>
								</div>
							</div>

							{/* humberger button */}
							<div className='flex lg:hidden'>
								{itemCount > 0 && (
									<div className='flex text-base items-center p-0 space-x-1'>
										<button
											onClick={props.onShoppingCartClick}
											className='bg-black rounded py-1 px-1  w-full text-white
                           hover:bg-gray-600 hover:shadow focus:outline-none active:outline-none active:ring-0'
										>
											<div className='flex items-center'>
												<RiShoppingBag3Line size={20} />
												<div className='flex px-1'>{itemCount}</div>
											</div>
										</button>
									</div>
								)}

								<button
									onClick={props.onHaumburgerClick}
									// className='mobile-menu-button outline-none focus:outline-none active:outline-none'
									// style={{ border: '1px solid rgba(27,31,35,.15)' }}
									className='ml-3 sm:p-2 flex items-center rounded bg-gray-50 text-black hover:bg-text-50
                focus:outline-none active:outline-none'
								>
									<GiHamburgerMenu size={25} color='gray' />
								</button>
							</div>

							{/* Right element menu header  shopping cart*/}
							<div className='hidden lg:block'>
								<div className='flex items-center'>
									{itemCount > 0 && (
										<div className='flex text-base items-center p-0 space-x-1'>
											<button
												onClick={props.onShoppingCartClick}
												style={{ border: '1px solid rgba(27,31,35,.15)' }}
												className='ml-3 p-1 pl-3 pr-3 flex items-center rounded bg-black text-white hover:bg-gray-600 focus:outline-none active:outline-none'
											>
												<div className='flex items-center'>
													<RiShoppingBag3Line size={20} />
													<div className='flex px-1'>{itemCount}</div>
												</div>
											</button>
										</div>
									)}

									{/* <div className='flex'>
										<div className='flex  bg-red-800 text-white flex-row uppercase items-center w-full px-4 py-2  text-sm font-semibold text-left bg-transparent  md:w-auto md:inline md:mt-0 md:ml-2  hover:bg-black focus:bg-black focus:outline-none focus:shadow-outline'>
											<a href='/'>
												<div className='flex space-x-1 justify-center items-center py-0.5'>
													<span>
														<GoPlus />
													</span>
													<span>Annuncio</span>
												</div>
											</a>
										</div>
									</div> */}
									<button
										onClick={() => {
											if (!authenticate) {
												props.onLogin();
											} else {
												props.postNewAd();
											}
										}}
										// style={{ border: '1px solid rgba(27,31,35,.15)' }}
										className='ml-3 h-9 p-1 pl-3 pr-3 border border-1 border-gray-200 flex items-center rounded-sm   text-white  focus:outline-none active:outline-none bg-gradient-to-r bg-black hover:bg-gray-700'
									>
										<span>
											<GoPlus />
										</span>
										<span className='font-medium ml-2'>{t('Ad')}</span>
									</button>
									{!authenticate && (
										<button
											onClick={props.onLogin}
											style={{ border: '1px solid rgba(27,31,35,.15)' }}
											className='ml-3 h-9 p-1 pl-3 pr-3 flex items-center rounded-sm bg-gray-50 text-black hover:text-gray-700 focus:outline-none active:outline-none'
										>
											<span>
												<RiAccountCircleLine />
											</span>
											<span className='font-medium ml-2'>{t('Log in')}</span>
										</button>
									)}
									{authenticate && (
										<Suspense fallback={<LoadingButtonSpinner />}>
											<Avatar avatar={user.avatar} onProfileClick={props.onProfileClick} />
										</Suspense>
									)}
									{/* {authenticate && (
										<button
											aria-label='avatar'
											onClick={props.onProfileClick}
											type='button'
											className='block focus:outline-none ml-3'
										>
											<span className='block h-12 w-12 text-justified text-center overflow-hidden  rounded-full border-none  shadow-xl '>
												<img
													className='w-12 h-12 rounded-full border-4 border-gray-300 hover:border-gray-200'
													src={`${process.env.REACT_APP_SERVER_URL_ROOT}/${user.avatar}`}
													alt='avatar'
												/>
											</span>
										</button>
									)} */}
								</div>
							</div>
						</div>
					</div>
				</nav>
			</div>
			{authenticate && !user.active && (
				<div className=' bg-gray-200 h-15  sm:h-10 w-full p-2   '>
					<p className='text-center font-semibold  '>
						{t('Your account has not yet been activated, request the activation email')}{' '}
						<button
							onClick={resendActivationToken}
							className='text-blue-800 focus:outline-none outline-none'
						>
							{t('here')}
						</button>{' '}
					</p>
				</div>
			)}
		</React.Fragment>
	);
}

export default TopHeaderBar;
