import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const SectionDeskTopItem = (props: any) => {
	const { t } = useTranslation();
	const { image, title, url, sectionId, onClick } = props;
	return (
		<Link
			style={{ backgroundColor: 'rgb(249 246 243)' }}
			to={{
				pathname: url,
				search: '?section=' + sectionId,
			}}
			// href={url}
			onClick={() => onClick(title)}
			className='min-h-catitem flex   border-2 border-white  hover:border-2 hover:border-black cursor-pointer '
		>
			<div className='flex w-full'>
				<div className='w-full flex flex-col p-2 '>
					<div className='mx-2 pb-0'>
						<h3 className='font-semibold leading-snug w-1/2'>{t(title)}</h3>
					</div>
				</div>
				{image && (
					<img className=' h-16 w-16 object-left-top object-cover' src={image} alt={title} />
				)}
			</div>
		</Link>
	);
};

export const SectionMobileItem = (props: any) => {
	const { t } = useTranslation();
	const { image, url, title, sectionId, onClick } = props;
	return (
		<Link
			to={{
				pathname: url,
				search: '?section=' + sectionId,
			}}
			onClick={() => onClick(title)}
			className='block focus:outline-none px-1'
		>
			<span className='block h-20 w-20  overflow-hidden rounded-xl shadow-xl text-black border-4 border-black'>
				{image && <img className='w-20 h-20  object-cover bg-black' src={image} alt={title} />}
			</span>
			<span className='font-medium text-sm  leading-3 line-clamp-2 mt-1 pb-2'>{t(title)}</span>
		</Link>
	);
};
